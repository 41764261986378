import styled, { css } from 'styled-components';

type Props = {
  $size: string;
  $color?: string;
  $strokeColor?: string;
};

export const IconWrap = styled.div<Props>`
  display: flex;
  svg {
    width: ${(props) => props.$size};
    height: ${(props) => props.$size};

    path {
      ${(props) =>
        props.$color
          ? css`
              fill: ${props.$color};
            `
          : ''};
      stroke: ${(props) => props.$strokeColor};
    }
  }
`;
