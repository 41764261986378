import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_NOTIFICATIONS_COUNT } from 'api/notifications/notifications.query';
import { Maybe } from 'utils/types';

interface UserNotificationsCount {
  conversationNotificationCount: Maybe<number>;
}

export const useUserNotificationsCount = (): UserNotificationsCount => {
  const { data } = useQuery(GET_CURRENT_USER_NOTIFICATIONS_COUNT, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  });

  return {
    conversationNotificationCount: data?.currentUserNotificationsCount?.conversation_notification_count,
  };
};
