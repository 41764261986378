import { ReactComponent as InformationIcon } from 'assets/vectors/fi-rr-information.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button/Button';
import CircularProgressBar from 'components/ui/CircularProgressBar/CircularProgressBar';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import {
  CircularProgressContainer,
  EmptyGoalCircleLabel,
  EmptyGoalCircleLabelText,
  GoalSection,
  GoalText,
  GoodJobText,
  HalfContainer,
} from '../Activity.styles';

interface ActivityGoalSectionProps {
  stepsGoal?: number;
  percentage?: number;
  shouldShowPercentage: boolean;
  onShowInfo: () => void;
}

const GOOD_JOB_PERCENTAGE = 50;

export const ActivityGoalSection: React.FC<ActivityGoalSectionProps> = ({
  stepsGoal,
  percentage,
  shouldShowPercentage,
  onShowInfo,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.activity' });

  const Information = (
    <span>
      <Button variant="icon" aria-label={t('showInformation')} onClick={onShowInfo}>
        <Icon element={InformationIcon} color="black.600" size={16} aria-hidden="true" />
      </Button>
    </span>
  );

  const shouldShowProgress = shouldShowPercentage && !!stepsGoal;

  return (
    <>
      <GoalSection>
        <CircularProgressContainer>
          <CircularProgressBar
            progress={shouldShowProgress ? percentage : undefined}
            showCompletionText
            size={121}
            startGradientColor="other.greenSea"
            endGradientColor="other.greenDark"
            label={!shouldShowProgress ? '-' : undefined}
            footerLabel={
              !shouldShowPercentage ? (
                <EmptyGoalCircleLabel>
                  <EmptyGoalCircleLabelText>{t('atGoal')}</EmptyGoalCircleLabelText>
                  {Information}
                </EmptyGoalCircleLabel>
              ) : undefined
            }
          />
        </CircularProgressContainer>
        <HalfContainer>
          <GoalText>
            <Typography.Span variant="SubHeadline1Medium" color="black.800">
              {t('goal')}
            </Typography.Span>
            <Typography.Span variant="SubHeadline1" color="black.800">
              <span style={{ fontWeight: 600, fontSize: 24 }}>
                {stepsGoal ? stepsGoal.toLocaleString('en-US') : '-'}
              </span>{' '}
              {t('steps')}
            </Typography.Span>
          </GoalText>
        </HalfContainer>
      </GoalSection>
      {!!percentage && percentage >= GOOD_JOB_PERCENTAGE && (
        <GoodJobText variant="Body1" color="other.green">
          {t('greatJob')}
        </GoodJobText>
      )}
    </>
  );
};

export default ActivityGoalSection;
