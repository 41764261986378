import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as Info } from 'assets/vectors/fi-rr-information.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { PopupModal } from 'components/ui/PopupModal/PopupModal';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './DownloadZoomModal.styles';

type Props = {
  onClose: () => void;
  onDownloadClick: () => void;
  isOpen: boolean;
};

export const DownloadZoomModal: React.FC<Props> = ({ onClose, onDownloadClick, isOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.downloadZoom' });
  return (
    <PopupModal isOpen={isOpen} onRequestClose={onClose}>
      <Styled.Content>
        <Styled.SIcon element={CloseIcon} size={16} onClick={onClose} tabIndex={0} aria-label="Close modal" />
        <Typography color="black.800" variant="Headline2">
          {t('downloadTitle')}
        </Typography>
        <Icon element={Info} size={108} />
        <Typography color="black.800">{t('downloadDesc')}</Typography>
        <Styled.ButtonNav>
          <Button size="md" variant="secondary-transparent" onClick={onClose}>
            {t('close')}
          </Button>
          <Button size="md" onClick={onDownloadClick}>
            {t('download')}
          </Button>
        </Styled.ButtonNav>
      </Styled.Content>
    </PopupModal>
  );
};
