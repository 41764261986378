import { useMutation, useQuery } from '@apollo/client';
import { GET_FORMANSWERGROUP_BY_NAME, UPDATE_FORMANSWER_GROUP } from 'api/notifications/notifications.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import generateHTMLFromJSON from 'utils/generateHTMLFromJson';
import { Mixpanel } from 'utils/mixpanel';
import { DaysOfWeek, MotivUserData } from 'utils/types';

import LeftLabelCheckbox from 'components/form/Checkbox/LeftLabelCheckbox/LeftLabelCheckbox';
import LeftLabelSwitch from 'components/form/Switch/LeftLabelSwitch';
import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';

import { Container, NotificationContainer } from './Notifications.styles';

interface ReminderDays {
  name: DaysOfWeek;
}

export const Notification: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useQuery(GET_CURRENT_USER_DATA, { fetchPolicy: 'network-only' });
  const { data: motiv_user_form, loading: userDataLoading } = useQuery(GET_FORMANSWERGROUP_BY_NAME, {
    variables: {
      userId: data?.currentUser?.id,
      name: 'Motiv user data',
    },
    skip: isEmpty(data?.currentUser?.id),
    fetchPolicy: 'network-only',
  });
  const [updateFormAnswer, { loading: updateLoading }] = useMutation(UPDATE_FORMANSWER_GROUP);

  const motivUserDataForm = motiv_user_form?.formAnswerGroups?.at(0);

  const jsonFormAnswer = motivUserDataForm?.form_answers.find((form) => form.label === 'json_data') || null;

  const prettyJsonFormAnswer =
    motivUserDataForm?.form_answers.find((form) => form.label === 'pretty_json_data') || null;

  const parsedJSON: MotivUserData =
    jsonFormAnswer && JSON.parse(jsonFormAnswer?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '{}');

  const [currentUpdating, setCurrentUpdating] = useState('');

  const reminderDays: ReminderDays[] = [
    {
      name: DaysOfWeek.Monday,
    },
    {
      name: DaysOfWeek.Wednesday,
    },
    {
      name: DaysOfWeek.Friday,
    },
  ];

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - Notifications' });
  }, []);

  const updateFormAnswerGroup = async () => {
    try {
      const prettyJson = generateHTMLFromJSON(parsedJSON);
      await updateFormAnswer({
        variables: {
          input: {
            id: motivUserDataForm?.id,
            form_answers: [
              {
                answer: `<p style="display:none;">${JSON.stringify(parsedJSON)}</p>`,
                custom_module_id: jsonFormAnswer?.custom_module?.id,
                user_id: data?.currentUser?.id,
              },
              {
                answer: prettyJson,
                custom_module_id: prettyJsonFormAnswer?.custom_module?.id,
                user_id: data?.currentUser?.id,
              },
            ],
          },
        },
        refetchQueries: [GET_FORMANSWERGROUP_BY_NAME],
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      console.error('Failed update user form..', (error as Error).stack);
    }
  };

  const onChange = async (day: DaysOfWeek) => {
    setCurrentUpdating(day);
    if (parsedJSON) {
      if (parsedJSON.reminderDays.includes(day)) {
        // Day exists, remove it
        parsedJSON.reminderDays = parsedJSON.reminderDays.filter((d) => d !== day);
      } else {
        // Day doesn't exist, add it
        parsedJSON.reminderDays.push(day);
      }
      // Return the updated parsedJSON
      await updateFormAnswerGroup();
    }
  };

  const onToggleChange = async (type: 'email' | 'text') => {
    setCurrentUpdating('');
    if (parsedJSON) {
      const currentValue = parsedJSON[`${type}Reminders`];
      if (currentValue === undefined || currentValue === null) {
        parsedJSON[`${type}Reminders`] = false;
      } else {
        parsedJSON[`${type}Reminders`] = !currentValue;
      }
      await updateFormAnswerGroup();
    }
  };

  return (
    <BaseLayout
      headerProps={{ title: 'Notifications', variant: 'back', onBackClick: () => navigate(paths.settings.main) }}
      footer={null}
    >
      <NotificationContainer>
        <LeftLabelSwitch
          labelProps={{
            style: {
              fontSize: 'large',
            },
          }}
          loading={userDataLoading}
          label="Appointment reminder EMAIL messages"
          checked={parsedJSON?.emailReminders ?? true}
          onChange={() => onToggleChange('email')}
        />
      </NotificationContainer>
      <NotificationContainer>
        <LeftLabelSwitch
          labelProps={{
            style: {
              fontSize: 'large',
            },
          }}
          loading={userDataLoading}
          label="Appointment reminder TEXT messages"
          checked={parsedJSON?.textReminders ?? true}
          onChange={() => onToggleChange('text')}
        />
      </NotificationContainer>
      <Container> Motiv engagement TEXT reminders sent on: </Container>
      {reminderDays.map((day) => (
        <NotificationContainer key={day.name}>
          <LeftLabelCheckbox
            labelProps={{
              style: {
                paddingLeft: '1.25rem',
              },
            }}
            loading={updateLoading && currentUpdating == day.name}
            label={`${day.name.charAt(0).toUpperCase()}${day.name.slice(1)}`}
            checked={parsedJSON?.reminderDays?.includes(day.name)}
            onChange={() => onChange(day.name)}
          />
        </NotificationContainer>
      ))}
    </BaseLayout>
  );
};

export default Notification;
