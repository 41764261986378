import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.theme.color.other.linen};
  height: 100vh;
  max-height: 100%;
`;

export const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const NavigationContainer = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`;

export const StyledButton = styled(Button)`
  width: auto;
`;
