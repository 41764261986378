import { parse } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { Maybe } from 'utils/types';

export const DATE_TIME_FORMAT = `EEEE MMM, dd 'at' h:mmaaa`;
export const DATE_FORMAT = 'yyyy-MM-dd';

// in ms
export const DEFAULT_POLLING_INTERVAL = 10000;

export const parseHealthieDate = (date: string, format = 'yyyy-MM-dd HH:mm:ss xx'): Date => {
  return parse(date, format, new Date());
};

export const capitalizeWords = (str: Maybe<string>): string | undefined => {
  return str ? str.split(' ').map(capitalize).join(' ') : undefined;
};

export type NotEmpty<T> = T extends null | undefined ? never : T;

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

export const onEnterKey = <T>(event: React.KeyboardEvent<T>, fn: () => void, onlyEnter = false): void => {
  if (event.key === 'Enter' || (!onlyEnter && event.key === ' ')) {
    fn();
  }
};
