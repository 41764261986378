import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 60px;
`;

export const ProgressText = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 8px;
  text-align: center;
`;

export const AfterAppointmentText = styled(Typography)`
  text-align: center;
`;
