import { format } from 'date-fns';

import { DateRange } from '../activity/types';
import { DateOption } from './types';

export const dateOptionsTransform = (weeklyDateRanges: DateRange[]): DateOption[] => {
  const dateFormat = 'MM.dd.yyyy';
  return weeklyDateRanges.map((item, index: number) => ({
    ...item,
    label: `${format(item.start, dateFormat)}-${format(item.end, dateFormat)}`,
    value: index + 1,
  }));
};
