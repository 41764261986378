import carousel1Desktop from 'assets/images/onboarding/desktop/welcome-carousel-1-desktop.png';
import carousel2Desktop from 'assets/images/onboarding/desktop/welcome-carousel-2-desktop.png';
import carousel3Desktop from 'assets/images/onboarding/desktop/welcome-carousel-3-desktop.png';
import carousel4Desktop from 'assets/images/onboarding/desktop/welcome-carousel-4-desktop.png';
import carousel1Mobile from 'assets/images/onboarding/mobile/welcome-carousel-1-mobile.png';
import carousel2Mobile from 'assets/images/onboarding/mobile/welcome-carousel-2-mobile.png';
import carousel3Mobile from 'assets/images/onboarding/mobile/welcome-carousel-3-mobile.png';
import carousel4Mobile from 'assets/images/onboarding/mobile/welcome-carousel-4-mobile.png';
import carousel1Tablet from 'assets/images/onboarding/tablet/welcome-carousel-1-tablet.png';
import carousel2Tablet from 'assets/images/onboarding/tablet/welcome-carousel-2-tablet.png';
import carousel3Tablet from 'assets/images/onboarding/tablet/welcome-carousel-3-tablet.png';
import carousel4Tablet from 'assets/images/onboarding/tablet/welcome-carousel-4-tablet.png';
import React, { useEffect, useState } from 'react';
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';

import { Button } from 'components/ui/Button/Button';
import { Carousel } from 'components/ui/Carousel/Carousel';

import { ButtonsWrapper, Wrapper } from './Welcome.styles';

export const Welcome: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.welcome' });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const signUpToken = searchParams.get('s');

  const [images, setImages] = useState<string[]>([]);

  const redirectionPath = signUpToken === null ? paths.logInPage : `${paths.setupPassword}?s=${signUpToken}`;

  useEffect(() => {
    if (isDesktop) {
      setImages([carousel1Desktop, carousel2Desktop, carousel3Desktop, carousel4Desktop]);
    }

    if (isTablet) {
      setImages([carousel1Tablet, carousel2Tablet, carousel3Tablet, carousel4Tablet]);
    }

    if (isMobileOnly) {
      setImages([carousel1Mobile, carousel2Mobile, carousel3Mobile, carousel4Mobile]);
    }
  }, []);

  const slides = [
    {
      image: images[0],
      header: t('slide1Header'),
      content: t('slide1Desc'),
    },
    {
      image: images[1],
      header: t('slide2Header'),
      content: t('slide2Desc'),
    },
    {
      image: images[2],
      header: t('slide3Header'),
      content: t('slide3Desc'),
    },
    {
      image: images[3],
      header: t('slide4Header'),
      content: t('slide4Desc'),
    },
  ];

  return (
    <Wrapper>
      {images.length === 4 && (
        <Carousel
          slides={slides}
          bottomSection={
            <ButtonsWrapper>
              <Button data-testid="get-started-button" variant="primary" onClick={() => navigate(redirectionPath)}>
                {t('getStarted')}
              </Button>

              <Button data-testid="log-in-button" variant="secondary" onClick={() => navigate(redirectionPath)}>
                {t('logIn')}
              </Button>
            </ButtonsWrapper>
          }
        />
      )}
    </Wrapper>
  );
};
