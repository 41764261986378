import { gql } from '__generated__/gql';

export const CREATE_ENTRY = gql(`
  mutation createEntry($category: String, $type: String, $metric_stat: String, $user_id: String, $created_at: String )  {
    createEntry (input:{
      category: $category,
      type: $type,
      metric_stat: $metric_stat,
      user_id: $user_id,
      created_at: $created_at,
    })
    {
      entry {
        id
      }
      messages
      {
        field
        message
      }
    }
  }
`);
