import { gql } from '__generated__';

export const GET_CARE_PLAN = gql(`
  query getCarePlan($date: String!) {
    currentUser {
      id
      first_name
      active_care_plan {
        id
        name
        description
        created_at
        documents {
          id
          display_name
        }
        goals {
          id
          name
          description
          start_on
          due_date
          repeat
          created_at
          cursor
          goal_histories{
            id
            created_at
            completed_on
            cursor
          }
          is_completed_for_date(date: $date)
          subgoals{
            is_completed_for_date(date: $date)
            id
            name
            start_on
            created_at
            cursor
            goal_histories{
              id
              created_at
              completed_on
              cursor
            }
            is_completed_for_date(date: $date)
          }
        }
      }
    }
  }
`);
