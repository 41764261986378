import { gql } from '__generated__/gql';

export const GET_CURRENT_USER_NOTIFICATIONS_COUNT = gql(`
    query currentUserNotificationsCount {
        currentUserNotificationsCount {
            id
            conversation_notification_count
        }
    }
`);

export const GET_FORMANSWERGROUP_BY_NAME = gql(`
  query FormAnswerGroups($userId: String, $name: String) {
    formAnswerGroups(user_id: $userId, name: $name) {
      id
      name
      form_answers {
        custom_module {
          id
        }
        id
        label
        answer
      }
    }
  }
`);

export const UPDATE_FORMANSWER_GROUP = gql(`
  mutation UpdateFormAnswerGroup($input: updateFormAnswerGroupInput) {
    updateFormAnswerGroup(input: $input) {
      form_answer_group {
        id
      }
    }
  }
`);
