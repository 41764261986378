import React from 'react';

import { DisabledRouteWhenAuthenticated } from './DisabledRouteWhenAuthenticated';
import { ProtectedRoute } from './ProtectedRoute';

export const generateDisabledRoute: React.FC<JSX.Element> = (children) => (
  <DisabledRouteWhenAuthenticated>{children}</DisabledRouteWhenAuthenticated>
);

export const generateProtectedRoute: React.FC<JSX.Element> = (children) => <ProtectedRoute>{children}</ProtectedRoute>;
