import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Section = styled(Typography.Div)`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.black[50]};
  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.color.black[50]};
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const ButtonContainer = styled.div`
  padding: 48px 16px;
`;

export const SIcon = styled(Icon)`
  align-self: center;
  margin-top: auto;
  svg {
    width: 70px;
    height: 40px;
  }
  position: fixed;
  bottom: calc(${(props) => props.theme.nav.height} + 36px);
`;
