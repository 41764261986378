import { useQuery } from '@apollo/client';
import { GET_GOALS } from 'api/goal/goal.query';
import { ReactComponent as Medication } from 'assets/vectors/fi-rr-medication.svg';
import { countBy, entries, flow, head, isEmpty, last, maxBy, meanBy, partialRight } from 'lodash';
import { useGetMappedMedications } from 'pages/progress/medications/hooks';
import { filterGoalsByDateRange, sortByDateDesc } from 'pages/progress/medications/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

import { CardsContainer, CardsContainerCenter, FlexLayout, PlanInfoContainer } from '../WeeklyReport.styles';
import { TilesProps } from '../types';
import { SingleRow } from './SingleRow';

export const medicationExpectedGoal = 100;

export const MedicationsTile: React.FC<TilesProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.weeklyReport' });

  const { startDate, endDate } = props;

  const { data, loading } = useQuery(GET_GOALS, {
    variables: {
      keywords: 'medications',
      start_range: startDate,
      end_range: endDate,
    },
  });

  const filteredMedicationsAnswers = useGetMappedMedications(data);
  const sortedAnswers = useMemo(() => sortByDateDesc(filteredMedicationsAnswers), [filteredMedicationsAnswers]);

  const filteredAnswers = useMemo(() => {
    return filterGoalsByDateRange(sortedAnswers, { startRange: startDate, endRange: endDate });
  }, [sortedAnswers, startDate, endDate]);

  const avgPercentage = !isEmpty(filteredAnswers) ? meanBy(filteredAnswers, 'percentageCompleted') : 0;

  const mostOccuringAnswer = flow(
    partialRight(countBy, 'name'),
    entries,
    partialRight(maxBy, last),
    head,
  )(filteredAnswers);

  if (loading) {
    return (
      <CardsContainerCenter>
        <Spinner size={24} />
      </CardsContainerCenter>
    );
  }
  return (
    <>
      <CardsContainer>
        <PlanInfoContainer>
          <Icon element={Medication} size={18} aria-hidden="true" />
          <Typography color="black.800" variant="Body1">
            {t('medication.title')}
          </Typography>
        </PlanInfoContainer>

        <FlexLayout>
          <div>
            <SingleRow
              title={t('medication.average')}
              value={t('medication.averageValue', { value: Math.floor(avgPercentage) })}
              valueProps={{
                color: 'blue.600',
              }}
              label={t('medication.taken')}
            />
          </div>
          <div style={{ textAlign: 'end' }}>
            <SingleRow
              title={t('goal')}
              value={t('medication.goalValue', { value: medicationExpectedGoal })}
              label={t('medication.taken')}
            />
          </div>
        </FlexLayout>

        <div style={{ marginTop: 16 }}>
          <SingleRow
            title={t('medication.mostUsedAnswer')}
            value={mostOccuringAnswer ?? t('notAvailable')}
            label={''}
          />
        </div>
      </CardsContainer>
    </>
  );
};
