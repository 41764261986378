import React from 'react';
import { Maybe } from 'utils/types';

import { Input } from 'components/form/Input/Input';
import { Radio } from 'components/form/Radio/Radio';
import { Tab } from 'components/form/Tab/Tab';

import { Typography } from '../Typography/Typography';
import { HorizontalContainer, QuestionContainer, QuestionDesc } from './Question.styles';

export type QuestionOption = {
  value?: string;
  name: string;
  label?: string;
};

export type QuestionProps = {
  index?: number;
  title?: Maybe<string>;
  description?: string;
  type?: 'singleselect' | 'multiselect' | 'text' | 'date' | 'number' | 'textarea' | 'horizontal';
  options?: Maybe<QuestionOption>[];
};

export const parseQuestionTitle = (title: Maybe<string>, index?: number): string | null =>
  title ? (isFinite(parseInt(title[0])) || !index ? title : `${index}. ${title}`) : null;

export const Question: React.FC<QuestionProps> = ({ title, description, type = 'text', options, index }) => {
  let content;
  if (type === 'singleselect') {
    content = options?.map((option, index) => (option ? <Tab key={index} type="radio" {...option} /> : null));
  } else if (type === 'multiselect') {
    content = options?.map((option, index) => (option ? <Tab key={index} type="checkbox" {...option} /> : null));
  } else if (type === 'horizontal') {
    content = (
      <HorizontalContainer>
        {options?.map((option, index) => (option ? <Radio key={index} {...option} type="vertical" /> : null))}
      </HorizontalContainer>
    );
  } else {
    content = options?.map((option, index) => (option ? <Input key={index} type={type} {...option} /> : null));
  }
  const questionTitle = parseQuestionTitle(title, index);
  return (
    <QuestionContainer>
      {questionTitle && (
        <Typography weight={500} size={20} lineHeight={28} color="black.800" tabIndex={0}>
          {questionTitle}
        </Typography>
      )}
      {description && (
        <QuestionDesc variant="Helper1" tabIndex={0}>
          {description}
        </QuestionDesc>
      )}
      {content}
    </QuestionContainer>
  );
};
