import { useVisualViewport } from 'hooks/useVisualViewport';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const Viewport: React.FC<Props> = ({ className, children }: Props) => {
  const { visualViewportHeight: vh } = useVisualViewport();
  return (
    <section className={className} style={{ height: vh ? `${vh}px` : '100%' }}>
      {children}
    </section>
  );
};
