import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.color.other.linen};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const ContentHeader = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 30px;
`;

export const CardDescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
  justify-content: center;
`;

export const WeightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
`;

export const WeightInput = styled.input<{ $width: number }>`
  background: transparent;
  color: ${({ theme }) => theme.color.other.violet};
  width: ${(props) => `${props.$width}px`};
  display: block;
  min-width: 60px;
  max-width: 300px;
  box-sizing: border-box;
  border: none;
  font-size: 64px;
  text-align: center;
  margin-right: 2px;
  &:focus::placeholder {
    color: transparent;
  }
`;

export const WeightInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 16px;
`;

export const WeightInstruction = styled(Typography)`
  text-align: center;
  margin: 16px 95px;
`;
