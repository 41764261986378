import { useQuery } from '@apollo/client';
import { Goal } from '__generated__/graphql';
import { GET_CARE_PLAN } from 'api/progress/carePlan.query';
import { format } from 'date-fns';
import { useAppointmentsData } from 'pages/appointments/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_POLLING_INTERVAL } from 'utils/helpers';
import { isGoalNameInMapping } from 'utils/helpers/goals';

interface CarePlanGoals {
  refetch: () => void;
  weeklyGoals: Goal[];
  dailyGoals: Goal[];
  loading: boolean;
}

const coachCheckInFakeGoal = (t: (item: string) => string): Goal => ({
  __typename: 'Goal',
  name: t('coachCheckIn'),
  goal_histories: [],
  id: `${Date.now()}`,
  created_at: '',
  start_on: '',
  cursor: null,
});

export function filterGoalsCurrentWeek(goals: Goal[]): Goal[] {
  // Get the current date with time set to midnight
  const currentDate: Date = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const filteredGoals: Goal[] = goals.filter((goal) => {
    // Get the start date with time set to midnight
    const startOnDate: Date = new Date(goal.start_on.replace(/-/g, '/'));
    startOnDate.setHours(0, 0, 0, 0);

    // Get the due date with time set to midnight, or null if it doesn't exist
    const dueDate: Date | null = goal.due_date ? new Date(goal.due_date.replace(/-/g, '/')) : null;
    if (dueDate) {
      dueDate.setHours(0, 0, 0, 0);
    }

    // Filtering condition: Start date not in the future and due date is not passed
    return startOnDate <= currentDate && (dueDate === null || currentDate <= dueDate);
  });

  return filteredGoals;
}

export const useCarePlanGoals = (): CarePlanGoals => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home' });
  const {
    data,
    refetch,
    loading: carePlanLoading,
  } = useQuery(GET_CARE_PLAN, {
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: { date: format(Date.now(), 'yyyy-MM-dd') },
  });

  const { upcomingAppointments, pastAppointments, pastAppointmentsLoading, upcomingAppointmentsLoading } =
    useAppointmentsData();

  const [weeklyGoals, dailyGoals] = React.useMemo(() => {
    let goals = data?.currentUser?.active_care_plan?.goals ?? [];
    goals = goals.map((goal) => {
      if (goal && goal.name && goal.name.includes('Blood')) {
        return { ...goal, name: goal.name.replace(/\d+$/, '') };
      }
      if (
        (goal && goal.name && goal.name.includes('Personal')) ||
        (goal && goal.name && goal.name.includes('Moving')) ||
        (goal && goal.name && goal.name.includes('Exercise')) ||
        (goal && goal.name && goal.name.includes('Medications'))
      ) {
        // If the goal name contains "Personal," and it's not null or undefined, create a new object with the modified name
        return { ...goal, name: goal.name.replace(/\d/g, '') };
      }
      return goal;
    });
    goals = filterGoalsCurrentWeek(goals);
    const supportedGoals = goals.filter((goal) => isGoalNameInMapping(goal?.name));
    const weeklyGoals = supportedGoals.filter((goal) => goal.repeat === 'Weekly' || goal.repeat === 'Once') as Goal[];

    let maxLessonsNum = 0;
    let maxLessonsGoal: Goal | undefined;
    const otherWeeklyGoals: Goal[] = [];

    weeklyGoals.map((goal) => {
      if (goal.name?.includes('Lessons')) {
        const num = parseInt(goal.name.match(/\d+/)?.[0] ?? '0');
        if (num > maxLessonsNum) {
          maxLessonsNum = num;
          maxLessonsGoal = goal;
        }
      } else {
        otherWeeklyGoals.push(goal);
      }
    });

    const filteredWeekly = [maxLessonsGoal, ...otherWeeklyGoals].filter(
      (goal) => goal !== undefined && goal !== null,
    ) as Goal[];

    const dailyGoals = supportedGoals.filter((goal) => goal.repeat === 'Daily') as Goal[];

    // Add coach check in if necessary
    if (upcomingAppointments.length === 0 && pastAppointments.length === 0) {
      dailyGoals.push(coachCheckInFakeGoal(t));
    }

    return [filteredWeekly, dailyGoals];
  }, [data?.currentUser?.active_care_plan?.goals, upcomingAppointments, pastAppointments, t]);

  return {
    weeklyGoals,
    dailyGoals,
    refetch,
    loading: carePlanLoading || pastAppointmentsLoading || upcomingAppointmentsLoading,
  };
};
