import { useEffect, useState } from 'react';

interface UseVisualViewportType {
  visualViewportWidth?: number;
  visualViewportHeight?: number;
}

export const useVisualViewport = (): UseVisualViewportType => {
  const [visualViewportWidth, setVisualViewportWidth] = useState<number>();
  const [visualViewportHeight, setVisualViewportHeight] = useState<number>();

  const handleResizeEvent = (event: Event) => {
    const { width, height } = event.currentTarget as VisualViewport;
    setVisualViewportWidth(width);
    setVisualViewportHeight(height);
  };

  useEffect(() => {
    setVisualViewportWidth(window.innerWidth);
    setVisualViewportHeight(window.innerHeight);
    window.visualViewport?.addEventListener('resize', handleResizeEvent);

    return () => window.removeEventListener('resize', handleResizeEvent);
  }, []);

  return { visualViewportWidth, visualViewportHeight };
};
