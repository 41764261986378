import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.color.other.linen};
  border-radius: 16px;
  padding: 22.5px;
  padding-bottom: 40px;
  padding-top: 21px;
  margin: 16px;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  overflow: auto;
`;

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.color.other.modalBackground};
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 21px;
`;
