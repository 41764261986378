import { gql } from '__generated__';

export const GET_CARE_PLAN_DOCUMENTS = gql(`
query getCarePlanDocuments {
  currentUser {
    active_care_plan {
      documents {
        id
        display_name
        expiring_url
      }
    }
  }
}
`);
