import { theme } from 'assets/styles/theme';
import get from 'lodash/get';
import { FlattenObjectKeys } from 'models/utils.model';
import React from 'react';

import { CenteredSpinner, LoaderContainer } from './Spinner.styles';

export interface SpinnerProps {
  size?: number;
  color?: FlattenObjectKeys<typeof theme.color>;
}

export const Spinner: React.FC<SpinnerProps> = ({ size = 40, color = 'blue.700' }: SpinnerProps) => {
  const iconColor = get(theme.color, color) || theme.color.blue[700];

  return (
    <CenteredSpinner>
      <LoaderContainer size={size} color={iconColor}>
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.75 50V54.25H9.25V50H0.75ZM50 90.75C47.6528 90.75 45.75 92.6528 45.75 95C45.75 97.3472 47.6528 99.25 50 99.25V90.75ZM9.25 50C9.25 27.4947 27.4947 9.25 50 9.25V0.75C22.8003 0.75 0.75 22.8003 0.75 50H9.25ZM50 9.25C72.5053 9.25 90.75 27.4947 90.75 50H99.25C99.25 22.8003 77.1997 0.75 50 0.75V9.25ZM90.75 50C90.75 72.5053 72.5053 90.75 50 90.75V99.25C77.1997 99.25 99.25 77.1997 99.25 50H90.75Z"
            fill="#245296"
          />
        </svg>
      </LoaderContainer>
    </CenteredSpinner>
  );
};
