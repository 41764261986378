import { ReactComponent as Step_4 } from 'assets/images/BP-manual/BP-step-4.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from '../../BloodPressureManual.styles';

export const Step4: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressureManual.step4' });

  return (
    <Styled.StepContainer>
      <Styled.ImageContainer isDark>
        <Step_4 />
      </Styled.ImageContainer>
      <Styled.DescriptionContainer>
        <Styled.Description>{t('description')}</Styled.Description>
      </Styled.DescriptionContainer>
    </Styled.StepContainer>
  );
};
