import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_INFO } from 'api/user/user.mutation';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { isValid } from 'date-fns';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { capitalizeWords } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';
import { dateRegex } from 'utils/validators/patterns';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { Header } from 'components/layout/BaseLayout/Header';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from '../General.styles';

type Fields = 'firstName' | 'lastName' | 'birthDate';

const MAX_FIELD_LENGTH = 30;

export const PersonalInfo: React.FC = () => {
  const [editingField, setEditingField] = useState<Fields | null>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });
  const navigate = useNavigate();
  const { data } = useQuery(GET_CURRENT_USER_DATA, { fetchPolicy: 'network-only' });
  const [updateUserInfo] = useMutation(UPDATE_USER_INFO);
  const user = data?.currentUser;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().when([], {
      is: () => editingField === 'firstName',
      then: Yup.string()
        .max(MAX_FIELD_LENGTH, t('maxLengthValidation', { max: MAX_FIELD_LENGTH }))
        .required(t('fieldIsRequired').toString()),
    }),
    lastName: Yup.string().when([], {
      is: () => editingField === 'lastName',
      then: Yup.string()
        .required(t('fieldIsRequired').toString())
        .max(MAX_FIELD_LENGTH, t('maxLengthValidation', { max: MAX_FIELD_LENGTH })),
    }),
    birthDate: Yup.string().when([], {
      is: () => editingField === 'birthDate',
      then: Yup.string()
        .matches(dateRegex, t('dateNotValid').toString())
        .test('valid', t('dateNotValid').toString(), (value) => {
          if (!value) return true;
          const [month, day, year] = value.split('-');
          const date = new Date(Number(year), Number(month) - 1, Number(day));
          return isValid(date) && date <= new Date();
        })
        .required(t('fieldIsRequired').toString()),
    }),
  });

  const formatDOB = (date: string) => {
    const [yyyy, mm, dd] = date.split('-');
    if (!mm || !dd || !yyyy) return '';

    return `${mm}-${dd}-${yyyy}`;
  };

  const formattedDate = user?.dob ? formatDOB(user.dob) : '';

  const initialFormValues = {
    firstName: user?.first_name ? capitalizeWords(user.first_name) : '',
    lastName: user?.last_name ? capitalizeWords(user.last_name) : '',
    birthDate: user?.dob ? formattedDate : '',
  };

  const onSubmit = async (values: typeof initialFormValues) => {
    try {
      const [mm, dd, yyyy] = values.birthDate.split('-');
      await updateUserInfo({
        variables: {
          first_name: editingField === 'firstName' ? capitalizeWords(values.firstName) : undefined,
          last_name: editingField === 'lastName' ? capitalizeWords(values.lastName) : undefined,
          dob: editingField === 'birthDate' ? `${yyyy}-${mm}-${dd}` : undefined,
        },
      });
    } catch (error) {
      throw new Error(`Updating personal details failed: ${error}`);
    }
    setEditingField(null);
  };

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - Personal Info' });
  }, []);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, isValid, resetForm, setFieldValue, errors, setFieldError, setTouched }) => (
        <Styled.Container>
          <Styled.HeaderContainer>
            <Header
              variant="back"
              onBackClick={() => navigate(paths.settings.main)}
              title={
                editingField === 'firstName'
                  ? t('personalInfoPage.editFirstName')
                  : editingField === 'lastName'
                  ? t('personalInfoPage.editLastName')
                  : editingField === 'birthDate'
                  ? t('personalInfoPage.editDateOfBirth')
                  : t('personalInfo')
              }
            />
          </Styled.HeaderContainer>
          {data ? (
            <Styled.Sections>
              <Styled.Section>
                {editingField === 'firstName' ? (
                  <Input
                    label={t('personalInfoPage.firstName')}
                    name="firstName"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                ) : (
                  <>
                    <Styled.Description>
                      <Typography weight={500} color="black.700">
                        {t('personalInfoPage.firstName')}
                      </Typography>
                      <Styled.FieldText color="black.600">{capitalizeWords(user?.first_name)}</Styled.FieldText>
                    </Styled.Description>
                    <Styled.SButton
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        resetForm();
                        setEditingField('firstName');
                      }}
                    >
                      <Typography variant="Helper1">{t('edit')}</Typography>
                    </Styled.SButton>
                  </>
                )}
              </Styled.Section>
              <Styled.Section>
                {editingField === 'lastName' ? (
                  <Input
                    label={t('personalInfoPage.lastName')}
                    name="lastName"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                ) : (
                  <>
                    <Styled.Description>
                      <Typography weight={500} color="black.700">
                        {t('personalInfoPage.lastName')}
                      </Typography>
                      <Styled.FieldText color="black.600">{capitalizeWords(user?.last_name)}</Styled.FieldText>
                    </Styled.Description>
                    <Styled.SButton
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        resetForm();
                        setEditingField('lastName');
                      }}
                    >
                      <Typography variant="Helper1">{t('edit')}</Typography>
                    </Styled.SButton>
                  </>
                )}
              </Styled.Section>
              <Styled.Section>
                {editingField === 'birthDate' ? (
                  <PatternFormat
                    name="birthDate"
                    format="##-##-####"
                    allowEmptyFormatting
                    mask="_"
                    value={values.birthDate}
                    label={t('personalInfoPage.dateOfBirth')}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    customInput={Input}
                    inputMode="decimal"
                    onChange={(e) => {
                      setTouched({ birthDate: true });
                      setFieldValue('birthDate', e.currentTarget.value);
                      setFieldError('bithDate', errors.birthDate);
                    }}
                  />
                ) : (
                  <>
                    <Styled.Description>
                      <Typography weight={500} color="black.700">
                        {t('personalInfoPage.dateOfBirth')}
                      </Typography>
                      <Styled.FieldText color="black.600">{formattedDate}</Styled.FieldText>
                    </Styled.Description>
                    <Styled.SButton
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        resetForm();
                        setEditingField('birthDate');
                      }}
                    >
                      <Typography variant="Helper1">{t('edit')}</Typography>
                    </Styled.SButton>
                  </>
                )}
              </Styled.Section>
            </Styled.Sections>
          ) : (
            <LoadingPage />
          )}
          {editingField && (
            <Styled.Buttons>
              <Styled.FormButton variant="link" onClick={() => setEditingField(null)}>
                {t('cancel')}
              </Styled.FormButton>
              <Styled.FormButton type="submit" onClick={() => onSubmit(values)} disabled={!isValid}>
                {t('saveChanges')}
              </Styled.FormButton>
            </Styled.Buttons>
          )}
        </Styled.Container>
      )}
    </Formik>
  );
};
