import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const ErrorContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 24px;
  flex: 1;
`;

export const ErrorIcon = styled(Icon)`
  transform: rotate(180deg);
  margin-top: 16px;
`;

export const TryAgain = styled(Typography)`
  max-width: 200px;
`;
