import { useQuery } from '@apollo/client';
import { GET_COURSE_MEMBERSHIP, GET_QUESTIONAIRES } from 'api/questionnaires/questionnaires.query';
import { parseHealthieDate } from 'utils/helpers';
import { Maybe } from 'utils/types';

import { CourseItem, isItemVisible } from './Questionnaires.model';

type Return = {
  anyVisible?: boolean;
  completedCount: number;
  loading?: boolean;
  visibleItems?: CourseItem[];
  courseId?: Maybe<string>;
};

export const useQuestionnaires = (): Return => {
  const { data: cm, loading: cmLoading } = useQuery(GET_COURSE_MEMBERSHIP, { fetchPolicy: 'no-cache' });
  const courseMembership = cm?.currentUser?.course_memberships?.[0];

  const { data, loading } = useQuery(GET_QUESTIONAIRES, {
    fetchPolicy: 'no-cache',
    variables: { membership_id: courseMembership?.id },
    skip: !courseMembership?.id,
  });
  // const course = data?.currentUser?.course_memberships?.[0].course;

  const mostRecentCourseMembership = data?.currentUser?.course_memberships?.reduce((latest, current) => {
    const latestDate = parseHealthieDate(latest.start_at as string);
    const currentDate = parseHealthieDate(current.start_at as string);
    return currentDate > latestDate ? current : latest;
  });

  const course = mostRecentCourseMembership?.course;

  const courseStartDate = parseHealthieDate(courseMembership?.start_at as string);
  const visibleItems = course?.course_items?.filter((item) => isItemVisible(item, courseStartDate));
  const anyVisible = visibleItems && visibleItems?.length > 0;
  const completedCount = visibleItems?.filter((item) => !!item.completed_course_item).length || 0;

  return { anyVisible, completedCount, visibleItems, loading: loading || cmLoading, courseId: course?.id };
};
