import React from 'react';
import { Maybe } from 'utils/types';

import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';
import { Typography } from '../Typography/Typography';
import { HeaderDescription, HeaderWrapper } from './ArticleHeader.styles';

type Props = {
  icon?: JSX.Element;
  progress?: number;
  label?: Maybe<string>;
  description?: Maybe<string>;
  button?: React.ReactElement;
  className?: string;
};

export const ArticleHeader: React.FC<Props> = ({ icon, progress = 0, label, description, className, button }) => {
  return (
    <HeaderWrapper className={className}>
      <CircularProgressBar size={48} progress={progress} strokeWidth={4}>
        {icon}
      </CircularProgressBar>
      <HeaderDescription>
        <Typography weight={500} size={20} lineHeight={28}>
          {label}
        </Typography>
        <Typography size={14} color="black.600">
          {description}
        </Typography>
      </HeaderDescription>
      {button}
    </HeaderWrapper>
  );
};
