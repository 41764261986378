import { ReactComponent as InformationIcon } from 'assets/vectors/fi-rr-information.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button/Button';
import CircularProgressBar, { ProgressBarProps } from '../CircularProgressBar/CircularProgressBar';
import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import { CardDescriptionContainer, Container } from './TaskHeader.styles';

export interface TaskHeaderProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name?: string;
  description?: string;
  progressPercentage: number;
  circleProps?: Partial<ProgressBarProps>;
  onInformationIconClick?: () => void;
}

export const TaskHeader: React.FC<TaskHeaderProps> = ({
  icon,
  name,
  description,
  progressPercentage,
  circleProps,
  onInformationIconClick,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.taskHeader' });

  const Information = (
    <span style={{ marginLeft: 8 }}>
      <Button variant="icon" aria-label={t('showInformation')} onClick={onInformationIconClick}>
        <Icon element={InformationIcon} color="black.800" size={16} aria-hidden="true" />
      </Button>
    </span>
  );

  return (
    <Container>
      <CircularProgressBar size={48} progress={progressPercentage} strokeWidth={3} {...circleProps}>
        <Icon element={icon} size={20} />
      </CircularProgressBar>
      <CardDescriptionContainer>
        <Container>
          <Typography weight={500} size={20} lineHeight={28} color="black.800">
            {name}
          </Typography>
          {onInformationIconClick && Information}
        </Container>
        <Typography size={14} color="black.600">
          {description}
        </Typography>
      </CardDescriptionContainer>
    </Container>
  );
};

export default TaskHeader;
