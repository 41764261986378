import React from 'react';

import { Typography } from 'components/ui/Typography/Typography';

import { SingleRowContainer } from '../WeeklyReport.styles';
import { SingleRowProps } from '../types';

export const SingleRow: React.FC<SingleRowProps> = (props) => {
  const { title, value, label, valueProps } = props;
  return (
    <>
      <Typography color="black.600" variant="Helper1">
        {title}
      </Typography>
      <SingleRowContainer>
        <Typography color="black.600" size={16} weight={600} {...valueProps}>
          {value}
        </Typography>
        <Typography variant="Body1" color="black.800">
          {label}
        </Typography>
      </SingleRowContainer>
    </>
  );
};
