import { Form } from 'formik';
import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Header = styled(Typography.Div)`
  padding: 24px;
`;

export const SForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 24px;
  flex-grow: 1;
`;

export const SButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 24px;
`;

export const AddressStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const SIcon = styled(Icon)`
  display: inline;
`;
