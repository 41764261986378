import { icons } from 'assets/vectors/iconsList';
import { setFirstAppointmentLater } from 'hooks/useOnboardingFlow';
import { env } from 'index';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Header } from 'components/layout/BaseLayout/Header';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './ScheduleFirstAppointment.styles';

export const ScheduleFirstAppointment: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.ScheduleFirstAppointment' });
  const location = useLocation();
  const repeat = location.state?.repeat || false;
  const navigate = useNavigate();

  const onDoItLaterClick = async () => {
    setFirstAppointmentLater();
    navigate(paths.homePage);
  };

  return (
    <BaseLayout header={<Header showBack={false} variant="back" title={t('scheduleCoachApp')} />} footer={<div></div>}>
      <Styled.ScheduleFirstAppointmentContainer>
        <Styled.Header>
          <Typography variant="Headline2">{t('headline', { first: repeat ? '' : 'first ' })}</Typography>
        </Styled.Header>
        <Styled.PageContent>
          <Icon element={icons['firstAppointment']} size={150} />
          <>
            <Styled.Description>
              <Typography weight={600}>{t('7days')}</Typography>
              {t('personalcareplan')}
            </Styled.Description>
            <Styled.Description>
              {t('pleasenote')}
              <Typography weight={600}>{t('illinois')}</Typography>
              {t('questions')}
            </Styled.Description>
            <Styled.Excited weight={600}>{t('excited')}</Styled.Excited>
          </>
        </Styled.PageContent>
        <Styled.ButtonsContainer>
          <Styled.SButton variant="link" size="md" onClick={onDoItLaterClick}>
            {t('doItLater')}
          </Styled.SButton>
          <Styled.SButton
            variant="primary"
            size="md"
            onClick={() =>
              navigate(
                generatePath(paths.scheduleAppointmentWithSpecialist, {
                  specialistType: 'coach',
                  appointmentTypeId: env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
                }),
              )
            }
          >
            {t('scheduleNow')}
          </Styled.SButton>
        </Styled.ButtonsContainer>
      </Styled.ScheduleFirstAppointmentContainer>
    </BaseLayout>
  );
};
