import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const PageTitle = styled(Typography)`
  margin: 16px 0 4px;
`;

export const PlanInfoContainer = styled(Typography.Div)`
  display: flex;
  gap: 16px;
  padding-bottom: 16px;
`;

export const FlexLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
