import styled from 'styled-components';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import { DiagramProps } from './Diagram';

type ResponsiveContainerProps = Pick<DiagramProps, 'width' | 'height'>;

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ResponsiveContainer = styled.div<ResponsiveContainerProps>`
  position: relative;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  max-width: 100%;
`;

export const NoDataContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoDataTitle = styled(Typography)`
  margin-bottom: 4px;
`;

export const NoDataIconComponent = styled(Icon).attrs({
  size: 56,
})`
  margin-bottom: 16px;
`;

export const LegendContainer = styled.div`
  text-align: right;
`;

export const LegendComponent = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  background-color: ${({ theme }) => theme.color.other.linenLight};
  padding: 4px 10px;
  border-radius: 4px;
`;
