import styled from 'styled-components';

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.color.black[50]};
  &:first-child,
  &:last-child {
    border-radius: 8px;
  }
  background-color: ${(props) => props.theme.color.other.white};
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
  width: 100%;
`;

export const NoAppointmentsContainer = styled.div`
  text-align: center;
  gap: 18px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
