import { ReactComponent as Step_1 } from 'assets/images/BP-manual/BP-step-1.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from '../../BloodPressureManual.styles';

export const Step1: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressureManual.step1' });

  return (
    <Styled.StepContainer>
      <Styled.ImageContainer>
        <Step_1 />
      </Styled.ImageContainer>
      <Styled.DescriptionContainer>
        <Styled.Description>
          {t('insert')}&nbsp;<Styled.Description weight={700}> {t('batteries')}&nbsp;</Styled.Description>
          {t('into')}
        </Styled.Description>
      </Styled.DescriptionContainer>
    </Styled.StepContainer>
  );
};
