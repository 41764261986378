import { icons } from 'assets/vectors/iconsList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Icon } from 'components/ui/Icon/Icon';

import * as Styled from './NoCarePlan.styles';

export const NoCarePlan: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.progress' });
  const navigate = useNavigate();

  return (
    <BaseLayout headerProps={{ variant: 'back', title: t('progress'), onBackClick: () => navigate(-1) }}>
      <Styled.ContentContainer>
        <Icon element={icons['fi-rr-progress2.svg']} size={56} color={'other.white'} strokeColor={'black.600'} />
        <Styled.ProgressText color="black.600" weight={600}>
          {t('progressAndCarePlan')}
        </Styled.ProgressText>
        <Styled.AfterAppointmentText variant="Helper1">{t('willBeAvailable')}</Styled.AfterAppointmentText>
      </Styled.ContentContainer>
    </BaseLayout>
  );
};
