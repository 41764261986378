import { useQuery } from '@apollo/client';
import { Entry } from '__generated__/graphql';
import { GET_BLOOD_PRESSURE_MEASUREMENTS } from 'api/bloodPressureCuff/bloodPressureCuff.query';
import { ReactComponent as HeartRate } from 'assets/vectors/fi-rr-heart rate small.svg';
import floor from 'lodash/floor';
import mean from 'lodash/mean';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

import { useGetMeasurementsStatistics } from '../../bloodPressure/components/hooks';
import { groupMeasurements, useGetAverage } from '../../bloodPressure/utils';
import { CardsContainer, CardsContainerCenter, FlexLayout, PlanInfoContainer } from '../WeeklyReport.styles';
import { TilesProps } from '../types';
import { SingleRow } from './SingleRow';

export const BloodPressureTile: React.FC<TilesProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.weeklyReport' });

  const { startDate, endDate, goals } = props;

  const { data, loading } = useQuery(GET_BLOOD_PRESSURE_MEASUREMENTS, {
    variables: {
      start_range: startDate,
      end_range: endDate,
    },
  });

  const entries = (data?.entries || []) as Entry[];
  const { systolicData, diastolicData, heartRateData } = groupMeasurements(entries, startDate, endDate);
  const allReadings = diastolicData.filter((item) => item.y !== 0).length;
  const { requiredMeasurementsInWeek, goal } = useGetMeasurementsStatistics(goals);

  const averageBloodPressure = useGetAverage(systolicData, diastolicData);
  const averageHeartRate = floor(mean(heartRateData), 0);

  if (loading) {
    return (
      <CardsContainerCenter>
        <Spinner size={24} />
      </CardsContainerCenter>
    );
  }
  return (
    <>
      <CardsContainer>
        <PlanInfoContainer>
          <Icon element={HeartRate} size={18} color={'transparent'} strokeColor={'black.800'} aria-hidden="true" />
          <Typography color="black.800" variant="Body1">
            {t('bloodPressure')}
          </Typography>
        </PlanInfoContainer>

        <SingleRow
          title={t('completedReadings')}
          value={t('completedReadingsValue', {
            completed: allReadings,
            required: requiredMeasurementsInWeek,
          })}
          label={t('readings')}
        />

        <FlexLayout>
          <div style={{ marginTop: 16 }}>
            <SingleRow
              title={t('averageWeek')}
              value={averageBloodPressure}
              valueProps={{
                color: 'other.red',
              }}
              label={t('mmHg')}
            />
          </div>
          <div style={{ textAlign: 'end' }}>
            <SingleRow
              title={t('goal')}
              value={goal ? t('goalValue', { value: goal }) : t('notAvailable')}
              label={t('mmHg')}
            />
          </div>
        </FlexLayout>

        <div style={{ marginTop: 16 }}>
          <SingleRow
            title={t('averageHeartRate')}
            valueProps={{
              color: 'other.red',
            }}
            value={averageHeartRate || t('notAvailable')}
            label={t('bpm')}
          />
        </div>
      </CardsContainer>
    </>
  );
};
