import { Formik } from 'formik';
import React from 'react';
import parse from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Question, parseQuestionTitle } from 'components/ui/Question/Question';

import { BRACKET } from '../Questionnaires.model';
import {
  AnswerSummary,
  AnswersWrapper,
  ButtonContainer,
  QuestionContainer,
  QuestionTitle,
  QuestionnaireForm,
  SingleAnswer,
} from './Questionnaire.styles';
import { useQuestionnaire } from './useQuestionnaire';

export const Questionnaire: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.questionnaires' });
  const { questionnaireId } = useParams();
  const [searchParams] = useSearchParams();

  const {
    onSubmit,
    onBackClick,
    saveForLater,
    answers,
    isSubmitStep,
    currentQuestion,
    questionIndex,
    questionCount,
    title,
  } = useQuestionnaire(
    questionnaireId,
    searchParams.get('course_id'),
    searchParams.get('course_item_id'),
    searchParams.get('incomplete_item_id'),
  );

  const schema = Yup.object({
    answer: Yup.lazy((value) => {
      if (Array.isArray(value)) {
        return Yup.array().min(1);
      } else if (typeof value === 'string') {
        return Yup.string();
      } else if (typeof value === 'number') {
        return Yup.number()
          .min(0, t('cannotBeNegative'))
          .test('notAllowedChars', t('cannotContainSpecial'), (val) => !val?.toString().match(/[^0-9.,]/g));
      } else {
        return Yup.object();
      }
    }),
  });

  return currentQuestion ? (
    <Formik
      initialValues={{ answer: currentQuestion?.answer }}
      validateOnChange
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isValid, values, setValues, isSubmitting }) => {
        if (isSubmitting && isSubmitStep) return <LoadingPage />;
        return (
          <BaseLayout
            footer={null}
            headerProps={{
              variant: 'back',
              onBackClick: () => onBackClick(setValues),
              title: isSubmitStep
                ? t('completeYourAssessments')
                : t('question', { count: questionIndex + 1, total: questionCount }),
            }}
          >
            <QuestionnaireForm>
              <QuestionContainer>
                {isSubmitStep ? (
                  <AnswersWrapper>
                    {answers?.map((answer, index) => (
                      <AnswerSummary key={answer.custom_module_id} tabIndex={0}>
                        {parseQuestionTitle(answer.label, index + 1)}
                        {Array.isArray(answer.answer) ? (
                          answer.answer.map((a) => (
                            <SingleAnswer weight={600} key={a}>
                              {a.replace(BRACKET, '')}
                            </SingleAnswer>
                          ))
                        ) : (
                          <SingleAnswer weight={600}>{answer.answer.replace(BRACKET, '')}</SingleAnswer>
                        )}
                      </AnswerSummary>
                    ))}
                  </AnswersWrapper>
                ) : (
                  <>
                    {title && (
                      <QuestionTitle color="black.600" tabIndex={0}>
                        {parse(title)}
                      </QuestionTitle>
                    )}
                    <Question {...currentQuestion} index={questionIndex + 1} />
                  </>
                )}
              </QuestionContainer>
              <ButtonContainer>
                {isSubmitStep ? (
                  <Button type="submit">{t('submit')}</Button>
                ) : (
                  <Button type="submit" disabled={!isValid || !values.answer}>
                    {t('next')}
                  </Button>
                )}
                <Button variant="secondary" type="button" onClick={() => saveForLater(values)}>
                  {t('saveForLater')}
                </Button>
              </ButtonContainer>
            </QuestionnaireForm>
          </BaseLayout>
        );
      }}
    </Formik>
  ) : null;
};
