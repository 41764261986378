import styled, { css } from 'styled-components';

import { ButtonProps } from './Button';

type StyledButtonProps = Pick<ButtonProps, 'variant' | 'size'>;

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: ${(props) => (props.size === 'lg' ? '14px 48px' : props.size === 'md' ? '14px 15px' : '7px 12px')};
  width: ${(props) => (props.size === 'md' || props.size === 'lg' ? '100%' : 'unset')};
  border-radius: 32px;
  border: ${(props) => (props.variant === 'secondary' ? `1px solid ${props.theme.color.blue[600]}` : 'none')};
  font-weight: ${(props) =>
    props.size === 'md' || props.size === 'lg' ? props.theme.font.weight[600] : props.theme.font.weight[500]};
  font-size: ${(props) =>
    props.size === 'md' || props.size === 'lg' ? props.theme.font.size[16] : props.theme.font.size[14]};

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary-light':
        return css`
          background-color: ${theme.color.other.yellow};
          color: ${theme.color.black[900]};
        `;
      case 'linen':
        return css`
          background-color: ${theme.color.other.linen};
          color: ${theme.color.black[900]};
          font-weight: 600;
        `;
      case 'secondary':
        return css`
          background-color: ${theme.color.other.white};
          color: ${theme.color.blue[600]};
        `;
      case 'secondary-transparent':
        return css`
          background-color: ${theme.color.transparent};
          color: ${theme.color.blue[600]};
          border: 1px solid ${theme.color.blue[600]};
        `;
      case 'danger':
        return css`
          background-color: ${theme.color.other.redLight};
          color: ${theme.color.other.red};
        `;
      case 'link':
        return css`
          background-color: transparent;
          color: ${theme.color.blue[600]};
          border-radius: unset;
          padding: unset;
          width: unset;
        `;
      case 'back':
      case 'icon':
        return css`
          background-color: transparent;
          color: ${theme.color.black[800]};
          font-weight: unset;
          padding: unset;
          display: grid;
          grid-auto-flow: column;
          align-items: center;
        `;
      case 'square-secondary':
        return css`
          background-color: transparent;
          color: ${theme.color.blue[600]};
          border: 1px solid ${theme.color.blue[600]};
          border-radius: 8px;
          padding: 18px 14px;
          font-weight: ${theme.font.weight[400]};
        `;
      case 'square-primary':
        return css`
          background-color: ${theme.color.blue[600]};
          color: ${theme.color.other.white};
          border: 1px solid ${theme.color.blue[600]};
          border-radius: 8px;
          padding: 18px 14px;
          font-weight: ${theme.font.weight[400]};
        `;
      case 'primary':
      default:
        return css`
          background-color: ${theme.color.blue[600]};
          color: ${theme.color.other.white};
        `;
    }
  }}
  &:disabled {
    background-color: ${(props) => props.theme.color.black[50]};
    color: ${(props) => props.theme.color.black[500]};
    border: none;
  }
`;
