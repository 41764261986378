import { ChartOptions } from 'chart.js';
import { DefaultTheme } from 'styled-components';

export const defaultChartOptions = (theme: DefaultTheme, showTendencyOnly = false): ChartOptions<'line'> => ({
  responsive: true,
  maintainAspectRatio: false,
  font: {
    family: 'poppins',
  },
  plugins: {
    legend: {
      display: false,
    },
    // @ts-expect-error: There is a custom plugin added that is not in the type definition
    htmlLegend: {
      containerID: 'legend-container',
    },
    tooltip: {
      borderColor: theme.color.black[900],
      backgroundColor: theme.color.other.white,
      titleColor: theme.color.black[900],
      bodyColor: theme.color.black[900],
      displayColors: false,
      cornerRadius: 8,
      padding: 6,
      caretSize: 4,
      borderWidth: 1,
      bodyFont: { weight: '500', family: 'poppins', size: 10 },
      callbacks: {
        title: () => '',
        label: (value) => {
          return `${value.formattedValue} ${value.dataset.label}`;
        },
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        round: 'day',
        displayFormats: {
          day: 'M/d',
        },
        unit: 'day',
      },
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        autoSkipPadding: 32,
        maxRotation: 0,
        color: theme.color.black[400],
        font: {
          size: 10,
        },
      },
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y1: {
      display: false,
    },
  },
  elements: {
    point: {
      pointStyle: showTendencyOnly ? false : 'circle',
      radius: 5,
      hoverRadius: 5.5,
      hitRadius: 5.5,
    },
    line: {
      borderWidth: 2,
    },
  },
});
