import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { format, isMatch, parse } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Pdf from 'components/pdf';
import PdfPage from 'components/pdf/page';

import { CarePlanGoalTyped } from '../../CarePlan.model';
import { PdfPlanItem } from './PdfPlanItem';

type Props = {
  data: CarePlanGoalTyped[];
  phoneNumber?: string | null;
  carePlanDate?: string;
};

const CarePlanPdf: React.FC<Props> = ({ data, phoneNumber, carePlanDate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.carePlan.pdf' });
  const styles = StyleSheet.create({
    viewStyles: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '16px 30px',
      borderBottom: '1px solid #a3a3a3',
    },
  });
  return (
    <Pdf>
      <PdfPage coachNumber={phoneNumber}>
        <View style={styles.viewStyles}>
          <Text>{t('carePlan')}</Text>
          {carePlanDate && isMatch(carePlanDate || '', 'MM.dd.yyyy') && (
            <Text>{t('updatedOn', { date: format(parse(carePlanDate, 'MM.dd.yyyy', new Date()), 'MM/dd/yyyy') })}</Text>
          )}
        </View>
        {data.map((carePlanGoal) => (
          <PdfPlanItem key={carePlanGoal.type} {...carePlanGoal} />
        ))}
      </PdfPage>
    </Pdf>
  );
};

export default CarePlanPdf;
