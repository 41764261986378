import { gql } from '__generated__/gql';

export const CREATE_GOAL_HISTORY = gql(`
  mutation createGoalHistory($goal_id: String, $completed_on: String, $mark_parent_complete: Boolean, $user_id: String){
    createGoalHistory(input: {goal_id: $goal_id, completed_on: $completed_on, mark_parent_complete: $mark_parent_complete, user_id: $user_id}){
      goalHistory{
        name
        completed_on
      }
      messages {
        field
        message
      }
    }
  }
`);

export const DELETE_GOAL_HISTORY = gql(`
  mutation deleteGoalHistory($id: ID, $completed_on: String){
    deleteGoalHistory(input: {id: $id, completed_on: $completed_on}){
      messages {
        field
        message
      }
    }
  }
`);
