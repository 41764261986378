import range from 'lodash/range';
import React from 'react';
import { Swiper as SwiperType } from 'swiper';

import { Dot, Wrapper } from './SlideIndicator.styles';

interface Props {
  swiperRef: React.MutableRefObject<SwiperType | undefined>;
  slides: number;
  currentSlideIndex: number;
}

export const SlideIndicator: React.FC<Props> = ({ swiperRef, slides, currentSlideIndex }) => {
  return (
    <Wrapper>
      {range(slides).map((_, index) => (
        <Dot key={index} isCurrent={currentSlideIndex === index} onClick={() => swiperRef.current?.slideTo(index)} />
      ))}
    </Wrapper>
  );
};
