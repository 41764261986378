import { ReactComponent as FitbitLogoIcon } from 'assets/images/kit/fitbit-logo-icon.svg';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { DeviceContainer, FitbitLogoContainer } from './Devices.styles';

export const Devices: React.FC = () => {
  const navigate = useNavigate();
  const { hasLoggedInBefore } = useCurrentFitbitTokens();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings.devicesPage' });

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - Devices' });
  }, []);

  return (
    <BaseLayout
      headerProps={{ title: 'Devices', variant: 'back', onBackClick: () => navigate(paths.settings.main) }}
      footer={null}
    >
      <DeviceContainer>
        <FitbitLogoContainer>
          <Icon element={FitbitLogoIcon} />
          <Typography.Span variant="Body1Bold" color="black.800" style={{ marginLeft: 8 }}>
            {t('fitbit')}
          </Typography.Span>
        </FitbitLogoContainer>
        {hasLoggedInBefore ? (
          <Typography color="other.green" size={14}>
            {t('connected')}
          </Typography>
        ) : (
          <Button
            size="sm"
            style={{ width: 93 }}
            onClick={() => {
              navigate(paths.fitbitSetup, { state: { next: paths.settings.main } });
            }}
          >
            {t('connect')}
          </Button>
        )}
      </DeviceContainer>
    </BaseLayout>
  );
};

export default Devices;
