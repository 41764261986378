import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.color.black[600]};
`;

export const RowWrapper = styled(Typography.Div)`
  margin: 8px 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }
`;
