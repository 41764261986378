import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const ModalOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  inset: 0;
  padding: 24px;
`;

export const ConsentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 10px;
  margin-right: -10px;
  overflow-y: auto;
  word-break: break-word;
`;

export const ConsentContent = styled(Typography.Div)`
  text-align: justify;
  p {
    margin: revert;
  }

  ol,
  ul {
    padding-inline-start: 28px;

    &::marker {
      width: 24px;
    }

    ol,
    ul {
      margin-left: -10px;
    }
  }
`;
