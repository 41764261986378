import styled from 'styled-components';

export const Wrapper = styled.div`
  /* I can't use theme properties here because it's injected into DOM and Webpack returns loop of errors */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'poppins';
  font-family: 400;
  font-size: 16px;

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.1s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  div {
    margin-bottom: 16px;
  }
`;

export const StyledEmoji = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
`;
