import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 40px;
  border: none;
  background-color: ${({ theme }) => theme.color.blue[50]};
  margin-right: 24px;
  margin-left: 24px;

  &:disabled {
    background-color: ${({ theme }) => theme.color.blue[100]};

    svg path {
      fill: ${({ theme }) => theme.color.blue[200]};
    }
  }
`;
