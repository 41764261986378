import { ReactComponent as Step_8 } from 'assets/images/BP-manual/BP-step-8.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from '../../BloodPressureManual.styles';

export const Step8: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressureManual.step8' });

  return (
    <Styled.StepContainer>
      <Styled.ImageContainer isDark>
        <Step_8 />
      </Styled.ImageContainer>
      <Styled.DescriptionContainer>
        <Styled.Description>{t('description1')}</Styled.Description>
        <Styled.Description>{t('description2')}</Styled.Description>
      </Styled.DescriptionContainer>
    </Styled.StepContainer>
  );
};
