import styled from 'styled-components';

interface DotProps {
  isCurrent?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 24px 0;
`;

export const Dot = styled.div<DotProps>`
  width: ${({ isCurrent }) => (isCurrent ? '28px' : '8px')};
  height: 8px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.other.white};
  transition-timing-function: ease-in;
  transition: 0.2s;
`;
