import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  bottom: 16px;
  gap: 16px;
  padding: 0 24px;
`;
