import { useQuery } from '@apollo/client';
import { GET_AVAILABLE_SLOTS } from 'api/appointment/appointment.query';
import { ReactComponent as Messages } from 'assets/vectors/fi--rr-messages.svg';
import { ReactComponent as ArrowLeft } from 'assets/vectors/fi-rr-angle-small-left.svg';
import { ReactComponent as ArrowRight } from 'assets/vectors/fi-rr-angle-small-right.svg';
import { icons } from 'assets/vectors/iconsList';
import {
  addBusinessDays,
  addDays,
  eachDayOfInterval,
  endOfDay,
  format,
  isSameDay,
  isToday,
  startOfDay,
  subDays,
} from 'date-fns';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { capitalizeWords, parseHealthieDate } from 'utils/helpers';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Header } from 'components/layout/BaseLayout/Header';
import { ErrorScheduling } from 'components/layout/Error/ErrorScheduling';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import {
  ArrowButton,
  Container,
  Duration,
  DurationContainer,
  LoadingContainer,
  NavigatorContainer,
  NoSlotsContainer,
  SingleDayDateContainer,
  SlotsContainer,
} from '../../ScheduleAppointment.styles';
import { ScheduleAppointmentProps } from '../../types';
import { ScheduleActions } from '../ScheduleActions/ScheduleActions';

const VISIBLE_DAYS = 2;

type Slot = {
  user_id: string;
  date: string;
  appointment_id: string;
  is_fully_booked: boolean;
  length: number;
  has_waitlist_enabled: boolean;
};

export const Slots: React.FC<ScheduleAppointmentProps> = (props) => {
  const { appointmentTypeId, providerId, appointmentToRescheduleId, specialistType } = props;

  const { t } = useTranslation('translation', { keyPrefix: 'pages.scheduleAppointment' });
  const title = specialistType
    ? t('titleWithSpecialistType', { specialistType: capitalizeWords(specialistType) })
    : t('title');
  const recheduleTitle = t('rescheduleTitle');
  const navigate = useNavigate();

  const currentDate = new Date();
  const twoWeeksLater = addBusinessDays(currentDate, 13);
  const [fromDate, setFromDate] = useState(startOfDay(currentDate));
  const [toDate, setToDate] = useState(endOfDay(addDays(currentDate, 1)));
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>();
  const [scheduleError, setScheduleError] = useState<string>('');

  const canScrollToPreviousDay = !isToday(fromDate);

  const { data, loading } = useQuery(GET_AVAILABLE_SLOTS, {
    skip: !providerId,
    variables: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
      start_date: format(fromDate, 'yyyy-MM-dd'),
      end_date: format(toDate, 'yyyy-MM-dd'),
      org_level: appointmentTypeId === process.env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
      provider_id: providerId,
      appt_type_id: appointmentTypeId,
      appointment_to_reschedule_id: appointmentToRescheduleId,
    },
    fetchPolicy: 'no-cache', // required - errors with slots when fetching from cache
  });

  // Get available slots for the next 2 weeks in business days
  const { data: twoWeekData, loading: twoWeekLoading } = useQuery(GET_AVAILABLE_SLOTS, {
    skip: !providerId,
    variables: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
      start_date: format(currentDate, 'yyyy-MM-dd'),
      end_date: format(twoWeeksLater, 'yyyy-MM-dd'),
      org_level: appointmentTypeId === process.env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
      provider_id: providerId,
      appt_type_id: appointmentTypeId,
      appointment_to_reschedule_id: appointmentToRescheduleId,
    },
    fetchPolicy: 'no-cache',
  });

  const getAllAppointmentsInDay = (date: Date) => {
    if (data?.availableSlotsForRange) {
      const appointmentsFromGivenDay = data.availableSlotsForRange.filter((item) => {
        return item?.date ? isSameDay(date, parseHealthieDate(item.date)) : false;
      });
      return sortBy(appointmentsFromGivenDay, (item) => {
        return item?.date ? parseHealthieDate(item.date) : null;
      });
    }
    return [];
  };

  const handleNext = () => {
    setFromDate(addDays(fromDate, VISIBLE_DAYS));
    setToDate(addDays(toDate, VISIBLE_DAYS));
  };

  const handlePrevious = () => {
    setFromDate(subDays(fromDate, VISIBLE_DAYS));
    setToDate(subDays(toDate, VISIBLE_DAYS));
  };

  const handleSelectSlot = (slot: Slot) => () => {
    if (slot) {
      if (slot === selectedSlot) {
        setSelectedSlot(null);
      } else {
        setSelectedSlot(slot);
      }
    }
  };

  const dateInterval = eachDayOfInterval({ start: fromDate, end: toDate });

  if (twoWeekLoading) {
    return (
      <BaseLayout>
        <LoadingContainer>{t('loading')}</LoadingContainer>
      </BaseLayout>
    );
  }

  if (twoWeekData?.availableSlotsForRange?.length === 0) {
    return (
      <ErrorScheduling
        iconElement={Messages}
        headerTitle={appointmentToRescheduleId ? recheduleTitle : title}
        headline={t('noAvailableSlots.header')}
        description={t('noAvailableSlots.information', {
          provider: capitalizeWords(specialistType || 'Coach or Therapist'),
        })}
        buttonContent="Refresh"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (scheduleError) {
    if (scheduleError.includes('no longer available')) {
      return (
        <ErrorScheduling
          headerTitle={appointmentToRescheduleId ? recheduleTitle : title}
          headline={t('timeNotAvailable')}
          description={t('goBackToPrevious')}
          onClick={() => setScheduleError('')}
          onBackClick={() => setScheduleError('')}
        />
      );
    } else {
      return (
        <ErrorScheduling
          headerTitle={appointmentToRescheduleId ? recheduleTitle : title}
          headline={t('notAbleToSchedile')}
          description={t('tryLater')}
          buttonContent="Refresh"
          onClick={() => window.location.reload()}
        />
      );
    }
  }

  return (
    <BaseLayout
      footer={
        <>
          <ScheduleActions
            appointmentToRescheduleId={appointmentToRescheduleId}
            selectedSlot={selectedSlot?.date || null}
            providerId={
              appointmentTypeId === process.env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID
                ? selectedSlot?.user_id || ''
                : providerId
            }
            appointmentTypeId={appointmentTypeId}
            onScheduleError={setScheduleError}
          />
        </>
      }
      header={
        <Header
          onBackClick={() => navigate(paths.homePage)}
          variant="back"
          title={appointmentToRescheduleId ? recheduleTitle : title}
        />
      }
    >
      <Container>
        {data?.availableSlotsForRange?.[0]?.length && (
          <DurationContainer>
            <Duration>
              <Icon color="other.white" element={icons['fi-rr-time.svg']} title={t('clock')} />
              <Typography.Span variant="Helper2" color="black.800">
                {t('slotLength', { value: data?.availableSlotsForRange[0].length })}
              </Typography.Span>
            </Duration>
          </DurationContainer>
        )}

        <SlotsContainer>
          <NavigatorContainer>
            <ArrowButton isActive={canScrollToPreviousDay} onClick={handlePrevious}>
              <Icon element={ArrowLeft} color={canScrollToPreviousDay ? 'blue.800' : 'black.200'} />
            </ArrowButton>
          </NavigatorContainer>

          {dateInterval.map((dayDate, index) => {
            return (
              <div key={index} style={{ width: '100%' }}>
                <SingleDayDateContainer>
                  <Typography variant="SubHeadline1" color="black.800">
                    {format(new Date(dayDate), 'E')}
                  </Typography>
                  <Typography variant="Helper1" color="black.600">
                    {format(new Date(dayDate), 'MMM dd')}
                  </Typography>
                </SingleDayDateContainer>

                <div style={{ marginTop: 16 }}>
                  {loading && <LoadingContainer>{t('loading')}</LoadingContainer>}
                  {!loading && getAllAppointmentsInDay(dayDate).length === 0 && (
                    <NoSlotsContainer>{t('noSlots')}</NoSlotsContainer>
                  )}
                  {getAllAppointmentsInDay(dayDate).map((singleSlot, index) => (
                    <div key={index}>
                      <Button
                        variant={singleSlot?.date === selectedSlot?.date ? 'square-primary' : 'square-secondary'}
                        style={{ marginBottom: 8 }}
                        onClick={handleSelectSlot(singleSlot as Slot)}
                      >
                        {singleSlot?.date ? format(parseHealthieDate(singleSlot.date), 'h:mm aaa') : ''}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
          <div style={{ marginTop: 10 }}>
            <ArrowButton isActive onClick={handleNext}>
              <Icon element={ArrowRight} color={'blue.800'} />
            </ArrowButton>
          </div>
        </SlotsContainer>
      </Container>
    </BaseLayout>
  );
};
