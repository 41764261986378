import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as Share } from 'assets/vectors/fi-rr-share.svg';
import { ReactComponent as Logo } from 'assets/vectors/logoM.svg';
import { ReactComponent as DotsMenu } from 'assets/vectors/three-dots.svg';
import React from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './InstallPwaModal.styles';

type InstallPwaModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const InstallPwaModal: React.FC<InstallPwaModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home.installPwa' });

  const message = isIOS ? t('iosInstallMsg') : t('androidInstallMsg');

  return (
    <Modal
      isOpen={isOpen}
      className="_"
      overlayClassName="_"
      ariaHideApp={false}
      contentElement={(props, children) => <Styled.Modal {...props}>{children}</Styled.Modal>}
      overlayElement={(props, contentElement) => <Styled.Overlay {...props}>{contentElement}</Styled.Overlay>}
      onRequestClose={onClose}
    >
      <Styled.Arrow />
      <Styled.Content>
        <Button variant="icon" size="sm" onClick={onClose} aria-label={t('close')} style={{ alignSelf: 'flex-end' }}>
          <Icon element={CloseIcon} size={18} />
        </Button>
        <Icon element={Logo} color="other.white" size={56} />
        <Typography color="black.800" variant="Headline2">
          {t('install')}
        </Typography>
        <Typography color="black.800">{t('installDescription')}</Typography>
        <hr />
        <Styled.HelperText color="black.800" size={18} weight={500} lineHeight={24}>
          {t('tap')}
          <Icon
            style={{ display: 'inline', margin: `0px ${isIOS ? '8px' : '4px'}` }}
            element={isIOS ? Share : DotsMenu}
            size={26}
            color="blue.700"
          />
          {message}
        </Styled.HelperText>
      </Styled.Content>
    </Modal>
  );
};
