import { Form } from 'formik';
import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const Header = styled(Typography.Div)`
  padding: 24px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 24px;
  flex-grow: 1;
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 24px;
`;

export const MakeSureYourPasswordMatch = styled(Typography.Div)`
  margin: 8px 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.other.linen};
`;
