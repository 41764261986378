import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 16px;
`;

export const SIcon = styled(Icon)`
  align-self: flex-end;
`;

export const QuestionIcon = styled(Icon)`
  align-self: center;
`;

export const Title = styled(Typography)`
  margin: 20px 40px 40px 40px;
  text-align: center;
`;

export const NumberedItems = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-position: inside;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 22px 40px 22px;
  gap: 10px;
`;

export const SButton = styled(Button)`
  flex-basis: 50%;
  padding: 14px 19px;
  font-weight: 600;
  background-color: ${(props) => props.variant === 'secondary' && props.theme.color.other.linen};
`;
