import { Entry } from '__generated__/graphql';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseHealthieDate } from 'utils/helpers';

import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './SingleMeasurement.styles';

interface Props {
  measurements: Entry[];
}

export const SingleMeasurement: React.FC<Props> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.recordBlodPressureSync' });

  const { measurements } = props;
  const diastolic = measurements.find((item) => item?.category?.toLowerCase().includes('diastolic'));
  const systolic = measurements.find((item) => item?.category?.toLowerCase().includes('systolic'));
  const heartRateMeasurement = measurements.find((item) => item?.category?.toLowerCase().includes('heart rate'));

  return (
    <>
      <Styled.HorizontalLine />
      <Styled.Container>
        <div>
          <Typography color="black.600" variant="Helper1">
            {t('dateTitle', {
              value: systolic?.created_at ? format(parseHealthieDate(systolic?.created_at), `'at' h:mm aaa`) : '',
            })}
          </Typography>
        </div>
        <Styled.TextContainer>
          <Typography variant="Body1">
            {t('bloodPressure')}
            <Typography color="other.red" weight={600}>
              {t('bloodPressureValue', {
                diastolic: Number(diastolic?.metric_stat),
                systolic: Number(systolic?.metric_stat),
              })}
            </Typography>
            {t('unit')}
          </Typography>
          {heartRateMeasurement && (
            <Typography variant="Body1">
              {t('heartRate')}
              <Typography color="other.red" weight={600}>
                {t('heartRateValue', {
                  value: Number(heartRateMeasurement?.metric_stat),
                })}
              </Typography>
              {t('heartRateUnit')}
            </Typography>
          )}
        </Styled.TextContainer>
      </Styled.Container>
    </>
  );
};
