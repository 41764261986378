import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  text-align: center;
`;

export const SIcon = styled(Icon)`
  align-self: flex-end;
`;

export const HelperText = styled(Typography)`
  text-align: center;
`;

export const Modal = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.color.other.linen};
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  width: 95%;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid ${(props) => props.theme.color.other.linen};
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.color.other.modalBackground};
`;
