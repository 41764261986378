import { Maybe } from '__generated__/graphql';
import axios from 'axios';
import { env } from 'index';

import { ExercisesResponse } from './exercises.model';

type GetPhysiotecProgramPayload = {
  programId?: Maybe<string>;
};
export const getPhysiotecProgram = ({ programId }: GetPhysiotecProgramPayload): Promise<ExercisesResponse> => {
  let uri = `${env.REACT_APP_AWS_LAMBDA_URL}/physiotec-program-details`;
  if (env.REACT_APP_AWS_LAMBDA_URL.includes('dev'))
    uri = `${env.REACT_APP_AWS_LAMBDA_URL}/physiotec-program-details-stg`;
  return axios.post(
    uri,
    { program_id: programId },
    {
      headers: {
        'healthie-token': localStorage.getItem('token'),
      },
    },
  );
};
