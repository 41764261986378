import { Goal } from '__generated__/graphql';
import { useQuestionnaires } from 'pages/questionnaires/useQuestionnaires';
import { useTranslation } from 'react-i18next';

export const useAssesmentGoals = (): Goal | undefined => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.questionnaires' });
  const { anyVisible, visibleItems } = useQuestionnaires();

  return anyVisible
    ? ({
        id: '',
        goal_histories: [],
        name: t('weeklyTaskTitle'),
        subgoals: visibleItems?.map((item) => ({
          id: '',
          goal_histories: [],
          is_completed_for_date: !!item.completed_course_item,
        })),
      } as unknown as Goal)
    : undefined;
};
