import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';

import { Button } from 'components/ui/Button/Button';
import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

export const FitbitSync: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.fitbitSync' });
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const fitbitStateParam = searchParams.get('state');
  const nextLocation = fitbitStateParam ? decodeURIComponent(fitbitStateParam) : '/';

  const isGoingToGeneralSettings = nextLocation === paths.settings.main;

  return (
    <TaskCompletionPage
      headerTitle={t('connectingWithFitbit')}
      taskTitle={t('connectedSuccessfully')}
      taskDescription={t('connectedDescription')}
      footer={
        <Button variant="linen" onClick={() => navigate(nextLocation)}>
          {t(isGoingToGeneralSettings ? 'goToGeneralSettings' : 'goHome')}
        </Button>
      }
      color="other.backgroundBlue"
    />
  );
};
