import { useApolloClient, useMutation } from '@apollo/client';
import { SAVE_USER_FITBIT_CRED } from 'api/fitbit/fitbit.mutation';
import { GET_FORM_BY_NAME } from 'api/fitbit/fitbit.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import CryptoJS from 'crypto-js';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import React, { useState } from 'react';
import { AuthProvider, TAuthConfig } from 'react-oauth2-code-pkce';
import { useOutlet } from 'react-router-dom';
import { paths } from 'router/paths';

// Encrypt function using crypto-js
function encrypt(text: string, secretKey: string) {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
}

export const FitbitAuthProvider: React.FC = () => {
  const outlet = useOutlet();
  const { getToken, hasLoggedInBefore } = useCurrentFitbitTokens();
  const [afterLogin, setAfterLogin] = useState(false);

  const redirectTo = paths.fitbitSync;

  const client = useApolloClient();
  const [saveUserFitbitCred] = useMutation(SAVE_USER_FITBIT_CRED);

  const afterFitbitLogin = async () => {
    const {
      data: { currentUser },
    } = await client.query({
      query: GET_CURRENT_USER_DATA,
    });

    const {
      data: { formAnswerGroups },
    } = await client.query({
      query: GET_FORM_BY_NAME,
      variables: {
        form_name: 'Motiv-Fitbit',
        user_id: currentUser?.id,
      },
    });

    if (
      formAnswerGroups &&
      formAnswerGroups[0].form_answers.filter((answer) => answer.label === 'access_token' && !answer.displayed_answer)
        .length > 0
    ) {
      const motiv_fitbit = formAnswerGroups[0];

      const secret = process.env.REACT_APP_FITBIT_SAVE_CRED_KEY || '';
      // Encrypt tokens before saving
      const encryptedAccessToken = encrypt(getToken('ROCP_token') || '', secret);
      const encryptedRefreshToken = encrypt(getToken('ROCP_refreshToken') || '', secret);

      saveUserFitbitCred({
        variables: {
          form_answer: {
            answer: encryptedAccessToken,
            custom_module_id:
              motiv_fitbit.form_answers.filter((answer) => answer.label === 'access_token')[0].custom_module?.id || '',
            user_id: currentUser?.id,
          },
          form_group_id: motiv_fitbit.id,
        },
      });

      saveUserFitbitCred({
        variables: {
          form_answer: {
            answer: encryptedRefreshToken,
            custom_module_id:
              motiv_fitbit.form_answers.filter((answer) => answer.label === 'refresh_token')[0].custom_module?.id || '',
            user_id: currentUser?.id,
          },
          form_group_id: motiv_fitbit.id,
        },
      });
    }
  };

  const authConfig: TAuthConfig = {
    autoLogin: false,
    clientId: '23QQYW' || '',
    authorizationEndpoint: 'https://www.fitbit.com/oauth2/authorize',
    tokenEndpoint: 'https://api.fitbit.com/oauth2/token',
    redirectUri: `https://${window.location.host}${redirectTo}`,
    scope: 'activity heartrate',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onRefreshTokenExpire: () => {},
    postLogin: () => {
      setAfterLogin(true);
      afterFitbitLogin();
    },
  };

  if (!hasLoggedInBefore && !afterLogin) {
    return <AuthProvider authConfig={authConfig}>{outlet}</AuthProvider>;
  }
  return <>{outlet}</>;
};
