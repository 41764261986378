import { endOfWeek, format, startOfWeek } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getCurrentWeek = (): { start: string; end: string } => {
  // Define the timezone
  const timeZone = 'America/Chicago';

  const now = new Date();
  const zonedNow = utcToZonedTime(now, timeZone);

  const startOfWeekDate = startOfWeek(zonedNow, { weekStartsOn: 1 });
  const endOfWeekDate = endOfWeek(zonedNow, { weekStartsOn: 1 });

  const start = format(startOfWeekDate, 'yyyy-MM-dd');
  const end = format(endOfWeekDate, 'yyyy-MM-dd');

  return { start, end };
};

// const formatDate = (date: Date): string => {
//   const year = date.getFullYear();
//   const month = (date.getMonth() + 1).toString().padStart(2, '0');
//   const day = date.getDate().toString().padStart(2, '0');
//   return `${year}-${month}-${day}`;
// };

export interface Exercise {
  execise_id: string;
  completed: boolean;
  tags: string;
  title: string;
  dateCompleted: string;
}

export interface WeeklyReportProps {
  exerciseData: Exercise[];
  startDate: string;
  endDate: string;
}

export function generateWeeklyExerciseTables(weeklyData: WeeklyReportProps[]): string {
  let html = '';

  weeklyData.forEach(({ startDate, endDate, exerciseData }, index) => {
    const formattedStartDate = startDate ? new Date(startDate).toISOString().split('T')[0] : '';
    const formattedEndDate = endDate ? new Date(endDate).toISOString().split('T')[0] : '';
    const weekLabel = `Week of ${formattedStartDate} to ${formattedEndDate}`;

    const paddingTop = index === 0 ? '20px' : '0';
    const paddingBottom = '20px';

    html += `
          <h2 style="padding: ${paddingTop} 0 ${paddingBottom}; font-size: '24px'}; font-weight: bold">${weekLabel}</h2>
            <table>
              <thead>
                  <tr>
                    <th style="text-align: left;">Exercise ID</th>
                    <th style="text-align: left;">Title</th>
                    <th style="text-align: left;">Tags</th>
                    <th style="text-align: left;">Completed</th>
                    <th style="text-align: left;">Date Completed</th>
                  </tr>
              </thead>
              <tbody>
      `;

    exerciseData.forEach((exercise) => {
      html += `
              <tr>
                  <td>${exercise.execise_id}</td>
                  <td>${exercise.title}</td>
                  <td>${exercise.tags}</td>
                  <td>${exercise.completed ? 'Yes' : 'No'}</td>
                  <td>${new Date(exercise.dateCompleted).toLocaleString()}</td>
              </tr>
          `;
    });

    html += `
              </tbody>
          </table>
      `;
  });

  return html;
}
