import { gql } from '__generated__';

export const CREATE_QUESTIONNAIRE_ANSWER = gql(`
  mutation createQuestionnaireAnswer($finished: Boolean, $custom_module_form_id: String, $user_id: String, $form_answers: [FormAnswerInput!]!, $course_item_id: ID, $course_id: String) {
    createFormAnswerGroup(input: {custom_module_form_id: $custom_module_form_id, user_id: $user_id, form_answers: $form_answers, finished: $finished, course_item_id: $course_item_id, course_id: $course_id}) {
      newFormAnswerGroup: form_answer_group {
        id
        name
        created_at
        updated_at
        finished
        user {
          id
          next_required_step
        }
        is_group_appt_note
        custom_module_form {
          id
        }
        individual_client_notes {
          id
          content
          user {
            id
            full_name
          }
        }
      }
      messages {
        field
        message
      }
    }
  }
`);

export const UPDATE_QUESTIONNAIRE_ANSWER = gql(`
  mutation updateQuestionnaireAnswer($id: ID,  $finished: Boolean, $form_answers: [FormAnswerInput!]!, $course_item_id: ID) {
    updateFormAnswerGroup(input: {id: $id, finished: $finished, form_answers: $form_answers, course_item_id: $course_item_id}) {
      formAnswerGroup: form_answer_group {
        name
        id
        created_at
        updated_at
        user_id
        finished
        locked_at 
        is_group_appt_note
        form_answers(is_for_editing: true) {
          id
          answer
          user_id
          label
          displayed_answer
          custom_module {
            id
            options
            label
            sublabel
            mod_type
            other_module_ids_to_autoscore_on
            custom_module_form_section_id
            custom_module_form_id
            required
            custom_module_condition {
              id
              conditional_custom_module_id
              filter_type
              value_to_filter
            }
          }
        }
      }
      messages {
        field
        message
      }
    }
  }
`);

export const CREATE_DASI_ENTRY = gql(`
  mutation createDasiEntry(
    $type: String,
    $category: String,
    $description: String,
    $user_id: String,
    $reflection: String
  ) {
    createEntry(input: {
      type: $type,
      description: $description,
      user_id: $user_id,
      category: $category,
      reflection: $reflection
    })
      {
      entry {
        id
      }
      messages {
        field
        message
      }
    }
  }
`);
