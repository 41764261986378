import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const TickIcon = styled(Icon)`
  position: absolute;
  pointer-events: none;
  display: none;
  right: 18px;
`;

export const TabInput = styled.input`
  position: relative;
  appearance: none;
  margin: 0;
  flex-shrink: 0;
  background: ${({ theme }) => theme.color.other.white};
  border: 2px solid ${({ theme }) => theme.color.black[900]};
  &[type='checkbox'] {
    width: 20px;
    height: 20px;
    border-radius: 2px;

    &:checked {
      border: none;
      ~ ${TickIcon} {
        display: flex;
      }
    }
  }
  &[type='radio'] {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &:checked {
      border-color: ${({ theme }) => theme.color.blue[500]};
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.blue[500]};
      }
    }
  }
`;

export const TabLabel = styled(Typography.Label)<{ checked?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px 16px;
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  background-color: ${({ theme, checked }) => (checked ? theme.color.blue[700] : theme.color.other.white)};
  border-radius: 8px;
  cursor: pointer;
`;
