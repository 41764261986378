import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const ProgressPage = styled.main`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProgressSubTitle = styled(Typography)`
  margin-top: 8px;
`;
