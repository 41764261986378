import { icons } from 'assets/vectors/iconsList';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button/Button';
import * as Styled from './Accordion.styles';

export type AccordionItem = {
  label: string;
  content: React.ReactNode;
  variant?: 'accordion';
};

export interface AccordionProps {
  accordionItems: AccordionItem[];
}

export const Accordion: React.FC<AccordionProps> = ({ accordionItems }: AccordionProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.accordion' });

  const [isOpen, setIsOpen] = useState(accordionItems.map(() => false));

  const toggleAccordion = (index: number) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <Styled.AccordionSections>
      {accordionItems.map((item, index) => (
        <Styled.AccordionSection key={index} isAccordion={item.variant === 'accordion'}>
          <Styled.AccordionTitle
            color="blue.700"
            onClick={() => toggleAccordion(index)}
            weight={item.variant === 'accordion' ? 600 : 500}
          >
            {item.variant === 'accordion' ? item.label : `${index + 1}. ` + item.label}
            <Button variant="icon" style={{ width: 'auto' }} aria-label={isOpen[index] ? t('hide') : t('show')}>
              <Styled.ArrowIcon element={icons['fi-rr-angle-small-up.svg']} isOpen={isOpen[index]} />
            </Button>
          </Styled.AccordionTitle>
          <Styled.AccordionContent
            variant="Helper1"
            isActive={isOpen[index]}
            isAccordion={item.variant === 'accordion'}
          >
            <>{item.content}</>
          </Styled.AccordionContent>
        </Styled.AccordionSection>
      ))}
    </Styled.AccordionSections>
  );
};
