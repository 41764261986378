import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const ReadingsContainer = styled.div`
  margin-top: 24px;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 74px;
`;

export const AnswerItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
  padding: 20px 20px;
`;

export const AnswerItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-top: 1px solid ${(props) => props.theme.color.black[50]};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const TimeTabsContainer = styled.div`
  padding: 10px 16px;
`;

export const AnswerText = styled(Typography.Div)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  margin: 10px 18px;
  align-items: center;
`;

export const PregressDescriptionTabs = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-left: 25px;
`;
