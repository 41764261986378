import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const TimeTabsContainer = styled.div`
  padding: 10px 16px;
`;

export const TimeSwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const SelectContainer = styled.div`
  width: 60%;
  margin-left: 1rem;
  margin-top: 24px;
`;

export const ListItem = styled.div`
  boarder: 1 solid;
`;

export const ProgressContainer = styled.div`
  display: flex;
  margin: 10px 18px;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 74px;
`;

export const ItemContainer = styled.li`
  display: inline-flex;
`;

export const IconContainer = styled.div`
  margin-left: 1rem;
  padding-top: 4px;
`;
