import { theme } from 'assets/styles/theme';
import get from 'lodash/get';
import React from 'react';

import { TextColor, TextStyle, TextVariant, textStyles } from './Typography.model';
import * as Styled from './Typography.styles';

type IsDefinedVariant =
  | {
      variant?: TextVariant;
      size?: never;
      lineHeight?: never;
      weight?: never;
    }
  | {
      variant?: never;
      size?: TextStyle['size'];
      lineHeight?: TextStyle['lineHeight'];
      weight?: TextStyle['weight'];
    };

type Props = IsDefinedVariant & {
  as?: TextStyle['as'];
  color?: TextColor;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  tabIndex?: number;
  style?: React.CSSProperties;
};

type Compound = {
  H1: React.FC<Props & React.HTMLProps<HTMLSpanElement>>;
  H2: React.FC<Props & React.HTMLProps<HTMLSpanElement>>;
  H3: React.FC<Props & React.HTMLProps<HTMLSpanElement>>;
  H4: React.FC<Props & React.HTMLProps<HTMLSpanElement>>;
  Div: React.FC<Props & React.HTMLProps<HTMLDivElement>>;
  Span: React.FC<Props & React.HTMLProps<HTMLSpanElement>>;
  P: React.FC<Props & React.HTMLProps<HTMLParagraphElement>>;
  A: React.FC<Props & React.HTMLProps<HTMLLinkElement>>;
  Label: React.FC<Props & React.HTMLProps<HTMLLabelElement>>;
};

export const Typography: React.FC<Props> & Compound = ({
  variant,
  as,
  size = 16,
  lineHeight = 20,
  weight = 400,
  color = 'black.900',
  className,
  children,
  ...rest
}: Props) => {
  const textStyle = variant ? textStyles[variant] : { size, lineHeight, weight };
  const textColor = get(theme.color, color);
  const textAs = as ? as : variant ? textStyle.as : 'span';
  return (
    <Styled.Component
      as={textAs}
      $color={textColor}
      $size={textStyle.size}
      $weight={textStyle.weight}
      $lineHeight={textStyle.lineHeight}
      $letterSpacing={textStyle.letterSpacing}
      className={className}
      {...rest}
    >
      {children}
    </Styled.Component>
  );
};

Typography.Div = (props) => <Typography as={'div'} {...props} />;

Typography.Span = (props) => <Typography as={'span'} {...props} />;

Typography.P = (props) => <Typography as={'p'} {...props} />;

Typography.A = (props) => <Typography as={'a'} {...props} />;

Typography.Label = (props) => <Typography as={'label'} {...props} />;

Typography.H1 = (props) => <Typography as={'h1'} {...props} />;

Typography.H2 = (props) => <Typography as={'h2'} {...props} />;

Typography.H3 = (props) => <Typography as={'h3'} {...props} />;

Typography.H4 = (props) => <Typography as={'h4'} {...props} />;
