import { theme } from 'assets/styles/theme';
import { ReactComponent as NoDataIcon } from 'assets/vectors/fi-rr-no-data.svg';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { Spinner } from '../Spinner/Spinner';
import { Typography } from '../Typography/Typography';
import { LEGEND_HEIGHT, htmlLegendPlugin } from './CustomLegendPlugin';
import {
  ChartContainer,
  LegendComponent,
  LegendContainer,
  NoDataContainer,
  NoDataIconComponent,
  NoDataTitle,
  ResponsiveContainer,
} from './Diagram.styled';

export interface DiagramProps {
  data: number[];
  // Label for each data point
  labels: string[];
  color?: string;
  lineColor?: string;
  unit?: string;
  width?: string;
  height?: string;
  ariaLabel: string;
  dataLegendLabel: string;
  loading?: boolean;
  showTendencyOnly?: boolean;
}

export const Diagram: React.FC<DiagramProps> = ({
  data = [],
  labels = [],
  color = theme.color.other.green,
  lineColor,
  unit,
  height,
  width,
  ariaLabel,
  dataLegendLabel,
  loading,
  showTendencyOnly,
}: DiagramProps) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);
  const { t } = useTranslation('translation', { keyPrefix: 'components.diagram' });

  const getDiagramDescription = () => labels.map((label, index) => `${label} - ${data[index]} ${unit}`).join(', ');

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    font: {
      family: 'poppins',
    },
    plugins: {
      legend: {
        display: false,
      },
      // @ts-expect-error: There is a custom plugin added that is not in the type definition
      htmlLegend: {
        containerID: 'legend-container',
      },
      tooltip: {
        borderColor: lineColor || color,
        backgroundColor: theme.color.other.white,
        titleColor: theme.color.black[900],
        bodyColor: theme.color.black[900],
        displayColors: false,
        cornerRadius: 8,
        padding: 6,
        caretSize: 4,
        borderWidth: 1,
        bodyFont: { weight: '500', family: 'poppins', size: 10 },
        callbacks: {
          title: () => '',
          label: (value) =>
            unit ? `${value.parsed.y.toLocaleString('en-US')} ${unit}` : value.parsed.y.toLocaleString('en-US'),
        },
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          autoSkipPadding: 16,
          color: theme.color.black[400],
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          autoSkipPadding: 32,
          maxRotation: 0,
          color: theme.color.black[400],
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        pointStyle: showTendencyOnly ? false : 'circle',
        radius: 5,
        hoverRadius: 5.5,
        hitRadius: 5.5,
      },
      line: {
        borderWidth: 2,
      },
    },
  };

  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      const gradient = chart.ctx.createLinearGradient(0, 0, 0, height ? parseInt(height) : chart.chartArea.height);
      gradient.addColorStop(0, color);
      gradient.addColorStop(1, theme.color.other.white);

      setChartData({
        datasets: [
          {
            data,
            fill: true,
            tension: 0.4,
            backgroundColor: gradient,
            borderColor: lineColor || color,
            pointBackgroundColor: lineColor || color,
            pointBorderColor: theme.color.other.white,
            pointHoverBorderColor: theme.color.other.white,
            label: dataLegendLabel,
          },
        ],
        labels,
      });
    }
  }, [color, data, labels, dataLegendLabel, height, lineColor]);

  if (loading) {
    return (
      <NoDataContainer width={width} height={height}>
        <Spinner size={60} />
      </NoDataContainer>
    );
  }

  if (isEmpty(data) || every(data, isNaN)) {
    return (
      <NoDataContainer width={width} height={`calc(${height} + ${LEGEND_HEIGHT})`}>
        <NoDataIconComponent title={t('noDataIconAlt')} element={NoDataIcon} />
        <NoDataTitle variant="Body1Bold">{t('noDataTitle')}</NoDataTitle>
        <Typography variant="Helper1">{t('noDataSubtitle')}</Typography>
      </NoDataContainer>
    );
  }

  return (
    <ChartContainer>
      <LegendContainer>
        <LegendComponent id="legend-container" />
      </LegendContainer>
      <ResponsiveContainer width={width} height={height}>
        <Line
          data={chartData}
          options={chartOptions}
          plugins={[htmlLegendPlugin]}
          ref={chartRef}
          aria-label={`${ariaLabel} ${getDiagramDescription()}`}
          role="img"
        />
      </ResponsiveContainer>
    </ChartContainer>
  );
};
