import jwtDecode from 'jwt-decode';

interface TokenPayload {
  exp: number;
}

export const isTokenExpired = (token: string): boolean => {
  const tokenPayload: TokenPayload = jwtDecode(token);
  const expirationTime: number = tokenPayload.exp * 1000;
  const currentTime: number = Date.now();
  return currentTime >= expirationTime;
};
