import styled from 'styled-components';

export const TabsWrapper = styled.div`
  padding: 16px;
`;

export const ChatWrapper = styled.div`
  position: relative;
`;

export const AlertWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const MessageWrapper = styled.div`
  margin: 24px;
`;

export const SendIconWrapper = styled.div`
  svg {
    margin-left: 4px;
  }
`;

export const Bold = styled.strong``;

export const StyledButton = styled.a`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #2e69c1;
`;
