import { Maybe } from '__generated__/graphql';
import { format, parse, parseISO, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';

import { MonthOption } from '../medications/utils';

export type DateRange = {
  start: string;
  end: string;
};

const DEFAULT_FORMAT = 'yyyy-MM-dd';

const getDaysAgo = (days: number) => subDays(new Date(), days);

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getPreviousWeekRange = (): DateRange => {
  const startRange = format(getDaysAgo(14), DEFAULT_FORMAT);
  const endRange = format(getDaysAgo(7), DEFAULT_FORMAT);
  return { start: startRange, end: endRange };
};

export const getLast7DaysRange = (): DateRange => {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const startDate = formatDate(sevenDaysAgo);
  const endDate = formatDate(today);
  return { start: startDate, end: endDate };
};

export const getCurrentMonthRange = (option: MonthOption | null): DateRange => {
  if (option) {
    return {
      start: option.dateRange.split(':')[0],
      end: option.dateRange.split(':')[1],
    };
  }
  const today = new Date();
  const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

  const startDate = formatDate(currentMonthStart);
  const endDate = formatDate(today);
  return { start: startDate, end: endDate };
};

export const getAllTimeRange = (): DateRange => {
  const today = new Date();
  const startDate = '2000-01-01'; // Fixed start date
  const endDate = formatDate(today);
  return { start: startDate, end: endDate };
};

export const getDateRange = (
  currentTab: number,
  subtab: TimeSwitchType,
  selectedMonth: MonthOption | null,
): DateRange => {
  if (currentTab === TimeTabsIndex.week) {
    if (subtab === 'last') {
      return getPreviousWeekRange();
    } else if (subtab === 'this') {
      return getLast7DaysRange();
    }
  } else if (currentTab === TimeTabsIndex.month) {
    return getCurrentMonthRange(selectedMonth);
  }
  return getAllTimeRange();
};

export const formatDateToETString = (inputDateStr: string): string => {
  const inputDate = new Date(inputDateStr);
  const etTimeZone = 'America/New_York';

  // Convert input date to the corresponding Eastern Time (ET) time zone
  const etDate = utcToZonedTime(inputDate, etTimeZone);

  // Custom formatting
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = etDate.getDate();
  const month = months[etDate.getMonth()];
  const hours = etDate.getHours();
  const minutes = etDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hour12 = hours % 12 === 0 ? 12 : hours % 12;
  const paddedMinutes = String(minutes).padStart(2, '0');

  return `${day} ${month} at ${hour12}:${paddedMinutes} ${ampm}`;
};

export function parseHealthieTime(givenTime: string): string {
  const parsedTime = parse(givenTime, 'yyyy-MM-dd HH:mm:ss XX', new Date());
  const parsedHealthieTime = format(parsedTime, 'yyyy-MM-dd HH:mm:ssXXX');
  return parsedHealthieTime;
}

export const formatDateToReadableDate = (dateString: Maybe<string>, formatString = "iii MMM d 'at' h:mm a"): string => {
  if (!dateString) {
    return '';
  }
  const date = parseISO(dateString);
  return format(date, formatString);
};
