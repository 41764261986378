import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { TTokenData } from 'react-oauth2-code-pkce/dist/Types';

interface UseFtitbitLoginType {
  loading: boolean;
  error: string | null;
  token: string;
  login: (state?: string) => void;
  tokenData: TTokenData | undefined;
}

export const useFitbitLogin = (): UseFtitbitLoginType => {
  const { login, token, tokenData, loginInProgress, error } = useContext<IAuthContext>(AuthContext);
  return { loading: loginInProgress, error, token, login, tokenData };
};
