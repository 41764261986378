import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';

export const Container = styled.div`
  background-color: white;
  padding: 16px 23px;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const FooterButtonStyled = styled(Button)`
  color: ${(props) => props.theme.color.black[900]};
`;
