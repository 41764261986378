import { Goal } from '__generated__/graphql';
import { ReactComponent as Progress } from 'assets/vectors/fi-rr-progress2.svg';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'utils/types';

import { Container } from 'components/layout/Error/ErrorLayout.styles';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { ActivityTile } from './ActivityTile';
import { BloodPressureTile } from './BloodPressureTile';
import { MedicationsTile } from './MedicationsTile';

interface ContentProps {
  goals: Maybe<Goal[]>;
  startDate: string | null;
  endDate: string | null;
  isSingle?: boolean;
}

export const WeeklyPlanContent: React.FC<ContentProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.weeklyReport' });
  const { hasLoggedInBefore } = useCurrentFitbitTokens();

  const { startDate, endDate, goals, isSingle } = props;
  if (startDate && endDate && goals) {
    const props = {
      goals,
      startDate,
      endDate,
      isSingle,
    };

    return (
      <>
        <BloodPressureTile {...props} />
        {hasLoggedInBefore && <ActivityTile {...props} />}
        <MedicationsTile {...props} />
      </>
    );
  }

  return (
    <Container>
      <Icon element={Progress} title="Information icon" size={56} color={'other.white'} strokeColor={'black.600'} />
      <Typography variant="Body1">{t('carePlanNotAvailable')}</Typography>
    </Container>
  );
};
