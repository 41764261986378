import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Button, ButtonText, Indicator, Wrapper } from './Tabs.styled';

export interface Tab {
  label: string;
  ariaLabel: string;
}

export interface Props {
  selectedTab?: number;
  tabs: Tab[];
  onTabChange: (currentTab: number) => void;
}

export const Tabs: React.FC<Props> = ({ selectedTab = 0, tabs, onTabChange }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [indicatorLeft, setIndicatorLeft] = useState(0);
  const [currentTab, setCurrentTab] = useState(selectedTab);

  useLayoutEffect(() => {
    const updateIndicator = () => {
      if (wrapperRef.current) {
        const buttons = wrapperRef.current.querySelectorAll('button');
        if (buttons.length > currentTab) {
          const activeButton = buttons[currentTab];
          setIndicatorWidth(activeButton.offsetWidth);
          setIndicatorLeft(activeButton.offsetLeft);
        }
      }
    };
    updateIndicator();
    window.addEventListener('resize', updateIndicator);
    return () => window.removeEventListener('resize', updateIndicator);
  }, [currentTab, tabs.length]);

  useEffect(() => {
    setCurrentTab(selectedTab);
  }, [selectedTab]);

  const onClick = (tabIndex: number) => {
    setCurrentTab(tabIndex);
    onTabChange(tabIndex);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Indicator style={{ width: `${indicatorWidth}px`, left: `${indicatorLeft}px` }} />
      {tabs.map((tab, index) => {
        const isSelected = currentTab === index;
        return (
          <Button key={index} aria-label={tab.ariaLabel} onClick={() => onClick(index)}>
            <ButtonText variant="Helper1" color={isSelected ? 'other.white' : 'black.800'}>
              {tab.label}
            </ButtonText>
          </Button>
        );
      })}
    </Wrapper>
  );
};
