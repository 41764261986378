import { ReactComponent as MessagesColor } from 'assets/vectors/fi--rr-messages-color.svg';
import { ReactComponent as Messages } from 'assets/vectors/fi--rr-messages.svg';
import { ReactComponent as ProgressColor } from 'assets/vectors/fi-rr-progress2-color.svg';
import { ReactComponent as Progress } from 'assets/vectors/fi-rr-progress2.svg';
import { ReactComponent as SettingsColor } from 'assets/vectors/fi-rr-settings-color.svg';
import { ReactComponent as Settings } from 'assets/vectors/fi-rr-settings.svg';
import { ReactComponent as Home } from 'assets/vectors/fi=rr=home.svg';
import { ReactComponent as HomeColor } from 'assets/vectors/fi=rr=home_color.svg';
import React from 'react';

export type NavItem = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  filledIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  route: string;
};

// TODO: get proper paths
export const navItems: NavItem[] = [
  {
    icon: Home,
    filledIcon: HomeColor,
    text: 'home',
    route: '/',
  },
  {
    icon: Progress,
    filledIcon: ProgressColor,
    text: 'progress',
    route: '/progress',
  },
  {
    icon: Messages,
    filledIcon: MessagesColor,
    text: 'chat',
    route: '/chat',
  },
  {
    icon: Settings,
    filledIcon: SettingsColor,
    text: 'settings',
    route: '/settings',
  },
];
