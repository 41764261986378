import { ReactComponent as InformationIcon } from 'assets/vectors/fi-rr-information.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { HorizontalFlexContainer, StepsBox } from '../Activity.styles';

interface StepsSectionProps {
  average: number;
  currentSteps: number;
  shouldShowAverage: boolean;
  onShowInfo: () => void;
}

export const StepsSection: React.FC<StepsSectionProps> = ({ average, currentSteps, shouldShowAverage, onShowInfo }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.activity' });

  const Information = (
    <span style={{ display: 'inline-block', marginLeft: '4px' }}>
      <Button variant="icon" aria-label={t('showInformation')} onClick={onShowInfo}>
        <Icon element={InformationIcon} color="black.600" size={16} aria-hidden="true" />
      </Button>
    </span>
  );

  return (
    <HorizontalFlexContainer>
      <StepsBox>
        <Typography variant="Body1" color="black.600">
          {t('lastReadingLabel')}
        </Typography>
        <Typography.Span color="black.800">
          <Typography.Span size={20} weight={500}>
            {currentSteps.toLocaleString('en-US')}
          </Typography.Span>{' '}
          {t('perDay')}
        </Typography.Span>
      </StepsBox>
      <StepsBox>
        <Typography.Span variant="Body1" color="black.600" style={{ display: 'flex', alignItems: 'center' }}>
          {t('averageStepsLabel')}
          {!shouldShowAverage && Information}
        </Typography.Span>
        <Typography.Span color="black.800">
          <Typography.Span size={20} weight={500}>
            {shouldShowAverage ? average.toLocaleString('en-US') : '-'}
          </Typography.Span>{' '}
          {shouldShowAverage && t('perDay')}
        </Typography.Span>
      </StepsBox>
    </HorizontalFlexContainer>
  );
};

export default StepsSection;
