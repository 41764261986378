import { useCurrentToken } from 'hooks/useCurrentToken';
import { useOnboardingFlow } from 'hooks/useOnboardingFlow';
import { ExercisesLoading } from 'pages/exercisesList/components/ExercisesLoading';
import { ActivityLoading } from 'pages/progress/activity/components/ActivityLoading';
import React from 'react';
import { Navigate, matchPath, useLocation } from 'react-router-dom';

import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import { paths } from '../paths';

interface Props {
  children: JSX.Element;
}

export const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { getToken } = useCurrentToken();
  const { checkingInProgress } = useOnboardingFlow();
  const { state, pathname } = useLocation();

  if (!getToken()) {
    return <Navigate to={paths.welcomePage} replace />;
  }

  if (checkingInProgress) {
    if (matchPath(paths.completeYourExerciseProgram, pathname)) {
      return <ExercisesLoading />;
    }

    if (
      matchPath(paths.activity, pathname) ||
      matchPath(paths.keepMoving, pathname) ||
      matchPath(paths.bloodPressure, pathname) ||
      matchPath(paths.recordYourBloodPressureSync, pathname) ||
      matchPath(paths.recordYourBloodPressure, pathname)
    ) {
      return <ActivityLoading />;
    }

    return <LoadingPage withLogo={state?.onboarding} />;
  }

  return children;
};
