import { ReactComponent as Phone } from 'assets/vectors/fi-rr-phone-no.svg';
import { AppointmentCard } from 'pages/home/components/Card/AppointmentCard';
import * as Styled from 'pages/home/components/Cards/Cards.styles';
import { useGetAllAppointmentTypes } from 'pages/scheduleAppointment/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { AppointmentsList as AppointmentsListP } from './types';

type AppointmentsListProps = AppointmentsListP & {
  isFirstAppointment?: boolean;
  openDownloadZoomModal?: () => void;
};

export const AppointmentsList: React.FC<AppointmentsListProps> = (props) => {
  const { items, isPast, isFirstAppointment, openDownloadZoomModal } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'pages.appointments' });

  const appointmentTypes = useGetAllAppointmentTypes();

  return (
    <>
      {items.length === 0 && (
        <Styled.NoAppointmentsContainer>
          <Icon aria-label={t('noAppointments').toString()} element={Phone} size={40} color="black.600" />
          <Typography variant="Body1Bold" color="black.600">
            {t('noAppointments')}
          </Typography>
        </Styled.NoAppointmentsContainer>
      )}
      {!!items.length && (
        <Styled.CardsContainer>
          {items.map(({ appointmentTypeId, id, ...appointment }) => (
            <Styled.CardWrapper key={id}>
              <AppointmentCard
                {...appointment}
                appointmentId={id}
                isPast={isPast}
                title={appointmentTypes.find((item) => item.id === appointmentTypeId)?.name || t('appointment')}
                isFirstAppointment={isFirstAppointment}
                openDownloadZoomModal={openDownloadZoomModal}
              />
            </Styled.CardWrapper>
          ))}
        </Styled.CardsContainer>
      )}
    </>
  );
};
