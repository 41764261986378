import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
`;

export const SIcon = styled(Icon)`
  align-self: flex-end;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  flex-direction: row;
  margin-top: 58px;
  justify-content: center;
`;

export const Descriptions = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const Description = styled(Typography)`
  text-align: center;
  padding: 0 47px;
`;
