import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths } from 'router/paths';

import { Typography } from 'components/ui/Typography/Typography';
import { TextColor } from 'components/ui/Typography/Typography.model';

import { HeaderContainer, StyledButton } from './BaseLayout.styles';

export interface HeaderProps {
  title?: string | null;
  children?: React.ReactNode;
  variant?: 'back';
  showBack?: boolean;
  helpColor?: TextColor;
  onBackClick?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  children,
  title,
  variant,
  helpColor = 'blue.600',
  onBackClick,
  showBack = true,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.baseLayout' });

  if (variant === 'back') {
    return (
      <HeaderContainer>
        {showBack ? (
          <StyledButton variant="back" onClick={onBackClick} aria-label={t('goBack')} />
        ) : (
          <div style={{ margin: '1rem' }}></div>
        )}
        {title ? <Typography.Div variant="Helper1">{title}</Typography.Div> : children}
        <Link to={`${paths.settings.main}/${paths.settings.getHelp}`} style={{ textDecoration: 'none' }}>
          <Typography.Div weight={600} color={helpColor}>
            {t('help')}
          </Typography.Div>
        </Link>
      </HeaderContainer>
    );
  }

  return (
    <>
      {title ? <Typography variant="Headline1">{title}</Typography> : children}
      <Link to={`${paths.settings.main}/${paths.settings.getHelp}`} style={{ textDecoration: 'none' }}>
        <Typography weight={600} color={helpColor}>
          {t('help')}
        </Typography>
      </Link>
    </>
  );
};
