import styled, { css } from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
  margin-top: 127px;
`;

export const Headline = styled.div`
  margin-bottom: 24px;
`;

export const DiagramContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -16px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.color.other.white};
  padding: 16px;
`;

export const TimeSwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const Section = styled.section`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HorizontalFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const commonBox = css`
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  background-color: ${({ theme }) => theme.color.other.white};
  border-radius: 8px;
`;

export const StepsBox = styled.div`
  ${commonBox}
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;

export const BoxVertical = styled(StepsBox)`
  flex-direction: row;
  padding: 16px;
`;

export const GoalSection = styled.div`
  ${commonBox}
  padding: 19px 27px;
  display: flex;
  justify-content: center;
  gap: 35px;

  align-items: center;
`;

export const GoalText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const GoodJobText = styled(Typography)`
  margin: 8px 0;
`;

export const ViewReadingsButton = styled.button`
  ${commonBox}
  cursor: pointer;
  padding: 14px 16px;
  text-align: left;
`;

export const CircularProgressContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`;

export const HalfContainer = styled.div`
  width: 50%;
`;

export const EmptyGoalCircleLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyGoalCircleLabelText = styled.span`
  margin-right: 4px;
  white-space: nowrap;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const ModalTitle = styled(Typography)`
  margin-bottom: 8px;
`;

export const ModalText = styled(Typography)`
  margin-bottom: 34px;
  text-align: center;
`;

export const ModalButton = styled(Button)`
  width: auto;
`;
