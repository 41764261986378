import { gql } from '__generated__/gql';

export const GET_BLOOD_PRESSURE_MEASUREMENTS = gql(`
  query entries(
    $start_range: String
    $end_range: String
  ) {
    entries(
      start_range: $start_range
      end_range: $end_range
      page_size: 500
      category: "Blood Pressure"
      type: "MetricEntry"
    ) {
      type
      name
      category
      id
      description
      created_at
      updated_at
      subentries {
        created_at
        name
        type
        category
        metric_stat_string
        metric_stat
      }
    }
  }
`);
