import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.other.yellowAlert};
  padding: 14px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
`;

export const IconButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  height: 20px;
  padding: 0;
`;
