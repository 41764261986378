import { useQuery } from '@apollo/client';
import { Entry } from '__generated__/graphql';
import { GET_BLOOD_PRESSURE_MEASUREMENTS } from 'api/bloodPressureCuff/bloodPressureCuff.query';
import { GET_GOAL } from 'api/goal/goal.query';
import { ReactComponent as HeartRate } from 'assets/vectors/fi-rr-heart rate small.svg';
import { format } from 'date-fns';
import { useTitle } from 'hooks/useTitle';
import { TaskCard } from 'pages/home/components/Card/TaskCard';
import { ActivityLoading } from 'pages/progress/activity/components/ActivityLoading';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { DATE_FORMAT } from 'utils/helpers';

import { Header } from 'components/layout/BaseLayout/Header';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './RecordBloodPressureSync.styles';
import { SingleMeasurement } from './components/SingleMeasurement/SingleMeasurement';

export interface Params {
  goalId: string;
}

export const RecordBloodPressureSync: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.recordBlodPressureSync' });
  const navigate = useNavigate();
  const { goalId } = useParams<keyof Params>() as Params;
  const todayDate = format(Date.now(), DATE_FORMAT);

  const { data, loading } = useQuery(GET_BLOOD_PRESSURE_MEASUREMENTS, {
    variables: {
      start_range: todayDate,
      end_range: todayDate,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: allMeasurements, loading: allMeasurementsLoading } = useQuery(GET_BLOOD_PRESSURE_MEASUREMENTS);

  const { data: goalData, loading: isGoalLoading } = useQuery(GET_GOAL, {
    variables: {
      id: goalId,
      date: format(Date.now(), DATE_FORMAT),
    },
  });

  const title = useTitle({ isLoading: isGoalLoading, repeat: goalData?.goal?.repeat });

  useEffect(() => {
    if (!allMeasurementsLoading && allMeasurements?.entries) {
      const isFirstMeasurementDone = localStorage.getItem('measurementManualDone') === 'true';
      if (!isFirstMeasurementDone && allMeasurements?.entries.length === 0) {
        navigate(paths.bloodPressureManualFirstTimeSlides.replace(':goalId', goalId));
      }
    }
  }, [navigate, goalId, allMeasurements, allMeasurementsLoading]);

  if (loading) return <ActivityLoading />;

  const renderEntriesContent = () => {
    if (data?.entries?.length === 0) {
      return (
        <Styled.CenterContainer>
          <Icon element={HeartRate} size={40} color={'transparent'} strokeColor={'black.600'} />
          <Typography variant="Body1Bold" color="black.600">
            {t('noReadings')}
          </Typography>
        </Styled.CenterContainer>
      );
    }
    return data?.entries?.map((item) => (
      <SingleMeasurement
        key={item?.id}
        measurements={item?.subentries?.filter((item): item is Entry => item !== null) || []}
      />
    ));
  };

  return (
    <Styled.LayoutContainer>
      <Styled.ContentContainer>
        <Styled.Header>
          <Header variant="back" title={title} onBackClick={() => navigate(paths.homePage)} />
        </Styled.Header>
        <Styled.PageContentContainer>
          <Styled.ExtendingContainer>
            {goalData?.goal && (
              <Styled.PaddingContainer>
                <TaskCard goal={goalData?.goal} titleProps={{ color: 'black.800' }} />
              </Styled.PaddingContainer>
            )}
            <Styled.HeaderContainer>
              <Typography weight={600} color="black.800">
                {t('header', {
                  value: format(Date.now(), 'EEEE, MMMM dd'),
                })}
              </Typography>
            </Styled.HeaderContainer>
            {renderEntriesContent()}
          </Styled.ExtendingContainer>
          <Styled.Center>
            <Styled.StyledLink to={paths.bloodPressureManual.replace(':goalId', goalId)}>
              <Typography>{t('needHelp')}</Typography>
              &nbsp;
              <Typography color="blue.600">{t('viewUserManual')}</Typography>
            </Styled.StyledLink>
          </Styled.Center>
          <Styled.PaddingContainer>
            <Button onClick={() => navigate(paths.bloodPressure)}>{t('finish')}</Button>
          </Styled.PaddingContainer>
        </Styled.PageContentContainer>
      </Styled.ContentContainer>
    </Styled.LayoutContainer>
  );
};
