import { ReactComponent as Info } from 'assets/vectors/fi-rr-info.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { Container, Headline } from './ErrorLayout.styles';

interface ErrorLayoutProps {
  headerTitle: string;
  headline: string;
  description: string;
  backNavigateTo: string;
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({ headerTitle, headline, description, backNavigateTo }) => {
  const navigate = useNavigate();

  return (
    <BaseLayout
      footer={null}
      headerProps={{
        title: headerTitle,
        variant: 'back',
        onBackClick: () => navigate(backNavigateTo ? backNavigateTo : paths.homePage),
      }}
    >
      <Container>
        <Headline>
          <Typography variant="Headline2">{headline}</Typography>
        </Headline>
        <Icon element={Info} title="Information icon" size={108} color="other.red" />
        <Typography variant="Body1">{description}</Typography>
      </Container>
    </BaseLayout>
  );
};
