import { theme } from 'assets/styles/theme';
import { ReactComponent as NoDataIcon } from 'assets/vectors/fi-rr-no-data.svg';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT } from 'utils/helpers';

import { LEGEND_HEIGHT, htmlLegendPlugin } from 'components/ui/Diagram/CustomLegendPlugin';
import {
  ChartContainer,
  LegendComponent,
  LegendContainer,
  NoDataContainer,
  NoDataIconComponent,
  NoDataTitle,
  ResponsiveContainer,
} from 'components/ui/Diagram/Diagram.styled';
import { Typography } from 'components/ui/Typography/Typography';

import { DiagramProps } from '../../types';
import { defaultChartOptions } from './chartOptions';

export const BloodPressureDiagram: React.FC<DiagramProps> = ({
  systolicData = [],
  diastolicData = [],
  height,
  ariaLabel,
  showTendencyOnly,
}: DiagramProps) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Filler, Legend);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure.diagram' });

  const getDiagramDescription = (): string => {
    const sysLabels = systolicData.map((item) => {
      return t('systolicLabel', { value: item.y, date: format(new Date(item.x), DATE_TIME_FORMAT) });
    });
    const diasLabels = diastolicData.map((item) =>
      t('diastolicLabel', { value: item.y, date: format(new Date(item.x), DATE_TIME_FORMAT) }),
    );

    return [...sysLabels, ...diasLabels].join('; ');
  };

  const chartOptions = defaultChartOptions(theme, showTendencyOnly);

  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });

  const initialGradient = useCallback(() => {
    const chart = chartRef.current;
    if (chart) return chart.ctx.createLinearGradient(0, 0, 0, height ? parseInt(height) : chart.chartArea.height);
  }, [chartRef, height]);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      const primaryGradient = initialGradient();
      if (primaryGradient) {
        primaryGradient.addColorStop(0, theme.color.blue[500]);
        primaryGradient.addColorStop(0.5, theme.color.blue[400]);
        primaryGradient.addColorStop(1, theme.color.other.white);
      }
      const secondaryGradient = initialGradient();
      if (secondaryGradient) {
        secondaryGradient.addColorStop(0, theme.color.other.shell);
        secondaryGradient.addColorStop(1, theme.color.other.white);
      }

      setChartData({
        datasets: [
          {
            data: diastolicData,
            backgroundColor: primaryGradient,
            label: t('diastolic'),
            borderColor: theme.color.blue[500],
            pointBackgroundColor: theme.color.blue[600],
            fill: true,
            tension: 0.4,
            pointBorderColor: theme.color.other.white,
            pointHoverBorderColor: theme.color.other.white,
          },
          {
            data: systolicData,
            backgroundColor: secondaryGradient,
            label: t('systolic'),
            borderColor: theme.color.other.shell,
            pointBackgroundColor: theme.color.other.shell,
            fill: true,
            tension: 0.4,
            pointBorderColor: theme.color.other.white,
            pointHoverBorderColor: theme.color.other.white,
          },
        ],
      });
    }
  }, [systolicData, diastolicData, height, t, initialGradient]);

  if (isEmpty(systolicData) || isEmpty(diastolicData)) {
    return (
      <NoDataContainer height={`calc(${height} + ${LEGEND_HEIGHT})`}>
        <NoDataIconComponent title={t('noDataIconAlt') || ''} element={NoDataIcon} />
        <NoDataTitle variant="Body1Bold">{t('noDataTitle')}</NoDataTitle>
        <Typography variant="Helper1">{t('noDataSubtitle')}</Typography>
      </NoDataContainer>
    );
  }

  return (
    <ChartContainer>
      <LegendContainer>
        <LegendComponent id="legend-container" />
      </LegendContainer>
      <ResponsiveContainer height={height}>
        <Line
          data={chartData}
          options={chartOptions}
          ref={chartRef}
          aria-label={`${ariaLabel} ${getDiagramDescription()}`}
          role="img"
          plugins={[htmlLegendPlugin]}
        />
      </ResponsiveContainer>
    </ChartContainer>
  );
};
