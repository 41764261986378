import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const SwitchButton = styled.button<{ selected: boolean }>`
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none;
  cursor: pointer;
  ${({ selected, theme }) =>
    selected &&
    `
  border-bottom: 2px solid ${theme.color.black[900]};

`}
`;

export const SwitchText = styled(Typography.Span).attrs(() => ({
  variant: 'SubHeadline1Medium',
  size: 18,
}))``;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 38px;
`;
