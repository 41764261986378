import styled from 'styled-components';

export const AppointmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
