import { User } from '__generated__/graphql';
import axios from 'axios';
import { env } from 'index';

export type GetProvidersPayload = {
  userId: string;
};

export const getProviders = async ({ userId }: GetProvidersPayload): Promise<User[]> => {
  const response = await axios.post(`${env.REACT_APP_AWS_LAMBDA_URL}/providers`, { userId });
  return response.data;
};
