import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  padding: 16px;
`;

export const TextContainer = styled.div`
  margin-top: 5px;
`;

export const HorizontalLine = styled.hr`
  border: 1px solid ${(props) => props.theme.color.black[50]};
`;
