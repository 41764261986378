import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

import { SuccessButton } from '../ForgotPassword.styles';

export const ResetSuccess: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.forgotPassword.resetSuccess' });
  const navigate = useNavigate();
  return (
    <TaskCompletionPage
      headerTitle={t('reset')}
      taskTitle={t('success')}
      taskDescription={t('successDesc')}
      color="blue.700"
      footer={<SuccessButton onClick={() => navigate(paths.logInPage)}>{t('toLogin')}</SuccessButton>}
    />
  );
};
