import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

import { ArrowButtonProps } from './types';

export const Header = styled(Typography.Div)`
  padding: 24px;
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const SlotsContainer = styled.div`
  margin-top: 17px;
  gap: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: scroll;
`;

export const LoadingContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`;

export const NoSlotsContainer = styled.div`
  text-align: center;
`;

export const SingleDayDateContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const NavigatorContainer = styled.div`
  margin-top: 12px;
`;

export const ArrowButton = styled.button<ArrowButtonProps>`
  background-color: ${({ isActive, theme }) => (isActive ? '#E0E7EF' : theme.color.black[50])};
  border: none;
  border-radius: 40px;
  padding: 6px;
  cursor: pointer;
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  padding-bottom: 0;
`;

export const DurationContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 17px;
`;

export const Duration = styled.div`
  background-color: ${({ theme }) => theme.color.other.white};
  border-radius: 4px;
  padding: 4px 10px;
  flex-direction: row;
  display: flex;
  gap: 4px;
  align-items: center;
`;
