import { ReactComponent as Tick } from 'assets/vectors/fi-rr-cross-done.svg';
import { Field, FieldProps } from 'formik';
import React from 'react';

import { TabInput, TabLabel, TickIcon } from './Tab.styles';

type TabProps = Omit<React.HTMLProps<HTMLInputElement>, 'label' | 'ref' | 'as'> & {
  label?: React.ReactNode;
  name?: string;
};

export const Tab: React.FC<TabProps> = ({ label, name, type, ...rest }) => {
  return (
    <Field name={name} type={type} {...rest}>
      {({ field }: FieldProps) => {
        const checked = field.checked;
        return (
          <TabLabel
            checked={checked}
            aria-checked={checked}
            aria-labelledby={`${name}-${checked}-label`}
            variant="Body1"
            color={checked ? 'other.white' : 'black.800'}
          >
            {label}
            <TabInput type={type} {...field} tabIndex={0} />
            <TickIcon element={Tick} color="blue.700" size={16} />
          </TabLabel>
        );
      }}
    </Field>
  );
};
