import { ReactComponent as CrossSmall } from 'assets/vectors/fi-rr-cross-small.svg';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import { IconButton, Wrapper } from './Alert.styled';

export interface Props {
  content: ReactNode;
  isClosable?: boolean;
}

export const Alert: React.FC<Props> = ({ content, isClosable = true }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.alert' });
  const [isClosed, setIsClosed] = useState(false);

  if (isClosed) return null;

  return (
    <Wrapper>
      <Typography.Div variant="Helper1" aria-label="alert note">
        {content}
      </Typography.Div>
      {isClosable && (
        <IconButton aria-label={t('closeButtonAriaLabel') as string} onClick={() => setIsClosed(true)}>
          <Icon element={CrossSmall} color="black.800" size={16} />
        </IconButton>
      )}
    </Wrapper>
  );
};
