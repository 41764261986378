import { icons } from 'assets/vectors/iconsList';
import React from 'react';

import { Icon } from '../Icon/Icon';
import { StyledButton } from './Button.styled';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'primary-light'
    | 'linen'
    | 'secondary'
    | 'secondary-transparent'
    | 'danger'
    | 'link'
    | 'back'
    | 'square-secondary'
    | 'square-primary'
    | 'icon';
  size?: 'sm' | 'md' | 'lg';
}

export const Button: React.FC<ButtonProps> = ({
  children,
  size = 'lg',
  variant = 'primary',
  onClick,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton size={size} variant={variant} onClick={onClick} {...rest} role="button">
      {variant === 'back' && <Icon element={icons['fi-rr-angle-small-left.svg']} />}
      {children}
    </StyledButton>
  );
};
