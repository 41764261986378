import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 16px;
  max-width: 90vw;
  max-height: 80vh;
  overflow-y: auto;
`;

export const SIcon = styled(Icon)`
  align-self: flex-end;
`;

export const Title = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 8px;
`;

export const NumberedItems = styled.ol`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-position: inside;
  margin-bottom: 24px;
`;
