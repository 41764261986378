import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  max-width: 90vw;
  max-height: 80vh;
  gap: 24px;
  text-align: center;
`;

export const SIcon = styled(Icon)`
  align-self: flex-end;
`;

export const ButtonNav = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  margin-top: 48px;
`;
