import { Form } from 'formik';
import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';

export const SuccessButton = styled(Button)`
  color: ${(props) => props.theme.color.black[900]};
  background-color: ${(props) => props.theme.color.other.linen};
`;

export const ForgotPasswordForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
`;

export const ButtonNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`;
