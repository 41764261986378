import styled from 'styled-components';

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const EntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Box = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.color.other.white};
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin: 8px 0;
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 20px;
`;
