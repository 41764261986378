import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardDescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
  justify-content: center;
`;

export const CardTitle = styled(Typography.P)`
  word-break: break-word;
`;
export const CardDescription = styled(Typography.P)`
  word-break: break-word;
`;
export const CardProgress = styled(Typography.P)`
  word-break: break-word;
`;

export const PhoneCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  background-color: ${(props) => props.theme.color.blue[50]};
  border-radius: 50%;
`;

export const CompletedCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  background-color: ${(props) => props.theme.color.other.greenLight};
  border-radius: 50%;
`;

export const SButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.other.greenDark};
  padding: 6px 18px;
`;
