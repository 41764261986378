import { Document } from '@react-pdf/renderer';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const Pdf: React.FC<Props> = ({ children }) => {
  return <Document>{children}</Document>;
};

export default Pdf;
