import { ApolloError, useQuery } from '@apollo/client';
import { Fitbit } from '__generated__/graphql';
import { GET_FORM_BY_NAME } from 'api/fitbit/fitbit.query';
import { GET_CURRENT_USER_FITBIT } from 'api/user/user.query';

type FitbitTokensReturn = {
  hasLoggedInBefore: boolean;
  getToken: (type: 'ROCP_token' | 'ROCP_refreshToken') => string | null;
  getTokenExpireAt: () => string | null;
  fitbit?: Pick<Fitbit, 'id'> | null;
  loadingFitbitInfo: boolean;
  getCurrentUserError?: ApolloError;
};

export const useCurrentFitbitTokens = (): FitbitTokensReturn => {
  const { data: currentUserFitbitData, loading, error } = useQuery(GET_CURRENT_USER_FITBIT);

  const { data } = useQuery(GET_FORM_BY_NAME, {
    variables: {
      form_name: 'Motiv-Fitbit',
      user_id: currentUserFitbitData?.currentUser?.id,
    },
    fetchPolicy: 'network-only',
  });

  const getToken = (type: 'ROCP_token' | 'ROCP_refreshToken') => {
    const token = localStorage.getItem(type);
    // Tokens need to be parsed to string because they are parsed to JSON by oauth lib
    const parsedToken = token && token !== 'undefined' ? JSON.parse(token) : null;
    return parsedToken;
  };

  const getTokenExpireAt = () => {
    const expirationDateInEpoch = localStorage.getItem('ROCP_tokenExpire');
    return expirationDateInEpoch ? new Date(parseInt(expirationDateInEpoch) * 1000).toISOString() : null;
  };

  return {
    hasLoggedInBefore: !!data?.formAnswerGroups?.[0]?.form_answers?.[0]?.displayed_answer,
    getToken,
    getTokenExpireAt,
    fitbit: currentUserFitbitData?.currentUser?.fitbit,
    loadingFitbitInfo: loading,
    getCurrentUserError: error,
  };
};
