import React from 'react';
import { onEnterKey } from 'utils/helpers';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';
import { TextColor } from 'components/ui/Typography/Typography.model';

import { ItemTitle, ItemWrap } from './ProgressItem.styles';

export type ProgressItemProps = {
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
  iconStroke?: TextColor;
  iconColor?: TextColor;
  onClick: () => void;
};

export const ProgressItem: React.FC<ProgressItemProps> = ({
  label,
  icon,
  iconStroke = 'transparent',
  iconColor = 'blue.700',
  children,
  onClick,
}: ProgressItemProps) => {
  return (
    <ItemWrap onClick={onClick} role="button" tabIndex={0} onKeyDown={(e) => onEnterKey(e, onClick)}>
      <ItemTitle>
        <Icon element={icon} size={20} color={iconColor} strokeColor={iconStroke} aria-hidden="true" />
        <Typography.Div weight={600} color="blue.700">
          {label}
        </Typography.Div>
      </ItemTitle>
      {children && (
        <Typography.Div variant="Helper1" color="black.600" style={{ marginLeft: 30, marginTop: 4 }}>
          {children}
        </Typography.Div>
      )}
    </ItemWrap>
  );
};
