import { ReactComponent as CrossDone } from 'assets/vectors/fi-rr-cross-done.svg';
import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { IconContainer, ItemContainer } from './PersonalGoalsProgress.styles';
import { formatDateToReadableDate } from './utils';

type TransformedGoal = {
  id: string;
  name: string;
  created_at: string;
};

// Styled Components
const ItemListContainer = styled.div`
  width: 100%;
`;

const ListItem = styled.div`
  padding: 10px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${(props) => props.theme.color.black[50]};
  margin: 10px 0;
`;

const ItemDescription = styled(Typography.Div)`
  flex: 1;
  margin-right: 10px;
`;

const ItemTitle = styled.p`
  margin: 5px 0;
`;

type Goals = {
  transformedGoals: TransformedGoal[];
};

const GoalItem: React.FC<Goals> = ({ transformedGoals }: Goals) => {
  return (
    <ItemListContainer>
      {transformedGoals.map((item, index) => (
        <React.Fragment key={item.id}>
          {index === 0 && <Divider />}
          <ListItem>
            <ItemTitle>{formatDateToReadableDate(item.created_at)}</ItemTitle>
            <ItemDescription weight={500} size={16} lineHeight={24}>
              <ItemContainer>
                &#x2022; {item.name}
                <IconContainer>
                  <Icon element={CrossDone} size={16} color="other.green" aria-label={'Personal Goal Completed'} />
                </IconContainer>
              </ItemContainer>
            </ItemDescription>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </ItemListContainer>
  );
};

export default GoalItem;
