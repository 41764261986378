import { useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { GET_CARE_PLAN } from 'api/progress/carePlan.query';
import { GET_CURRENT_USER_PROVIDERS } from 'api/user/user.query';
import { format } from 'date-fns';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import { filterGoalsCurrentWeek } from 'pages/home/hooks/useCarePlan';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';

import { CarePlanGoalTyped, CarePlanItem, getCarePlanItemType } from './CarePlan.model';
import { FlexLayout, PageTitle, PlanInfoContainer } from './CarePlan.styles';
import CarePlanPdf from './components/PlanItem/CarePlanPdf';
import { PlanItem } from './components/PlanItem/PlanItem';

export const CarePlan: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.carePlan' });
  const navigate = useNavigate();
  const results = useQuery(GET_CARE_PLAN, { variables: { date: format(Date.now(), 'yyyy-MM-dd') } }).data?.currentUser;
  const { hasLoggedInBefore } = useCurrentFitbitTokens();

  const data = useMemo(() => {
    return {
      ...results,
      active_care_plan: {
        ...results?.active_care_plan,
        goals: filterGoalsCurrentWeek(results?.active_care_plan?.goals || []),
      },
    };
  }, [results]);

  const { data: userProviders } = useQuery(GET_CURRENT_USER_PROVIDERS);
  const phoneNumber = userProviders?.currentUser?.providers?.find(
    (provider) => provider?.qualifications?.toLowerCase() === 'coach',
  )?.phone_number;

  // version 1: don't allow duplicate Goals
  const itemMap = new Map<CarePlanItem, CarePlanGoalTyped>();
  data?.active_care_plan?.goals.forEach((goal) => {
    const itemType = getCarePlanItemType(goal.name?.toLocaleLowerCase());
    itemType && !itemMap.get(itemType.type) && itemMap.set(itemType.type, { goal, ...itemType });
  });
  const carePlanItems = Array.from(itemMap.values())
    .sort((a, b) => (a && b ? a.order - b.order : 0))
    .filter((item) => {
      if (!hasLoggedInBefore) {
        return item.type !== 'activity';
      }
      return item;
    });

  const carePlanDate = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    const match = data?.active_care_plan?.description?.match(/\d{2}[.]\d{2}[.]\d{4}/);
    if (match) return match[0];
  }, [data]);

  // version 2: allow duplicate Goals
  // const carePlanItems = data?.active_care_plan?.goals
  //   ?.map((goal) => {
  //     const itemType = getCarePlanItemType(goal.name?.toLocaleLowerCase());
  //     return itemType ? { goal, ...itemType } : null;
  //   })
  //   .filter((g2) => !!g2)
  //   .sort((a, b) => (a && b ? a.order - b.order : 0));

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Progress - Care Plan' });
  }, []);

  return (
    <BaseLayout
      footer={null}
      headerProps={{ variant: 'back', title: t('plan'), onBackClick: () => navigate(paths.progress) }}
    >
      <FlexLayout>
        <div>
          <PageTitle variant="SubHeadline1">{t('plan')}</PageTitle>
          <PlanInfoContainer variant="Helper1" color="black.600">
            {data?.active_care_plan?.description}
          </PlanInfoContainer>
        </div>
        {carePlanItems && (
          <PDFDownloadLink
            document={<CarePlanPdf data={carePlanItems || []} phoneNumber={phoneNumber} carePlanDate={carePlanDate} />}
            fileName="Care Plan.pdf"
          >
            {(pdfData) => (
              <Button
                size="sm"
                onClick={() => {
                  Mixpanel.track('Care_Plan_downloads');
                }}
              >
                {pdfData.loading ? t('loading') : t('download')}
              </Button>
            )}
          </PDFDownloadLink>
        )}
      </FlexLayout>
      {carePlanItems?.map((item) => (
        <PlanItem key={item?.goal.id} {...item} />
      ))}
    </BaseLayout>
  );
};
