import { useQuery } from '@apollo/client';
import { GET_SINGLE_APPOINTMENT } from 'api/appointment/appointment.query';
import { env } from 'index';
import React from 'react';
import { useParams } from 'react-router-dom';

import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import { Slots } from './components/Slots/Slots';
import { AppointmentTypesParams } from './types';

export const RescheduleAppointmentSlot: React.FC = () => {
  const { appointmentId } = useParams<keyof AppointmentTypesParams>() as AppointmentTypesParams;
  const { data, loading } = useQuery(GET_SINGLE_APPOINTMENT, {
    variables: {
      id: appointmentId,
    },
  });

  let appointmentTypeId = data?.appointment?.appointment_type_id;
  let keepOriginalAppointmentId = true;

  // If appt type is group orientation it has to be rescheduled to an individual orientation session
  if (data?.appointment?.appointment_type?.id === env.REACT_APP_GROUP_ORIENTATION_APPOINTMENT_TYPE_ID) {
    appointmentTypeId = Number(env.REACT_APP_INDIVIDUAL_ORIENTATION_APPOINTMENT_TYPE_ID);
    keepOriginalAppointmentId = false;
  }

  if (loading || !data) {
    return <LoadingPage />;
  }

  // Converting to strings becuase healthie API returns different types
  // from different queries with the same objects
  return (
    <Slots
      appointmentToRescheduleId={String(data?.appointment?.id)}
      appointmentTypeId={String(appointmentTypeId)}
      keepOriginalAppointmentId={keepOriginalAppointmentId}
      providerId={String(data?.appointment?.other_party_id)}
    />
  );
};
