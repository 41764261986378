import React from 'react';
import { Maybe } from 'utils/types';

import { IconProps } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import { ValueWrapper, Wrapper } from './IconBadge.styles';

export interface Props {
  icon: JSX.Element;
  size: IconProps['size'];
  value: Maybe<number>;
  showBadgeNumber?: boolean;
}

export const IconBadge: React.FC<Props> = ({ icon, size, value, showBadgeNumber = true }) => {
  return (
    <Wrapper $size={size}>
      {!!value && (
        <ValueWrapper>
          <Typography.Span variant="Helper2Bold" color="other.white">
            {showBadgeNumber ? value : ''}
          </Typography.Span>
        </ValueWrapper>
      )}
      <div>{icon}</div>
    </Wrapper>
  );
};
