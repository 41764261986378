import { HealthwiseArticleApiResponse, HealthwiseTokenResponse } from 'api/healthwise/types';
import axios, { AxiosResponse } from 'axios';
import { env } from 'index';
import { isTokenExpired } from 'utils/helpers/token';
import { Maybe } from 'utils/types';

class HealthwiseApiService {
  private readonly baseUrl = 'https://content.healthwise.net';
  private readonly authUrl = 'https://auth.healthwise.net';

  private async get<T>(url: string, headers: Record<string, string>): Promise<T> {
    try {
      const response: AxiosResponse<T> = await axios.get(`${this.baseUrl}${url}`, { headers });
      return response.data;
    } catch (error) {
      throw new Error(`Healthwise GET Request failed: ${error}`);
    }
  }

  private async getToken(): Promise<string> {
    const token = localStorage.getItem('healthwiseToken');
    if (token && !isTokenExpired(token)) {
      return token;
    } else {
      const token = await this.getHealthwiseTokenRequest();
      localStorage.setItem('healthwiseToken', token);
      return token;
    }
  }

  private async getHealthwiseTokenRequest(): Promise<string> {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${env.REACT_APP_HEALTHWISE_KEY}`)}`,
    };
    const requestData = {
      grant_type: 'client_credentials',
      scope: '*',
    };
    try {
      const response: AxiosResponse<HealthwiseTokenResponse> = await axios.post(
        `${this.authUrl}/oauth2/token`,
        requestData,
        { headers },
      );
      return response.data.access_token;
    } catch (e) {
      throw new Error('Unable to authorize Healthwise');
    }
  }

  public async getArticle(articleId: string): Promise<HealthwiseArticleApiResponse> {
    const url = `/articles/${articleId}/en-us`;
    const bearerToken = await this.getToken();
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
      'X-HW-Version': '1',
    };
    return this.get<HealthwiseArticleApiResponse>(url, headers);
  }

  public async getArticlesByIds(
    articleIds: Maybe<string>[] | undefined,
  ): Promise<(HealthwiseArticleApiResponse | undefined)[] | null> {
    if (articleIds) {
      const responses = await Promise.all(
        articleIds
          .filter((id) => id !== undefined && id !== null)
          .map((id) => {
            if (id) return this.getArticle(id);
          }),
      );
      return responses;
    }
    return null;
  }
}

export default new HealthwiseApiService();
