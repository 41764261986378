import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const ItemWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  margin: 0 -16px;

  border-top: 1px solid ${({ theme }) => theme.color.black[50]};
  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.black[50]};
  }
`;

export const ItemDescription = styled(Typography)`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export const ListItem = styled.div`
  position: relative;
  margin-left: 20px;

  &::before {
    content: '';
    left: -10px;
    top: 8px;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.black[600]};
  }
`;
