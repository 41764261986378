import { useQuery } from '@apollo/client';
import checkRepeatIntake from 'api/appointment/checkRepeatIntake';
import { GET_CARE_PLAN } from 'api/progress/carePlan.query';
import { differenceInCalendarDays, format } from 'date-fns';
import { env } from 'index';
import { isEmpty } from 'lodash';
import { useAppointmentsData } from 'pages/appointments/hooks';
import React, { useLayoutEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useTranslation } from 'react-i18next';
import { capitalizeWords, parseHealthieDate } from 'utils/helpers';
import { percentageOfWeeklyGoalsCompleted } from 'utils/helpers/goals';
import { Maybe } from 'utils/types';

import { Badge } from 'components/ui/Badge/Badge';
import { Typography } from 'components/ui/Typography/Typography';

export const Note: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home.notes' });
  const {
    pastCompletedAppointments,
    pastCompletedAppointmentsLoading,
    upcomingAppointments,
    upcomingAppointmentsLoading,
  } = useAppointmentsData();
  const { data, loading } = useQuery(GET_CARE_PLAN, {
    variables: { date: format(Date.now(), 'yyyy-MM-dd') },
  });
  const active_care_plan = data?.currentUser?.active_care_plan;
  const name = capitalizeWords(data?.currentUser?.first_name);

  const [isExploding, setIsExploding] = useState(false);
  const [scheduleRepeatIntake, setScheduleRepeatIntake] = useState(false);
  const [repeatIntakeScheduled, setRepeatIntakeScheduled] = useState(false);

  const countDayFrom = (dateString: Maybe<string>): number => {
    if (!dateString) return 0;
    const startDate = parseHealthieDate(dateString);
    const daysSinceStart = differenceInCalendarDays(new Date(), startDate) + 1;
    return daysSinceStart;
  };

  const hasMostActionsCompleted =
    [6, 7].includes(countDayFrom(active_care_plan?.created_at)) &&
    percentageOfWeeklyGoalsCompleted(active_care_plan?.goals) >= 0.6;

  useLayoutEffect(() => {
    const confettiExploded = sessionStorage.getItem('confettiExploded') === 'true';
    if (hasMostActionsCompleted && !confettiExploded) {
      setTimeout(() => {
        setIsExploding(true);
        sessionStorage.setItem('confettiExploded', 'true');
      }, 500);
    }
  }, [hasMostActionsCompleted]);

  useLayoutEffect(() => {
    const checkRepeatIntakeNotScheduled = async () => {
      if (!data?.currentUser?.id) {
        return;
      }
      const userId = data.currentUser.id;
      const { repeatIntake, shouldScheduleRepeatIntake } = await checkRepeatIntake(userId);
      const intakeAppointment = upcomingAppointments.find(
        (item) => item.appointmentTypeId === env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
      );
      if (repeatIntake && intakeAppointment) {
        setRepeatIntakeScheduled(true);
      }
      if (shouldScheduleRepeatIntake && !intakeAppointment) {
        setScheduleRepeatIntake(true);
      }
    };
    checkRepeatIntakeNotScheduled();
  }, [data, upcomingAppointments]);

  const text = React.useMemo(() => {
    if (!isEmpty(active_care_plan?.documents)) {
      return {
        title: t('hi', {
          name: name,
        }),
        description: t('endOfProgram'),
      };
    }
    if (
      isEmpty(upcomingAppointments) &&
      (isEmpty(pastCompletedAppointments) || scheduleRepeatIntake) &&
      !active_care_plan
    ) {
      return {
        title: t('hiThere', {
          name: name,
        }),
        description: t('scheduleFirstAppointment', { first: scheduleRepeatIntake ? '' : 'first' }),
      };
    } else if (
      ((isEmpty(pastCompletedAppointments) && upcomingAppointments.length > 0) || repeatIntakeScheduled) &&
      !active_care_plan
    ) {
      return {
        title: t('hello', {
          name: name,
        }),
        description: t('firstAppointmentScheduled', { first: repeatIntakeScheduled ? '' : 'first' }),
      };
    } else if (
      active_care_plan?.name?.split(' ').includes('1') &&
      [1, 2].includes(countDayFrom(active_care_plan?.created_at))
    ) {
      return {
        title: t('hiThere', {
          name: name,
        }),
        description: t('carePlanWeek1'),
      };
    } else if (hasMostActionsCompleted) {
      return {
        title: t('hiThere', {
          name: name,
        }),
        description: t('mostActionsCompleted'),
      };
    } else if (
      [6, 7].includes(countDayFrom(active_care_plan?.created_at)) &&
      percentageOfWeeklyGoalsCompleted(active_care_plan?.goals) < 0.6
    ) {
      return {
        title: t('hiThere', {
          name: name,
        }),
        description: t('leastActionsCompleted'),
      };
    }
    return null;
  }, [
    active_care_plan,
    name,
    pastCompletedAppointments,
    t,
    upcomingAppointments,
    hasMostActionsCompleted,
    scheduleRepeatIntake,
    repeatIntakeScheduled,
  ]);

  if (text && !loading && !upcomingAppointmentsLoading && !pastCompletedAppointmentsLoading) {
    return (
      <Badge header={text?.title}>
        {isExploding && (
          <ConfettiExplosion
            particleCount={300}
            particleSize={5}
            duration={5000}
            style={{ left: '50%', top: '50%', transform: 'translate(50%, -50%)', margin: 0, position: 'absolute' }}
            onComplete={() => setIsExploding(false)}
          />
        )}
        <Typography>{text.description}</Typography>
      </Badge>
    );
  } else {
    return null;
  }
};
