import styled from 'styled-components';

import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';

export const ArticleItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid ${(props) => props.theme.color.black[50]};
  padding: 20px 20px;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
  }
`;

export const ArticleDescription = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const ViewButton = styled(Button)`
  margin-left: auto;
  padding: 7px 14px;
`;
