import { useMutation, useQuery } from '@apollo/client';
import { SETUP_PASSWORD } from 'api/user/user.mutation';
import { GET_TOKEN_VALIDITY } from 'api/user/user.query';
import { ReactComponent as CrossDone } from 'assets/vectors/fi-rr-cross-done.svg';
import { Formik, FormikHelpers } from 'formik';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { useVisualViewport } from 'hooks/useVisualViewport';
import React, { useEffect, useState } from 'react';
import { isChrome } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { isPasswordValid } from 'utils/validators/password';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { PasswordValidator } from 'components/ui/PasswordValidator/PasswordValidator';

import { Header, MakeSureYourPasswordMatch, StyledButton, StyledForm, Wrapper } from './SetupPassword.styles';

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

const initialFormValues = { password: '', passwordConfirmation: '' };

export const SetupPassword: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.onboarding.setupPassword' });
  const navigate = useNavigate();
  const { setToken } = useCurrentToken();
  const { visualViewportHeight } = useVisualViewport();
  const [searchParams] = useSearchParams();
  const [showServerError, setShowServerError] = useState(false);

  const signUpToken = searchParams.get('s');

  // queries
  const { data: getTokenValidityData, loading: getTokenValidityLoading } = useQuery(GET_TOKEN_VALIDITY, {
    variables: {
      signup_token: signUpToken,
    },
    fetchPolicy: 'network-only',
  });

  // mutations
  const [setupPassword, { loading: setupPasswordLoading, data: setupPasswordData }] = useMutation(SETUP_PASSWORD);

  // effects
  useEffect(() => {
    if (signUpToken === null || getTokenValidityData?.validateSignupToken === false) {
      navigate(paths.welcomePage);

      return () => {
        setTimeout(() => {
          alert(t('setPasswordLinkIsBroken'));
        }, 1000);
      };
    }
  });

  useEffect(() => {
    const token = setupPasswordData?.signIn?.api_key;
    if (token) {
      setToken(token);
      navigate(paths.homePage, { state: { onboarding: true } });
    }
  }, [setupPasswordData, navigate, setToken]);

  // helpers
  const validatePasswords = (values: FormValues) => {
    return isPasswordValid(values.password) && values.password === values.passwordConfirmation;
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .default('')
      .test('contains-white-spaces', t('passwordContainsWhiteSpaces').toString(), (value) => {
        return value ? !value.includes(' ') : false;
      })
      .test('is-password-valid', t('passwordDoesNotMeetCriteria').toString(), (value) => {
        return isPasswordValid(value as string);
      })
      .required(),
    passwordConfirmation: Yup.string()
      .default('')

      .test('are-passwords-valid', t('passwordDoesNotMeetCriteria').toString(), function (value) {
        return validatePasswords({ password: this.parent.password, passwordConfirmation: value as string });
      })
      .required(),
  });

  // Error handling
  const mapServerError = (error: string) => {
    switch (true) {
      case error.includes('password is too weak'):
        return t('passwordIsTooWeakError');

      default:
        return error;
    }
  };

  const onSubmit = async (values: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    const response = await setupPassword({
      variables: {
        token: signUpToken,
        password: values.password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
      },
    });

    const serverErrors = response.data?.signIn?.messages;

    if (serverErrors?.length) {
      const error = serverErrors[0]?.message.toLowerCase() as string;
      setFieldError('password', mapServerError(error));
      setShowServerError(true);
    } else {
      setFieldError('password', undefined);
    }
  };

  let content;

  if (getTokenValidityLoading) {
    content = <LoadingPage withLogo />;
  }

  if (getTokenValidityData?.validateSignupToken) {
    content = (
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => {
          const hasErrors = !!errors.password;
          const arePasswordsValid = validatePasswords(values);
          const isButtonDisabled = !arePasswordsValid || setupPasswordLoading || hasErrors;
          const isAnyWhiteSpaceInPassword = values.password.includes(' ');

          const shouldHideInputError = () => {
            if (showServerError || isAnyWhiteSpaceInPassword) {
              return false;
            }

            return true;
          };

          return (
            <>
              <Header variant="Headline2" color="black.800">
                {t('header')}
              </Header>
              <StyledForm
                onFocus={(element) => {
                  if (isChrome) {
                    element.target.scrollIntoView();
                  }
                }}
              >
                <div>
                  <Input
                    type="password"
                    name="password"
                    label={t('enterPassword')}
                    hideErrorMessage={shouldHideInputError()}
                  />
                  <PasswordValidator password={values.password} />
                </div>
                <div>
                  <Input
                    type="password"
                    name="passwordConfirmation"
                    label={t('repeatPassword')}
                    hideErrorMessage={true}
                  />
                  <MakeSureYourPasswordMatch variant="Helper1" aria-label={t('makeSureYourPasswordMatch')}>
                    {arePasswordsValid && (
                      <Icon aria-label={t('passwordMeetsCriteria')} element={CrossDone} size={16} color="other.green" />
                    )}
                    {t('makeSureYourPasswordMatch')}
                  </MakeSureYourPasswordMatch>
                </div>
                <StyledButton variant="primary" type="submit" disabled={isButtonDisabled}>
                  {t('next')}
                </StyledButton>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    );
  }

  const wrapperHeight = visualViewportHeight ? `${visualViewportHeight}px` : '100%';

  return <Wrapper style={{ minHeight: wrapperHeight }}>{content}</Wrapper>;
};
