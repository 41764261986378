import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import * as ActionCable from '@rails/actioncable';
import { App } from 'App';
import { theme } from 'assets/styles/theme';
import { cleanEnv, str } from 'envalid';
import { Kind, OperationTypeNode } from 'graphql';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { useCurrentToken } from 'hooks/useCurrentToken';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { paths } from 'router/paths';
import { ThemeProvider } from 'styled-components';
import checkAccessibility from 'utils/checkAccessibility';

import GlobalStyle from './globalStyles';
import './i18n/i18n';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Validate all environment variables
export const env = cleanEnv(process.env, {
  NODE_ENV: str({ choices: ['development', 'staging', 'production'] }),
  REACT_APP_HEALTHIE_API_URL: str(),
  REACT_APP_HEALTHIE_WEBSOCKET_URL: str(),
  REACT_APP_COACH_APPOINTMENT_TYPE_ID: str(),
  REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID: str(),
  REACT_APP_HEALTHWISE_KEY: str(),
  REACT_APP_THERAPIST_APPOINTMENT_TYPE_ID: str(),
  REACT_APP_THERAPIST_APPOINTMENT_FIRST_TIME_TYPE_ID: str(),
  REACT_APP_GROUP_ORIENTATION_APPOINTMENT_TYPE_ID: str(),
  REACT_APP_INDIVIDUAL_ORIENTATION_APPOINTMENT_TYPE_ID: str(),
  REACT_APP_FITBIT_CLIENT_ID: str(),
  REACT_APP_AWS_LAMBDA_URL: str(),
  REACT_APP_FITBIT_SAVE_CRED_KEY: str(),
  REACT_APP_USER_EXERCISE_CHART_ID: str(),
  REACT_APP_HEALTHIE_ADMIN_TOKEN: str(),
});

const httpLink = createHttpLink({
  uri: env.REACT_APP_HEALTHIE_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const { getToken } = useCurrentToken();
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Basic ${token}` : '',
      authorizationsource: 'API',
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (message.includes('API Key is Invalid')) {
        window.location.href = paths.logoutPage;
      }
    });
});

const cable = ActionCable.createConsumer(
  `${env.REACT_APP_HEALTHIE_WEBSOCKET_URL}?token=${localStorage.getItem('token')}`,
);
const wsLink = new ActionCableLink({ cable });

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    if (!('operation' in definition)) {
      return false;
    }

    const { kind, operation } = definition;
    return kind === Kind.OPERATION_DEFINITION && operation === OperationTypeNode.SUBSCRIPTION;
  },
  wsLink,
  authLink.concat(httpLink),
);

export const client = new ApolloClient({
  link: from([errorLink, link]),
  cache: new InMemoryCache(),
});
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const PWARefresher = () => {
  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      self.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'visible') {
          window.location.reload();
        }
      });
    }
  }, []);
  return null;
};
root.render(
  <QueryClientProvider client={queryClient}>
    <PWARefresher />
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </QueryClientProvider>,
);

serviceWorkerRegistration.unregister();

checkAccessibility(React);
