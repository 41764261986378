import { gql } from '__generated__';

export const GET_ALL_CARE_PLANS = gql(`
query getCarePlans($patient_id: ID!) {
  carePlans(patient_id: $patient_id) {
    created_at
    id
    is_active
    name
    description
    goals {
      id
      name
      description
      start_on
      repeat
      created_at
      cursor
      goal_histories {
        id
        created_at
        completed_on
        cursor
      }
      subgoals {
        id
        name
        created_at
        start_on
        cursor
        goal_histories {
          id
          created_at
          cursor
          completed_on
        }
      }
    }
  }
}

`);
