import styled from 'styled-components';

import { ArticleHeader } from 'components/ui/ArticleHeader/ArticleHeader';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
  height: 100%;
`;

export const Content = styled.div`
  overflow-y: auto;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > :nth-child(2) {
    margin-top: 16px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const GoalItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid ${(props) => props.theme.color.black[50]};
  padding: 20px 20px;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
  }
`;

export const ContentHeader = styled(ArticleHeader)`
  margin: 0px 20px;
`;

export const ViewMyProgress = styled.div`
  background-color: ${({ theme }) => theme.color.other.linen};
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 18px;
  width: 100%;
`;
