import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const MotivationHeader = styled(Typography.Div)`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const MotivationContent = styled(Typography.Div)`
  margin-top: -8px;
  word-break: break-word;
`;

export const ButtonNav = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SaveButton = styled(Button)`
  width: 160px;
  padding: 14px 20px;
`;
