import styled from 'styled-components';

import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';

export const PageContainer = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.bgColor};
`;

export const Content = styled.main`
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  gap: 30px;
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const ContentHeader = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 30px;
`;

export const ButtonContainer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px;
`;

export const WeightInstruction = styled(Typography)`
  text-align: center;
  margin: 16px 95px;
`;

export const SButton = styled(Button)`
  flex: 1;
  background-color: transparent;
  font-weight: unset;
  padding: unset;
`;

export const HeaderTitle = styled(Typography.Div)`
  flex: 1;
  text-align: center;
  white-space: nowrap;
`;

export const Spacer = styled.div`
  flex: 1;
  text-align: right;
`;

export const TaskTitle = styled(Typography)`
  margin: 40px 24px 0 24px;
  text-align: center;
`;

export const TaskDescription = styled(Typography.Div)`
  text-align: center;
  margin: 0 46px;
`;

export const ProgressButton = styled(Button)`
  color: ${(props) => props.theme.color.black[900]};
  background-color: ${(props) => props.theme.color.other.linen};
`;

export const HomeButton = styled(Button)<{ bgColor: string }>`
  color: ${(props) => props.theme.color.other.white};
  background-color: ${(props) => props.bgColor};
  border-color: ${(props) => props.theme.color.other.white};
`;
