import styled from 'styled-components';

import { IconProps } from '../Icon/Icon';

interface WrapperProps {
  $size: IconProps['size'];
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;

  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`;

export const ValueWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.color.other.red};
  border-radius: 50%;
`;
