import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from 'assets/images/pdf/logo.png';
import { theme } from 'assets/styles/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
  coachNumber?: string | null;
};

const DEFAULT_COACH_PHONE_NUMBER = '1-855-542-1232';

const PdfPage: React.FC<Props> = ({ children, coachNumber }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.pdf' });
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%',
    },
    header: {
      backgroundColor: theme.color.blue[600],
      height: 60,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '0 30px',
      color: 'white',
    },
    logo: {
      width: 45,
    },
    headerText: {
      fontSize: '10px',
      textAlign: 'right',
    },
    footer: {
      fontSize: '8px',
      padding: '4px 30px',
      marginBottom: 10,
    },
    pageNumber: {
      fontSize: 6,
      color: theme.color.black[600],
    },
    pageNumberContainer: {
      textAlign: 'right',
    },
  });
  return (
    <Page size="A4">
      <View style={styles.container}>
        <View>
          <View style={styles.header}>
            <Image style={styles.logo} src={logo} />
            <View>
              <Text style={styles.headerText}>{t('headerFirstLine')}</Text>
              <Text style={styles.headerText}>
                {t('headerSecondLine', { phone: coachNumber || DEFAULT_COACH_PHONE_NUMBER })}
              </Text>
            </View>
          </View>
          {children}
        </View>
        <View style={styles.footer}>
          <Text>{t('footer')}</Text>
          <View style={styles.pageNumberContainer}>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
          </View>
        </View>
      </View>
    </Page>
  );
};

export default PdfPage;
