import { useQuery } from '@apollo/client';
import { INTAKE_FLOW, ONBOARDING_ITEM_IDS } from 'api/consents/consents.query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { Header } from 'components/layout/BaseLayout/Header';

import * as Styled from '../General.styles';
import { SingleConsent } from './SingleConsent';

export const MyConsents: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });
  const navigate = useNavigate();
  const userID = useQuery(INTAKE_FLOW).data?.currentUser?.id;
  const { data: onboardingData } = useQuery(ONBOARDING_ITEM_IDS, {
    variables: { user_id: userID },
    skip: !userID,
  });
  const onboardItems = onboardingData?.onboardingFlow?.onboarding_items;

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - My consents' });
  }, []);

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Header variant="back" onBackClick={() => navigate(paths.settings.main)} title={t('myConsents')} />
      </Styled.HeaderContainer>
      {onboardItems && (
        <Styled.Sections>
          {onboardItems?.map((item) => (
            <SingleConsent key={item.id} onboardItemId={item.id} />
          ))}
        </Styled.Sections>
      )}
    </Styled.Container>
  );
};
