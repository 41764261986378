import { Entry } from '__generated__/graphql';
import { format } from 'date-fns';
import { chunk, groupBy, sortBy } from 'lodash';
import { parseHealthieDate } from 'utils/helpers';

import { BloodPressureMeasurements, PdfData } from './types';

const getMeasurement = (entry: Entry, measurementType: BloodPressureMeasurements) => {
  return entry?.subentries?.find((item) => item?.category?.toLowerCase().includes(measurementType))?.metric_stat;
};

export const preparePdfData = (data: Entry[]): PdfData[][][][] => {
  const readingsData: PdfData[] = data.map((entry) => ({
    id: entry?.id || '',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createdAt: parseHealthieDate(entry!.created_at!),
    pressure: `${getMeasurement(entry, 'systolic')}/${getMeasurement(entry, 'diastolic')}`,
    heartRate: getMeasurement(entry, 'heart rate'),
  }));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const groupedData = groupBy(readingsData, (el) => format(el!.createdAt!, 'yyyy-MM-dd'));
  const sortedData = sortBy(groupedData, (group) => {
    return readingsData.indexOf(group[0]);
  });

  const output = [];
  let column: PdfData[][] = [];
  let accumulator = 0;
  for (let i = 0; i < sortedData.length; i++) {
    const day = sortedData[i];
    if (accumulator + day.length + 1 <= 14) {
      column.push(day);
      accumulator += day.length + 1;
    } else {
      if (day.length > 13) {
        const chunks = chunk(day, 13);
        let j = 0;
        for (j; j < chunks.length - 1; j++) {
          output.push([chunks[j]]);
        }
        column = [chunks[j]];
        accumulator = chunks[j].length;
        continue;
      }
      output.push(column);
      column = [];
      column.push(day);
      accumulator = day.length + 1;
    }
  }
  output.push(column);

  const chunkData = chunk(output, 2);
  return chunkData;
};
