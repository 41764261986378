import { ResetLinkPayload, sendResetPasswordLink } from 'api/forgotPassword/forgotPassword.api';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import { ButtonNav, ForgotPasswordForm } from './ForgotPassword.styles';
import { useErrorParser } from './useErrorParser';

type FormValues = {
  email: string;
};

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.forgotPassword.enterEmail' });
  const navigate = useNavigate();
  const { parseError } = useErrorParser();

  const { mutate: sendResetLink, isLoading } = useMutation<void, AxiosError<string>, ResetLinkPayload>(
    sendResetPasswordLink,
  );

  const onSubmit = async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    sendResetLink(
      { email: values.email },
      {
        onSuccess: () => {
          navigate({ pathname: paths.emailSent, search: `?to=${values.email}` });
        },
        onError: (error) => {
          const err = parseError(error.response?.data);
          err ? setErrors({ email: err }) : navigate(paths.resetError);
        },
      },
    );
  };

  const schema = Yup.object({
    email: Yup.string().email(t('emailInvalid')).required(),
  });
  return isLoading ? (
    <LoadingPage />
  ) : (
    <BaseLayout
      footer={null}
      headerProps={{ variant: 'back', title: t('reset'), onBackClick: () => navigate(paths.logInPage) }}
    >
      <Formik
        onSubmit={onSubmit}
        validationSchema={schema}
        initialValues={{ email: '' }}
        validateOnChange
        validateOnBlur
        validateOnMount
      >
        {({ isValid }) => {
          return (
            <ForgotPasswordForm>
              <Typography variant="Headline2">{t('resetYour')}</Typography>
              <Typography variant="Body1">{t('resetDesc')}</Typography>
              <Input name="email" label={t('email')} />
              <ButtonNav>
                <Button
                  type="button"
                  variant="link"
                  onClick={() => navigate(paths.logInPage)}
                  style={{ marginLeft: 48 }}
                >
                  {t('cancel')}
                </Button>
                <Button type="submit" disabled={!isValid || isLoading} style={{ width: 150 }}>
                  {t('next')}
                </Button>
              </ButtonNav>
            </ForgotPasswordForm>
          );
        }}
      </Formik>
    </BaseLayout>
  );
};
