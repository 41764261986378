import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const TextsContainer = styled.div`
  margin: 0 16px;
  max-width: 800px;
  margin: 0 auto;
`;

export const Description = styled(Typography.P)`
  margin-top: 24px;
`;

export const ButtonContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.color.other.linen};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
  min-height: 100%;
`;

export const Content = styled.div`
  padding: 16px;
  margin-bottom: 88px;
`;

export const CompletedButton = styled(Button)`
  background-color: ${(props) => props.theme.color.other.green}; ;
`;

export const InnerHtml = styled.div`
  ul {
    list-style-position: inside;
  }
  img {
    width: 95%;
  }
  h2,
  h3 {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  table {
    font-size: 12px;
  }
  video {
    width: 100%;
    margin-top: 15px;
  }
`;
