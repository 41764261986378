import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as QuestionIcon } from 'assets/vectors/fi-rr-question.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PopupModal } from 'components/ui/PopupModal/PopupModal';

import * as Styled from '../../LogOutModal/LogOutModal.styles';

interface ChangeModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: () => void;
  resetEditing: () => void;
  text: string;
}

export const ChangeModal: React.FC<ChangeModalProps> = ({
  onClose,
  isOpen,
  onSubmit,
  resetEditing,
  text,
}: ChangeModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });

  return (
    <PopupModal isOpen={isOpen} onRequestClose={onClose}>
      <Styled.Content>
        <Styled.SIcon element={CloseIcon} size={20} onClick={onClose} tabIndex={0} aria-label={t('closeModal')} />
        <Styled.Title weight={600} size={24} lineHeight={32}>
          {text}
        </Styled.Title>
        <Styled.QuestionIcon element={QuestionIcon} size={108} title={t('areYouSureLogOut')} />
      </Styled.Content>
      <Styled.ButtonsContainer>
        <Styled.SButton
          variant="secondary"
          onClick={() => {
            onClose();
            resetEditing();
          }}
        >
          {t('no')}
        </Styled.SButton>
        <Styled.SButton
          type="submit"
          onClick={() => {
            onSubmit();
            onClose();
            resetEditing();
          }}
        >
          {t('yes')}
        </Styled.SButton>
      </Styled.ButtonsContainer>
    </PopupModal>
  );
};
