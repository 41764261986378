import { useMutation } from '@apollo/client';
import { LOGIN } from 'api/login/login.mutation';
import { Formik, FormikHelpers } from 'formik';
import { useCurrentToken } from 'hooks/useCurrentToken';
import isNil from 'lodash/isNil';
import { ActionButtons, EnterTitle, LoginForm } from 'pages/logIn/LogIn.styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';

type LoginForm = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.logIn' });
  const navigate = useNavigate();
  const { setToken } = useCurrentToken();
  const [login, { data, error, loading }] = useMutation(LOGIN);

  useEffect(() => {
    if (data?.signIn?.api_key && !error) {
      setToken(data.signIn.api_key);
      navigate(paths.homePage);
    }
  }, [data, error, navigate, setToken]);

  const initial: LoginForm = {
    email: '',
    password: '',
  };

  const validation = Yup.object({
    email: Yup.string().email(t('emailIncorrect')).required(t('emailRequired')),
    password: Yup.string().required(t('passwordRequired')),
  });

  const onSubmit = async (values: LoginForm, helpers: FormikHelpers<LoginForm>) => {
    const { data } = await login({
      variables: { email: values.email, password: values.password },
    });

    const errors = data?.signIn?.messages;

    if (data?.signIn?.api_key && isNil(errors)) {
      setToken(data.signIn.api_key);
      navigate(paths.homePage);
    } else {
      helpers.setErrors({
        email: '',
        password: t('passwordIncorrect'),
      });
    }
  };

  return (
    <Formik
      initialValues={initial}
      validationSchema={validation}
      onSubmit={onSubmit}
      validateOnChange
      validateOnBlur
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <BaseLayout
            footer={null}
            headerProps={{ variant: 'back', title: t('login'), onBackClick: () => navigate(paths.welcomePage) }}
          >
            <LoginForm>
              <EnterTitle variant="Headline2">{t('enter')}</EnterTitle>
              <Input name="email" label={t('email')} hideErrorMessage={true} />
              <Input name="password" label={t('password')} type="password" />
              <ActionButtons>
                <Button type="button" variant="link" onClick={() => navigate(paths.forgotPassword)}>
                  {t('forgot')}
                </Button>
              </ActionButtons>
              <Button type="submit" disabled={!isValid || loading} style={{ marginTop: 'auto' }}>
                {t('login')}
              </Button>
            </LoginForm>
          </BaseLayout>
        );
      }}
    </Formik>
  );
};
