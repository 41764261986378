import { ReactComponent as FitbitLogo } from 'assets/images/kit/fitbit-logo.svg';
import { ReactComponent as InfoIcon } from 'assets/vectors/fi-rr-exclamation.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStoreUrl } from 'utils/helpers/fitbitStore';

import { Icon } from 'components/ui/Icon/Icon';
import Modal from 'components/ui/Modal/Modal';
import { Typography } from 'components/ui/Typography/Typography';

import {
  ButtonToDownloadTheApp,
  FitbitLogoContainer,
  FlexCenterContainer,
  InfoModalButton,
  InfoModalContainer,
  LinkToDownloadTheApp,
  StoreLinkContainer,
} from './Steps.styles';

export const Step2: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.fitbitSetup.step2' });
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const storeUrl = getStoreUrl();

  return (
    <>
      <Modal isOpen={infoModalVisible} onRequestClose={() => setInfoModalVisible(false)} contentLabel={t('modalLabel')}>
        <InfoModalContainer>
          <Typography variant="Headline2" color="black.800">
            {t('modalInfo')}
          </Typography>
          <Icon element={InfoIcon} size={108} color="black.600" />
          <InfoModalButton onClick={() => setInfoModalVisible(false)} size="lg">
            {t('closeButton')}
          </InfoModalButton>
        </InfoModalContainer>
      </Modal>
      <FlexCenterContainer>
        <Typography variant="Body1">{t('description')}</Typography>
        <StoreLinkContainer>
          {storeUrl ? (
            <LinkToDownloadTheApp variant="Body1Bold" color="blue.600" href={storeUrl} target="_blank">
              {t('downloadButton')}
            </LinkToDownloadTheApp>
          ) : (
            <ButtonToDownloadTheApp onClick={() => setInfoModalVisible(true)} variant="link">
              {t('downloadButton')}
            </ButtonToDownloadTheApp>
          )}
        </StoreLinkContainer>
        <FitbitLogoContainer>
          <FitbitLogo title={t('fitbitLogoAlt')} />
        </FitbitLogoContainer>
      </FlexCenterContainer>
    </>
  );
};

export default Step2;
