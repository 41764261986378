import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const SettingsLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
  height: 100%;
`;

export const SettingsPageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;

export const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const STypography = styled(Typography.Div)`
  grid-column-start: 2;
  text-align: center;
  align-self: center;
`;

export const SIcon = styled(Icon)`
  margin-left: auto;
`;
