import { useQuery } from '@apollo/client';
import { GET_ACTIVITY_DATA } from 'api/fitbit/fitbit.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as Steps } from 'assets/vectors/fi-rr-steps.svg';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

import { getAverage, getDates, getStepsByDate } from '../../activity/timeUtils';
import { CardsContainer, CardsContainerCenter, FlexLayout, PlanInfoContainer } from '../WeeklyReport.styles';
import { TilesProps } from '../types';
import { SingleRow } from './SingleRow';

export const ActivityTile: React.FC<TilesProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.weeklyReport' });

  const { startDate, endDate, goals, isSingle } = props;
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  const { data, loading } = useQuery(GET_ACTIVITY_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      client_id: currentUserData?.currentUser?.id,
      start_range: startDate,
      end_range: endDate,
    },
  });

  const entries = data?.entries || [];
  const hasEntries = !isEmpty(entries);

  const chartData = hasEntries ? getStepsByDate(entries, getDates(startDate, endDate, 'yyyy-MM-dd')) : [];

  const average = `${getAverage(chartData)}`;

  let filteredGoals = goals?.filter((goal) => /moving/i.test(goal?.name?.toLowerCase() || '')) || [];

  if (isSingle) {
    filteredGoals = goals.filter((goal) => {
      const goalDate: Date = new Date(goal.start_on);
      const rangeStartDate: Date = new Date(startDate);
      const rangeEndDate: Date = new Date(endDate);

      return goalDate >= rangeStartDate && goalDate <= rangeEndDate;
    });

    // Sort the filtered goals by the start_on date, newest first
    filteredGoals.sort((a, b) => {
      const dateA: Date = new Date(a.start_on);
      const dateB: Date = new Date(b.start_on);
      return dateB > dateA ? 1 : dateB < dateA ? -1 : 0;
    });
  }

  const goal = !isEmpty(filteredGoals) ? filteredGoals[0] : null;

  const stepsGoal = goal?.description ? parseInt(goal?.description || '') : 0;

  if (loading) {
    return (
      <CardsContainerCenter>
        <Spinner size={24} />
      </CardsContainerCenter>
    );
  }
  return (
    <>
      <CardsContainer>
        <PlanInfoContainer>
          <Icon element={Steps} size={18} aria-hidden="true" />
          <Typography color="black.800" variant="Body1">
            {t('activity.title')}
          </Typography>
        </PlanInfoContainer>

        <FlexLayout>
          <div>
            <SingleRow
              title={t('activity.averagePerDay')}
              value={average}
              valueProps={{
                color: 'other.green',
              }}
              label={t('activity.steps')}
            />
          </div>
          <div style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
            <SingleRow
              title={t('activity.goalPerDay')}
              value={t('activity.goalValue', { value: stepsGoal })}
              label={t('activity.steps')}
            />
          </div>
        </FlexLayout>
      </CardsContainer>
    </>
  );
};
