import { ReactComponent as ArrowSmallLeft } from 'assets/vectors/fi-rr-angle-small-left.svg';
import { ReactComponent as ArrowSmallRight } from 'assets/vectors/fi-rr-angle-small-right.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Autoplay, Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { ArrowButton } from './ArrowButton/ArrowButton';
import { BottomSection, Header, MainContent, NavigationWrapper, Slide } from './Carousel.styles';
import { SlideIndicator } from './SlideIndicator/SlideIndicator';

export interface SingleSlide {
  image?: string;
  header: string;
  content: string;
}

export interface CarouselProps {
  slides: SingleSlide[];
  bottomSection?: JSX.Element;
}

export const Carousel: React.FC<CarouselProps> = ({ slides, bottomSection }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.carousel' });

  const [bottomSectionHeight, setBottomSectionHeight] = useState<number | undefined>();
  const [textContentHeight, setTextContentHeight] = useState<number | undefined>();
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperRef = useRef<SwiperType>();
  const bottomSectionRef = useRef<HTMLDivElement>(null);
  const textContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBottomSectionHeight(bottomSectionRef.current?.clientHeight);
    setTextContentHeight(textContentRef.current?.clientHeight);
  }, [bottomSectionRef.current?.clientHeight]);

  return (
    <div style={{ height: '100%' }}>
      <Swiper
        modules={[Autoplay]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
        style={{ height: '100%' }}
      >
        <NavigationWrapper bottomPadding={textContentHeight}>
          <ArrowButton
            disabled={activeIndex === 0}
            ariaLabel={t('previous')}
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <Icon element={ArrowSmallLeft} size={24} />
          </ArrowButton>
          <ArrowButton
            disabled={activeIndex === slides.length - 1}
            ariaLabel={t('next')}
            onClick={() => swiperRef.current?.slideNext()}
          >
            <Icon element={ArrowSmallRight} size={24} />
          </ArrowButton>
        </NavigationWrapper>

        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Slide bgImage={slide.image || ''}>
              {(!bottomSection || (bottomSection && bottomSectionHeight)) && (
                <MainContent ref={textContentRef} bottomPadding={bottomSectionHeight}>
                  <Header>
                    <Typography.Div size={24} lineHeight={32} color="other.white" weight={600}>
                      {slide.header}
                    </Typography.Div>
                  </Header>
                  <Typography.Div size={18} lineHeight={24} color="other.white" weight={400}>
                    <ReactMarkdown>{slide.content}</ReactMarkdown>
                  </Typography.Div>
                </MainContent>
              )}
            </Slide>
          </SwiperSlide>
        ))}
        <BottomSection ref={bottomSectionRef}>
          <SlideIndicator swiperRef={swiperRef} slides={slides.length} currentSlideIndex={activeIndex} />
          {bottomSection && bottomSection}
        </BottomSection>
      </Swiper>
    </div>
  );
};
