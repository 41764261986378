import { GetAppointmentNotesResponse, getAppointmentNotes } from 'api/appointmentNotes/appointmentNotes.api';
import { ReactComponent as PhoneIconComp } from 'assets/vectors/fi-rr-phone.svg';
import { addMinutes, isWithinInterval, subMinutes } from 'date-fns';
import { AppointmentStatus, SingleAppointment } from 'pages/appointments/components/AppointmentsList/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { parseHealthieDate } from 'utils/helpers';
import { Maybe } from 'utils/types';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './Card.styles';
import { useAppointmentDate } from './hooks';

const HOW_MANY_MINUTES_BEFORE_CALL_JOIN_BUTTON_IS_AVAILABLE = 30;
const HOW_MANY_MINUTES_AFTER_CALL_JOIN_BUTTON_IS_AVAILABLE = 5;

interface AppointmentCardProps {
  title: string;
  isPast?: boolean;
  appointmentId: Maybe<string>;
  start: Maybe<string>;
  end: Maybe<string>;
  zoomJoinUrl?: Maybe<string>;
  isDetailsPage?: boolean;
  status?: AppointmentStatus;
  isFirstAppointment?: boolean;
  openDownloadZoomModal?: () => void;
}

export const convertToWebClientUrl = (zoomUrl: string): string => {
  return zoomUrl.replace('/j/', '/wc/join/');
};

export const getZoomUrl = (
  item: SingleAppointment,
  appointmentNotes: GetAppointmentNotesResponse,
): string | null | undefined => {
  if (item.id) {
    const note = appointmentNotes?.[item.id];
    if (note && note.useRegularZoomLink) {
      return item.zoomJoinUrl;
    }
  }
  return convertToWebClientUrl(item.zoomJoinUrl ?? '');
};

export const AppointmentCard: React.FC<AppointmentCardProps> = ({
  title,
  zoomJoinUrl,
  isPast = false,
  appointmentId,
  isDetailsPage,
  start,
  end,
  status,
  isFirstAppointment,
  openDownloadZoomModal,
}: AppointmentCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home' });
  const appointmentDate = useAppointmentDate(start);
  const [appointmentNotesLoading, setAppointmentNotesLoading] = useState(true);
  const [appointmentNotes, setAppointmentNotes] = useState<GetAppointmentNotesResponse>({});

  const isAbleToJoin = useCallback(() => {
    return start && end
      ? isWithinInterval(new Date(), {
          start: subMinutes(parseHealthieDate(start), HOW_MANY_MINUTES_BEFORE_CALL_JOIN_BUTTON_IS_AVAILABLE),
          end: addMinutes(parseHealthieDate(end), HOW_MANY_MINUTES_AFTER_CALL_JOIN_BUTTON_IS_AVAILABLE),
        })
      : false;
  }, [start, end]);

  const [canJoin, setCanJoin] = useState<boolean>(isAbleToJoin());

  useEffect(() => {
    setCanJoin(isAbleToJoin());

    let intervalId: NodeJS.Timeout | null = null;

    if (!canJoin) {
      intervalId = setInterval(() => {
        const newCanJoin = isAbleToJoin();
        setCanJoin(newCanJoin);

        if (newCanJoin && intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      }, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isAbleToJoin, canJoin]);

  useEffect(() => {
    const fetchAppointmentNotes = async () => {
      try {
        if (appointmentId) {
          setAppointmentNotesLoading(true);
          const appointmentNotes = await getAppointmentNotes({
            appointmentIds: [appointmentId],
          });
          setAppointmentNotes(appointmentNotes);
        }
      } catch (error) {
        /* Intentionally left empty */
      } finally {
        setAppointmentNotesLoading(false);
      }
    };
    if (canJoin) {
      fetchAppointmentNotes();
    }
  }, [canJoin, appointmentId]);

  const handleEditClick = () => {
    if (appointmentId) {
      navigate(paths.singleAppointment.replace(':appointmentId', appointmentId));
    }
  };

  const handleJoinClick = () => {
    if (isFirstAppointment) {
      openDownloadZoomModal?.();
    } else if (zoomJoinUrl) {
      if (appointmentId && appointmentNotes[appointmentId]?.useRegularZoomLink) {
        window.open(zoomJoinUrl, '_blank', 'noopener,noreferrer');
      } else {
        window.open(convertToWebClientUrl(zoomJoinUrl), '_blank', 'noopener,noreferrer');
      }
    }
  };

  const renderButtonWithAction = () => {
    // Join button
    if (canJoin && !appointmentNotesLoading) {
      return (
        <Styled.SButton variant="primary" size="sm" style={{ minWidth: 65 }} onClick={handleJoinClick}>
          {t('join')}
        </Styled.SButton>
      );
    }

    // Edit button (details)
    if (!isPast && !isDetailsPage) {
      return (
        <Button variant="primary" size="sm" style={{ minWidth: 65 }} onClick={handleEditClick}>
          {t('edit')}
        </Button>
      );
    }
  };

  return (
    <Styled.CardContainer>
      <Styled.PhoneCircle>
        <Icon element={PhoneIconComp} size={24} />
      </Styled.PhoneCircle>
      <Styled.CardDescriptionContainer>
        <Styled.CardTitle weight={600} color={isPast ? 'black.400' : 'blue.700'}>
          {title}
          <Typography.Span color={'black.300'}>{status === 'No-Show' && t('appointmentCard.missed')}</Typography.Span>
          <Typography.Span color={'black.300'}>
            {status === 'Cancelled' && t('appointmentCard.cancelled')}
          </Typography.Span>
        </Styled.CardTitle>
        <Styled.CardDescription size={14} color={isPast ? 'black.300' : 'black.600'}>
          {appointmentDate}
        </Styled.CardDescription>
      </Styled.CardDescriptionContainer>
      {renderButtonWithAction()}
    </Styled.CardContainer>
  );
};
