import { gql } from '__generated__/gql';

export const GET_AVAILABLE_SLOTS = gql(`
  query availableSlots(
    $end_date: String
    $start_date: String
    $org_level: Boolean
    $provider_id: String
    $appt_type_id: String
    $appointment_to_reschedule_id: ID
    $timezone: String
  ) {
    availableSlotsForRange(
      end_date: $end_date
      start_date: $start_date
      org_level: $org_level
      provider_id: $provider_id
      appt_type_id: $appt_type_id
      appointment_to_reschedule_id: $appointment_to_reschedule_id
      timezone: $timezone
    ) {
      user_id
      date
      appointment_id
      is_fully_booked
      length
      has_waitlist_enabled
    }
  }
`);

export const GET_APPOINTMENTS = gql(`
  query appointments(
    $filter: String,
    $should_paginate: Boolean,
    $offset: Int,
    $is_active: Boolean,
    $with_all_statuses: Boolean
  ) {
    appointments(
      is_active: $is_active,
      filter: $filter,
      is_org: true,
      should_paginate: $should_paginate,
      offset: $offset,
      with_all_statuses: $with_all_statuses
    ) {
      id
      end
      pm_status
      start
      zoom_join_url
      appointment_type {
        name
        id
      }
    }
  }
  `);

export const GET_APPOINTMENT_TYPES = gql(`
    query appointmentTypes {
      appointmentTypes {
        id
        name
        user_id
        appointment_setting {
          user_id
        }
        provider_appt_type_connections {
          provider_name
          id
          user_id
        }
      }
    }
  `);

export const GET_SINGLE_APPOINTMENT = gql(`
      query appointment($id: ID) {
        appointment(id: $id) {
          id
          end
          minimum_advance_reschedule_time
          minimum_advance_cancel_time
          other_party_id
          provider {
              first_name
              name
              id
              avatar_url
              phone_number
              qualifications
          }
          appointment_type_id
          date
          start
          appointment_type {
            name
            id
          }
        }
      }
    `);
