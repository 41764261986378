import { addDays, eachDayOfInterval, format, formatISO, parse, parseISO, startOfMonth, subDays } from 'date-fns';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { parseHealthieDate } from 'utils/helpers';
import { Maybe } from 'utils/types';

import { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';

import { DateRange, StepsEntry, TimeInterval, TimeSubTabs } from './types';

const defaultFormat = 'yyyy-MM-dd';

type TimeRange = {
  startRange: string;
  endRange: string;
};

const getDaysAgo = (days: number) => subDays(new Date(), days);

export const getThisWeekWithTodayInterval = (): TimeRange => ({
  startRange: format(getDaysAgo(6), defaultFormat),
  endRange: format(new Date(), defaultFormat),
});

export const getLastWeekInterval = (): TimeRange => {
  const startRange = format(getDaysAgo(13), defaultFormat);
  const endRange = format(getDaysAgo(7), defaultFormat);
  return { startRange, endRange };
};

export const getThisMonthInterval = (): TimeRange => {
  const today = new Date();
  const startDate = startOfMonth(today);

  return {
    startRange: format(startDate, defaultFormat),
    endRange: format(new Date(), defaultFormat),
  };
};

export const getLastMonthInterval = (): TimeRange => {
  const today = new Date();
  const lastDayOfLastMonth = today.setDate(0);
  const lastDateOfMonth = format(lastDayOfLastMonth, defaultFormat);
  const firstDateOfMonth = format(startOfMonth(lastDayOfLastMonth), defaultFormat);

  return {
    startRange: firstDateOfMonth,
    endRange: lastDateOfMonth,
  };
};

export const getTimeRange = (
  range: TimeInterval,
  entries?: StepsEntry[],
  courseStartDate?: Maybe<string>,
): TimeRange => {
  switch (range) {
    case 'thisWeek':
      return getThisWeekWithTodayInterval();
    case 'lastWeek':
      return getLastWeekInterval();
    case 'thisMonth':
      return getThisMonthInterval();
    case 'lastMonth':
      return getLastMonthInterval();
    case 'allTime':
      if (entries) {
        return getEntriesTimeRange(entries, courseStartDate);
      }
      return {
        // setting start range to 1970
        startRange: format(new Date(+0), defaultFormat),
        endRange: format(new Date(), defaultFormat),
      };
  }
};

export const timeRanges: Record<number, Record<TimeSubTabs, TimeInterval> | TimeInterval> = {
  [TimeTabsIndex.week]: {
    this: 'thisWeek',
    last: 'lastWeek',
  },
  [TimeTabsIndex.month]: {
    this: 'thisMonth',
    last: 'lastMonth',
  },
  [TimeTabsIndex.all_time]: 'allTime',
};

export const getCurrentTimeInterval = (currentTab: number, currentSubTab: TimeSwitchType): TimeInterval => {
  const currentTabRange = timeRanges[currentTab];
  return typeof currentTabRange === 'string' ? currentTabRange : currentTabRange[currentSubTab];
};

export const getIntervalForTab = (
  currentTab: number,
  currentSubTab: TimeSwitchType,
  entries?: StepsEntry[],
  courseStartDate?: Maybe<string>,
): TimeRange => {
  const currentTimeRange = getCurrentTimeInterval(currentTab, currentSubTab);
  return getTimeRange(currentTimeRange, entries, courseStartDate);
};

export const getDates = (startDate: string, endDate: string, dateFormat = defaultFormat): string[] => {
  const dates = eachDayOfInterval({ start: parseISO(startDate), end: parseISO(endDate) });
  return dates.map((date) => format(date, dateFormat));
};

export const getEntriesDates = (entries: StepsEntry[]): string[] => {
  return entries.map((entry) => entry?.created_at || '');
};

export const healthieDateToISO = (date: string, dateFormat?: string): string => {
  const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss X', new Date());
  const isoDate = formatISO(parsedDate, { format: 'basic' });
  const formattedDate = dateFormat ? format(parseISO(isoDate), defaultFormat) : isoDate;
  return formattedDate;
};

export const getEntriesTimeRange = (entries: StepsEntry[], courseStartDate?: Maybe<string>): TimeRange => {
  if (isEmpty(entries)) {
    return {
      startRange: format(new Date(), defaultFormat),
      endRange: format(new Date(), defaultFormat),
    };
  }

  const entriesDates = getEntriesDates(entries);

  let firstEntryDate = healthieDateToISO(entriesDates[entries.length - 1], defaultFormat);
  const lastEntryDate = entriesDates[0]
    ? healthieDateToISO(entriesDates[0], defaultFormat)
    : formatISO(new Date(), { format: 'basic' });

  if (firstEntryDate === lastEntryDate && courseStartDate) {
    firstEntryDate = format(parseHealthieDate(courseStartDate), defaultFormat);
  }
  return {
    startRange: firstEntryDate,
    endRange: lastEntryDate,
  };
};

export const getStepsByDate = (entries: StepsEntry[], dates: string[]): number[] => {
  const stepsByDate = new Array(dates.length).fill(0);

  entries.forEach((entry) => {
    if (entry?.created_at) {
      const date = healthieDateToISO(entry.created_at, defaultFormat);
      const steps = entry?.metric_stat || 0;
      const dateIndex = dates.indexOf(date);

      if (dateIndex !== -1) {
        stepsByDate[dateIndex] = steps;
      }
    }
  });

  return stepsByDate;
};

export const getAverage = (chartData: number[]): number => {
  if (isEmpty(chartData)) return 0;
  const sum = sumBy(chartData, (n) => (isNaN(n) ? 0 : n));
  const average = round(sum / chartData.length);
  return average;
};

export const isThisWeekCompleted = (entries: StepsEntry[]): boolean => {
  const timeInterval = getTimeRange('thisWeek');
  const steps = getStepsByDate(entries, getDates(timeInterval.startRange, timeInterval.endRange, 'yyyy-MM-dd'));
  const finishedWeek = every(steps, (stepsValue) => stepsValue !== 0);
  return finishedWeek;
};

export const getDateRangeWeekly = (startDate: Date, endDate: Date, inFuture = true): DateRange[] => {
  const dateRange = [];

  while (startDate <= endDate) {
    const newEndDate = addDays(new Date(startDate), 6);
    if ((newEndDate > new Date() && inFuture) || (!inFuture && newEndDate < new Date())) {
      dateRange.push({
        start: new Date(startDate),
        end: newEndDate,
      });
    }
    startDate = addDays(newEndDate, 1);
  }
  return dateRange;
};
