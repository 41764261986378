import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as Info } from 'assets/vectors/fi-rr-information.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button/Button';
import { PopupModal } from 'components/ui/PopupModal/PopupModal';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './ErrorModal.styles';

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const ErrorModal: React.FC<ModalProps> = ({ onClose, isOpen }: ModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.appointments.errorModal' });

  return (
    <PopupModal isOpen={isOpen} onRequestClose={onClose}>
      <Styled.Content>
        <Styled.SIcon element={CloseIcon} size={16} onClick={onClose} tabIndex={0} aria-label="Close modal" />
        <Typography weight={600} size={28} lineHeight={36}>
          {t('title')}
        </Typography>
        <Styled.ErrorIcon element={Info} size={108} color="black.600" title={t('errorIcon')} />
        <Styled.Description color="black.800" variant="Body1">
          {t('description')}
        </Styled.Description>
        <Styled.Footer>
          <Button onClick={onClose}>{t('okay')}</Button>
        </Styled.Footer>
      </Styled.Content>
    </PopupModal>
  );
};
