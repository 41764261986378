type Return = {
  clearToken: () => void;
  getToken: () => string | null;
  setToken: (token: string) => void;
};

export const useCurrentToken = (): Return => {
  // TODO: somehow check if the token is still valid

  const clearToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('setFirstAppointmentLater');
    localStorage.removeItem('measurementManualDone');
    sessionStorage.removeItem('firstLoading');
  };

  const getToken = () => {
    return localStorage.getItem('token');
  };

  const setToken = (token: string) => {
    localStorage.setItem('token', token);
  };

  return {
    clearToken,
    getToken,
    setToken,
  };
};
