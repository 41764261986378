import { gql } from '__generated__/gql';

export const LOGIN = gql(`
  mutation login($email: String, $password: String) {
    signIn(input: { email: $email, password: $password, generate_api_token: true, allow_multiple_api_keys: false }) {
      api_key
      messages {
        field
        message
      }
    }
  }
`);
