import React from 'react';

import { Wrapper } from './ArrowButton.styles';

interface Props {
  children: JSX.Element;
  disabled: boolean;
  ariaLabel: string;
  onClick: () => void;
}

export const ArrowButton: React.FC<Props> = ({ children, disabled, ariaLabel, onClick }) => {
  return (
    <Wrapper data-testid="arrow-button" aria-label={ariaLabel} disabled={disabled} onClick={onClick}>
      {children}
    </Wrapper>
  );
};
