import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0px -4px 50px rgba(188, 195, 208, 0.25);
  border-radius: 8px 8px 0px 0px;
  background-color: ${(props) => props.theme.color.blue[700]};
`;

export const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex-basis: 20%;
  text-decoration: none;
`;
