import { useMutation, useQuery } from '@apollo/client';
import { CREATE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_GOAL } from 'api/goal/goal.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { useTitle } from 'hooks/useTitle';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { DATE_FORMAT } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';
import * as yup from 'yup';

import { Radio } from 'components/form/Radio/Radio';
import { Header } from 'components/layout/BaseLayout/Header';
import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './RecordYourMedications.styles';

interface RecordYourMedicationsFormValues {
  option: string;
}

export const RecordYourMedications: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.recordYourMedications' });
  const navigate = useNavigate();
  const { goalId } = useParams();
  const {
    data: goalData,
    refetch,
    loading: isLoading,
  } = useQuery(GET_GOAL, {
    variables: {
      id: goalId,
      date: format(Date.now(), DATE_FORMAT),
    },
    fetchPolicy: 'network-only',
  });
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  const [createGoalHistory] = useMutation(CREATE_GOAL_HISTORY);
  const title = useTitle({ isLoading, repeat: goalData?.goal?.repeat });
  const initialValues: RecordYourMedicationsFormValues = {
    option: '',
  };
  const validationSchema = yup.object().shape({
    option: yup.string().required(() => t('pleaseSelectAnOption')),
  });

  const onSubmit = async (values: RecordYourMedicationsFormValues) => {
    const { data: refetchedGoalData } = await refetch();
    if (refetchedGoalData?.goal?.is_completed_for_date) {
      navigate(paths.homePage);
    } else {
      try {
        Mixpanel.track('Record_Meds_Goal');
        await createGoalHistory({
          variables: {
            user_id: currentUserData?.currentUser?.id,
            goal_id: values.option,
            completed_on: format(Date.now(), DATE_FORMAT),
            mark_parent_complete: true,
          },
        });
        navigate(paths.recordYourMedicationsCompleted);
      } catch (error) {
        //TODO ERROR HANDLING
        navigate(paths.homePage);
        throw new Error(`Answer saving failed: ${error}`);
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnMount>
      {({ values, isValid, touched }) => (
        <Styled.PageContainer>
          <Styled.HeaderContainer>
            <Header onBackClick={() => navigate(paths.homePage)} variant="back" title={title} />
          </Styled.HeaderContainer>
          <Styled.Content>
            {goalData && (
              <>
                <Styled.ContentHeader>
                  <Typography variant="Headline2">
                    {goalData?.goal?.repeat === 'Weekly'
                      ? t('didYouTakeMedicationsWeek')
                      : t('didYouTakeMedicationsToday')}
                  </Typography>
                  <Typography variant="Helper1">{t('pleaseChooseOne')}</Typography>
                </Styled.ContentHeader>
                {goalData?.goal?.subgoals && !isEmpty(goalData?.goal?.subgoals) && (
                  <Styled.RadiosContainer>
                    {goalData.goal.subgoals.map((subgoal) => {
                      if (subgoal?.name && subgoal?.id) {
                        return <Radio label={subgoal.name} name="option" value={subgoal.id} key={subgoal.id} />;
                      }
                      return null;
                    })}
                  </Styled.RadiosContainer>
                )}
              </>
            )}
          </Styled.Content>
          <Styled.ButtonContainer>
            <Button
              type="submit"
              onClick={() => onSubmit(values)}
              disabled={!touched || !isValid}
              aria-label={t('save')}
            >
              {t('save')}
            </Button>
          </Styled.ButtonContainer>
        </Styled.PageContainer>
      )}
    </Formik>
  );
};
