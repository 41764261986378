import axios from 'axios';
import { env } from 'index';

export type GetAppointmentNotesPayload = {
  appointmentIds: string[];
};

export type GetAppointmentNotesResponse = {
  [key: string]: {
    useRegularZoomLink: boolean;
  };
};

export const getAppointmentNotes = async ({
  appointmentIds,
}: GetAppointmentNotesPayload): Promise<GetAppointmentNotesResponse> => {
  const response = await axios.post(`${env.REACT_APP_AWS_LAMBDA_URL}/appointment-notes`, { appointmentIds });
  return response.data;
};
