import uniqBy from 'lodash/uniqBy';
import { AppointmentsList } from 'pages/appointments/components/AppointmentsList/AppointmentsList';
import { DownloadZoomModal } from 'pages/appointments/components/DownloadZoomModal/DownloadZoomModal';
import { useAppointmentsData } from 'pages/appointments/hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths } from 'router/paths';

import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

import { AppointmentsHeader, LoaderContainer } from './AppointmentsHome.styles';

export const AppointmentsHome: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home' });
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const { upcomingAppointments, upcomingAppointmentsLoading, isFirstAppointment, onDownloadZoomClick } =
    useAppointmentsData();

  if (upcomingAppointmentsLoading) {
    return (
      <>
        <Typography size={20} lineHeight={28} weight={500}>
          {t('appointments')}
        </Typography>
        <LoaderContainer>
          <Spinner size={24} />
        </LoaderContainer>
      </>
    );
  }

  const coachAppTypes = [
    process.env.REACT_APP_COACH_APPOINTMENT_TYPE_ID,
    process.env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
  ];

  const oneByType = uniqBy(upcomingAppointments, ({ appointmentTypeId }) =>
    coachAppTypes.includes(appointmentTypeId || '') ? 'coach' : 'therapist',
  );
  return (
    <>
      <AppointmentsHeader>
        <Typography size={20} lineHeight={28} weight={500}>
          {t('appointments')}
        </Typography>
        <Link to={paths.appointments} style={{ textDecoration: 'none' }}>
          <Typography color="blue.600" weight={600} size={16}>
            {t('viewAll')}
          </Typography>
        </Link>
      </AppointmentsHeader>
      <AppointmentsList
        items={oneByType}
        isFirstAppointment={isFirstAppointment}
        openDownloadZoomModal={() => setDownloadModalVisible(true)}
      />
      <DownloadZoomModal
        isOpen={downloadModalVisible}
        onClose={() => setDownloadModalVisible(false)}
        onDownloadClick={onDownloadZoomClick}
      />
    </>
  );
};
