import styled from 'styled-components';

interface MediaWrapperProps {
  visualViewportWidth: number;
}

export const MediaWrapper = styled.div<MediaWrapperProps>`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: ${({ visualViewportWidth }) => visualViewportWidth - 40}px;
  aspect-ratio: 3/2;

  div {
    width: 100%;
    max-height: 480px;

    img {
      width: ${({ visualViewportWidth }) => visualViewportWidth - 60}px;
      height: 100%;
      height: -webkit-fill-available;
    }

    iframe {
      width: 350px;
      height: 200px;
      border: none;
    }
  }
`;

export const IframeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.other.white};
`;

export const Content = styled.div`
  padding: 0px 16px 16px 16px;
`;

export const TagsWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.blue[100]};
  border-radius: 16px;
  padding: 0px 16px;
  margin: 0px 4px 8px 0px;
`;
