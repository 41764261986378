import { gql } from '__generated__';

export const INTAKE_FLOW = gql(`
  query intakeFlow {
    currentUser { 
      id
    }
  }
`);

export const ONBOARDING_ITEM_IDS = gql(`
  query onboardingFlow($user_id: ID,) {
    onboardingFlow(user_id: $user_id) { 
      id
      name
      onboarding_items {
        id
        display_name
        completed_onboarding_item {
          user_id
          date_to_show
        }
      }
    }
  }
`);

export const FORM_ID = gql(`
  query getOnboardingItem($id: ID) {
    onboardingItem(id: $id) {
      item_id
    }
  }
`);

export const FORM_DATA = gql(`
  query getCustomModuleForm($id: ID) {
    customModuleForm(id: $id) {
      custom_modules {
        mod_type
        id
        label
        options
        options_array
      }
    }
  }
`);
