import styled from 'styled-components';

interface SlideProps {
  bgImage: string;
}

interface MainContentProps {
  bottomPadding?: number;
}

interface NavigationWrapperProps {
  bottomPadding?: number;
}

export const Slide = styled.div<SlideProps>`
  height: 100%;
  background: ${({ bgImage }) =>
    `linear-gradient(0deg, rgba(0, 0, 0, 0.78) -1.68%, rgba(0, 0, 0, 0) 90.39%), url(${bgImage}) no-repeat center center`};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
`;

export const Header = styled.div`
  padding: 0 32px;
  margin-bottom: 8px;
`;

export const MainContent = styled.div<MainContentProps>`
  padding-bottom: ${({ bottomPadding }) => (bottomPadding ? `${bottomPadding}px` : '24px')};
  padding-right: 24px;
  padding-left: 24px;
  text-align: center;
  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const NavigationWrapper = styled.div<NavigationWrapperProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: red;
  position: absolute;
  z-index: 1;
  bottom: ${({ bottomPadding }) => (bottomPadding ? `${bottomPadding + 48}px` : '50%')};
`;

export const BottomSection = styled.div`
  width: 100%;
  padding-bottom: 24px;
  position: absolute;
  z-index: 1;
  bottom: 0;
`;
