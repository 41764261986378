import { gql } from '__generated__/gql';

export const GET_GOAL = gql(`
  query singleGoal($id: ID, $date: String!){
    goal(id: $id){
      id
      name
      repeat
      description
      start_on
      created_at
      cursor
      is_completed_for_date(date: $date)
      subgoals{
        id
        name
        created_at
        cursor
        start_on
        is_completed_for_date(date: $date)
        goal_histories{
          id
          completed_on
          created_at
          cursor
        }
      }
      goal_histories{
        id
        completed_on 
        created_at
        cursor
      }
    }
  }
`);

export const GET_GOALS = gql(`
  query goals(
    $user_id: ID
    $keywords: String
    $start_range: String
    $end_range: String
    $offset: Int
    $per_page: Int
  ) {
    goals(
      user_id: $user_id
      keywords: $keywords
      start_range: $start_range
      end_range: $end_range
      offset: $offset
      per_page: $per_page
    ) {
      id
      name
      description
      start_on
      repeat
      created_at
      goal_histories {
        id
        created_at
        completed_on
      }
      subgoals {
        id
        name
        created_at
        start_on
        goal_histories {
          id
          created_at
          completed_on
        }
      }
    }
    goalsCount(    
      offset: $offset    
      user_id: $user_id
      keywords: $keywords
    )
  }
`);
