import { useQuery } from '@apollo/client';
import { INTAKE_FLOW, ONBOARDING_ITEM_IDS } from 'api/consents/consents.query';
import { ReactComponent as Secure } from 'assets/vectors/fi-rr-secure.svg';
import axios from 'axios';
import { FieldArray, Formik } from 'formik';
import { env } from 'index';
import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import { ConsentCheckbox, FormAnswerType } from './ConsentCheckbox/ConsentCheckbox';
import { ConsentModal } from './ConsentModal/ConsentModal';
import * as Styled from './ConsentStep.styles';
import { ConsentSubmitter } from './ConsentSubmitter/ConsentSubmitter';
import { useConsentModals } from './useConsentModals';

export interface ConsentsState {
  [key: string]: boolean | null;
}

export interface ConsentsAction {
  type: string;
  id: string;
  value?: boolean;
}

function reducer(state: ConsentsState, action: ConsentsAction): ConsentsState {
  const { type, id, value } = action;
  switch (type) {
    case 'onInit':
      return { ...state, [id]: null };
    case 'onCompleted':
    case 'onError':
      return { ...state, [id]: value as boolean };
    default:
      throw new Error();
  }
}

export const ConsentStep: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.logIn.consents' });
  const [answers, setAnswers] = useState<FormAnswerType[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [consentsState, consentsDispatch] = useReducer(reducer, {});

  const consentsValues = Object.values(consentsState);
  const consentsLoadingStatus =
    consentsValues.length > 0
      ? consentsValues.every((consent) => consent)
        ? 'success'
        : consentsValues.every((consent) => consent === false)
        ? 'error'
        : 'loading'
      : 'loading';

  const addAnswer = (answer: FormAnswerType) => {
    if (!answers.find((a) => a.formID === answer.formID)) {
      setAnswers([...answers, answer]);
    }
  };

  const { data: intakeData, loading: intakeLoading } = useQuery(INTAKE_FLOW);
  const userID = intakeData?.currentUser?.id;

  const { data: onboardData, loading: onboardLoading } = useQuery(ONBOARDING_ITEM_IDS, {
    variables: { user_id: userID },
    skip: !userID,
  });

  const onboardIDs = onboardData?.onboardingFlow?.onboarding_items?.map((item) => item.id);

  const { currentModal, openModal, closeModal } = useConsentModals();

  const onSubmit = async () => {
    // invoke lambda to change users group as we can't do using client's API KEY
    // Will wait here so it finished first before it does other stuff. Do not care about response
    const postRequest = axios.post(`${env.REACT_APP_AWS_LAMBDA_URL}/signedUp`, {
      event_type: 'patient.signedup',
      resource_id: userID,
      resource_id_type: '',
    });
    const getRequest = axios.get(`${env.REACT_APP_AWS_LAMBDA_URL}/welcome-track?userId=${userID}&signedUp=true`);

    Promise.all([postRequest, getRequest]);
    setIsSubmitting(true);
  };

  return (
    <>
      {!onboardIDs || intakeLoading || onboardLoading || isSubmitting ? (
        <>
          <LoadingPage withLogo />
          {isSubmitting && (
            <ConsentSubmitter userID={userID as string} answers={answers} afterSubmit={() => setIsSubmitting(false)} />
          )}
        </>
      ) : (
        <Styled.ConsentWrapper>
          {consentsLoadingStatus === 'loading' && <LoadingPage withLogo />}
          {consentsLoadingStatus === 'error' && (
            <>
              <Typography variant="Headline2">{t('errorEncountered')}</Typography>
              <Typography style={{ marginTop: 12 }}>{t('errorEncounteredDesc')}</Typography>
            </>
          )}

          {consentsLoadingStatus === 'success' && <Typography variant="Headline2">{t('header')}</Typography>}
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            initialValues={{
              consents: Array(onboardIDs.length).fill(false) as boolean[],
            }}
          >
            {({ values }) => {
              const isFilled = values.consents.every((val) => val === true);
              return (
                <Styled.ConsentForm>
                  <FieldArray
                    name="consents"
                    render={() => (
                      <>
                        {onboardIDs.map((onboardID, index) => (
                          <ConsentCheckbox
                            key={onboardID}
                            onboardId={onboardID}
                            openModal={openModal}
                            index={index}
                            addAnswer={addAnswer}
                            consentsDispatch={consentsDispatch}
                            consentsLoadingStatus={consentsLoadingStatus}
                          />
                        ))}
                      </>
                    )}
                  />

                  {consentsLoadingStatus === 'success' && (
                    <>
                      <Styled.MotivNote variant="Helper1" color="black.700">
                        <Icon size={16} color="black.600" element={Secure} />
                        {t('note')}
                      </Styled.MotivNote>
                      <Styled.CreateAccountButton type="submit" disabled={!isFilled || isSubmitting}>
                        {t('create')}
                      </Styled.CreateAccountButton>
                    </>
                  )}
                  <ConsentModal
                    visible={!!currentModal}
                    onClose={closeModal}
                    headline={currentModal?.headline}
                    content={currentModal?.content}
                  />
                </Styled.ConsentForm>
              );
            }}
          </Formik>
        </Styled.ConsentWrapper>
      )}
    </>
  );
};
