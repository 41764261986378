import { Goal } from '__generated__/graphql';
import { CompleteActivityGoalType } from 'hooks/useCompleteActivityGoal';
import React from 'react';

import { AppointmentsHome } from '../Appointments/AppointmentsHome';
import { TaskCard } from '../Card/TaskCard';
import * as Styled from './Cards.styles';

interface CardsProps {
  goals?: Goal[];
  activity?: Pick<CompleteActivityGoalType, 'stepsGoal' | 'percentage' | 'currentSteps'>;
  variant: 'goals' | 'appointments';
}

export const Cards: React.FC<CardsProps> = ({ variant, goals, activity }: CardsProps) => {
  if (variant === 'appointments') {
    return <AppointmentsHome />;
  }

  return (
    <Styled.CardsContainer>
      {variant === 'goals' &&
        goals?.map((goal) => (
          <Styled.CardWrapper key={goal?.id}>
            <TaskCard goal={goal} activity={activity} />
          </Styled.CardWrapper>
        ))}
    </Styled.CardsContainer>
  );
};
