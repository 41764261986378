import { useQuery } from '@apollo/client';
import { GET_GOAL } from 'api/goal/goal.query';
import HealthwiseApiService from 'api/healthwise/healthwiseApiService';
import { HealthwiseArticleApiResponse } from 'api/healthwise/types';
import { ReactComponent as IconComp } from 'assets/vectors/fi-rr-lesson.svg';
import { format } from 'date-fns';
import { useTitle } from 'hooks/useTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { getCompletionNumericalRange, getCompletionPercentage } from 'utils/helpers/goals';

import { Header } from 'components/layout/BaseLayout/Header';
import { ArticleTab } from 'components/ui/ArticleTab/ArticleTab';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import * as Styled from './CompleteYourLessons.styles';

export const CompleteYourLessons: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.lessons' });
  const navigate = useNavigate();
  const [articles, setArticles] = useState<(HealthwiseArticleApiResponse | null | undefined)[] | null>(null);
  const { goalId } = useParams();

  const { data: goalData, loading: isLoading } = useQuery(GET_GOAL, {
    variables: { id: goalId, date: format(Date.now(), 'yyyy-MM-dd') },
    fetchPolicy: 'network-only',
  });
  const title = useTitle({ isLoading, repeat: goalData?.goal?.repeat });
  const subgoals = useMemo(
    () =>
      goalData?.goal?.subgoals?.map((subgoal) => {
        return {
          id: subgoal?.id,
          name: subgoal?.name?.split(',')[0], // Allows you to add anything after a , in healthie
          completed: subgoal?.is_completed_for_date,
        };
      }),
    [goalData],
  );
  useEffect(() => {
    async function fetchArticles() {
      setArticles(
        await HealthwiseApiService.getArticlesByIds(
          subgoals?.filter((subgoal) => subgoal.name !== 'custom').map((subgoal) => subgoal.name),
        ),
      );
    }
    fetchArticles();
  }, [subgoals]);

  const navigateToLesson = (articleId: string | undefined) => {
    if (articleId)
      navigate(`/lesson/${goalId}/${subgoals?.find((subgoal) => subgoal.name === articleId)?.id}/${articleId}`);
  };

  const isLessonCompleted = (articleId: string | undefined) => {
    return subgoals && subgoals.find((subgoal) => subgoal.name == articleId && subgoal.completed);
  };

  const customGoal = goalData?.goal?.subgoals?.filter((sub) => sub?.name?.split(',')[0] === 'custom')?.[0];

  const customGoalParts = customGoal?.name?.split(/,(.+)/);
  const customGoalsJson = JSON.parse(customGoalParts?.[1] || '{}');

  return (
    <Styled.PageContainer>
      <Styled.HeaderContainer>
        <Header onBackClick={() => navigate(paths.homePage)} variant="back" title={title} />
      </Styled.HeaderContainer>
      <Styled.Content>
        <Styled.ContentHeader
          label={goalData?.goal?.name}
          description={t('lessonsCompleted', { range: getCompletionNumericalRange(goalData?.goal) })}
          icon={<Icon element={IconComp} size={20} aria-hidden />}
          progress={getCompletionPercentage(goalData?.goal)}
        />
        <Styled.LessonsItems>
          {articles ? (
            articles.map((article: HealthwiseArticleApiResponse | null | undefined, idx) => (
              <ArticleTab
                label={`${t('lesson')} ${idx + 1}`}
                title={article?.data.title?.consumer}
                hidePlayIcon={article?.data.type !== 'video'}
                isCompleted={!!isLessonCompleted(article?.data.id)}
                key={article?.data.id}
                onClick={() => navigateToLesson(article?.data.id)}
              />
            ))
          ) : (
            <LoadingPage />
          )}
          {customGoal && (
            <ArticleTab
              label={`${t('lesson')} ${articles?.length ? articles.length + 1 : 1}`}
              title={customGoalsJson?.title}
              hidePlayIcon={customGoalsJson?.type !== 'video'}
              isCompleted={!!isLessonCompleted('custom')}
              key={customGoal.id}
              onClick={() => navigateToLesson('custom')}
            />
          )}
        </Styled.LessonsItems>
      </Styled.Content>
    </Styled.PageContainer>
  );
};
