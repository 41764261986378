import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  height: 100%;
`;

export const ExtendingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CenterContainer = styled(ExtendingContainer)`
  align-items: center;
  justify-content: center;
`;

export const Center = styled.div`
  justify-content: center;
  display: flex;
`;

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.theme.color.other.linen};
  height: 100vh;
  max-height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0px;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px 16px 0 16px;
`;

export const PaddingContainer = styled.div`
  padding: 10px 16px;
`;

export const HeaderContainer = styled.div`
  padding: 16px;
  margin-top: 18px;
`;

export const StyledLink = styled(Link)`
  flex-direction: row;
  display: flex;
  text-decoration: none;
  margin-top: 24px;
`;
