import { useQuery } from '@apollo/client';
import { FORM_DATA, FORM_ID } from 'api/consents/consents.query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Mixpanel } from 'utils/mixpanel';

import { Checkbox } from 'components/form/Checkbox/Checkbox';
import { Button } from 'components/ui/Button/Button';

import { ConsentsAction } from '../ConsentStep';

export type Answers = {
  custom_module_id: string;
  answer?: string;
};

export type FormAnswerType = {
  formID: string;
  answers: Answers[];
};

type Props = {
  onboardId?: string;
  openModal: (headline: string, content: string) => void;
  index: number;
  addAnswer: (answer: FormAnswerType) => void;
  consentsDispatch: (action: ConsentsAction) => void;
  consentsLoadingStatus: string;
};
('[');
export const ConsentCheckbox: React.FC<Props> = ({
  onboardId,
  openModal,
  index,
  addAnswer,
  consentsDispatch,
  consentsLoadingStatus,
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.logIn.consents' });

  useEffect(() => {
    if (onboardId) consentsDispatch({ type: 'onInit', id: onboardId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formId = useQuery(FORM_ID, {
    variables: { id: onboardId },
    fetchPolicy: 'network-only',
    onError: () => {
      if (onboardId) consentsDispatch({ type: 'onError', id: onboardId, value: false });
    },
  }).data?.onboardingItem?.item_id;
  const formData = useQuery(FORM_DATA, {
    variables: { id: formId },
    fetchPolicy: 'network-only',
    skip: !formId,
    onError: () => {
      if (onboardId) consentsDispatch({ type: 'onError', id: onboardId, value: false });
    },
    onCompleted: () => {
      if (onboardId) consentsDispatch({ type: 'onCompleted', id: onboardId, value: true });
    },
  }).data?.customModuleForm?.custom_modules;

  const content = formData?.[0];
  const answer = formData?.[1];

  const sendMixpanelEvent = (label: string) => {
    const isPP = label.includes('Privacy Policy');
    const isTelehealth = label.includes('Telehealth');

    if (isTelehealth) {
      Mixpanel.track('Telehealth_consent_completed');
    }

    if (isPP) {
      Mixpanel.track('ToUPP_consent_completed');
    }
  };

  if (consentsLoadingStatus !== 'success') return <></>;

  return formData && formId && answer ? (
    <Checkbox
      name={`consents[${index}]`}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked === true && content?.label) {
          sendMixpanelEvent(content?.label);
        }

        const data: FormAnswerType = {
          formID: formId,
          answers: [],
        };

        formData.map((d) => {
          const ans: Answers = {
            custom_module_id: d.id,
          };
          if (['textarea', 'text', 'read_only'].includes(d?.mod_type || '') && d.options) {
            ans.answer = d.options;
          } else if (['agree_to_above'].includes(d?.mod_type || '')) {
            ans.answer = 'true';
          }
          data.answers.push(ans);
        });

        addAnswer(data);
      }}
      label={
        <div>
          {t('read')}{' '}
          <Button
            style={{ textAlign: 'left' }}
            type="button"
            variant="link"
            onClick={() => openModal(content?.label as string, content?.options as string)}
          >
            {content?.label}
          </Button>
        </div>
      }
    />
  ) : (
    <></>
  );
};
