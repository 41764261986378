import { useUserNotificationsCount } from 'hooks/useUserNotificationsCount';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { Icon } from '../Icon/Icon';
import { IconBadge } from '../IconBadge/IconBadge';
import { Typography } from '../Typography/Typography';
import { navItems } from './Navigation.model';
import * as Styled from './Navigation.styles';

export const Navigation: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.navigation' });
  const { pathname } = useLocation();
  const { conversationNotificationCount } = useUserNotificationsCount();

  return (
    <Styled.Nav>
      {navItems.map((item) => {
        const isSelected = pathname === item.route;

        if (item.text === 'chat') {
          return (
            // I had to add location.replace() because there was a problem with appearing new messages
            // Problem described here -> MOT-289
            // I know that's not the best solution but it works for now
            // It's hard to find the cause because we don't have access to the Chat SDK code
            <Styled.NavItem
              key={item.text}
              to={item.route}
              state={{ fromNav: true }}
              onClick={() => {
                location.replace(paths.chat);
                Mixpanel.track_pageview({ page: 'Chat' });
              }}
            >
              <IconBadge
                size={24}
                icon={
                  <Icon
                    element={isSelected ? item.filledIcon : item.icon}
                    color={isSelected ? 'other.white' : 'transparent'}
                    strokeColor={isSelected ? 'transparent' : 'blue.100'}
                    size={24}
                  />
                }
                value={conversationNotificationCount}
                showBadgeNumber={false}
              />
              <Typography variant="Helper2" color={isSelected ? 'other.white' : 'blue.100'}>
                {t(item.text)}
              </Typography>
            </Styled.NavItem>
          );
        }

        return (
          <Styled.NavItem key={item.text} to={item.route}>
            <Icon
              element={isSelected ? item.filledIcon : item.icon}
              color={isSelected ? 'other.white' : 'transparent'}
              strokeColor={isSelected ? 'transparent' : 'blue.100'}
              size={24}
            />
            <Typography variant="Helper2" color={isSelected ? 'other.white' : 'blue.100'}>
              {t(item.text)}
            </Typography>
          </Styled.NavItem>
        );
      })}
    </Styled.Nav>
  );
};
