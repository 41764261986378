import { useMutation, useQuery } from '@apollo/client';
import { CREATE_FORM_ANSWER_GROUP, UPDATE_FORM_ANSWER_GROUP } from 'api/exercises/exercise.mutation';
import { GET_EXERCISE_CHART, GET_USER_CHART_BY_NAME } from 'api/exercises/exercise.query';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useExerciseChart = (userId: string) => {
  const name = 'User Exercise Tracker';
  const [createUserFormAnswerGroup, { loading: chartCreateLoading }] = useMutation(CREATE_FORM_ANSWER_GROUP);
  const [updateUserFormAnswerGroup, { loading: updateChartLoading }] = useMutation(UPDATE_FORM_ANSWER_GROUP);
  const { data: chartData } = useQuery(GET_EXERCISE_CHART, {
    variables: {
      customModuleFormId: process.env.REACT_APP_USER_EXERCISE_CHART_ID || '1977659',
    },
  });

  const {
    data: userChart,
    loading: userChartLoading,
    refetch: refetchUserChart,
  } = useQuery(GET_USER_CHART_BY_NAME, {
    variables: {
      userId: userId,
      name: name,
    },
  });

  const chartId = chartData?.customModuleForm?.id;

  const formAnswers = chartData?.customModuleForm?.custom_modules.map((module) => {
    return {
      user_id: userId,
      custom_module_id: module.id,
    };
  });

  const createUserChart = async () => {
    try {
      if (userChart && userChart?.formAnswerGroups?.length === 0 && !userChartLoading && !chartCreateLoading) {
        const { data } = await createUserFormAnswerGroup({
          variables: {
            input: {
              name: name,
              finished: true,
              custom_module_form_id: chartId,
              user_id: userId,
              form_answers: formAnswers,
            },
          },
        });
        refetchUserChart();
        return data;
      }
      return userChart;
      // Refetch userChart after creating the chart
    } catch (error) {
      console.error('Error creating user chart:', error);
      throw error;
    }
  };

  const updateUserChart = async (payload: {
    form_id: string;
    formAnswers: {
      id: string;
      custom_module_id: string;
      answer: string;
      user_id: string;
    }[];
  }) => {
    try {
      const res = await updateUserFormAnswerGroup({
        variables: {
          input: {
            id: payload.form_id,
            form_answers: payload.formAnswers,
          },
        },
      });
      refetchUserChart();
      return res;
    } catch (error) {
      console.error('Error updating user chart:', error);
      throw error;
    }
  };

  return {
    updateUserChart,
    userChart,
    userChartLoading,
    updateChartLoading,
    createUserChart,
  };
};
