import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const ProgressCircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 78px;
  flex: 1;
`;

export const GoodJobText = styled(Typography)`
  margin-top: 24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

export const HeartRateContainer = styled.div`
  margin-top: 101px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeartRateValue = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const ModalTitle = styled(Typography)`
  margin-bottom: 8px;
  text-align: center;
`;

export const ModalText = styled(Typography)`
  margin-bottom: 34px;
  text-align: center;
`;

export const ModalButton = styled(Button)`
  width: auto;
`;
