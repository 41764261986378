import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import bloodPressurePng from 'assets/images/pdf/blood-pressure.png';
import activityPng from 'assets/images/pdf/dumbell.png';
import medicationPng from 'assets/images/pdf/medication.png';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'utils/types';

import Pdf from 'components/pdf';
import PdfPage from 'components/pdf/page';

import { DateOption } from '../../types';
import { medicationExpectedGoal } from '../MedicationsTile';

type Props = {
  dateRange: Maybe<DateOption>;
  phoneNumber?: string | null;
  bloodPressure: {
    completedMeasurements: number;
    requiredMeasurementsInWeek: number;
    averageBloodPressure: string;
    goal: Maybe<string>;
    averageHeartRate: number;
  };
  activity: {
    average: string;
    stepsGoal: number;
  };
  medications: {
    avgPercentage: number;
    mostOccurringAnswer?: string;
  };
};

export const WeeklyReportPdf: React.FC<Props> = ({ phoneNumber, dateRange, bloodPressure, activity, medications }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.weeklyReport.pdf' });

  const styles = StyleSheet.create({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '16px 30px 8px 30px',
      fontSize: 16,
    },
    summary: {
      padding: '0px 30px 24px 30px',
      fontSize: 14,
    },
    icon: {
      width: 16,
      height: 16,
    },
    mainContainer: {
      padding: '16px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardsContainer: {
      width: '75%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #E6E6E6',
      alignItems: 'flex-start',
      justifyContent: 'center',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '16px',
    },
    planInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      paddingBottom: '16px',
    },
    flexLayout: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    singleRow: {
      marginBottom: '16px',
    },
    singleRowContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      marginTop: '8px',
    },
    body1: {
      fontSize: 16,
      fontWeight: 'normal',
    },
    helper1: {
      fontSize: 12,
      fontWeight: 'normal',
    },
    valueText: {
      fontSize: 16,
      fontWeight: 'extrabold',
    },
  });
  return (
    <Pdf>
      <PdfPage coachNumber={phoneNumber}>
        <View style={styles.header}>
          <Text>{t('weeklyReport')}</Text>
          {dateRange && (
            <Text>
              {t('week', {
                date: `${format(dateRange.start, 'MM.dd.yyyy')} - ${format(dateRange.end, 'MM.dd.yyyy')}`,
              })}
            </Text>
          )}
        </View>
        <View style={styles.summary}>
          <Text>{t('summaryOfYourHealthData')}</Text>
        </View>
        <View style={styles.mainContainer}>
          <View style={styles.cardsContainer}>
            <View style={styles.planInfoContainer}>
              <Image src={bloodPressurePng} style={styles.icon} />
              <Text style={styles.body1}>{t('bloodPressure')}</Text>
            </View>

            <View style={styles.singleRow}>
              <Text style={styles.helper1}>{t('completedReadings')}</Text>
              <View style={styles.singleRowContainer}>
                <Text style={styles.valueText}>
                  {t('completedReadingsValue', {
                    completed: bloodPressure.completedMeasurements,
                    required: bloodPressure.requiredMeasurementsInWeek,
                  })}
                </Text>
                <Text style={styles.body1}>{t('readings')}</Text>
              </View>
            </View>

            <View style={styles.flexLayout}>
              <View style={styles.singleRow}>
                <Text style={styles.helper1}>{t('averageWeek')}</Text>
                <View style={styles.singleRowContainer}>
                  <Text style={{ ...styles.valueText, color: '#CE2727' }}>{bloodPressure.averageBloodPressure}</Text>
                  <Text style={styles.body1}>{t('mmHg')}</Text>
                </View>
              </View>

              <View style={styles.singleRow}>
                <Text style={styles.helper1}>{t('goal')}</Text>
                <View style={styles.singleRowContainer}>
                  <Text style={styles.valueText}>
                    {bloodPressure.goal ? t('goalValue', { value: bloodPressure.goal }) : t('notAvailable')}
                  </Text>
                  <Text style={styles.body1}>{t('mmHg')}</Text>
                </View>
              </View>
            </View>

            <View style={styles.singleRow}>
              <Text style={styles.helper1}>{t('averageHeartRate')}</Text>
              <View style={styles.singleRowContainer}>
                <Text style={{ ...styles.valueText, color: '#CE2727' }}>
                  {bloodPressure.averageHeartRate || t('notAvailable')}
                </Text>
                <Text style={styles.body1}>{t('bpm')}</Text>
              </View>
            </View>
          </View>

          <View style={styles.cardsContainer}>
            <View style={styles.planInfoContainer}>
              <Image src={activityPng} style={styles.icon} />
              <Text style={styles.body1}>{t('activity.title')}</Text>
            </View>

            <View style={styles.flexLayout}>
              <View style={styles.singleRow}>
                <Text style={styles.helper1}>{t('activity.averagePerDay')}</Text>
                <View style={styles.singleRowContainer}>
                  <Text style={{ ...styles.valueText, color: '#209D6A' }}>{activity.average}</Text>
                  <Text style={styles.body1}>{t('activity.steps')}</Text>
                </View>
              </View>

              <View style={styles.singleRow}>
                <Text style={styles.helper1}>{t('activity.goalPerDay')}</Text>
                <View style={styles.singleRowContainer}>
                  <Text style={styles.valueText}>{t('activity.goalValue', { value: activity.stepsGoal })}</Text>
                  <Text style={styles.body1}>{t('activity.steps')}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.cardsContainer}>
            <View style={styles.planInfoContainer}>
              <Image src={medicationPng} style={styles.icon} />
              <Text style={styles.body1}>{t('medication.title')}</Text>
            </View>

            <View style={styles.flexLayout}>
              <View style={styles.singleRow}>
                <Text style={styles.helper1}>{t('medication.average')}</Text>
                <View style={styles.singleRowContainer}>
                  <Text style={{ ...styles.valueText, color: '#2E69C1' }}>
                    {t('medication.averageValue', { value: Math.floor(medications.avgPercentage) })}
                  </Text>
                  <Text style={styles.body1}>{t('medication.taken')}</Text>
                </View>
              </View>

              <View style={styles.singleRow}>
                <Text style={styles.helper1}>{t('goal')}</Text>
                <View style={styles.singleRowContainer}>
                  <Text style={styles.valueText}>{t('medication.goalValue', { value: medicationExpectedGoal })}</Text>
                  <Text style={styles.body1}>{t('medication.taken')}</Text>
                </View>
              </View>
            </View>
            <View style={styles.singleRow}>
              <Text style={styles.helper1}>{t('medication.mostUsedAnswer')}</Text>
              <View style={styles.singleRowContainer}>
                <Text style={styles.valueText}>{medications.mostOccurringAnswer ?? t('notAvailable')}</Text>
              </View>
            </View>
          </View>
        </View>
      </PdfPage>
    </Pdf>
  );
};
