import { useQuery } from '@apollo/client';
import { GET_CARE_PLAN } from 'api/progress/carePlan.query';
import { GET_USER_MOTIVATION_ENTRY } from 'api/progress/motivation/motivation.query';
import { GET_PROGRESS_ENTRIES } from 'api/progress/progress.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as CarePlan } from 'assets/vectors/fi-rr-assesment.svg';
import { ReactComponent as HeartRate } from 'assets/vectors/fi-rr-heart rate small.svg';
import { ReactComponent as Medication } from 'assets/vectors/fi-rr-medication.svg';
import { ReactComponent as PersonalGoals } from 'assets/vectors/fi-rr-personal goals.svg';
import { ReactComponent as Activity } from 'assets/vectors/fi-rr-steps.svg';
import { ReactComponent as WeeklyReport } from 'assets/vectors/fi-rr-weekly report.svg';
// import { ReactComponent as Weight } from 'assets/vectors/fi-rr-weight.svg';
import { compareAsc, format, isToday, isValid, isYesterday } from 'date-fns';
import { useCompleteActivityGoal } from 'hooks/useCompleteActivityGoal';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { notEmpty, parseHealthieDate } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import { NoCarePlan } from './NoCarePlan/NoCarePlan';
import { useGetLatestCompletedPersonalGoal } from './PersonalGoalsProgress/hooks';
import { ProgressPage, ProgressSubTitle } from './Progress.styles';
import { Motivation } from './components/Motivation/Motivation';
import { ProgressItem, ProgressItemProps } from './components/ProgressItem/ProgressItem';

type Entry = {
  type: string;
  updated_at: string;
  readingDate: string;
  readingTime: string;
};

export const Progress: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.progress' });
  const navigate = useNavigate();
  const { hasLoggedInBefore } = useCurrentFitbitTokens();

  const userID = useQuery(GET_CURRENT_USER_DATA).data?.currentUser?.id;
  const entries = useQuery(GET_PROGRESS_ENTRIES, { variables: { client_id: userID } }).data?.entries ?? [];
  const { data: carePlanData, loading: carePlanDataLoading } = useQuery(GET_CARE_PLAN, {
    variables: { date: format(Date.now(), 'yyyy-MM-dd') },
  });
  const goals = carePlanData?.currentUser?.active_care_plan?.goals;

  const { isFirstWeek } = useCompleteActivityGoal();

  const { data, loading, refetch } = useQuery(GET_USER_MOTIVATION_ENTRY, {
    variables: {
      type: 'NoteEntry',
      category: 'motivation',
      client_id: userID,
    },
  });

  const goalsToEntries =
    goals
      ?.map((goal) => {
        if (goal.goal_histories.length === 0) {
          return null;
        }

        if (goal.name?.toLowerCase().includes('personal')) {
          return {
            type: 'Personal',
            category: '',
            updated_at: goal.goal_histories[0].completed_on,
          };
        } else if (goal.name?.toLowerCase().includes('medications')) {
          return {
            type: 'Medications',
            category: '',
            updated_at: goal.goal_histories[0].completed_on,
          };
        }
        return null;
      })
      .filter(notEmpty) ?? [];

  const latestPersonalGoalDate = useGetLatestCompletedPersonalGoal(carePlanData);

  const latestEntries = [...entries, ...goalsToEntries]?.filter(notEmpty).reduce((latest, current) => {
    const { type, updated_at, category } = current;
    if (updated_at && type) {
      const entryDate = parseHealthieDate(
        updated_at,
        type === 'Medications' || type === 'Personal' ? 'yyyy-MM-dd' : undefined,
      );

      const key = category ? `${type}-${category}` : type;

      const isLaterEntry =
        latest[key]?.updated_at && compareAsc(entryDate, new Date(latest[key]?.updated_at as string)) > 0;

      if ((!latest[key] || isLaterEntry) && isValid(entryDate)) {
        const readingTime = format(entryDate, 'h:mm aaaa');
        let readingDate = '';
        if (isToday(entryDate)) {
          readingDate = t('today');
        } else if (isYesterday(entryDate)) {
          readingDate = t('yesterday');
        } else {
          readingDate = format(entryDate, 'MM/dd/yyyy');
        }
        latest[key] = { type, updated_at, readingDate, readingTime };
      }
    }

    return latest;
  }, {} as { [key: string]: Entry });

  const progressData: ProgressItemProps[] = [
    {
      label: t('carePlan'),
      children: t('accessCare'),
      icon: CarePlan,
      onClick: () => navigate(paths.carePlan),
    },
  ];

  if (!isFirstWeek) {
    progressData.unshift({
      label: t('weeklyReport'),
      children: t('accessWeekly'),
      iconStroke: 'blue.700',
      iconColor: 'transparent',
      icon: WeeklyReport,
      onClick: () => navigate(paths.weeklyReport),
    });
  }

  const healthData: ProgressItemProps[] = [
    {
      label: t('bloodPressure'),
      children: latestEntries?.['MetricEntry-Blood Pressure']
        ? t('lastBloodPressure', {
            date: latestEntries['MetricEntry-Blood Pressure'].readingDate,
            time: latestEntries['MetricEntry-Blood Pressure'].readingTime,
          })
        : null,
      icon: HeartRate,
      iconStroke: 'blue.700',
      iconColor: 'transparent',
      onClick: () => navigate(paths.bloodPressure),
    },
    ...(hasLoggedInBefore
      ? [
          {
            label: t('activity'),
            children: latestEntries?.['MetricEntry-Steps']
              ? t('lastActivity', {
                  date: latestEntries['MetricEntry-Steps'].readingDate,
                  time: latestEntries['MetricEntry-Steps'].readingTime,
                })
              : null,
            icon: Activity,
            onClick: () => navigate(!hasLoggedInBefore ? paths.fitbitSetup : paths.activity),
          },
        ]
      : []),
    // {
    //   label: t('weight'),
    //   children: latestEntries?.['MetricEntry']
    //     ? t('lastWeight', {
    //         date: latestEntries['MetricEntry'].readingDate,
    //         time: latestEntries['MetricEntry'].readingTime,
    //       })
    //     : null,
    //   icon: Weight,
    //   onClick: () => navigate(paths.carePlan),
    // },
    {
      label: t('medications'),
      children: latestEntries?.['Medications']
        ? t('lastMedications', {
            date: latestEntries['Medications'].readingDate,
          })
        : null,
      icon: Medication,
      onClick: () => navigate(paths.medications),
    },
    {
      label: t('personalGoals'),
      children: latestPersonalGoalDate
        ? t('lastPersonalGoals', {
            date: latestPersonalGoalDate,
          })
        : null,
      icon: PersonalGoals,
      onClick: () => navigate(paths.personalGoals),
    },
  ];

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Progress' });
  }, []);

  return (
    <>
      {carePlanDataLoading ? (
        <LoadingPage />
      ) : (
        <>
          {carePlanData?.currentUser?.active_care_plan ? (
            <BaseLayout headerProps={{ title: t('progress') }}>
              <ProgressPage>
                {!loading && <Motivation userID={userID} motivationEntry={data?.entries?.[0]} refetch={refetch} />}
                {progressData.map((item) => (
                  <ProgressItem key={item.label} {...item} />
                ))}
                <ProgressSubTitle variant="SubHeadline1">{t('healthData')}</ProgressSubTitle>
                {healthData.map((item) => (
                  <ProgressItem key={item.label} {...item} />
                ))}
              </ProgressPage>
            </BaseLayout>
          ) : (
            <NoCarePlan />
          )}
        </>
      )}
    </>
  );
};
