import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Entry } from '__generated__/graphql';
import { theme } from 'assets/styles/theme';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Pdf from 'components/pdf';
import PdfPage from 'components/pdf/page';

import { preparePdfData } from '../pdfUtils';

type Props = {
  data: Entry[];
  coachPhone?: string | null;
};

export const BloodPressureReadingPdf: React.FC<Props> = ({ data, coachPhone }) => {
  const styles = StyleSheet.create({
    headerStyles: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '16px 30px',
    },
    date: {
      marginTop: 8,
      fontSize: 14,
      color: theme.color.black[700],
    },
    subDate: {
      fontSize: 12,
      color: theme.color.black[600],
      paddingLeft: 6,
      margin: '5px 0',
    },
    container: {
      padding: '0 30px',
      display: 'flex',
      flexDirection: 'row',
    },
    itemStyles: {
      border: `1px solid ${theme.color.black[50]}`,
      width: '100%',
      padding: 6,
      borderRadius: 5,
      marginBottom: 8,
    },
    hours: {
      fontSize: 10,
      marginBottom: 8,
    },
    mainText: {
      color: theme.color.other.red,
    },
    itemText: {
      display: 'flex',
      flexDirection: 'row',
    },
    columnStyles: {
      width: '50%',
      padding: '0 10px',
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  });

  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure.pdf' });

  const pdfData = useMemo(() => preparePdfData(data), [data]);

  return (
    <Pdf>
      {pdfData.map((chunk, index) => (
        <PdfPage key={index} coachNumber={coachPhone}>
          <View style={styles.headerStyles}>
            <Text>{t('title')}</Text>
          </View>
          <View style={styles.container}>
            {chunk.map((column, index) => (
              <View
                key={index}
                style={{ ...styles.columnStyles, ...{ ...(index % 2 === 0 && { borderRight: '1px solid #E6E6E6' }) } }}
              >
                {column.map((group, i) => (
                  <View key={i}>
                    <Text style={styles.date}> {format(group[0].createdAt, 'EEE, MMM d')} </Text>
                    <View style={styles.flexContainer}>
                      <Text style={styles.subDate}>{t('bloodPressure')}</Text>
                      <Text style={styles.subDate}>{t('heartRate')}</Text>
                    </View>
                    {group.map((item) => (
                      <View key={item.id} style={styles.itemStyles}>
                        <Text style={styles.hours}>{format(item.createdAt, 'h:mm aaa')}</Text>
                        <View style={styles.flexContainer}>
                          <View style={styles.itemText}>
                            <Text style={styles.mainText}>{item.pressure} </Text>
                            <Text>{t('mmHg')}</Text>
                          </View>
                          {item.heartRate && (
                            <View style={styles.itemText}>
                              <Text style={styles.mainText}>{item.heartRate} </Text>
                              <Text>{t('bpm')}</Text>
                            </View>
                          )}
                        </View>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            ))}
          </View>
        </PdfPage>
      ))}
    </Pdf>
  );
};
