import React from 'react';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { CarePlanGoalTyped } from '../../CarePlan.model';
import { ItemDescription, ItemWrapper, ListItem } from './PlanItem.styles';
import { usePlanItem } from './usePlanItem';

export const PlanItem: React.FC<CarePlanGoalTyped> = ({ goal, type }: CarePlanGoalTyped) => {
  const itemValues = usePlanItem(goal, type);

  return (
    itemValues && (
      <ItemWrapper>
        <Icon element={itemValues.icon} size={16} color={null} />
        <div>
          <Typography variant="Body1">{itemValues.title}</Typography>
          <ItemDescription color="black.600" size={14}>
            {Array.isArray(itemValues.description)
              ? itemValues.description.map((item) => <ListItem key={item}>{item}</ListItem>)
              : itemValues.description}
          </ItemDescription>
        </div>
      </ItemWrapper>
    )
  );
};
