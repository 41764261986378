import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const Card = styled(Typography.Div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.blue[50]};
  position: relative;
`;

export const Main = styled(Typography)`
  margin: 0;
  font-weight: ${({ theme }) => theme.font.weight[500]};
  max-width: 100%;
  overflow-wrap: break-word;
`;
