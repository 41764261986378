import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';

import { Button } from 'components/ui/Button/Button';

import * as Styled from '../../BloodPressureManual.styles';

export interface Params {
  goalId: string;
}

interface FooterProps {
  currentStep: number;
  firstTimeEnter: boolean;
  setCurrentStep: (step: number) => void;
  totalSteps: number;
  handleSkip: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  currentStep,
  firstTimeEnter,
  setCurrentStep,
  totalSteps,
  handleSkip,
}: FooterProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressureManual' });
  const { goalId } = useParams<keyof Params>() as Params;

  const navigate = useNavigate();

  const handleDoItLater = () => {
    navigate(paths.homePage);
  };

  const isLastStep = currentStep === totalSteps;
  if (isLastStep) {
    return (
      <>
        <Styled.SButton onClick={handleDoItLater} variant="link">
          {' '}
          {t('doItLater')}
        </Styled.SButton>
        <Styled.SButton onClick={() => navigate(paths.recordYourBloodPressureSync.replace(':goalId', goalId))}>
          {t('syncNow')}
        </Styled.SButton>
      </>
    );
  } else {
    if (firstTimeEnter) {
      return (
        <>
          <Styled.SButton onClick={handleSkip} variant="link">
            {t('skip')}
          </Styled.SButton>
          <Styled.SButton onClick={() => setCurrentStep(currentStep + 1)}>{t('next')}</Styled.SButton>
        </>
      );
    } else {
      return <Button onClick={() => setCurrentStep(currentStep + 1)}>{t('next')}</Button>;
    }
  }
};
