import React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

export interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  loading?: boolean;
  label: string;
  labelProps?: React.ComponentProps<typeof Typography.Label>;
  onChange: () => void;
}

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(Typography.Label)`
  flex: 1;
  margin-right: 10px;
`;

export const CustomSwitch = styled.input`
  appearance: none;
  width: 50px; // Increased width
  height: 28px; // Increased height
  background-color: ${({ theme, checked }) =>
    checked ? theme.color.other.green : '#e0e0e0'}; // Dynamic background color
  border-radius: 14px; // Rounded corners (half of height)
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s; // Smooth transition for background color change
  border: none; // Remove border

  &:after {
    content: '';
    position: absolute;
    top: 2px; // Adjust top position
    left: ${(props) => (props.checked ? '24px' : '2px')}; // Dynamic left position
    width: 24px; // Increased width of circle
    height: 24px; // Increased height of circle
    background-color: white; // White circle
    border-radius: 50%; // Make it circular
    transition: left 0.3s; // Smooth transition for movement
  }

  &:disabled {
    opacity: 0.5; // Add opacity for disabled state
    cursor: not-allowed;
    ~ ${Label} {
      color: ${({ theme }) => theme.color.black[50]};
    }
  }
`;
const LeftLabelSwitch: React.FC<SwitchProps> = ({ label, loading, checked, onChange, labelProps, ...rest }) => {
  return (
    <SwitchContainer>
      <Label weight={500} size={16} lineHeight={24} {...labelProps}>
        {label}
      </Label>
      {loading ? (
        <Spinner size={20} />
      ) : (
        <CustomSwitch type="checkbox" checked={checked} onChange={onChange} {...rest} />
      )}
    </SwitchContainer>
  );
};

export default LeftLabelSwitch;
