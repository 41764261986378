import { ApolloQueryResult, useMutation } from '@apollo/client';
import { MotivationEntryQuery } from '__generated__/graphql';
import { CREATE_MOTIVATION_ENTRY, UPDATE_MOTIVATION_ENTRY } from 'api/progress/motivation/motivation.mutation';
import { ReactComponent as Pencil } from 'assets/vectors/fi-rr-pencil.svg';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { onEnterKey } from 'utils/helpers';
import { Maybe } from 'utils/types';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';

import { ButtonNav, MotivationContent, MotivationHeader, SaveButton } from './Motivation.styles';

type MotivationEntry = {
  __typename?: 'Entry';
  id: string;
  type?: string | null;
  category?: string | null;
  emotions?: string | null;
  name?: string | null;
  description?: string | null;
  reflection?: string | null;
  added_by_user?: {
    __typename?: 'User';
    id: string;
  } | null;
};

type Props = {
  userID?: string;
  motivationEntry: Maybe<MotivationEntry>;
  refetch: (
    variables?:
      | Partial<{
          type: string;
          category: string;
          client_id: string | undefined;
        }>
      | undefined,
  ) => Promise<ApolloQueryResult<MotivationEntryQuery>>;
};

type FormValues = {
  motivation?: string;
};

export const Motivation: React.FC<Props> = ({ userID, motivationEntry, refetch }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.progress' });
  const [editing, setEditing] = useState(false);
  const [createMotivation] = useMutation(CREATE_MOTIVATION_ENTRY);
  const [updateMotivation] = useMutation(UPDATE_MOTIVATION_ENTRY);

  const onSubmit = async (values: FormValues) => {
    if (motivationEntry)
      await updateMotivation({
        variables: {
          id: motivationEntry.id,
          reflection: values.motivation,
        },
      });
    else {
      await createMotivation({
        variables: {
          type: 'NoteEntry',
          category: 'motivation',
          user_id: userID,
          description: 'My motivation',
          reflection: values.motivation,
        },
      });
    }
    await refetch({
      type: 'NoteEntry',
      category: 'motivation',
      client_id: userID,
    });
    setEditing(false);
  };
  const motivation = motivationEntry?.reflection;
  const schema = Yup.object({
    motivation: Yup.string().max(120, t('tooLong', { count: 120 })),
  });

  const toggleEdit = () => {
    setEditing(!editing);
  };
  return (
    <>
      <MotivationHeader
        variant="SubHeadline1"
        color="blue.700"
        onClick={toggleEdit}
        tabIndex={0}
        onKeyDown={(e) => onEnterKey(e, toggleEdit)}
      >
        {t('motivation')}
        <Icon element={Pencil} color="blue.700" size={20} />
      </MotivationHeader>
      {motivation && !editing && <MotivationContent>"{motivation}"</MotivationContent>}
      {editing && (
        <Formik
          initialValues={{ motivation: motivation ?? '' }}
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange
          validateOnBlur
        >
          {({ values, isValid }) => {
            return (
              <Form style={{ marginBottom: 16 }}>
                <Input type="textarea" name="motivation" label={t('myMotivation')} value={values.motivation} />
                <ButtonNav>
                  <Button type="button" variant="link" onClick={() => setEditing(false)} style={{ marginLeft: 48 }}>
                    {t('cancel')}
                  </Button>
                  <SaveButton type="submit" disabled={!isValid}>
                    {t('saveChanges')}
                  </SaveButton>
                </ButtonNav>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
