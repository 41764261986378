import { gql } from '__generated__';

export const GET_PROGRESS_ENTRIES = gql(`
  query progressEntries(
    $client_id: String,
  ) {
    entries(
      client_id: $client_id,
    ) {
      id
      type
      added_by_user {
        id
      }
      category
      emotions
      name
      description
      reflection
      created_at
      updated_at
      metric_stat
      metric_stat_string
    }
  }
`);
