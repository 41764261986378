import { ReactComponent as Info } from 'assets/vectors/fi-rr-information.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

import { ButtonNav } from '../ForgotPassword.styles';
import { ErrorContent, ErrorIcon, TryAgain } from './ResetError.styles';

export const ResetError: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.forgotPassword.resetError' });
  const navigate = useNavigate();
  return (
    <BaseLayout
      headerProps={{ variant: 'back', title: t('reset'), onBackClick: () => navigate(paths.logInPage) }}
      footer={
        <ButtonNav style={{ padding: 16 }}>
          <Button variant="link" onClick={() => navigate(paths.logInPage)} style={{ marginLeft: 24 }}>
            {t('goBackHome')}
          </Button>
          <Button onClick={() => navigate(paths.forgotPassword)} style={{ width: 150 }}>
            {t('refresh')}
          </Button>
        </ButtonNav>
      }
    >
      <ErrorContent>
        <Typography weight={600} size={28} lineHeight={36}>
          {t('somethingWentWrong')}
        </Typography>
        <ErrorIcon element={Info} size={108} color="other.shell" />
        <TryAgain>{t('tryAgain')}</TryAgain>
      </ErrorContent>
    </BaseLayout>
  );
};
