import { useCarePlanGoals } from 'pages/home/hooks/useCarePlan';
import { GoalSection, GoalText, GoodJobText } from 'pages/progress/activity/Activity.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CircularProgressBar from 'components/ui/CircularProgressBar/CircularProgressBar';
import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

import { useGetMeasurementsStatistics } from './hooks';

const GOOD_JOB_PERCENTAGE = 50;

export const ActivityGoalSection: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure' });

  const { dailyGoals } = useCarePlanGoals();
  const { requiredMeasurementsInWeek, completedMeasurements } = useGetMeasurementsStatistics(dailyGoals);

  const percentage = (completedMeasurements / requiredMeasurementsInWeek) * 100;

  return (
    <>
      <GoalSection>
        {!dailyGoals ? (
          <Spinner size={24} />
        ) : (
          <>
            <CircularProgressBar
              progress={percentage}
              label={`${completedMeasurements}/${requiredMeasurementsInWeek}`}
              footerLabel={t('readingsTitle')}
              showCompletionText
              size={107}
              startGradientColor="other.progressRed"
              endGradientColor="other.red"
            />
            <GoalText>
              <Typography.Span variant="SubHeadline1Medium" color="black.800">
                {t('completed')}
              </Typography.Span>
              <Typography.Span variant="SubHeadline1" color="black.800">
                {t('bpReadings')}
              </Typography.Span>
            </GoalText>
          </>
        )}
      </GoalSection>
      {percentage >= GOOD_JOB_PERCENTAGE && (
        <GoodJobText variant="Body1" color="other.green">
          {t('greatJob')}
        </GoodJobText>
      )}
    </>
  );
};

export default ActivityGoalSection;
