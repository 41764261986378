import axios, { AxiosError } from 'axios';
import { env } from 'index';
import { useState } from 'react';

interface UpdateClientResponse {
  data: {
    updateClient: {
      user: {
        id: string;
      };
    };
  };
}

interface UseUpdateProviderResult {
  updateProvider: (id: string, dietitian_id: string) => Promise<void>;
  loading: boolean;
  error: AxiosError | null;
  data: { id: string } | null;
}

const useUpdateProvider = (): UseUpdateProviderResult => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [data, setData] = useState<{ id: string } | null>(null);

  const url = env.REACT_APP_HEALTHIE_API_URL;

  const updateProvider = async (id: string, dietitian_id: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post<UpdateClientResponse>(
        url,
        {
          query: `
          mutation UpdateClient($input: updateClientInput) {
            updateClient(input: $input) {
              user {
                id
              }
            }
          }
        `,
          variables: {
            input: {
              id,
              dietitian_id,
            },
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            AuthorizationSource: 'API',
            Authorization: `Bearer ${env.REACT_APP_HEALTHIE_ADMIN_TOKEN}`,
          },
        },
      );

      setData(response.data.data.updateClient.user);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  return { updateProvider, loading, error, data };
};

export default useUpdateProvider;
