import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from './Tabs';

interface TimeTabsProps {
  selectedTab?: number;
  onTabChange: (tab: number) => void;
}

export type TimeTabsType = 'week' | 'month' | 'all_time';

export const TimeTabsIndex: Record<TimeTabsType, number> = {
  week: 0,
  month: 1,
  all_time: 2,
};

export const TimeTabs: React.FC<TimeTabsProps> = ({ onTabChange, selectedTab }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tabs.timeTabs' });

  return (
    <Tabs
      selectedTab={selectedTab}
      tabs={[
        {
          label: t('weekTabTitle'),
          ariaLabel: t('weekTabAriaLabel'),
        },
        {
          label: t('monthTabTitle'),
          ariaLabel: t('monthAriaLabel'),
        },
        {
          label: t('allTimeTabTitle'),
          ariaLabel: t('allTimeTabAriaLabel'),
        },
      ]}
      onTabChange={onTabChange}
    />
  );
};

export default TimeTabs;
