import React from 'react';
import Modal from 'react-modal';

import * as Styled from './PopupModal.styles';

export const PopupModal: React.FC<Modal.Props> = ({ ...props }) => {
  return (
    <Modal
      className="_"
      overlayClassName="_"
      ariaHideApp={false}
      contentElement={(props, children) => <Styled.Modal {...props}>{children}</Styled.Modal>}
      overlayElement={(props, contentElement) => <Styled.Overlay {...props}>{contentElement}</Styled.Overlay>}
      {...props}
    />
  );
};
