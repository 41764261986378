export const en = {
  translation: {
    common: {
      next: 'Next',
      somethingWentWrong: 'Something went wrong. Please contact with support',
    },
    components: {
      accordion: {
        show: 'Show',
        hide: 'Hide',
      },
      alert: {
        closeButtonAriaLabel: 'Close alert',
      },
      taskHeader: {
        showInformation: 'Show information',
      },
      carousel: {
        previous: 'Previous',
        next: 'Next',
      },
      navigation: {
        home: 'Home',
        chat: 'Chat',
        lessons: 'Lessons',
        progress: 'Progress',
        settings: 'Settings',
      },
      baseLayout: {
        help: 'Help',
        goBack: 'go back',
      },
      loadingLayout: {
        loadingSubtitle: 'This may take up to 2 mins.',
      },
      passwordValidator: {
        passwordMustIncludeAtLeast: 'Password must include at least:',
        charactersLength: '8 characters',
        upperCase: '1 uppercase letter',
        lowerCase: '1 lowercase letter',
        numberOrSpecialCharacter: '1 number or special character',
        passwordDoesNotMeetCriteria: "Password doesn't meet criteria",
        passwordMeetsCriteria: 'Password meets criteria',
      },
      circularProgressBar: {
        complete: 'at goal',
      },
      timeSwitch: {
        previous: 'Previous',
        thisMonth: 'This month',
        thisWeek: 'Last 7 days',
      },
      tabs: {
        timeTabs: {
          weekTabTitle: 'Week',
          weekTabAriaLabel: 'Click here to see week statistics',
          monthTabTitle: 'Month',
          monthAriaLabel: 'Click here to see month statistics',
          allTimeTabTitle: 'All Time',
          allTimeTabAriaLabel: 'Click here to see all time statistics',
        },
      },
      diagram: {
        noDataTitle: 'No data available',
        noDataSubtitle: 'Try another date range',
        noDataIconAlt: 'A chart icon',
      },
      articleTab: {
        view: 'View',
        completedAria: '{{label}} is completed',
      },
      modal: {
        close: 'Close modal',
      },
      pdf: {
        headerFirstLine: 'For any help with Motiv contact',
        headerSecondLine: 'your Coach: {{phone}}',
        footer:
          'This information was downloaded from the Motiv platform in connection with your receiving health care services from Motiv affiliated health care providers and is for your individual use. Motiv is not responsible for the privacy or security of any information that you download from the Motiv platform. Any information downloaded from the platform may not be representative of your medical status beyond the identified time-frame for which such information measures or was reported. If you have any questions regarding the information you have received, please speak with your Motiv provider.',
      },
      downloadZoom: {
        downloadTitle: 'Download Zoom App',
        downloadDesc:
          'If you are using your phone, please download the free Zoom app from the App store by clicking on the download link on the next page, in order to successfully join this call.',
        close: 'Close',
        download: 'Download',
      },
    },
    pages: {
      weeklyReport: {
        download: 'Download',
        loading: 'Loading',
        errorHeadline: '',
        errorDescription: 'Your Weekly Report is not available. Please contact your Coach if you have any questions.',
        carePlanNotAvailable:
          'Your Weekly Report is not available. Please contact your Coach if you have any questions',
        week: 'Week',
        title: 'Weekly Report',
        summary: 'Summary of your health data',
        bloodPressure: 'Blood pressure',
        completedReadings: 'Completed readings',
        completedReadingsValue: '{{completed}}/{{required}}',
        readings: 'readings',
        averageWeek: 'Average per week',
        mmHg: 'mmHg',
        averageHeartRate: 'Average resting heart rate',
        bpm: 'bpm',
        notAvailable: 'N/A',
        goal: 'Goal',
        goalValue: '<{{value}}',
        activity: {
          goalPerDay: 'Goal per day',
          steps: 'steps',
          goalValue: '{{value}}',
          title: 'Activity',
          averagePerDay: 'Average per day',
          averageHeartRate: 'Average peak heart rate',
        },
        medication: {
          title: 'Medications',
          average: 'Average',
          taken: 'taken',
          mostUsedAnswer: 'Most used answer',
          goalValue: '{{value}}%',
          averageValue: '{{value}}%',
        },
        pdf: {
          weeklyReport: 'Weekly Report',
          week: 'Week: {{date}}',
          summaryOfYourHealthData: 'Summary of your health data',
          bloodPressure: 'Blood pressure',
          completedReadings: 'Completed readings',
          completedReadingsValue: '{{completed}}/{{required}}',
          readings: 'readings',
          averageWeek: 'Average per week',
          mmHg: 'mmHg',
          averageHeartRate: 'Average resting heart rate',
          bpm: 'bpm',
          notAvailable: 'N/A',
          goal: 'Goal',
          goalValue: '<{{value}}',
          activity: {
            goalPerDay: 'Goal per day',
            steps: 'steps',
            goalValue: '{{value}}',
            title: 'Activity',
            averagePerDay: 'Average per day',
            averageHeartRate: 'Average peak heart rate',
          },
          medication: {
            title: 'Medications',
            average: 'Average',
            taken: 'taken',
            mostUsedAnswer: 'Most used answer',
            goalValue: '{{value}}%',
            averageValue: '{{value}}%',
          },
        },
      },
      chat: {
        chat: 'Chat',
        loading: 'Loading...',
        somethingWentWrong: 'Something went wrong, please try again',
        coach: 'Coach',
        therapist: 'Therapist',
        coachTabAriaLabel: 'Click here to chat with coach',
        therapistTabAriaLabel: 'Click here to chat with therapist',
        outOfOfficeMessage: {
          ifYouAreExp: 'If you are experiencing any of these',
          signOrSymptoms: 'signs or symptoms',
          emergency: 'of a medical or mental health emergency, please contact 911.',
          endeavorSupport:
            'You can call Endeavor Health’s clinical support line 24/7 for urgent needs at 1-847-444-5300 and for urgent behavioral health needs at 1-847-570-2500.',
          message:
            'Messages sent outside the normal business hours of 8am - 4pm EST will be responded to within 1 business day, or the next business day if sent on the weekend.',
        },
        emptyPlaceholder1:
          'Hi {{ patientName }}, nice meeting you. My name is {{ providerName }} and I am excited to be your personal {{ providerRole }}.',
        emptyPlaceholder2: 'I will send you messages here from time to time.',
        emptyPlaceholder3: 'Please use this chat to reach out if you have any questions.',
        contactSupport: 'Chat with a coach or therapist has not been set up. Please, contact Motiv support.',
        sendMessage: 'Send message',
      },
      bloodPressure: {
        notAvailable: 'N/A',
        bpm: 'bpm',
        averageHeartRate: 'Average Resting Heart Rate',
        title: 'Blood Pressure & Heart Rate',
        sectionTitle: 'My Blood Pressure',
        average: 'Average',
        goal: 'Goal',
        mmHg: 'mmHg',
        chartLabel: 'Blood Pressure Chart',
        viewReadingsButton: 'View all readings',
        reportsSectionTitle: 'Reports',
        goalValue: '<{{value}}',
        completed: 'Completed',
        bpReadings: 'BP readings',
        readingsTitle: 'readings',
        greatJob: 'Great job measuring your blood pressure!',
        diagram: {
          systolic: 'Systolic',
          diastolic: 'Diastolic',
          noDataTitle: 'No data available',
          noDataSubtitle: 'Try another date range',
          noDataIconAlt: 'A chart icon',
          systolicLabel: 'Systolic - Date: {{ date }}, Value: {{value}}',
          diastolicLabel: 'Diastolic - Date: {{ date }}, Value: {{value}}',
        },
        readings: {
          bpm: 'bpm',
          heartRate: 'Resting heart rate',
          title: 'Blood pressure readings',
          activityLabel: 'Blood pressure',
          value: '{{ systolic }}/{{ diastolic }}',
          heartRateValue: '{{ value }}',
          mmHg: 'mmHg',
          noDataTitle: 'No data available',
          noDataSubtitle: 'Try another date range',
          download: 'Download',
          loading: 'Loading',
        },
        pdf: {
          mmHg: 'mmHg',
          bpm: 'bpm',
          heartRate: 'Resting heart rate',
          bloodPressure: 'Blood pressure',
          title: 'Blood pressure readings',
          weekTab: 'Week',
          monthTab: 'Month',
          allTimeTab: 'All Time',
        },
      },
      activity: {
        title: 'Activity',
        activityChartLabel: 'Activity chart',
        chartStepsLabel: 'steps',
        activityStepsSectionTitle: 'My activity steps',
        lastReadingLabel: 'Last',
        averageStepsLabel: 'Average',
        perDay: 'steps',
        loading: 'Syncing with your device',
        loadingTitle: 'Syncing Device',
        loadingDescription: 'This may take up to 2 minutes.',
        goal: 'Goal',
        steps: 'steps',
        greatJob: 'Great job on your steps!',
        reportsSectionTitle: 'Reports',
        viewReadingsButton: 'View all readings',
        readings: {
          title: 'Activity readings',
          perDay: 'steps',
          activityLabel: 'Activity',
          noDataTitle: 'No data available',
          noDataSubtitle: 'Try another date range',
          download: 'Download',
          loading: 'Loading',
        },
        peakHeartRate: 'Custom Max Heart Rate',
        bpm: 'bpm',
        notAvailable: 'N/A',
        atGoal: 'at goal',
        showInformation: 'Show information about goal',
        modalTitle: 'Average activity',
        modalInfo: 'Average activity will be visible when 7 days have passed.',
        closeButton: 'Close',
      },
      fitbitSetup: {
        next: 'Next',
        connect: 'Connect Now',
        title: 'Step {{step}} of {{total}}',
        step0: {
          header: 'Connect your Fitbit',
          description:
            'To see your Fitbit Activity in the Motiv App you will need to first connect your Fitbit with the Motiv App. Click “Connect My Fitbit” to get started.',
          connectButton: 'Connect My Fitbit',
        },
        step1: {
          description:
            'First, make sure you have your Fitbit device, which can be found in your Motiv Kit that was sent to your mailing address.',
          step1: 'Charge it',
          step2: 'Turn it on',
          step3: 'Click Next',
          imageDescription: 'Fitbit device image',
          modalLabel: 'Dialog that informs that the app cannot be downloaded on desktop',
        },
        step2: {
          description: `If you haven't already, using your phone download the Fitbit app from the Apple App Store or the Google Play Store`,
          fitbitLogoAlt: 'Fitbit logo',
          downloadButton: 'Download Fitbit App now',
          modalInfo: 'Sorry, the Fitbit app can only be downloaded on your phone',
          closeButton: 'Close',
        },
        step3: {
          description: 'Open the Fitbit App and follow the instructions to pair your Fitbit device to the Fitbit App.',
          fitbitLogoAlt: 'Fitbit logo',
        },
        step4: {
          description: 'After setting up your Fitbit app, connect it with Motiv.',
        },
        connectingWithFitbitHeaderTitle: 'Connecting with your Fitbit',
        connectingWithFitbitTitle: 'Connecting with your Fitbit',
        connectingWithFitbitDescription: 'This may take up to 2 minutes to fully connect.',
        connectWithFitbitErrorTitle: 'We have a problem connecting with your Fitbit',
        connectWithFitbitErrorDescription: 'Please try connecting again in 5 mins',
      },
      fitbitSync: {
        connectingWithFitbit: 'Connecting with Fitbit',
        connectedSuccessfully: 'Connected successfully!',
        connectedDescription:
          'It may take a couple minutes for you to start seeing your recorded Fitbit activity steps in the Motiv app.',
        goHome: 'Go to Home',
        goToGeneralSettings: 'Go back to General Settings',
      },
      scheduleAppointment: {
        cancel: 'Cancel',
        clock: 'Clock icon',
        slotLength: 'Duration: {{ value }} mins',
        coachCheckIn: 'Coach Check-In',
        therapistCheckIn: 'Therapist Check-In',
        orientation: 'Motiv Orientation',
        firstAppointment: {
          title: 'Coach Check-In',
          description: 'Not scheduled',
        },
        scheduling: 'Scheduling...',
        header: 'Header',
        doItLater: 'Do it later',
        schedule: 'Schedule',
        title: 'Schedule Appointment',
        titleWithSpecialistType: 'Schedule {{ specialistType }} Appointment',
        rescheduleTitle: 'Reschedule appointment',
        notAbleToSchedile: 'We are not able to schedule your appointment',
        tryLater:
          'Try again later, or if you need further assistance click the Help button at the top right of the screen.',
        timeNotAvailable: 'Sorry that time is no longer available.',
        goBackToPrevious: 'Please go back to the previous page and select another available time slot.',
        noSlots: 'No slots available',
        loading: 'Loading...',
        questionScheduling: 'Who are you scheduling your appointment with?',
        questionSchedulingTip: 'Please select your answer below',
        completed: {
          goHome: 'Go to home',
          header: 'Schedule Appointment',
          title: 'You are all set, {{ value }}!',
          information: 'Your appointment has been scheduled for {{ value }}.',
        },
        rescheduled: {
          header: 'Reschedule appointment',
          information: 'Your appointment has been rescheduled for {{ value }}.',
        },
        canceled: {
          information: 'If you want schedule new appointment go to appointments.',
          header: 'Cancel appointment',
          title: 'Your appointment has been canceled',
        },
        noAvailableSlots: {
          header: 'Oh no, looks like there are no times available.',
          information:
            "Please send a message to your {{ provider }} in the app or email us at: hello@withmotiv.com if you don't see any available time slots.",
        },
      },
      logIn: {
        enter: 'Enter your email and password to login into Motiv',
        verifyPhoneNumberHeader: "To verify it's you, please enter your phone number",
        enterCodeHeader: 'Thank you, please enter code you received in text message',
        phoneNumber: 'Phone number',
        email: 'Email',
        password: 'Password',
        remember: 'Rememeber me',
        forgot: 'Forgot password?',
        code: 'Code',
        next: 'Next',
        login: 'Login',
        didNotReceiveCode: "You didn't receive a code?",
        resendCode: 'Send me a new code',
        codeResent: 'The code has been resent',
        fieldIsRequired: 'Field is required',
        emailRequired: 'Email is required',
        emailIncorrect: 'Please enter a valid email',
        passwordRequired: 'Password is required',
        passwordIncorrect: 'Looks like your email or password is incorrect. Please try again.',
        phoneNumberWrongFormat: 'Phone number should match pattern: +11234567890',
        consents: {
          header: 'Last step, please accept these consents.',
          email: 'Email address',
          emailRequired: 'Email address is required',
          emailValid: 'Please enter a valid email address',
          read: "I have read and agree to Motiv's",
          privacy: 'Privacy Policy',
          and: 'and',
          terms: 'Terms of Use',
          pcm: 'Principal Care Management',
          note: 'Please note that at Motiv privacy is our top priority. We will never sell or give your information to third parties.',
          create: 'Create Account',
          agree: 'Agree to consents',
          errorEncountered: 'The app encountered an error while processing the request',
          errorEncounteredDesc: 'Please refresh the page and try again. Sorry for the inconvenience.',
        },
        consentsAccepted: {
          header: 'Welcome to Motiv!',
          description:
            'Your Motiv Account has been created successfully. Please write down your password and keep it in a safe place.',
          next: 'Next',
        },
        address: {
          provideMailingAddress: 'Please provide your mailing address so we can ship you the',
          motivKit: 'Motiv kit',
          addressLine1: 'Address line 1',
          addressLine2: 'Address line 2 (optional)',
          city: 'City',
          state: 'State',
          country: 'Country',
          postcode: 'ZIP / postcode',
          fieldIsRequired: 'Field is required',
          wrongState: 'Wrong state, try to use short version e.g. AZ, CA, NY',
          wrongFormatOfPhoneNumber: 'Wrong format of phone number, please use XXX-XXX-XXXX',
          wrongFormatOfZipCode: 'Wrong format of zip code, available formats: XXXXX or XXXXX-XXXX',
          next: 'Next',
          phoneNumber: 'Cell phone number',
          fitbit: 'Fitbit',
          bloodPressureCuff: 'Cellular-enabled blood pressure device',
          bands: 'Mini exercise bands',
          scale: 'Printed Rated Perceived Exertion (RPE) scale',
          stand: 'Smartphone stand',
          towel: 'Towel',
          yourMotivKit: 'Your Motiv Kit',
          includesFollowing: 'includes the following:',
          kitAltText: 'Motiv Kit equipment',
        },
      },
      onboarding: {
        setupPassword: {
          setPasswordLinkIsBroken: 'Set password link is broken',
          header: 'To get started, please create your password',
          enterPassword: 'Enter password',
          repeatPassword: 'Repeat your new password',
          fieldIsRequired: 'Field is required',
          makeSureYourPasswordMatch: 'Make sure your passwords match',
          passwordDoesNotMeetCriteria: "Password doesn't meet criteria",
          passwordContainsWhiteSpaces: 'Password cannot contain white spaces',
          next: 'Next',
          passwordIsTooWeakError: 'Password is too weak. A strong password avoids including common passwords',
        },
      },
      home: {
        edit: 'Edit',
        header: 'Home',
        help: 'Help',
        today: 'Today',
        week: 'Before Next Coach Appointment',
        appointments: 'Appointments',
        viewAll: 'View All',
        join: 'Join',
        todayAppointment: 'Today at {{ value }}',
        coachCheckIn: 'Coach Check-In',
        appointmentCard: {
          missed: ' - Missed',
          cancelled: ' - Cancelled',
        },
        progress: {
          notCompleted: 'Not completed',
          schedule: 'Schedule',
          activity: 'steps',
          recorded: 'recorded',
          medicationsRecorded: 'Recorded',
          medicationsNotRecorded: 'Not recorded',
          lessons: 'lessons completed',
          assessments: 'assessments completed',
          completed: 'completed',
          taskCompletedIcon: 'Task completed icon',
          icon: 'icon',
          print: 'Print',
        },
        notes: {
          hiThere: 'Hi there, {{name}}!',
          hello: 'Hello {{name}}!',
          congrats: 'Congrats {{name}}!',
          hi: 'Hi {{name}}!',
          endOfProgram:
            'Congrats, you will be graduating from the Motiv Program soon. Please see your last to do’s below. After your last day you will no longer have access to the Motiv app, so please complete these steps before that date.',
          scheduleFirstAppointment:
            'Please schedule your {{ first }} virtual appointment with your Coach so they can start creating your personalized care plan.',
          firstAppointmentScheduled:
            'Great job scheduling your {{ first }} appointment. Please do your best to complete the actions below before you meet with your Coach.',
          carePlanWeek1:
            'Here are the things we will be focusing on this week. Please complete your actions for today and this week.',
          mostActionsCompleted: 'Nice job completing most of your actions this week! Keep up the great work!',
          leastActionsCompleted: `Good job, let's make sure to complete all your actions for today and this week! Reach out via chat if you have any questions!`,
          programEnding:
            'Your Motiv program will be ending soon. Make sure to do your best in these last couple of weeks to complete all your actions! Keep up the great work!',
        },
        installPwa: {
          install: 'Install Motiv App',
          installDescription: 'Please install the App on your homescreen for a better experience.',
          tap: 'Just tap',
          iosInstallMsg: 'then select “Add to Home Screen”',
          androidInstallMsg: 'icon in upper right hand menu and select “Add to Home Screen” or “Install App”',
          close: 'Close Modal',
        },
      },
      exercises: {
        loadingTitle: 'Getting your exercises',
        loadingHeader: "Today's Goal",
        completeYourExerciseProgram: 'Complete Your Exercise Program',
        completed: 'Completed',
        exercisesCompleted: 'exercises completed',
        notCompleted: 'Not completed',
        exercise: 'Exercise',
        print: 'Print',
        markAllAsCompleted: 'Mark all as completed',
        exercises: 'Exercises',
        checkOff: 'Check off completed',
        contactWithSupport: 'Exercises should appear here, please contact Motiv support.',
      },
      welcome: {
        slide1Header: 'Welcome to Motiv',
        slide1Desc:
          'Your physician has invited you to join the Motiv program, which will support you in your cardiac wellness from the comfort of home. Click **"Get Started"** to complete your enrollment.',
        slide2Header: '1:1 Support',
        slide2Desc:
          'With Motiv, you will be paired with a Health Coach that will provide you with personalized support.',
        slide3Header: 'Education on the Go',
        slide3Desc: 'Receive exercise, nutrition, and wellness education and instructions created for you on demand.',
        slide4Header: "What's your motivation?",
        slide4Desc:
          "We'll work with you to build your personalized wellness program so you can get back to who and what you love.",
        getStarted: 'Get started',
        logIn: 'Log in',
      },
      forgotPassword: {
        enterEmail: {
          reset: 'Reset passsword',
          resetYour: 'Reset your password',
          resetDesc: "Enter your email and we'll send you a link to verify your identity.",
          email: 'Email',
          emailInvalid: 'Please enter a valid address email',
          cancel: 'Cancel',
          next: 'Next',
        },
        emailSent: {
          reset: 'Reset passsword',
          checkEmail: 'Check your emails',
          linkSentTo: 'Link sent to',
          linkDesc: 'We’ve sent you an email with a link to reset your password.',
          goBackHome: 'Go back to home',
        },
        newPassword: {
          reset: 'Reset passsword',
          chooseNew: 'Choose a new password',
          password: 'Password',
          repeatPassword: 'Repeat password',
          confirm: 'Confirm new password',
          pleasePassword: 'Please enter a password',
          fulfillCriteria: "Password doesn't match the following criteria",
          pleaseConfirm: 'Please confirm your password',
          fulfillConfirm: 'Make sure your passwords match',
        },
        resetSuccess: {
          reset: 'Reset passsword',
          success: 'Your password change was successful!',
          successDesc: 'You can now login with your new password.',
          toLogin: 'Go to login',
        },
        resetError: {
          reset: 'Reset passsword',
          somethingWentWrong: 'Something went wrong',
          tryAgain: 'Something went wrong. Please try again later.',
          goBackHome: 'Go back to home',
          refresh: 'Refresh',
        },
        errors: {
          userNotExisting: "User with this email doesn't exist",
          passwordTooWeak: 'Password is too weak. A strong password avoids including common passwords',
          linkExpired: 'Reset password link has expided, please request a new one',
        },
      },
      progress: {
        progress: 'Progress',
        motivation: 'My Motivation',
        myMotivation: 'My motivation is',
        healthData: 'Your Health Data',
        weeklyReport: 'Weekly Report',
        accessWeekly: 'Access your Weekly Report here',
        carePlan: 'Care Plan',
        accessCare: 'Access your Care Plan here',
        bloodPressure: 'Blood pressure',
        activity: 'Activity',
        weight: 'Weight',
        medications: 'Medications',
        personalGoals: 'Personal Goals',
        today: 'today',
        yesterday: 'yesterday',
        lastBloodPressure: 'Last reading was {{date}} at {{time}}',
        lastActivity: 'Last activity was {{date}} at {{time}}',
        lastWeight: 'Last recording was {{date}} at {{time}}',
        lastMedications: 'Last recording was {{date}}',
        lastPersonalGoals: 'Last recording was {{date}}',
        cancel: 'Cancel',
        saveChanges: 'Save changes',
        tooLong: "Can't be longer than {{count}} characters",
        month: 'Month',
        medicinesAdherence: 'Medication Adherence',
        noDataTitle: 'No data available',
        noDataSubtitle: 'Try another date range',
        progressAndCarePlan: 'Progress and Care Plan',
        willBeAvailable: 'This will be available after you meet with your Coach',
        pdf: {
          activityTitle: 'Steps readings',
          weekTab: 'Week',
          monthTab: 'Month',
          allTimeTab: 'All Time',
          steps: 'steps',
          activity: 'Activity',
        },
      },
      questionnaires: {
        allFilled: 'All assessments have been filled',
        question: 'Question {{count}} of {{total}}',
        completeYourAssessments: 'Complete Your Assessments',
        titleWeekly: 'Before Next Coach Appt',
        weeklyTaskTitle: 'Complete Your Assessments', //note: this has to contain word 'assessments'
        assessment: 'Assessment',
        next: 'Next',
        saveForLater: 'Save for later',
        enterAnswer: 'Please enter your answer here',
        submit: 'Submit your answers',
        answersSent: 'Thank you! Your answers have been submitted.',
        answersSentDesc:
          'Please note, there may be a delay in reviewing your answers. If you are experiencing a medical or mental health emergency please call 911 immediately.',
        goBack: 'Go back to home',
        completeNext: 'Go to next assessment',
        completed: '{{complete}}/{{incomplete}} completed',
        cannotBeNegative: 'Cannot be negative',
        cannotContainSpecial: 'Cannot contain special characters',
        agree: 'I agree',
      },
      appointment: {
        loading: 'Loading...',
      },
      appointments: {
        loadMore: 'Load more',
        cancel: 'Cancel Appointment',
        reschedule: 'Reschedule',
        scheduleAppointment: 'Schedule appointment',
        past: 'Past',
        upcoming: 'Upcoming',
        editHeader: 'Changing appointment',
        header: 'Appointments',
        appointment: 'Appointment',
        loading: 'Loading...',
        todayAppointment: 'Today at {{ value }}',
        appointmentCard: {
          appointmentType: 'Appointment type: {{ type }}',
          appointmentStartTime: 'Appointment start time: {{ time }}',
          join: 'Join',
        },
        errorModal: {
          errorIcon: 'Error Icon',
          title: 'Editing appointment',
          description:
            "Unfortunately you can't edit this appointment because it is too close to the appointment start time. Please reach out to your Coach if you need to make changes to this appointment.",
          okay: 'Close',
        },
        cancelModal: {
          title: 'Are you sure you want to cancel this Appointment?',
          no: 'No',
          yes: 'Yes, cancel',
        },
        noAppointments: 'No appointments scheduled',
      },
      settings: {
        personalInfo: 'Personal Info',
        accountSettings: 'Account Settings',
        myConsents: 'My Consents',
        frequentlyAskedQuestions: 'Frequently Asked Questions',
        logOut: 'Log out',
        settings: 'Settings',
        exit: 'Exit settings',
        help: 'Help',
        saveChanges: 'Save changes',
        cancel: 'Cancel',
        edit: 'Edit',
        devices: 'Devices',
        notifications: 'Notifications',
        getHelp: 'Get Help',
        fieldIsRequired: 'Field is required',
        emailNotValid: 'Email address is not valid',
        emailNotEqual: 'Provided emails do not match',
        closeModal: 'Close modal',
        faq: 'Frequently Asked Questions',
        phoneNumberNotValid: 'Phone number is not valid',
        dateNotValid: 'MM-DD-YYYY',
        yesLogOut: 'Yes, Log out',
        maxLengthValidation: 'Please enter a value no longer than {{max}} characters',
        areYouSureLogOut: 'Are you sure you want to log out?',
        areYouSureEmailChange: 'Are you sure you want to change your email?',
        areYouSurePasswordChange: 'Are you sure you want to change your password?',
        password: 'Password',
        changePassword: 'Change password',
        no: 'No',
        yes: 'Yes, change',
        logo: 'Logo',
        personalInfoPage: {
          firstName: 'First Name',
          lastName: 'Last Name',
          dateOfBirth: 'Date of Birth',
          editFirstName: 'Edit first name',
          editLastName: 'Edit last name',
          editDateOfBirth: 'Edit date of birth',
        },
        accountSettingsPage: {
          phoneNumber: 'Phone number',
          email: 'Email',
          confirmEmail: 'Confirm email',
        },
        devicesPage: {
          fitbit: 'Fitbit',
          connected: 'CONNECTED',
          connect: 'Connect',
        },
        notificationsPage: {},
        myConsentsPage: {
          privacyPolicyAndTermsOfUse: 'Privacy Policy and Terms of Use',
          principalCareManagement: 'Principal Care Management',
        },
        faqPage: {
          general: 'General',
          accountManage: 'Account Management',
          motivKitTitle: 'Motiv Kit',
          motivClinicalTeam: 'Motiv Clinical Team',
          emailsAndReminders: 'Emails & Reminders',
          techSupp: 'Technology Support Questions',
          downloadReports: 'Downloading Reports',
          endOfProgram: 'End of the Program',
          faq: 'Frequently Asked Questions',
          what: 'What is Motiv?',
          whatDescription:
            'Motiv is a virtual cardiac wellness program that you can do in the comfort of your own home. In Motiv’s virtual and personalized program, you will work 1-on-1 with your dedicated Coach and Therapist (if referred) who is a part of the larger Motiv clinical team. This program will help you increase your physical fitness, make healthier lifestyle choices, learn more about your condition, and identify ways to relieve stress. The team will regularly review data from your Motiv supplied Fitbit and blood pressure cuff and integrate this information into your overall plan. Your team will be with you every step of the way, supported by the Motiv easy-to-use app that seamlessly tracks your progress towards your goals.',
          duration: 'How long is the program?',
          durationDescription:
            'The program is 6 months long from the time you create your account in the Motiv app until you graduate.',
          cost: 'Is there a cost to be part of this program?',
          costDescription1:
            "The program is covered by Medicare and most commercial insurance plans. Your standard copayment or deductible may apply. The cost of the program depends on your insurance, how much you've already paid towards your deductible, and other factors. If your Coach determines you'd benefit from additional services such as meeting with our Therapists, there may be an extra cost, but we'll talk to you about it first and the final decision will be 100% yours.",
          help: 'How do I get help with using the Motiv app?',
          helpDescription:
            'You can access the “Help” menu from most screens in the Motiv app by tapping on “Help” in the upper right-hand corner of the screen.',
          access: 'How do I access the Motiv app?',
          accessDescription:
            'To access the Motiv app you just need a mobile browser on your smartphone (for example, Safari on an iPhone, or Chrome on an Android phone, etc.) and visit: www.withmotiv.com. You will be asked to download the Zoom app and the Fitbit app from the app stores to be part of the Motiv program. Please note, your physician will need to refer you to Motiv in order to participate in the program and login to the app.',
          save: 'How do I save the Motiv app to my phone’s home screen?',
          saveForIphones: 'For iPhones: ',
          saveForIphonesDescription: `Open the Motiv app in Safari. Press the "Share" button and select "Add to Home Screen'' from the popup. Lastly, tap "Add" in the top right corner and the Motiv app will now be on your home screen. `,
          saveForAndroids: 'For Androids: ',
          saveForAndroidsDescription: `Open the Motiv App in Chrome. Press the "three dots" icon in the upper right to open the menu. Select "Install App." Press the "Add" button in the popup. The app will now be available on your home screen.`,
          computer: 'Can I access the Motiv platform from my computer?',
          computerDescription:
            'Yes, you will have the ability to access the Motiv app from your personal computer’s web browser, however, we recommend using your smartphone for virtual sessions with your Motiv team in order to to use 2-way audio and visual capabilities.',
          forgotEmail: 'What should I do if I forgot the email I used to create my Motiv app account?',
          forgotEmailDescription:
            'If you’ve forgotten the email you used to create your Motiv app account, please call your Coach. If you can’t find your Coach’s phone number please tap on the “Help” menu which is in the upper right-hand corner of most screens in the Motiv smartphone app.',
          forgotPassword: 'What should I do if I forgot my Motiv app password?',
          forgotPasswordDescription:
            'You can recover your Motiv app password by clicking on the “Forgot password?” link on the Log in screen. An email will be sent to the email address associated with your account containing instructions on how to reset your password. ',
          motivKit: 'What is in the Motiv Kit?',
          motivKitDescription:
            'The kit includes a digital Blood Pressure Monitor, and exercise bands. The kit will be provided to you at no additional cost.',
          getMotivKit: 'How do I get the Motiv Kit?',
          getMotivKitDescription:
            'When you create a Motiv account in the app it will ask for your mailing address. After your account is created, we will mail you the Motiv Kit which typically takes 2-3 business days to arrive.',
          questionsKit: 'What do I do if I have questions about my Kit?',
          questionsKitDescription:
            'If you have any questions about your Kit please call or send an in-app chat message to your Coach.',
          whoCoach: 'Who are the Motiv Coaches & Therapists?',
          whoCoachDescription:
            'Motiv’s Coaches are Certified Health and Wellness Coaches who are also highly qualified Clinical Exercise Physiologists. Motiv’s Therapists are Licensed Clinical Social Workers experienced in working with patients who have a heart condition.',
          contactcoach: 'How do I contact my Coach?',
          contactCoachDescription:
            'Between scheduled sessions with your Coach, you can send chat messages through the Motiv smartphone app. To send your Coach a chat message, just click “Chat” in the bottom navigation of your Motiv app and start your conversation. If needed, you can also call your Coach. You can find your Coach’s phone number in the “Help” menu which is in the upper right-hand corner of most app screens.',
          contactTherapist: 'How do I contact my Therapist?',
          contactTherapistDescription:
            'Between scheduled sessions with your Therapist, you can call or chat with your Therapist. To find out your Therapist’s phone number, please contact your Coach directly for that information. If you have been referred to a Motiv Therapist, they can initiate the chat in the Motiv app.',
          connectTherapist: 'How do I connect with a Therapist if I want one?',
          connectTherapistDescription:
            'Please contact your Coach if you want to connect with a Motiv Therapist. You can find your Coach’s phone number by tapping on the “Help” button in the upper right hand corner of most app screens.',
          whenContact: 'When can I contact my Coach or Therapist?',
          whenContactDescription: `If you call or send chat messages between 8am – 4pm EST, your Coach or Therapist will respond within the same day. If you call or send messages outside those hours, it may take up to 1 business day  to receive a response. If you call or send messages during the weekend, they will return your call/message the next business day. Please call 911 in case of a medical or behavioral health emergency.`,
          warning: `OUR PROVIDERS DO NOT ADDRESS MEDICAL EMERGENCIES. IF YOU BELIEVE YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL 9-1-1 AND/OR GO TO THE NEAREST EMERGENCY ROOM.  PLEASE DO NOT ATTEMPT TO CONTACT OUR PROVIDERS. AFTER RECEIVING EMERGENCY HEALTHCARE TREATMENT, YOU SHOULD VISIT YOUR LOCAL PRIMARY CARE PROVIDER. IF YOU A RECEIVING REMOTE PATIENT MONITORING (RPM) SERVICES WITH MOTIV PROVIDERS, THE DATA AND HEALTH INFORMATION COLLECTED IN CONNECTION WITH SUCH SERVICES ARE NOT INTENDED OR USED TO IDENTIFY OR TRIAGE MEDICAL EMERGENCIES. IF YOU THINK YOU ARE EXPERIENCING A MEDICAL EMERGENCY PLEASE DIAL 911.`,
          whatEmails: 'What emails will I receive when I am in the program?',
          whatEmailsDescription:
            'You will receive occasional emails while enrolled in the program. Emails will include instructions on how to use your devices and any updates to the program that are important.',
          changeEmailNotifications: 'How do I change my email notification settings?',
          changeEmailNotificationsDescription:
            'Please reach out to your Coach if you would like to change your settings regarding your email notifications for the program. Please note that email notifications are a critical component of the Motiv program and essential to successful completion.',
          changeSMS: 'How do I change my SMS text message notification settings?',
          changeSMSDescription:
            'Please reach out to your Coach if you would like to change your settings regarding your SMS notifications for the program. Please note that SMS notifications are a critical component of the Motiv program and essential to successful completion.',
          issues: 'What do I do if I encounter issues with the app or my Blood Pressure Cuff provided by Motiv?',
          issuesDescription:
            ' Please reach out to your Coach if you have any questions regarding your devices or the app. They will work with you to troubleshoot any issues you may be having. If required, your Coach will escalate your issue to our technical support group to further assist you.',
          gettingStarted: 'How do I get started with the program?',
          gettingStartedDescription: 'You can view the video here:',
          appTutorial:
            'How do I view the “App Tutorial” video that walks me through the major features of the Motiv App?',
          appTutorialDescription: 'You can view the video here:',
          downloadBP: 'How do I download my Blood Pressure recorded data in the app?',
          downloadBPDescription:
            'To download your Blood Pressure recorded data in the app go to Progress > Blood Pressure > View all readings. There you will get an option to tap on the “download” button for either the week, month or all time views of your data. When you tap “download” it will open the PDF on your phone or computer and you can email that report to yourself or print it directly.',
          end: 'What happens at the end of the program?',
          endDescription:
            'As you approach your scheduled graduation date in the program, your Coach and Therapist will prepare a summary of your care plan accomplishments and future focus areas that will help you maintain your new healthy lifestyle into the future. You will be able to keep all the contents in your Kit after you graduate the program, and we will no longer be monitoring those device(s) after you graduate.',
          unable: 'What do I do if I am unable to complete the program?',
          unableDescription: 'Please reach out to your Coach if you would like to disenroll from the program.',
        },
        getHelpPage: {
          callCoach: 'For any help with Motiv call or text your Coach* at:',
          callMotiv: 'For any help with Motiv call us at:',
          coachAvailability: `*Coaches are available during the normal business hours of 8am-4pm EST. After hour calls will be responded to within 1 business day, or the next business day if contacting your coach on the weekend.`,
          urgentIssue:
            'For urgent issues after normal business hours, contact Endeavor Health’s clinical support line at 1-847-444-5300. For urgent behavioral health needs, contact Endeavor Health’s Behavioral Health support line at 1-847-570-2500.',
          signsOrSymptoms: 'signs or symptoms',
          ifYouAreExperiencing: 'If you are experiencing any of these',
          contact911:
            'of a medical emergency, please contact 911. For additional mental health support call or text 988.',
          motivUrl: 'www.withmotivhealth.com',
          moreHelp: 'For more help visit:',
          frequentlyAskedQuestions: 'Frequently Asked Questions',
          orVisit: 'or visit:',
          defaultPhoneNumber: '(917)-426-5658',
          goBack: 'go back',
        },
        getHelpModal: {
          immediateAttention: 'Seek immediate medical attention for any of the following:',
          closeModal: 'Close modal',
          pause: 'Please pause exercise until seen by a medical professional',
          note: 'Please note this is not a complete list of medical emergencies.',
          especially: '*Especially if you are experiencing symptoms.',
          1: 'Difficulty breathing, shortness of breath.',
          2: 'Unusual chest or belly pain or pressure lasting 2 minutes or more.',
          3: 'Fainting, sudden dizziness, weakness, or loss of consciousness.',
          4: 'Changes in vision.',
          5: 'Confusion or changes in mental status.',
          6: 'Any sudden or severe pain (including headaches).',
          7: 'If your resting blood pressure is: <=80 or >=180 mm Hg systolic, or >=120 mm Hg diastolic.*',
          8: 'If your resting heart rate is >=120 bpm and >=100 bpm if you are having symptoms.',
          9: 'If your blood glucose level is <=50 mg/dL.',
          10: 'Uncontrolled bleeding.',
          11: 'Severe or persistent vomiting or diarrhea.',
          12: 'Coughing or vomiting blood.',
          13: 'Suicidal or homicidal feelings.',
          14: 'Difficulty speaking, or numbness or weakness in any part of your body.',
          15: 'Fever over 103 that can not be controlled by taking over-the-counter medication.',
          16: 'Sudden or unexplained sweating/clamminess.',
        },
      },
      meetYourCoach: {
        yourCoach: 'Your Coach',
        yourTherapist: 'Your Therapist',
        meetYourCoach: 'You will be meeting with: ',
        doItLater: 'Do it later',
        loading: 'Loading...',
        Next: 'Next',
        healthAndWellness: 'Health and Wellness Coach',
        certifiedWellnessTitle: 'Certified Health and Wellness Coach,',
        certifiedExerciseTitle: 'Certified Clinical Exercise Physiologist',
        therapistDescription:
          ' is one of our experienced Motiv Therapists. Please make sure you are in Illinois for your virtual appointment!',
        coachDescriptionMotivTeam:
          ' is on the Motiv team and will be meeting with you to go over the orientation for the program!',
        coachDescription1:
          ' is one of our dedicated Health Coaches, with expertise in guiding you through lifestyle and behavior changes to support your health journey. Please make sure you are in Illinois for your virtual appointment!',
        nextWeek: 'next 7 days ',
        coachDescription2: 'so they can get to know you and start creating your personalized care plan.',
        coachDescription3: "Please note, you'll need to be ",
        illinois: 'in Illinois for all your virtual calls.',
        coachDescription4: 'If you have any questions please send your Coach an in-app message.',
      },
      ScheduleFirstAppointment: {
        headline: 'Please schedule your {{ first }} virtual appointment with your dedicated Health Coach!',
        '7days': 'Please schedule in the next 7 days ',
        personalcareplan: 'so your Coach can get to know you and create your personalized care plan.',
        pleasenote: "Please note, you'll need to be ",
        illinois: 'in Illinois for all your virtual calls. ',
        questions: 'If you have any questions please send your Coach an in-app message.',
        excited: 'We are excited to meet you!',
        doItLater: 'Do it later',
        scheduleNow: 'Schedule now',
        scheduleCoachApp: 'Schedule Coach Appointment',
      },
      carePlan: {
        plan: 'Care Plan',
        download: 'Download',
        loading: 'Loading',
        items: {
          goals: 'Personal Goals',
          bloodPressure: 'Blood Pressure & Heart Rate',
          bloodPressureDesc: 'Measure {{times}} times a day',
          activity: 'Activity',
          activityDesc: '{{daily}} steps/per day',
          exercise: 'Exercise',
          exerciseDesc: 'Complete your exercise program this week',
          weight: 'Weight',
          weightDesc: 'Record {{times}} times a week',
          appointments: 'Appointments',
          medications: 'Medications',
          medicationsDesc: 'Take and record medications as as prescribed by your healthcare provider',
          lessons: 'Lessons',
          lessonsDesc: 'Complete your assigned lessons per week',
          1: 'Measure one time a day',
          2: 'Measure two times a day',
          3: 'Measure three times a day',
        },
        pdf: {
          carePlan: 'Care Plan',
          updatedOn: 'Updated on: {{date}}',
        },
      },
      bloodPressureManual: {
        stepNumber: 'Step {{currentStep}} of {{TOTAL_STEPS}}',
        help: 'Help',
        doItLater: 'Do it later',
        syncNow: 'Sync now',
        skip: 'Skip',
        next: 'Next',
        intro: {
          cuffImage: 'Blood Pressure Cuff Image',
          skip: 'Skip',
          viewSteps: 'View steps',
          title: 'Record Your Blood Pressure',
          headline: 'Before you start, view the steps of how to use your new Blood Pressure monitor.',
          description: 'If you already know how to use your blood pressure monitor, click “Skip” to start recording.',
        },
        step1: {
          insert: 'Insert the',
          batteries: '4 AA batteries',
          into: 'into your device.',
        },
        step2: {
          description: 'Connect the cuff to the left side of your device.',
        },
        step3: {
          description1: 'When you are at rest:',
          description2:
            'Apply the cuff on your left or right upper arm so that the tube is aligned with your middle finger, pointing toward on your lower arm and palm.',
        },
        step4: {
          description: 'Wrap the cuff firmly in place around your upper arm.',
        },
        step5: {
          description:
            'Support your arm (i.e resting on a desk with a pillow or book to adjust arm height) so that the middle of the cuff is at heart level.',
        },
        step6: {
          description: 'Start the measurement on your device.',
        },
        step7: {
          description: 'Once you get your reading, a rectangling line will pop up.',
        },
        step8: {
          description1: 'Wait until your device shows “End” and it will shut off on its own.',
          description2:
            'If you want to sync your latest readings with Motiv please select “Sync Now”. Otherwise, please select “Do it later”.',
        },
      },
      recordBlodPressureSync: {
        needHelp: 'Need Help?',
        viewUserManual: 'View user manual',
        header: '{{value}} Readings',
        title: 'Record Your Blood Pressure',
        bloodPressure: 'Blood pressure: ',
        dateTitle: '{{value}}:',
        bloodPressureValue: ' {{systolic}}/{{diastolic}} ',
        unit: 'mmHg',
        finish: 'View my progress',
        heartRateUnit: 'bpm',
        heartRateValue: '{{value}} ',
        heartRate: 'Heart rate: ',
        noReadings: 'No readings for today',
      },
      recordYourWeight: {
        lbs: 'lbs',
        pleaseEnterYourWeight: 'Please manually enter your weight for today',
        save: 'Save',
        recordYourWeight: 'Record Your Weight',
        minWeightValidation: 'Please provide at least 2 digits',
        maxWeightValidation: 'Please provide max 3 digits',
        completed: {
          completedGoalTitle: 'Great job, your weight has been saved!',
          completedGoalDescription: 'To see all your recorded activity click on “View my Progress”.',
        },
      },
      recordYourMedications: {
        recordYourMedications: 'Record Your Medications',
        save: 'Save',
        didYouTakeMedicationsToday: 'Did you take your medications today?',
        didYouTakeMedicationsWeek: 'Did you take your medications this week?',
        pleaseChooseOne: 'Please choose one answer below',
        pleaseSelectAnOption: 'Please select an option',
        completed: {
          completedGoalTitle: 'Thank you for recording your medications!',
          completedGoalDescription: 'You can view your recorded medication progress by selecting “View my Progress”.',
        },
      },
      lessons: {
        completeYourLessons: 'Complete Your Lessons',
        lesson: 'Lesson',
        view: 'View',
        completed: 'Completed',
        lessonsCompleted: '{{range}} lessons completed',
        markAsCompleted: 'Mark as completed',
        videoTranscript: 'Video Transcript',
      },
      personal: {
        completedPersonalGoals: 'Complete Your Goals',
        completed: 'Completed',
        goalsCompleted: '{{range}} goals completed',
        viewMyProgress: 'View my progress',
      },
      completedPage: {
        viewMyProgress: 'View my Progress',
        goBackToHome: 'Go back to Home',
      },
      keepMoving: {
        title: 'Keep Moving',
        steps: 'Steps:',
        goal: 'goal: {{ goal }}',
        progressLabel: "Today's Steps",
        goodJobText: 'Great job on your steps!',
        fitbitLogoAlt: 'Fitbit logo',
        headerDescription: 'Today, {{ date }}',
        viewProgressButton: 'View my Progress',
        peakHeartRate: 'Custom Max Heart Rate',
        bpm: 'bpm',
        modalTitle: 'Fitbit Syncing Info',
        modalInfo:
          'There may be a slight delay in the steps count you see here and what is in your Fitbit app. By the end of the day it will represent your steps count accurately.',
        closeButton: 'Close',
        modalLabel: 'Fitbit syncing information',
        titleDaily: `Today's Goal`,
      },
      titles: { titleWeekly: 'Before Next Coach Appt', titleDaily: `Today's Goal` },
      endOfProgram: {
        certificate: 'Print Your Graduation Certificate',
        summary: 'Print Program Summary and Resources',
      },
    },
  },
};
