import { gql } from '__generated__/gql';

export const CREATE_USER_LOCATION = gql(`
  mutation createLocation(
    $line1: String
    $line2: String
    $city: String
    $state: String
    $country: String
    $zip: String
    $user_id: String
    $name: String
  ) {
    createLocation(
      input: {
        line1: $line1
        line2: $line2
        city: $city
        state: $state
        country: $country
        zip: $zip
        user_id: $user_id
        name: $name
      }
    ) {
      location {
        id
      }
      messages {
        field
        message
      }
    }
  }
`);

export const UPDATE_USER_PHONE_NUMBER = gql(`
mutation updateClientPhoneNumber($id: ID, $phone_number: String) {
  updateClient(input: { phone_number: $phone_number, id: $id }) {
    user {
      phone_number
    }
    messages {
      field
      message
    }
  }
}
`);

export const SETUP_PASSWORD = gql(`
  mutation setupPassword($token: String, $password: String, $timezone: String) {
    signIn(input: { token: $token, password: $password, timezone: $timezone, generate_api_token: true, allow_multiple_api_keys: false }) {
      api_key
      messages {
        message
        field
      }
    }
  }
`);

export const UPDATE_USER_INFO = gql(`
mutation updateUser($id: ID, $first_name: String, $last_name: String, $dob: String, $phone_number: String, $email: String) {
  updateUser(
    input: {
      id: $id
      first_name: $first_name
      last_name: $last_name
      dob: $dob
      phone_number: $phone_number
      email: $email
    }
  ) {
    user {
      id
      first_name
      last_name
      dob
      phone_number
      email
    }
    messages {
      field
    }
  }
}`);
