import styled from 'styled-components';

import { ArticleHeader } from 'components/ui/ArticleHeader/ArticleHeader';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const LessonsItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const ContentHeader = styled(ArticleHeader)`
  margin: 0px 20px;
`;
