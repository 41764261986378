import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { CarePlanGoalTyped } from '../../CarePlan.model';
import { usePlanItem } from './usePlanItem';

export const PdfPlanItem: React.FC<CarePlanGoalTyped> = ({ goal, type }: CarePlanGoalTyped) => {
  const styles = StyleSheet.create({
    itemView: {
      display: 'flex',
      flexDirection: 'row',
      padding: '16px 30px',
      borderBottom: '1px solid #a3a3a3',
    },
    icon: {
      width: 16,
      height: 16,
    },
    textView: {
      marginLeft: 12,
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    description: {
      fontSize: 12,
      fontWeight: 'thin',
    },
  });
  const itemValues = usePlanItem(goal, type);
  if (!itemValues) return null;
  return (
    <View style={styles.itemView}>
      <Image src={itemValues.pngIcon} style={styles.icon} />
      <View style={styles.textView}>
        <Text style={styles.title}>{itemValues.title}</Text>
        {Array.isArray(itemValues.description) ? (
          itemValues.description.map((item) => (
            <Text key={item} style={styles.description}>
              • {item}
            </Text>
          ))
        ) : (
          <Text style={styles.description}>{itemValues.description}</Text>
        )}
      </View>
    </View>
  );
};
