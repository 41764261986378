import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;   
    }

    /* Hides the bounce effect - the default effect in mobile browsers */
    html {
        overflow: hidden;
    }
    body {
        overflow: auto;
    }

    html, body , div#root {
        height: 100%;
        background-color: ${({ theme }) => theme.color.other.linen};
    }

    /* React Markdown */
    .react-markdown {
        font-family: 'poppins' !important;

        strong {
            font-weight: 500;
        }

        a {
            color: ${({ theme }) => theme.color.blue[600]};
            text-decoration: none;
        }
    }

    /* Chat SDK styles */
    div.healthie-container.chat-container {
        font-family: 'poppins' !important;
        background: ${({ theme }) => theme.color.other.linen};
    }


    div.chat-message-content {
        margin-left: 20px;
        word-break: break-word;
    }
    
    // Add margin to outgoing message container
    div.chat-message.outgoing {
        margin-right: 20px !important;
    }
    // Hide outgoing avatar
    div.chat-message.outgoing > div.avatar {
        display: none;
    }

    // Change size of incoming avatar
    div.chat-message.incoming > div.avatar {
        img {
            width: 35px;
            height: 35px;
        }
    }

    // Style outgoing message content
    div.chat-message.outgoing div.chat-message-content {
        font-family: 'poppins';
        font-size: 16px;
        font-weight: 400;
        background-color: ${({ theme }) => theme.color.blue[600]} !important;
        color: ${({ theme }) => theme.color.black[50]} !important;
        &:after {
            border-color: ${({ theme }) => theme.color.blue[600]} transparent transparent transparent !important;
        }
    }
    // Style incoming message content
    div.chat-message.incoming div.chat-message-content {
        font-family: 'poppins';
        font-size: 16px;
        font-weight: 400;
        background-color: ${({ theme }) => theme.color.blue[50]} !important;
        color: ${({ theme }) => theme.color.black[900]} !important;
        &:before {
            border-color: ${({ theme }) => theme.color.blue[50]} transparent transparent transparent !important;
        }
    }
    
    // Hide editor toolbar
    div.editor-toolbar-container {
        display: none !important;
    }
    div.chat-input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        background-color: transparent !important;
        padding: 16px 24px;
        background: ${({ theme }) => theme.color.other.white} !important;
    }
    div.chat-input-wrapper div.editor-wrapper {
        background: rgba(35, 103, 244, 0.05);
        border: 1px solid ${({ theme }) => theme.color.black[100]};
        border-radius: 8px;
        width: 100%;
    }
    div.chat-input-wrapper div.chat-input-toolbox {
        display: flex;
        align-items: center;
        padding: 0 !important;
        padding-left: 16px !important;
        button {
            padding: 0 !important;
            height: 40px;
            width: 40px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ theme }) => theme.color.blue[500]} !important;
        }
    }

    button.chat-input-button.primary-button:disabled {
        background: ${({ theme }) => theme.color.blue[50]} !important;
        border-color: ${({ theme }) => theme.color.blue[50]} !important;
        svg {
            path {
                fill: ${({ theme }) => theme.color.black[300]} !important;
            }
        }
    }

    div.editor-container {
        padding: 0 !important;
    }
    div.editor-container div.ProseMirror {
        height: auto;
        overflow: auto;
        min-height: 40px;
        max-height: 120px;
        box-sizing: border-box;
        padding: 10px 16px;
        word-break: break-word;
    }

    /* Hide empty placeholder */
    div.empty-placeholder {
        height: 100%;
        display: none !important;
    }
    table.hw-table-border {
        text-align: center !important;
    }
    div.HwNavigationSection li {
        margin-left: 10px;
    }
`;

export default GlobalStyle;
