import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as QuestionIcon } from 'assets/vectors/fi-rr-question.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { onEnterKey } from 'utils/helpers';

import { PopupModal } from 'components/ui/PopupModal/PopupModal';

import * as Styled from './LogOutModal.styles';

interface LogOutModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const LogOutModal: React.FC<LogOutModalProps> = ({ onClose, isOpen }: LogOutModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });
  const navigate = useNavigate();
  return (
    <PopupModal isOpen={isOpen} onRequestClose={onClose}>
      <Styled.Content>
        <Styled.SIcon
          element={CloseIcon}
          size={20}
          onKeyDown={(e) => onEnterKey(e, onClose)}
          onClick={onClose}
          tabIndex={0}
          aria-label={t('closeModal')}
        />
        <Styled.Title weight={600} size={24} lineHeight={32} color="black.800">
          {t('areYouSureLogOut')}
        </Styled.Title>
        <Styled.QuestionIcon color="black.600" element={QuestionIcon} size={108} title={t('areYouSureLogOut')} />
      </Styled.Content>
      <Styled.ButtonsContainer>
        <Styled.SButton variant="link" onClick={onClose}>
          {t('cancel')}
        </Styled.SButton>
        <Styled.SButton
          type="submit"
          onClick={() => {
            navigate(paths.logoutPage);
          }}
        >
          {t('yesLogOut')}
        </Styled.SButton>
      </Styled.ButtonsContainer>
    </PopupModal>
  );
};
