import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';
import { ProgressButton } from 'components/ui/TaskCompletionPage/TaskCompletionPage.styles';

import { useQuestionnaires } from '../useQuestionnaires';
import { ButtonNav, NextButton } from './QuestionnaireComplete.styles';

export const QuestionnaireComplete: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.questionnaires' });
  const navigate = useNavigate();
  const { visibleItems, courseId, loading } = useQuestionnaires();
  const nextItem = visibleItems?.find((item) => !item.completed_course_item);

  return loading ? (
    <LoadingPage />
  ) : (
    <TaskCompletionPage
      taskTitle={t('answersSent')}
      taskDescription={t('answersSentDesc')}
      color="blue.700"
      footer={
        <ButtonNav>
          <ProgressButton
            onClick={() => {
              if (nextItem) {
                navigate({
                  pathname: `${paths.questionnaires}/${nextItem.item_id}`,
                  search: `?${createSearchParams({
                    course_id: courseId || '',
                    course_item_id: nextItem.id,
                    incomplete_item_id: nextItem.incomplete_course_item_id || '',
                  })}`,
                });
              } else {
                Mixpanel.track('Assessments_Goal');
                navigate(paths.homePage);
              }
            }}
          >
            {t(nextItem ? 'completeNext' : 'goBack')}
          </ProgressButton>
          {nextItem && (
            <NextButton
              variant="secondary-transparent"
              onClick={() => {
                navigate(paths.homePage);
              }}
            >
              {t('goBack')}
            </NextButton>
          )}
        </ButtonNav>
      }
    />
  );
};
