import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

import { Headline, LoadingContainer } from './LoadingLayout.styles';

interface LoadingLayoutProps {
  headerTitle: string;
  headline: string;
  description?: string;
  backNavigateTo?: string;
}

export const LoadingLayout: React.FC<LoadingLayoutProps> = ({
  headerTitle,
  headline,
  description,
  backNavigateTo = -1,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.loadingLayout' });
  const navigate = useNavigate();

  return (
    <BaseLayout
      footer={null}
      headerProps={{
        title: headerTitle,
        variant: 'back',
        onBackClick: () => navigate(backNavigateTo as string),
      }}
    >
      <LoadingContainer>
        <Headline>
          <Typography variant="Headline2">{headline}</Typography>
        </Headline>
        <Spinner size={104} />
        <Typography variant="Body1">{description || t('loadingSubtitle')}</Typography>
      </LoadingContainer>
    </BaseLayout>
  );
};
