const minPasswordLength = 8;
const uppercasePattern = /(?=.*[A-Z])/;
const lowercasePattern = /(?=.*[a-z])/;
const numberOrSpecialCharacterPattern = /(?=.*[!*-@#$%^&+=()])/;

export const isPasswordValid = (password: string): boolean => {
  const lengthConstraint = password.length >= minPasswordLength;
  const uppercaseConstraint = uppercasePattern.test(password);
  const lowercaseConstraint = lowercasePattern.test(password);
  const numberOrSpecialCharacterConstraint = numberOrSpecialCharacterPattern.test(password);

  return lengthConstraint && uppercaseConstraint && lowercaseConstraint && numberOrSpecialCharacterConstraint;
};

export const validatePassword = (
  password: string,
): {
  lengthConstraint: boolean;
  uppercaseConstraint: boolean;
  lowercaseConstraint: boolean;
  numberOrSpecialCharacterConstraint: boolean;
} => {
  const lengthConstraint = password.length >= minPasswordLength;
  const uppercaseConstraint = uppercasePattern.test(password);
  const lowercaseConstraint = lowercasePattern.test(password);
  const numberOrSpecialCharacterConstraint = numberOrSpecialCharacterPattern.test(password);

  return {
    lengthConstraint,
    uppercaseConstraint,
    lowercaseConstraint,
    numberOrSpecialCharacterConstraint,
  };
};
