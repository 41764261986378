import styled from 'styled-components';

export const Headline = styled.div`
  margin-bottom: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
  margin-top: 127px;
`;

export const FooterContainer = styled.div`
  padding: 16px 23px;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
