import { gql } from '__generated__/gql';

export const GET_EXERCISE_CHART = gql(`
  query Custom_modules($customModuleFormId: ID) {
    customModuleForm(id: $customModuleFormId) {
      id
      name
      custom_modules {
        label
        id
      }
    }
  }
`);

export const GET_USER_CHART_BY_NAME = gql(`
  query UserFormAnswerGroups($userId: String, $name: String) {
    formAnswerGroups(user_id: $userId, name: $name) {
      id
      name
      form_answers {
        id
        label
        answer
        custom_module {
          id
        }
      }
    }
  }
`);
