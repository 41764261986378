import isNull from 'lodash/isNull';
import React, { useEffect, useRef, useState } from 'react';

import { Navigation } from 'components/ui/Navigation/Navigation';

import * as Styled from './BaseLayout.styles';
import { Header, HeaderProps } from './Header';

interface LayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  headerProps?: HeaderProps;
  contentContainerStyles?: React.CSSProperties;
}

export const BaseLayout: React.FC<LayoutProps> = ({
  children,
  footer,
  header,
  headerProps,
  contentContainerStyles,
}: LayoutProps) => {
  const [navHeight, setNavHeight] = useState<number | undefined>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNavHeight(ref.current?.clientHeight);
  }, [ref.current?.clientHeight]);
  return (
    <Styled.LayoutContainer>
      <Styled.ContentContainer style={{ marginBottom: navHeight, ...contentContainerStyles }}>
        <Styled.Header>{header ? header : <Header {...headerProps} />}</Styled.Header>
        {children}
      </Styled.ContentContainer>
      {!isNull(footer) && (
        <Styled.NavigationContainer ref={ref}>{footer ? footer : <Navigation />}</Styled.NavigationContainer>
      )}
    </Styled.LayoutContainer>
  );
};
