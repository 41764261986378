import { ReactComponent as CrossDone } from 'assets/vectors/fi-rr-cross-done.svg';
import { ReactComponent as Minus } from 'assets/vectors/fi-rr-minus.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { validatePassword } from 'utils/validators/password';

import { Icon } from '../Icon/Icon';
import { RowWrapper, Wrapper } from './PasswordValidator.styled';

export interface Props {
  password: string;
}

export const PasswordValidator: React.FC<Props> = ({ password }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.passwordValidator' });

  const { lengthConstraint, uppercaseConstraint, lowercaseConstraint, numberOrSpecialCharacterConstraint } =
    validatePassword(password);

  const minusIcon = (
    <Icon aria-label={t('passwordDoesNotMeetCriteria').toString()} element={Minus} size={16} color="black.600" />
  );
  const crossDoneIcon = (
    <Icon aria-label={t('passwordMeetsCriteria').toString()} element={CrossDone} size={16} color="other.green" />
  );

  const renderIcon = (isValid: boolean) => (isValid ? crossDoneIcon : minusIcon);

  return (
    <Wrapper>
      <RowWrapper variant="Helper1" aria-label={t('passwordMustIncludeAtLeast').toString()}>
        {t('passwordMustIncludeAtLeast')}
      </RowWrapper>

      <RowWrapper variant="Helper1" aria-label={t('charactersLength').toString()}>
        {renderIcon(lengthConstraint)}
        {t('charactersLength')}
      </RowWrapper>

      <RowWrapper variant="Helper1" aria-label={t('upperCase').toString()}>
        {renderIcon(uppercaseConstraint)}
        {t('upperCase')}
      </RowWrapper>
      <RowWrapper variant="Helper1" aria-label={t('lowerCase').toString()}>
        {renderIcon(lowercaseConstraint)}
        {t('lowerCase')}
      </RowWrapper>
      <RowWrapper variant="Helper1" aria-label={t('numberOrSpecialCharacter').toString()}>
        {renderIcon(numberOrSpecialCharacterConstraint)}
        {t('numberOrSpecialCharacter')}
      </RowWrapper>
    </Wrapper>
  );
};
