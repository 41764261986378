import { ReactComponent as Fitbit } from 'assets/images/kit/fitbit.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/ui/Typography/Typography';

import { FirstStepContainer, FlexCenterContainer, StepsList, StepsListItem } from './Steps.styles';

export const Step1: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.fitbitSetup.step1' });

  return (
    <FirstStepContainer>
      <Typography variant="Body1">{t('description')}</Typography>
      <Fitbit title={t('imageDescription') || 'Fitbit device image'} width={131} height={164} />
      <FlexCenterContainer>
        <StepsList>
          <StepsListItem>
            <Typography.Span variant="Body1">{t('step1')}</Typography.Span>
          </StepsListItem>
          <StepsListItem>
            <Typography.Span variant="Body1">{t('step2')}</Typography.Span>
          </StepsListItem>
          <StepsListItem>
            <Typography.Span variant="Body1">{t('step3')}</Typography.Span>
          </StepsListItem>
        </StepsList>
      </FlexCenterContainer>
    </FirstStepContainer>
  );
};

export default Step1;
