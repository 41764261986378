import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

const overflow = css`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  margin-right: -10px;
`;

export const QuestionnaireForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100% - 40px);
`;

export const QuestionContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  margin-bottom: 16px;
  ${overflow}
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${overflow}
`;

export const AnswerSummary = styled(Typography)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  margin: 0 -16px;

  border-top: 1px solid ${({ theme }) => theme.color.black[50]};
  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.black[50]};
  }
`;

export const SingleAnswer = styled(Typography)`
  margin-left: 32px;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    left: -16px;
    top: 8px;
    background-color: ${({ theme }) => theme.color.black[900]};
    border-radius: 50%;
    width: 5px;
    height: 5px;
  }
`;

export const QuestionTitle = styled(Typography.Div)`
  text-align: justify;
  p {
    margin: revert;
  }
  ol,
  ul {
    padding-inline-start: 28px;
    &::marker {
      width: 24px;
    }
    ol,
    ul {
      margin-left: -10px;
    }
  }
`;
