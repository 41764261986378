/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client';
import { Entry } from '__generated__/graphql';
import { GET_BLOOD_PRESSURE_MEASUREMENTS } from 'api/bloodPressureCuff/bloodPressureCuff.query';
import { GET_COURSE_MEMBERSHIP } from 'api/questionnaires/questionnaires.query';
import {
  DiagramContainer,
  Section,
  TimeSwitchContainer,
  ViewReadingsButton,
} from 'pages/progress/activity/Activity.styles';
import { ActivityLoading } from 'pages/progress/activity/components/ActivityLoading';
import { getEntriesTimeRange, getIntervalForTab, getTimeRange } from 'pages/progress/activity/timeUtils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { TimeTabs, TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitch, TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';
import { Typography } from 'components/ui/Typography/Typography';

import { mapIndexToTabName, mapTabNameToIndex } from '../utils';
import { BloodPressureDiagram } from './components/BloodPressureDiagram/BloodPressureDiagram';
import ActivityGoalSection from './components/GoalSection';
import HeartRateSection from './components/HeartRateSection';
import StatisticsSection from './components/StatisticsSection';
import { groupMeasurements } from './utils';

export const BloodPressure: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure' });

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');

  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState<TimeSwitchType>('this');
  const [timeInterval, setTimeInterval] = useState(getTimeRange('thisWeek'));

  useEffect(() => {
    const tabIndex = mapTabNameToIndex(tabParam);
    setCurrentTab(tabIndex);
  }, [tabParam]);

  const onTabChange = (tab: number) => {
    setCurrentTab(tab);
    setCurrentSubTab('this');

    const tabName = mapIndexToTabName(tab);

    navigate(`${paths.bloodPressure}?tab=${tabName}`, { replace: true });
  };

  const { data, loading: activityLoading } = useQuery(GET_BLOOD_PRESSURE_MEASUREMENTS, {
    fetchPolicy: 'no-cache',
  });
  const { data: courseData } = useQuery(GET_COURSE_MEMBERSHIP);

  const courseStartDate = courseData?.currentUser?.course_memberships?.[0]?.start_at;
  const entries = (data?.entries || []) as Entry[];

  const chartFromDate = timeInterval?.startRange || getEntriesTimeRange(entries, courseStartDate).startRange;
  const chartToDate = timeInterval?.endRange || getEntriesTimeRange(entries).endRange;

  const { systolicData, diastolicData, heartRateData } = groupMeasurements(entries, chartFromDate, chartToDate);
  const setChartInterval = () =>
    setTimeInterval(getIntervalForTab(currentTab, currentSubTab, entries, courseStartDate));

  useEffect(() => {
    setChartInterval();
  }, [currentTab, currentSubTab]);

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Progress - Blood Pressure' });
  }, []);

  const navigate = useNavigate();

  const shouldShowLoading = activityLoading;

  if (shouldShowLoading) return <ActivityLoading />;

  return (
    <BaseLayout headerProps={{ title: t('title'), variant: 'back', onBackClick: () => navigate(-1) }} footer={null}>
      <TimeTabs selectedTab={currentTab} onTabChange={onTabChange} />
      {currentTab !== TimeTabsIndex.all_time && (
        <TimeSwitchContainer>
          <TimeSwitch
            time={currentTab === TimeTabsIndex.week ? 'week' : 'month'}
            onSelect={(value: TimeSwitchType) => setCurrentSubTab(value)}
            selected={currentSubTab}
          />
        </TimeSwitchContainer>
      )}

      <DiagramContainer>
        <BloodPressureDiagram
          systolicData={systolicData}
          diastolicData={diastolicData}
          showTendencyOnly={currentTab !== TimeTabsIndex.week}
          ariaLabel={t('chartLabel')}
          height="196px"
        />
      </DiagramContainer>
      <Section>
        <Typography.H1 variant="SubHeadline1Medium">{t('sectionTitle')}</Typography.H1>
        <StatisticsSection systolicData={systolicData} diastolicData={diastolicData} />
        {currentTab === TimeTabsIndex.week && <ActivityGoalSection />}
        <HeartRateSection heartRateData={heartRateData} />
      </Section>

      <Section>
        <Typography.H1 variant="SubHeadline1Medium">{t('reportsSectionTitle')}</Typography.H1>
        <ViewReadingsButton
          id="saveChanges"
          onClick={() => {
            if (tabParam) {
              navigate(`${paths.bloodPressureReadings}?tab=${tabParam}`);
              return;
            }
            navigate(paths.bloodPressureReadings);
          }}
        >
          <Typography.Span variant="Body1Bold" color="blue.600">
            {t('viewReadingsButton')}
          </Typography.Span>
        </ViewReadingsButton>
      </Section>
    </BaseLayout>
  );
};
