/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { theme } from 'assets/styles/theme';

export const LEGEND_HEIGHT = '26px';

const getOrCreateLegendList = (_chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = '0px';
    listContainer.style.padding = '0px';

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

// Custom plugin for customizing Chart.js legend
export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, _args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'default';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';

      if (items.length && index !== 0) li.style.marginLeft = '10px';

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.strokeStyle;
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '8px';
      boxSpan.style.marginRight = '4px';
      boxSpan.style.width = '8px';
      boxSpan.style.borderRadius = '50%';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = theme.color.black[800];
      textContainer.style.fontSize = '10px';
      textContainer.style.letterSpacing = '0.02em';
      textContainer.style.margin = '0px';
      textContainer.style.padding = '0px';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export default htmlLegendPlugin;
