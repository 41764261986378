import { useQuery } from '@apollo/client';
import { GET_GOALS } from 'api/goal/goal.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { Header } from 'components/layout/BaseLayout/Header';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import TimeTabs, { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitch, TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';
import { Typography } from 'components/ui/Typography/Typography';

import { MonthOption, monthOptions } from '../medications/utils';
import GoalItem from './GoalItem';
import * as Styled from './PersonalGoalsProgress.styles';
import { useFilteredGoals, useTransformedAndSortedGoals } from './hooks';
import { getDateRange } from './utils';

const PersonalGoalsProgress: FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState<TimeSwitchType>('this');
  const [selectedMonth, setSelectedMonth] = useState<MonthOption | null>(monthOptions[new Date().getMonth()]);
  const dateRange = getDateRange(currentTab, currentSubTab, selectedMonth);
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  const { data, loading: goalsLoading } = useQuery(GET_GOALS, {
    variables: {
      user_id: currentUserData?.currentUser?.id,
      offset: 0,
      per_page: 10000, // Fix this later... Paginatation needs to happen here but will need to make component infinte scrolling
      keywords: 'Personal',
    },
    fetchPolicy: 'network-only',
  });

  const transformedGoals = useTransformedAndSortedGoals(data);
  const filteredGoals = useFilteredGoals(transformedGoals, dateRange.start, dateRange.end);

  const onTabChange = (tab: number) => {
    setCurrentTab(tab);
  };
  const { t } = useTranslation('translation', { keyPrefix: 'pages.progress' });

  const navigate = useNavigate();

  return (
    <>
      <Styled.HeaderContainer>
        <Header onBackClick={() => navigate(-1)} variant="back" title={t('personalGoals')} />
      </Styled.HeaderContainer>
      <Styled.TimeTabsContainer>
        <TimeTabs onTabChange={onTabChange} />
      </Styled.TimeTabsContainer>
      {currentTab !== TimeTabsIndex.all_time && currentTab !== TimeTabsIndex.month && (
        <Styled.TimeSwitchContainer>
          <TimeSwitch
            time={currentTab === TimeTabsIndex.week ? 'week' : 'month'}
            onSelect={(value: TimeSwitchType) => setCurrentSubTab(value)}
            selected={currentSubTab}
          />
        </Styled.TimeSwitchContainer>
      )}
      {currentTab === TimeTabsIndex.month && (
        <Styled.SelectContainer>
          <Select
            options={monthOptions}
            defaultValue={selectedMonth}
            onChange={(newValue) => {
              if (newValue) {
                setSelectedMonth(newValue);
              }
            }}
            placeholder={t('Month')}
          />
        </Styled.SelectContainer>
      )}
      {isEmpty(filteredGoals) && !goalsLoading && (
        <Styled.NoDataContainer>
          <Typography variant="Body1Bold">{t('noDataTitle')}</Typography>
          <Typography variant="Helper1">{t('noDataSubtitle')}</Typography>
        </Styled.NoDataContainer>
      )}
      <Styled.ProgressContainer>
        {goalsLoading ? <LoadingPage /> : <GoalItem transformedGoals={filteredGoals} />}
      </Styled.ProgressContainer>
    </>
  );
};

export default PersonalGoalsProgress;
