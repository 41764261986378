import parse from 'date-fns/parse';
import isNil from 'lodash/isNil';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ReadingsList, { Reading } from 'components/ui/ReadingsList/ReadingsList';
import { Typography } from 'components/ui/Typography/Typography';

import { StepsEntry } from '../types';

interface ActivityReadingsListProps {
  entries: StepsEntry[];
}

export const ActivityReadingsList: React.FC<ActivityReadingsListProps> = ({ entries }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.activity.readings' });

  const entriesWithDates = entries.filter((entry) => !isNil(entry) && !isNil(entry?.created_at));

  const readingsData: Reading[] = entriesWithDates.map((entry) => ({
    id: entry?.id || '',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createdAt: parse(entry!.created_at!, 'yyyy-MM-dd HH:mm:ss X', new Date()),
    updatedAt: entry?.updated_at ? parse(entry.updated_at, 'yyyy-MM-dd HH:mm:ss X', new Date()) : undefined,
    leftContent: (
      <>
        <Typography.Span variant="Body1Bold" color="other.green">
          {entry?.metric_stat ? entry.metric_stat.toLocaleString('en-US') : 0}
        </Typography.Span>{' '}
        <Typography.Span variant="Body1">{t('perDay')}</Typography.Span>
      </>
    ),
  }));

  return <ReadingsList data={readingsData} leftLabel={t('activityLabel')} />;
};

export default ActivityReadingsList;
