import { ReactComponent as BloodPressureCuffImage } from 'assets/images/BP-manual/blood-pressure-cuff.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './BloodPressureManual.styles';

interface BloodPressureManualProps {
  firstTimeEnter?: boolean;
  handleSkip: () => void;
  handleViewSteps: () => void;
}

export const BloodPressureManualIntro: React.FC<BloodPressureManualProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressureManual.intro' });
  const navigate = useNavigate();
  return (
    <BaseLayout
      footer={null}
      headerProps={{ variant: 'back', title: t('title'), onBackClick: () => navigate(paths.homePage) }}
    >
      <Styled.PageContentContainer>
        <Styled.ExtendingContainer>
          <Typography variant="SubHeadline1Medium" color="black.800">
            {t('headline')}
          </Typography>
          <BloodPressureCuffImage style={{ margin: 27 }} title={t('cuffImage')} />
          <Typography color="black.800">{t('description')}</Typography>
        </Styled.ExtendingContainer>
        <Styled.FooterContainer>
          <Styled.SButton variant="link" onClick={props.handleSkip}>
            {t('skip')}
          </Styled.SButton>
          <Styled.SButton variant="primary" onClick={props.handleViewSteps}>
            {t('viewSteps')}
          </Styled.SButton>
        </Styled.FooterContainer>
      </Styled.PageContentContainer>
    </BaseLayout>
  );
};
