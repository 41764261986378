import { Maybe } from '__generated__/graphql';

export type CarePlanItem =
  | 'personal'
  | 'pressure'
  | 'activity'
  | 'exercise'
  | 'weight'
  | 'appointments'
  | 'medications'
  | 'lessons';

export type CarePlanGoal = {
  __typename?: 'Goal';
  id: string;
  name?: string | null;
  description?: string | null;
  start_on?: string | null;
  repeat?: string | null;
  is_completed_for_date?: boolean | null;
  subgoals?: Array<{
    __typename?: 'Goal';
    id: string;
    name?: string | null;
    goal_histories: Array<{ __typename?: 'GoalHistory'; id: string; completed_on?: string | null }>;
  } | null> | null;
};

export type CarePlanGoalTyped = {
  goal: CarePlanGoal;
  order: number;
  type: CarePlanItem;
};

export const getCarePlanItemType = (name?: Maybe<string>): { order: number; type: CarePlanItem } | null => {
  if (!name) {
    return null;
  }
  if (name.match('personal')) {
    return {
      order: 6,
      type: 'personal',
    };
  }
  if (name.match('pressure')) {
    return {
      order: 3,
      type: 'pressure',
    };
  }
  if (name.match('moving')) {
    return {
      order: 2,
      type: 'activity',
    };
  }
  if (name.match('exercise')) {
    return {
      order: 5,
      type: 'exercise',
    };
  }
  if (name.match('weight')) {
    return {
      order: 7,
      type: 'weight',
    };
  }
  if (name.match('appointments')) {
    return {
      order: 1,
      type: 'appointments',
    };
  }
  if (name.match('medications')) {
    return {
      order: 4,
      type: 'medications',
    };
  }
  if (name.match('lessons')) {
    return {
      order: 8,
      type: 'lessons',
    };
  }
  return null;
};
