import styled, { css } from 'styled-components';

export type RadioButtonType = 'vertical' | 'horizontal';

export const RadioInput = styled.input`
  visibility: hidden;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.black[800]};
  margin-right: 10px;
  background-color: ${({ theme }) => theme.color.other.white};
  cursor: pointer;

  ${RadioInput}:checked + & {
    border-color: ${({ theme }) => theme.color.blue[500]};
  }

  ${RadioInput}:checked + & > span {
    background-color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const OutlierCircle = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto;
  ${RadioInput}:checked + & > span {
    background-color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const RadioContainer = styled.div<{ checked: boolean; type: RadioButtonType }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme, checked, type }) =>
    type === 'horizontal'
      ? css`
          width: 100%;
          padding: 18px 16px;
          border: 1px solid ${theme.color.black[50]};
          background-color: ${checked ? theme.color.blue[700] : theme.color.other.white};
          border-radius: 8px;
        `
      : css`
          flex-direction: column-reverse;
          gap: 4px;

          ${RadioLabel} {
            margin: 0;
          }
        `}
`;
