import { gql } from '__generated__';

export const GET_COURSE_MEMBERSHIP = gql(`
  query getCourseMembership{
    currentUser {
      id
      course_memberships {
        id
        start_at
        course_status
        course_id
      }
    }
  }
`);

export const GET_QUESTIONAIRES = gql(`
  query getQuestionnaires($membership_id: ID) {
    currentUser {
      id
      course_memberships {
        id
        start_at
        course_status
        course_id
        course {
          id
          course_type
          description
          name
          start_date
          users_progress
          course_items {
            id
            item_id
            name
            scheduled_release
            incomplete_course_item_id(course_membership_id: $membership_id)
            completed_course_item {
              completed_item_id
              created_at
            }
          }
        }
      }
    }
  }
`);

export const GET_SINGLE_QUESTIONNAIRE = gql(`
  query getSingleQuestionnaire($user_id: ID, $custom_module_form_id: ID, $incomplete_form_id: ID) {
    initialFormAnswers(user_id: $user_id, custom_module_form_id: $custom_module_form_id, incomplete_form_id: $incomplete_form_id) {
      answer
      custom_module_id
      user_id
    }
    customModuleForm(id: $custom_module_form_id) {
      id
      name
      created_at
      custom_modules {
        id
        options
        required
        label
        sublabel
        mod_type
        custom_module_form_id
        options_array
      }
    }
  }
`);
