import React from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'router/paths';

import { LoadingLayout } from 'components/layout/Loading/LoadingLayout';

export const ExercisesLoading: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.exercises' });

  return (
    <LoadingLayout headerTitle={t('loadingHeader')} headline={t('loadingTitle')} backNavigateTo={paths.homePage} />
  );
};
