import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths } from 'router/paths';

import { Navigation } from 'components/ui/Navigation/Navigation';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './HomeLayout.styles';

interface HomeLayoutProps {
  children: React.ReactNode;
}

export const HomeLayout: React.FC<HomeLayoutProps> = ({ children }: HomeLayoutProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home' });

  return (
    <Styled.HomeLayoutContainer>
      <Styled.HomeHeader>
        <Typography variant="Headline1"> {t('header')}</Typography>
        <Link to={`${paths.settings.main}/${paths.settings.getHelp}`} style={{ textDecoration: 'none' }}>
          <Typography weight={600} color="blue.600">
            {t('help')}
          </Typography>
        </Link>
      </Styled.HomeHeader>
      <Styled.HomePageContentContainer>{children}</Styled.HomePageContentContainer>
      <Styled.NavigationContainer>
        <Navigation />
      </Styled.NavigationContainer>
    </Styled.HomeLayoutContainer>
  );
};
