import React, { useState } from 'react';
import styled from 'styled-components';

import { Spinner } from '../Spinner/Spinner';
import { Typography } from '../Typography/Typography';

const AccordionContainer = styled.div`
  margin: 0 20px 0 20px;
  margin-bottom: 10px;
  overflow: hidden; /* Hide overflow content */
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: column; /* Make label, title, and preview text stack vertically */
  align-items: flex-start; /* Align items to start */
  cursor: pointer;
`;

const Label = styled(Typography)`
  margin-bottom: 16px;
  font-size: 14px; /* Smaller font size for the label */
`;

const TitleWrapper = styled(Typography)`
  width: 100%; /* Make the title and preview text fill the entire width of the accordion */
  display: flex;
  align-items: center; /* Align items vertically */
`;

const AccordionTitle = styled(Typography)`
  max-width: 85%;
  color: ${({ theme }) => theme.color.blue[700]};
  font-weight: bold;
`;

const ArrowIcon = styled.span`
  color: ${({ theme }) => theme.color.blue[700]};
  margin-right: 10px;
  transition: transform 0.3s ease; /* Add transition for smooth rotation */
`;

const PreviewText = styled(Typography)`
  padding-top: 10px;
  font-size: 14px; /* Same font size as the label */
`;

const AccordionContent = styled.div`
  margin-top: 10px; /* Add spacing between the title and the content */
  transition: visibility 0.3s ease, opacity 0.3s ease; /* Add transition for smooth visibility and opacity change */
  visibility: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? '1' : '0')};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  margin-left: auto; /* Move checkbox to the far right */
`;

export const CustomCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.color.black[900]};
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-left: auto; /* Move checkbox to the far right */

  &:after {
    background-color: ${({ theme }) => theme.color.other.green};
    content: '';
    position: absolute;
    left: 5px; /* Adjust left position to center the checkmark horizontally */
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: ${(props) => (props.checked ? 1 : 0)};
  }
  &:checked {
    background: ${({ theme }) => theme.color.other.green};
    border: none;
  }
  &:disabled {
    border-color: ${({ theme }) => theme.color.black[50]};
    ~ ${Label} {
      color: ${({ theme }) => theme.color.black[50]};
    }
  }
`;

interface AccordionProps {
  label: string;
  title: string;
  previewText: string; // Add previewText prop
  children: React.ReactNode;
  checked: boolean;
  checkBoxLoading?: boolean;
  onChange: () => Promise<boolean>;
  updateChartLoading?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  label,
  title,
  previewText,
  children,
  checked,
  onChange,
  checkBoxLoading,
  updateChartLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(checked);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxClick = async (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
    const res = await onChange();
    if (res) {
      setIsChecked((prev) => !prev);
    }
  };

  return (
    <>
      <AccordionContainer>
        <AccordionHeader onClick={toggleAccordion}>
          <Label variant="Helper1" color="black.600">
            {label}
          </Label>
          <TitleWrapper>
            {isOpen ? <ArrowIcon>▲</ArrowIcon> : <ArrowIcon>▼</ArrowIcon>}
            <AccordionTitle>{title}</AccordionTitle>
            {checkBoxLoading ? (
              <SpinnerWrapper>
                <Spinner size={20} />
              </SpinnerWrapper>
            ) : (
              <CustomCheckbox
                type="checkbox"
                checked={isChecked}
                onClick={handleCheckboxClick}
                readOnly={true}
                disabled={updateChartLoading}
              />
            )}
          </TitleWrapper>
          <PreviewText variant="Helper1">{previewText}</PreviewText>
        </AccordionHeader>
        {isOpen && <AccordionContent isOpen={isOpen}>{children}</AccordionContent>}
      </AccordionContainer>
    </>
  );
};

export default Accordion;
