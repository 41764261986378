import { gql } from '@apollo/client';

export const GET_USER_CONVERSATIONS = gql`
  query GetUserConversations($userId: ID) {
    conversations(user_id: $userId) {
      id
      conversation_memberships_count
      name
      community_chat_prefix
      includes_multiple_clients
      last_message_content
      last_note_viewed_id
      owner {
        id
        full_name
        first_name
        in_org
        email
        avatar_url
        is_patient
        active
        qualifications
      }
      invitees {
        id
        full_name
        first_name
        allow_direct_chat
        allow_community_chat
        active
        timezone
      }
      current_user_conversation_membership {
        id
        display_name
        archived
        display_other_user_name
        display_other_user_first_name
        viewed
        convo {
          updated_at
        }
      }
      closed_by {
        id
        full_name
      }
      closed_date
    }
  }
`;
