import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { Header } from 'components/layout/BaseLayout/Header';
import { Navigation } from 'components/ui/Navigation/Navigation';

import * as Styled from './SettingsLayout.styles';

interface SettingsLayoutProps {
  children: React.ReactNode;
}

export const SettingsLayout: React.FC<SettingsLayoutProps> = ({ children }: SettingsLayoutProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });

  return (
    <Styled.SettingsLayoutContainer>
      <Styled.HeaderContainer>
        <Header variant="back" onBackClick={() => navigate(paths.homePage)} title={t('settings')} />
      </Styled.HeaderContainer>
      <Styled.SettingsPageContentContainer>{children}</Styled.SettingsPageContentContainer>
      <Styled.NavigationContainer>
        <Navigation />
      </Styled.NavigationContainer>
    </Styled.SettingsLayoutContainer>
  );
};
