import { NewPasswordPayload, sendNewPassword } from 'api/forgotPassword/forgotPassword.api';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { isPasswordValid } from 'utils/validators/password';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { PasswordValidator } from 'components/ui/PasswordValidator/PasswordValidator';
import { Typography } from 'components/ui/Typography/Typography';

import { ForgotPasswordForm } from '../ForgotPassword.styles';
import { useErrorParser } from '../useErrorParser';

type FormValues = {
  password: string;
  confirm: string;
};

export const NewPassword: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.forgotPassword.newPassword' });
  const navigate = useNavigate();
  const { parseError } = useErrorParser();
  const [params] = useSearchParams();
  const { mutate: sendNew, isLoading } = useMutation<void, AxiosError<string>, NewPasswordPayload>(sendNewPassword);

  const onConfirm = (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    sendNew(
      { token: params.get('t') || '', password: values.password },
      {
        onSuccess: () => {
          navigate(paths.resetSuccess);
        },
        onError: (error) => {
          const err = parseError(error.response?.data);
          err ? setErrors({ password: err }) : navigate(paths.resetError);
        },
      },
    );
  };

  useEffect(() => {
    if (!params.get('t')) {
      navigate(paths.logInPage);
      return;
    }
  }, [navigate, params]);

  const schema = Yup.object({
    password: Yup.string()
      .default('')
      .required(t('pleasePassword'))
      .test('password-valid', t('fulfillCriteria'), (value) => {
        return isPasswordValid(value as string);
      }),
    confirm: Yup.string()
      .default('')
      .required(t('pleaseConfirm'))
      .test('confirm-password-valid', t('fulfillConfirm'), function (value) {
        return this.parent.password === value;
      }),
  });

  const initial: FormValues = { password: '', confirm: '' };
  return isLoading ? (
    <LoadingPage />
  ) : (
    <BaseLayout
      footer={null}
      headerProps={{ variant: 'back', title: t('reset'), onBackClick: () => navigate(paths.logInPage) }}
    >
      <Formik
        onSubmit={onConfirm}
        initialValues={initial}
        validationSchema={schema}
        validateOnChange
        validateOnBlur
        validateOnMount
      >
        {({ isValid, values }) => {
          return (
            <ForgotPasswordForm>
              <Typography variant="Headline2">{t('chooseNew')}</Typography>
              <Input type="password" name="password" label={t('password')} />
              <PasswordValidator password={values.password} />
              <Input type="password" name="confirm" label={t('repeatPassword')} />
              <Button type="submit" style={{ marginTop: 'auto' }} disabled={!isValid || isLoading}>
                {t('confirm')}
              </Button>
            </ForgotPasswordForm>
          );
        }}
      </Formik>
    </BaseLayout>
  );
};
