import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;
export const HelpContent = styled.div`
  background-color: ${({ theme }) => theme.color.other.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  border-radius: 8px;
  padding: 24px;
`;

export const MoreHelpContent = styled.div`
  background-color: ${({ theme }) => theme.color.other.white};
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  border-radius: 8px;
  padding: 24px;
`;

export const RessetedAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
