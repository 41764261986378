import React from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'router/paths';

import { LoadingLayout } from 'components/layout/Loading/LoadingLayout';

export const ActivityLoading: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.activity' });

  return (
    <LoadingLayout
      headerTitle={t('loadingTitle')}
      backNavigateTo={paths.homePage}
      headline={t('loading')}
      description={t('loadingDescription')}
    />
  );
};
