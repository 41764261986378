import { Form } from 'formik';
import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const EnterTitle = styled(Typography.H1)`
  margin-bottom: 16px;
`;

export const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  transition: height 0.25s ease;
  gap: 16px;
  flex: 1;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;
