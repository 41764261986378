import { useState } from 'react';

import { ConsentModalProps } from './ConsentModal/ConsentModal';

type UseConsentModals = {
  currentModal: ConsentModalProps | undefined;
  openModal: (headline: string, content: string) => void;
  closeModal: () => void;
};

export const useConsentModals = (): UseConsentModals => {
  const [currentModal, setCurrentModal] = useState<ConsentModalProps>();

  const openModal = (headline: string, content: string) => {
    setCurrentModal({
      headline,
      content,
    });
  };

  const closeModal = () => {
    setCurrentModal(undefined);
  };

  return {
    currentModal,
    openModal,
    closeModal,
  };
};
