import { useMutation, useQuery } from '@apollo/client';
import { CREATE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_CARE_PLAN } from 'api/progress/carePlan.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { format } from 'date-fns';
import { useAppointmentsData } from 'pages/appointments/hooks';
import { useEffect } from 'react';
import { DATE_FORMAT } from 'utils/helpers';

import { getGoalType } from '../components/Card/TaskCard';
import { useCarePlanGoals } from './useCarePlan';

export const useAppointmentGoals = (): void => {
  const { weeklyGoals } = useCarePlanGoals();
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  const { pastAppointments, pastAppointmentsLoading, upcomingAppointments, upcomingAppointmentsLoading } =
    useAppointmentsData();

  const [createGoalHistory] = useMutation(CREATE_GOAL_HISTORY);

  useEffect(() => {
    const checkInGoal = weeklyGoals.find((item) => getGoalType(item) === 'check' && !item.is_completed_for_date);
    if (checkInGoal && (pastAppointments.length > 0 || upcomingAppointments.length > 0)) {
      createGoalHistory({
        variables: {
          user_id: currentUserData?.currentUser?.id,
          goal_id: checkInGoal?.id,
          completed_on: format(Date.now(), DATE_FORMAT),
          mark_parent_complete: true,
        },
        refetchQueries: [GET_CARE_PLAN],
      });
    }
  }, [
    createGoalHistory,
    weeklyGoals,
    pastAppointments,
    pastAppointmentsLoading,
    upcomingAppointments,
    upcomingAppointmentsLoading,
    currentUserData?.currentUser?.id,
  ]);
};
