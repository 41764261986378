import { ReactComponent as SurveyIcon } from 'assets/vectors/ff-ri-survey.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import { AllFilled, AssessmentHeader, AssessmentTab } from './Questionnaires.styles';
import { useQuestionnaires } from './useQuestionnaires';

export const Questionnaires: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.questionnaires' });
  const navigate = useNavigate();

  const { anyVisible, completedCount, visibleItems, loading, courseId } = useQuestionnaires();

  return loading ? (
    <LoadingPage />
  ) : (
    <BaseLayout
      footer={null}
      headerProps={{
        title: t('titleWeekly'),
        variant: 'back',
        onBackClick: () => navigate(paths.homePage),
      }}
    >
      <AssessmentHeader
        progress={visibleItems ? (completedCount * 100) / visibleItems?.length : 0}
        icon={<Icon element={SurveyIcon} size={20} color={null} aria-hidden />}
        label={t('completeYourAssessments')}
        description={anyVisible ? t('completed', { complete: completedCount, incomplete: visibleItems?.length }) : ''}
      />
      {!anyVisible && <AllFilled variant="Title1">{t('allFilled')}</AllFilled>}
      {visibleItems?.map((item, index) => (
        <AssessmentTab
          key={item.id}
          title={`${t('assessment')} ${index + 1}`}
          hidePlayIcon
          grayOutIfCompleted
          isCompleted={!!item.completed_course_item}
          onClick={() =>
            !item.completed_course_item &&
            navigate({
              pathname: `${paths.questionnaires}/${item.item_id}`,
              search: `?${createSearchParams({
                course_id: courseId || '',
                course_item_id: item.id,
                incomplete_item_id: item.incomplete_course_item_id || '',
              })}`,
            })
          }
        />
      ))}
    </BaseLayout>
  );
};
