import { ReactComponent as FitbitLogo } from 'assets/images/kit/fitbit-logo.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/ui/Typography/Typography';

import { FitbitLogoContainer, FlexCenterContainer } from './Steps.styles';

export const Step3: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.fitbitSetup.step3' });

  return (
    <FlexCenterContainer>
      <Typography variant="Body1">{t('description')}</Typography>
      <FitbitLogoContainer>
        <FitbitLogo title={t('fitbitLogoAlt')} />
      </FitbitLogoContainer>
    </FlexCenterContainer>
  );
};

export default Step3;
