import { useCurrentToken } from 'hooks/useCurrentToken';
import React, { useEffect } from 'react';
import { paths } from 'router/paths';

import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

export const Logout: React.FC = () => {
  const { clearToken } = useCurrentToken();

  useEffect(() => {
    clearToken();
    window.location.href = paths.welcomePage;
  }, [clearToken]);

  return <LoadingPage />;
};
