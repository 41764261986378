import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';

export const ButtonNav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NextButton = styled(Button)`
  color: ${(props) => props.theme.color.other.linen};
  border: 1px solid ${(props) => props.theme.color.other.linen};
`;
