import { icons } from 'assets/vectors/iconsList';
import floor from 'lodash/floor';
import mean from 'lodash/mean';
import { BoxVertical, HorizontalFlexContainer, StepsBox } from 'pages/progress/activity/Activity.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { HeartRateMeasurement } from '../types';

export const HeartRateSection: React.FC<HeartRateMeasurement> = ({ heartRateData }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure' });
  const average = floor(mean(heartRateData), 0);
  return (
    <HorizontalFlexContainer>
      <BoxVertical>
        <Icon element={icons['fi-rr-heart-color.svg']} size={24} color="other.red" title={t('exclamation')} />
        <StepsBox style={{ border: 'none', padding: 0 }}>
          <Typography variant="Body1" color="black.600">
            {t('averageHeartRate')}
          </Typography>
          <Typography.Span color="black.800">
            <Typography.Span size={20} weight={500}>
              {average || t('notAvailable')}
            </Typography.Span>{' '}
            {t('bpm')}
          </Typography.Span>
        </StepsBox>
      </BoxVertical>
    </HorizontalFlexContainer>
  );
};

export default HeartRateSection;
