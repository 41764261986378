import { FormAnswerInput } from '__generated__/graphql';
import { addDays, isPast } from 'date-fns';
import isNumber from 'lodash/isNumber';
import { parseHealthieDate } from 'utils/helpers';
import { Maybe } from 'utils/types';

import { QuestionOption, QuestionProps } from 'components/ui/Question/Question';

export const BRACKET = /\[[^\]]+\]/g;

export type Answer = {
  custom_module_id: string;
  user_id: Maybe<string>;
  answer: string | string[];
  label: Maybe<string>;
};
export type Question = {
  id: string;
  title: string | null | undefined;
  type: QuestionProps['type'];
  options: QuestionOption[] | undefined;
  answer: string | string[];
};

export type CourseItem = {
  __typename?: 'CourseItem';
  id: string;
  item_id?: string | null;
  name?: string | null;
  scheduled_release?: string | null;
  incomplete_course_item_id?: string | null;
  completed_course_item?: {
    __typename?: 'CompletedCourseItem';
    completed_item_id?: string | null;
    created_at?: string | null;
  } | null;
};

export const dummyQuestion: Question = { id: '', type: 'text', title: '', options: [], answer: '' };

export const modTypeMapping: Record<string, QuestionProps['type']> = {
  radio: 'singleselect',
  checkbox: 'multiselect',
  text: 'text',
  textarea: 'textarea',
  number: 'number',
  date: 'date',
  agree_to_above: 'multiselect',
  horizontal_radio: 'horizontal',
};

export const isItemVisible = (item: CourseItem, courseStartDate: Date): boolean => {
  const itemStartDate = addDays(courseStartDate, parseInt(item.scheduled_release ?? '0'));
  const isActiveNow = isPast(addDays(itemStartDate, -1));
  const isCompleted = !!item.completed_course_item;
  const isCompletedWithin7days = item.completed_course_item?.created_at
    ? !isPast(addDays(parseHealthieDate(item.completed_course_item.created_at), 7))
    : false;
  return isActiveNow && (!isCompleted || isCompletedWithin7days);
};

const extractDasiValue = (content: string) =>
  content
    .match(BRACKET)
    ?.map((val) => parseFloat(val.slice(1, -1)))
    .filter(isNumber)
    .filter((el) => !isNaN(el)) || [];

type DASI = {
  total: string;
  vo2Peak: string;
  mets: string;
};

export const calculateDasi = (answers: Answer[]): DASI => {
  const valueArray = answers
    .map((answer) =>
      Array.isArray(answer.answer) ? answer.answer.map(extractDasiValue).flat() : extractDasiValue(answer.answer),
    )
    .flat();
  const total = valueArray.reduce((acc, val) => acc + val, 0);
  const vo2Peak = total * 0.43 + 9.6;
  const mets = vo2Peak / 3.5;
  return {
    total: total.toFixed(2),
    vo2Peak: vo2Peak.toFixed(2),
    mets: mets.toFixed(2),
  };
};

export const mapAnswerForSubmit = ({ answer, ...rest }: Answer): FormAnswerInput => ({
  ...rest,
  answer: Array.isArray(answer) ? answer.join('\n') : answer.toString(),
});
