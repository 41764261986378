import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  max-width: 400px;
  min-height: 300px;
`;

export const SIcon = styled(Icon)`
  align-self: flex-end;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

export const Description = styled(Typography)`
  text-align: center;
  margin-top: 20px;
`;

export const ErrorIcon = styled(Icon)`
  margin-top: 32px;
`;
