import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const PageTitle = styled(Typography)`
  margin: 16px 0 4px;
`;

export const PlanInfoContainer = styled(Typography.Div)`
  display: flex;
  gap: 16px;
  padding-bottom: 16px;
`;

export const FlexLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.color.black[50]};
  &:first-child,
  &:last-child {
    border-radius: 8px;
  }
  background-color: ${(props) => props.theme.color.other.white};
  margin-top: 16px;
  padding: 16px;
`;

export const CardsContainerCenter = styled(CardsContainer)`
  align-items: center;
`;

export const SingleRowContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;
`;

export const HeaderContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-bottom: 24px;
  margin-top: 16px;
`;

export const SelectContainer = styled.div`
  flex: 1;
  margin-right: 12px;
`;
