import { useQuery } from '@apollo/client';
import { Goal } from '__generated__/graphql';
import { GET_CARE_PLAN } from 'api/progress/carePlan.query';
import { format } from 'date-fns';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';

export const useEndOfProgramGoals = (): Goal[] => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.endOfProgram' });
  const documents = useQuery(GET_CARE_PLAN, {
    variables: { date: format(Date.now(), 'yyyy-MM-dd') },
  }).data?.currentUser?.active_care_plan?.documents;
  const certificateDoc = documents?.find((doc) => doc.display_name?.toLocaleLowerCase().includes('certificate'));
  const summaryDoc = documents?.find((doc) => doc.display_name?.toLocaleLowerCase().includes('summary'));
  const certificateGoal = certificateDoc
    ? {
        id: uniqueId(),
        goal_histories: [],
        name: t('certificate'),
        description: certificateDoc.id,
      }
    : undefined;

  const summaryGoal = summaryDoc
    ? {
        id: uniqueId(),
        goal_histories: [],
        name: t('summary'),
        description: summaryDoc.id,
      }
    : undefined;

  const endProgramGoals: Goal[] = [certificateGoal, summaryGoal].filter((goal) => goal) as unknown as Goal[];
  return endProgramGoals;
};
