import { useMutation, useQuery } from '@apollo/client';
import { GET_BLOOD_PRESSURE_MEASUREMENTS } from 'api/bloodPressureCuff/bloodPressureCuff.query';
import { CREATE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { DATE_FORMAT, DEFAULT_POLLING_INTERVAL } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';

import { getGoalType } from '../components/Card/TaskCard';
import { useCarePlanGoals } from './useCarePlan';

export const useBloodPressureGoals = (): void => {
  const today = format(Date.now(), DATE_FORMAT);
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  // Get required data
  const { dailyGoals } = useCarePlanGoals();
  const { data, loading } = useQuery(GET_BLOOD_PRESSURE_MEASUREMENTS, {
    pollInterval: DEFAULT_POLLING_INTERVAL,
    fetchPolicy: 'no-cache',
    variables: {
      start_range: today,
      end_range: today,
    },
  });

  const [createGoalHistory] = useMutation(CREATE_GOAL_HISTORY);

  const pressureGoal = useMemo(() => {
    return dailyGoals.find((item) => getGoalType(item) === 'pressure');
  }, [dailyGoals]);

  const proceedWithSubGoals = useCallback(() => {
    const [firstSubGoal, secondSubGoal] = pressureGoal?.subgoals || [];
    const [firstMeasurement, secondMeasurement] = data?.entries || [];

    if (firstSubGoal && !firstSubGoal.is_completed_for_date && firstMeasurement) {
      createGoalHistory({
        variables: {
          user_id: currentUserData?.currentUser?.id,
          goal_id: firstSubGoal.id,
          completed_on: format(Date.now(), DATE_FORMAT),
          mark_parent_complete: pressureGoal?.subgoals?.length === 1,
        },
      });
    }

    if (secondSubGoal && !secondSubGoal.is_completed_for_date && secondMeasurement) {
      Mixpanel.track('Blood_Pressure_Goal');
      createGoalHistory({
        variables: {
          user_id: currentUserData?.currentUser?.id,
          goal_id: secondSubGoal.id,
          completed_on: format(Date.now(), DATE_FORMAT),
          mark_parent_complete: true,
        },
      });
    }
  }, [pressureGoal?.subgoals, data?.entries, createGoalHistory, currentUserData?.currentUser?.id]);

  useEffect(() => {
    if (pressureGoal && !loading && data?.entries && !pressureGoal?.is_completed_for_date) {
      // Goal not completed, proceed
      proceedWithSubGoals();
    }
  }, [pressureGoal, data, loading, proceedWithSubGoals]);
};
