import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const FlexCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FirstStepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 68px;
`;

export const StepsList = styled.ol`
  margin: 0 auto;
`;

export const StepsListItem = styled.li`
  margin-bottom: 16px;
`;

export const FitbitLogoContainer = styled.div`
  position: absolute;
  top: 300px;

  img {
    width: 180px;
  }
`;

export const LinkToDownloadTheApp = styled(Typography.A)`
  text-decoration: none;
  font-size: 18px;
`;

export const ButtonToDownloadTheApp = styled(Button)`
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.03em;
`;

export const StoreLinkContainer = styled.div`
  margin-top: 32px;
`;

export const InfoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 40px;
`;

export const InfoModalButton = styled(Button)`
  width: auto;
  margin-top: 18px;
`;
