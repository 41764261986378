import { useMutation, useQuery } from '@apollo/client';
import { ResetLinkPayload, sendResetPasswordLink } from 'api/forgotPassword/forgotPassword.api';
import { UPDATE_USER_INFO } from 'api/user/user.mutation';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation as useMutationRQ } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';
import { phoneNumberPattern } from 'utils/validators/patterns';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { Header } from 'components/layout/BaseLayout/Header';
import { Button } from 'components/ui/Button/Button';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from '../General.styles';
import { ChangeModal } from './ChangeFieldModal/ChangeModal';

type Fields = 'phoneNumber';

export const AccountSettings: React.FC = () => {
  const [editingField, setEditingField] = useState<Fields | null>(null);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const { mutate: sendResetLink, isLoading: isChangePasswordLoading } = useMutationRQ<
    void,
    AxiosError<string>,
    ResetLinkPayload
  >(sendResetPasswordLink);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });
  const navigate = useNavigate();
  const { data } = useQuery(GET_CURRENT_USER_DATA, { fetchPolicy: 'network-only' });
  const [updateUserInfo] = useMutation(UPDATE_USER_INFO);
  const user = data?.currentUser;
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().when([], {
      is: () => editingField === 'phoneNumber',
      then: Yup.string().matches(phoneNumberPattern, t('phoneNumberNotValid')).required(t('fieldIsRequired')),
    }),
  });

  const initialFormValues = {
    phoneNumber: user?.phone_number ? user.phone_number : '',
  };

  const onSubmit = async (values: typeof initialFormValues) => {
    try {
      await updateUserInfo({
        variables: {
          phone_number: editingField === 'phoneNumber' ? values.phoneNumber : undefined,
        },
      });
    } catch (error) {
      throw new Error(`Updating account data failed: ${error}`);
    }
    setEditingField(null);
  };

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - Account Settings' });
  }, []);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, isValid, touched, resetForm }) => (
        <>
          <Styled.Container>
            <Styled.HeaderContainer>
              <Header
                variant="back"
                onBackClick={() => navigate(paths.settings.main)}
                title={editingField === 'phoneNumber' ? t('accountSettingsPage.phoneNumber') : t('accountSettings')}
              />
            </Styled.HeaderContainer>
            {isChangePasswordLoading ? (
              <LoadingPage />
            ) : (
              <>
                {data ? (
                  <Styled.Sections>
                    <Styled.Section>
                      {editingField === 'phoneNumber' ? (
                        <Input
                          label={t('accountSettingsPage.phoneNumber')}
                          name="phoneNumber"
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                        />
                      ) : (
                        <>
                          <Styled.Description>
                            <Typography weight={500} color="black.700">
                              {t('accountSettingsPage.phoneNumber')}
                            </Typography>
                            <Styled.FieldText color="black.600">{user?.phone_number}</Styled.FieldText>
                          </Styled.Description>
                          <Styled.SButton
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                              resetForm();
                              setEditingField('phoneNumber');
                            }}
                          >
                            <Typography variant="Helper1">{t('edit')}</Typography>
                          </Styled.SButton>
                        </>
                      )}
                    </Styled.Section>
                    <Styled.Section>
                      <Styled.Description>
                        <Typography weight={500} color="black.700">
                          {t('password')}
                        </Typography>
                        <Typography color="black.600">{'•••••••••••••••••'}</Typography>
                      </Styled.Description>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => {
                          setIsChangePasswordModalOpen(true);
                        }}
                      >
                        <Typography weight={600} size={14} color="blue.600">
                          {t('changePassword')}
                        </Typography>
                      </Button>
                    </Styled.Section>
                  </Styled.Sections>
                ) : (
                  <LoadingPage />
                )}
              </>
            )}
            {editingField && (
              <Styled.Buttons>
                <Styled.FormButton variant="link" onClick={() => setEditingField(null)}>
                  {t('cancel')}
                </Styled.FormButton>
                <Styled.FormButton
                  type="submit"
                  onClick={() => {
                    onSubmit(values);
                  }}
                  disabled={!touched || !isValid}
                >
                  {t('saveChanges')}
                </Styled.FormButton>
              </Styled.Buttons>
            )}
          </Styled.Container>
          <ChangeModal
            isOpen={isChangePasswordModalOpen}
            onClose={() => {
              if (isChangePasswordModalOpen) setIsChangePasswordModalOpen(false);
            }}
            onSubmit={() => {
              if (isChangePasswordModalOpen) {
                sendResetLink(
                  { email: user?.email ? user.email : '' },
                  {
                    onSuccess: () => {
                      navigate({ pathname: paths.emailSent, search: `?to=${user?.email}` });
                    },
                    onError: () => {
                      navigate(paths.resetError);
                    },
                  },
                );
              }
            }}
            text={t('areYouSurePasswordChange')}
            resetEditing={() => {
              setEditingField(null);
            }}
          />
        </>
      )}
    </Formik>
  );
};
