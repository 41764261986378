import { FooterButtonStyled } from 'pages/scheduleAppointment/components/ScheduleActions/ScheduleActions.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

export const ConsentsAccepted: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.logIn.consentsAccepted' });
  return (
    <TaskCompletionPage
      taskTitle={t('header')}
      taskDescription={t('description')}
      color="other.backgroundBlue"
      footer={
        <FooterButtonStyled
          onClick={() => navigate(paths.homePage, { state: { onboarding: true } })}
          variant="secondary"
        >
          {t('next')}
        </FooterButtonStyled>
      }
    />
  );
};
