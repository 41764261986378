import { GET_USER_CHART_BY_NAME } from 'api/exercises/exercise.query';
import { client } from 'index';

export type MotivUserData = {
  outreach: Group[];
};

export type Group = {
  name: string;
  created_at: string;
  notifications?: {
    notified_at: string;
  }[];
};

export const checkRepeatIntake = async (
  userId: string,
): Promise<{ repeatIntake: boolean; shouldScheduleRepeatIntake: boolean }> => {
  const queryUserData = await client.query({
    query: GET_USER_CHART_BY_NAME,
    variables: {
      userId,
      name: 'Motiv user data',
    },
    fetchPolicy: 'no-cache',
  });
  const motivUserDataForm = queryUserData.data.formAnswerGroups ? queryUserData.data.formAnswerGroups[0] : null;
  const jsonFormAnswer = motivUserDataForm?.form_answers.find((form) => form.label === 'json_data') || null;
  const parsedJSON: MotivUserData =
    jsonFormAnswer && JSON.parse(jsonFormAnswer?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '{}');

  const reapeatIntake = parsedJSON.outreach?.find((group) => group.name === 'AO: Repeat Intake');
  const repeatIntakeScheduled = parsedJSON.outreach?.find(
    (group) => group.name === 'AO: Repeat Intake Scheduled (email)',
  );
  if (reapeatIntake) {
    if (!repeatIntakeScheduled) {
      return {
        repeatIntake: true,
        shouldScheduleRepeatIntake: true,
      };
    } else {
      return {
        repeatIntake: true,
        shouldScheduleRepeatIntake: false,
      };
    }
  }
  return {
    repeatIntake: false,
    shouldScheduleRepeatIntake: false,
  };
};

export default checkRepeatIntake;
