import styled from 'styled-components';

export const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.other.white};
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  padding: 16px;
  border-radius: 8px;
  height: 78px;
  cursor: pointer;
`;

export const ItemTitle = styled.div`
  display: flex;
  gap: 10px;
`;
