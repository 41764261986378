import { useQuery } from '@apollo/client';
import { FORM_DATA, FORM_ID } from 'api/consents/consents.query';
import { ConsentModal } from 'pages/onboarding/ConsentStep/ConsentModal/ConsentModal';
import { useConsentModals } from 'pages/onboarding/ConsentStep/useConsentModals';
import React from 'react';

import { Spinner } from 'components/ui/Spinner/Spinner';
import { CenteredSpinner } from 'components/ui/Spinner/Spinner.styles';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from '../General.styles';

export const SingleConsent: React.FC<{ onboardItemId: string }> = ({ onboardItemId }) => {
  const { currentModal, openModal, closeModal } = useConsentModals();

  const formId = useQuery(FORM_ID, { variables: { id: onboardItemId } }).data?.onboardingItem?.item_id;
  const formData = useQuery(FORM_DATA, { variables: { id: formId }, skip: !formId }).data?.customModuleForm
    ?.custom_modules;
  const content = formData?.[0];

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      openModal(content?.label as string, content?.options as string);
    }
  };

  return (
    <>
      {content ? (
        <Styled.Section
          onClick={() => openModal(content?.label as string, content?.options as string)}
          tabIndex={0}
          onKeyDown={(e) => onKeyDown(e)}
        >
          <Typography weight={600} color="blue.600">
            {content?.label}
          </Typography>
        </Styled.Section>
      ) : (
        <Styled.Section>
          <CenteredSpinner>
            <Spinner size={30} />
          </CenteredSpinner>
        </Styled.Section>
      )}
      <ConsentModal
        visible={!!currentModal}
        onClose={closeModal}
        headline={currentModal?.headline}
        content={currentModal?.content}
      />
    </>
  );
};
