/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import { GET_APPOINTMENTS } from 'api/appointment/appointment.query';
import checkRepeatIntake from 'api/appointment/checkRepeatIntake';
import { INTAKE_FLOW, ONBOARDING_ITEM_IDS } from 'api/consents/consents.query';
import { GET_USER_LOCATION } from 'api/user/user.query';
import { env } from 'index';
import { pathToRegexp } from 'path-to-regexp';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { useCurrentToken } from './useCurrentToken';

interface OnboardingFlowStatus {
  isCompleted: boolean;
  checkingInProgress: boolean;
}

export const setFirstAppointmentLater = (): void => localStorage.setItem('setFirstAppointmentLater', 'true');

export const useOnboardingFlow = (): OnboardingFlowStatus => {
  const { getToken } = useCurrentToken();
  const navigate = useNavigate();

  const currentPath = location.pathname;

  const [checkingInProgress, setCheckingInProgress] = useState(true);
  const [checkLocation, setCheckLocation] = useState(true);
  const [checkConsents, setCheckConsents] = useState(true);
  const [checkAppointments, setCheckAppointments] = useState(true);

  const [getLocation] = useLazyQuery(GET_USER_LOCATION, {
    fetchPolicy: 'network-only',
  });

  const [getAppointments] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: 'network-only',
  });

  const [getUserId] = useLazyQuery(INTAKE_FLOW);
  const [getOnboardingItems] = useLazyQuery(ONBOARDING_ITEM_IDS);

  const availableLocationPaths = [pathToRegexp(paths.mailingAddress)];

  const availableConsentPaths = [pathToRegexp(paths.consents), pathToRegexp(paths.consentsAccepted)];

  const availableAppointmentPaths = [
    pathToRegexp(paths.setupFirsAppointment),
    pathToRegexp(
      generatePath(paths.scheduleAppointmentWithSpecialist, {
        specialistType: 'coach',
        appointmentTypeId: env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
      }),
    ),
    pathToRegexp(paths.appointmentActionConfirmation),
  ];

  const redirectWhenOnboardingIsDone = () => {
    if (!checkLocation) {
      for (const path of availableLocationPaths) {
        if (path.test(currentPath)) navigate(paths.homePage, { state: { onboarding: true } });
      }
    }

    if (!checkConsents) {
      for (const path of availableConsentPaths) {
        if (path.test(currentPath)) navigate(paths.homePage, { state: { onboarding: true } });
      }
    }

    if (!checkAppointments) {
      for (const path of availableAppointmentPaths) {
        if (path.test(currentPath)) navigate(paths.homePage, { state: { onboarding: true } });
      }
    }
  };

  const fetchUserLocation = async () => {
    if (checkLocation) {
      const locationResponse = await getLocation();
      const location = locationResponse.data?.currentUser?.location;

      if (location) {
        setCheckLocation(false);
        await fetchUserConsents();
      }

      if (location === null) {
        navigate(paths.mailingAddress, { state: { onboarding: true } });
      }
    } else {
      await fetchUserConsents();
    }
  };

  const fetchUserConsents = async () => {
    const userId = (await getUserId()).data?.currentUser?.id;
    if (checkConsents) {
      const onboardingResponse = (
        await getOnboardingItems({
          variables: { user_id: userId },
          fetchPolicy: 'network-only',
        })
      ).data?.onboardingFlow;

      const onboardingName = onboardingResponse?.name?.toLowerCase();
      const onboardingItems = onboardingResponse?.onboarding_items;

      if (pathToRegexp(paths.consentsAccepted).test(currentPath)) return;

      if (onboardingName === 'consents' && onboardingItems?.some((item) => item.completed_onboarding_item === null)) {
        navigate(paths.consents, { state: { onboarding: true } });
      } else {
        setCheckConsents(false);
        await fetchUserAppointments(userId);
      }
    } else {
      await fetchUserAppointments(userId);
    }
  };

  const fetchUserAppointments = async (userId?: string) => {
    const isSetFirstAppointmentLaterEqualTrue = localStorage.getItem('setFirstAppointmentLater') === 'true';
    if (checkAppointments && !isSetFirstAppointmentLaterEqualTrue) {
      const appointmentsPastResponse = await getAppointments({
        variables: {
          filter: 'past',
        },
      });
      const appointmentsFutureResponse = await getAppointments({
        variables: {
          filter: 'future',
        },
      });
      const intakeAppointment = appointmentsFutureResponse.data?.appointments?.find(
        (item) => item.appointment_type?.id === env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
      );
      const appointmentsFuture = appointmentsFutureResponse.data?.appointments;
      const appointmentsPast = appointmentsPastResponse.data?.appointments;
      const { shouldScheduleRepeatIntake } = userId
        ? await checkRepeatIntake(userId)
        : { shouldScheduleRepeatIntake: false };

      if (
        (!shouldScheduleRepeatIntake || intakeAppointment) &&
        (appointmentsFuture?.length || appointmentsPast?.length)
      ) {
        setCheckAppointments(false);
      }

      if (
        (shouldScheduleRepeatIntake && !intakeAppointment) ||
        (appointmentsPast?.length === 0 && appointmentsFuture?.length === 0)
      ) {
        if (
          availableAppointmentPaths.every((path: RegExp) => !path.test(currentPath)) &&
          !isSetFirstAppointmentLaterEqualTrue
        ) {
          navigate(paths.setupFirsAppointment, { state: { onboarding: true, repeat: shouldScheduleRepeatIntake } });
        }
      }
    }
  };

  const checkData = async () => {
    if (!getToken()) {
      navigate(paths.homePage, { state: { onboarding: true } });
    }

    setCheckingInProgress(true);
    await fetchUserLocation();
    setCheckingInProgress(false);
  };

  useEffect(() => {
    checkData();
  }, [currentPath]);

  useEffect(redirectWhenOnboardingIsDone, [checkLocation, checkConsents, checkAppointments]);

  return {
    checkingInProgress,
    isCompleted: !checkLocation && !checkConsents && !checkAppointments,
  };
};
