import { ReactComponent as AngleSmallLeft } from 'assets/vectors/fi-rr-angle-small-left.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './BloodPressureManual.styles';
import { BloodPressureManualIntro } from './BloodPressureManualIntro';
import { Footer } from './components/Footer/Footer';
import { Step1 } from './components/Steps/Step1';
import { Step2 } from './components/Steps/Step2';
import { Step3 } from './components/Steps/Step3';
import { Step4 } from './components/Steps/Step4';
import { Step5 } from './components/Steps/Step5';
import { Step6 } from './components/Steps/Step6';
import { Step7 } from './components/Steps/Step7';
import { Step8 } from './components/Steps/Step8';

const TOTAL_STEPS = 8;

const STEPS = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5 />, <Step6 />, <Step7 />, <Step8 />];

interface BloodPressureManualProps {
  firstTimeEnter?: boolean;
}
export interface Params {
  goalId: string;
}

export const BloodPressureManual: React.FC<BloodPressureManualProps> = ({ firstTimeEnter = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressureManual' });
  const navigate = useNavigate();
  const { goalId } = useParams<keyof Params>() as Params;

  const [currentStep, setCurrentStep] = useState(firstTimeEnter ? 0 : 1);

  const markManualAsDone = () => {
    localStorage.setItem('measurementManualDone', 'true');
  };

  const handleSkip = () => {
    markManualAsDone();
    navigate(paths.recordYourBloodPressureSync.replace(':goalId', goalId));
  };

  useEffect(() => {
    if (currentStep === TOTAL_STEPS) {
      markManualAsDone();
    }
  }, [currentStep]);

  const renderStep = (step: number) => {
    return STEPS[step - 1];
  };

  if (currentStep === 0) {
    return <BloodPressureManualIntro handleViewSteps={() => setCurrentStep(1)} handleSkip={handleSkip} />;
  }
  return (
    <Styled.BloodPressureManualContainer>
      <Styled.StepperContainer>
        <Icon
          element={AngleSmallLeft}
          onClick={() => {
            if (currentStep > 1) setCurrentStep(currentStep - 1);
            else {
              navigate(-1);
            }
          }}
        />
        <Typography size={14}>
          {t('stepNumber', {
            currentStep: currentStep,
            TOTAL_STEPS: TOTAL_STEPS,
          })}
        </Typography>
        <Link to={`${paths.settings.main}/${paths.settings.getHelp}`} style={{ textDecoration: 'none' }}>
          <Typography weight={600} color="blue.600">
            {t('help')}
          </Typography>
        </Link>
      </Styled.StepperContainer>
      <Styled.ContentContainer>{renderStep(currentStep)}</Styled.ContentContainer>
      <Styled.FooterContainerWithHorizontalPadding>
        <Footer
          handleSkip={handleSkip}
          currentStep={currentStep}
          firstTimeEnter={firstTimeEnter}
          setCurrentStep={(step) => setCurrentStep(step)}
          totalSteps={TOTAL_STEPS}
        />
      </Styled.FooterContainerWithHorizontalPadding>
    </Styled.BloodPressureManualContainer>
  );
};
