import { useCompleteActivityGoal } from 'hooks/useCompleteActivityGoal';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Mixpanel } from 'utils/mixpanel';

import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import { getGoalType } from './components/Card/TaskCard';
import { Cards } from './components/Cards/Cards';
import { HomeLayout } from './components/HomeLayout/HomeLayout';
import { InstallPwaModal } from './components/InstallPwaModal/InstallPwaModal';
import { Note } from './components/Note/Note';
import { useAssesmentGoals } from './hooks/useAssesmentGoals';
import { useCarePlanGoals } from './hooks/useCarePlan';
import { useEndOfProgramGoals } from './hooks/useEndOfProgramGoals';
import { useGoalsCompletion } from './hooks/useGoals';

export const Home: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home' });
  const [isModalOpen, setModalOpen] = useState(false);
  const { hasLoggedInBefore } = useCurrentFitbitTokens();

  // Setting this to true to avoid useEffect "delay"
  const [isOpenedInPwa, setIsOpenedInPwa] = useState(true);
  const { state } = useLocation();

  const { dailyGoals: dalies, weeklyGoals: weekly, refetch, loading: carePlanGoalsLoading } = useCarePlanGoals();
  const assessmentGoal = useAssesmentGoals();

  const endOfProgramGoals = useEndOfProgramGoals();
  const weeklyGoals = assessmentGoal
    ? [...weekly, assessmentGoal, ...endOfProgramGoals]
    : [...weekly, ...endOfProgramGoals];

  const dailyGoals = dalies.filter((goal) => {
    if (!hasLoggedInBefore) {
      return getGoalType(goal) !== 'moving';
    }
    return true;
  });

  // Marking goal as completed logic
  useGoalsCompletion();
  const { stepsGoal, currentSteps, percentage, goalLoading } = useCompleteActivityGoal();

  useEffect(() => {
    setIsOpenedInPwa(window.matchMedia('(display-mode: standalone)').matches);
    refetch();
  }, [refetch]);

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Home' });
    if (!localStorage.getItem('installedPwa')) {
      setModalOpen(true);
    }
  }, []);

  const loading = carePlanGoalsLoading || goalLoading;

  if (loading) return <LoadingPage withLogo={state?.onboarding} />;

  const shouldDisplayPwaModal = (isIOS || isAndroid) && !isOpenedInPwa;

  return (
    <HomeLayout>
      <Note />
      <Section>
        <Cards variant="appointments" />
      </Section>
      {!isEmpty(dailyGoals) && (
        <Section>
          <Typography variant="SubHeadline1">{t('today')}</Typography>
          <Cards variant="goals" goals={dailyGoals} activity={{ stepsGoal, currentSteps, percentage }} />
        </Section>
      )}
      {!isEmpty(weeklyGoals) && (
        <Section>
          <Typography variant="SubHeadline1">{t('week')}</Typography>
          <Cards variant="goals" goals={weeklyGoals} />
        </Section>
      )}
      {shouldDisplayPwaModal && (
        <InstallPwaModal
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            localStorage.setItem('installedPwa', 'true');
          }}
        />
      )}
    </HomeLayout>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AppointmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
