import styled, { css } from 'styled-components';

export const CenteredSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderContainer = styled.div<{ size: number; color: string }>`
  svg {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    transform-origin: center;
    animation: spin 0.9s infinite linear;

    path {
      ${({ color }) =>
        color
          ? css`
              fill: ${color};
            `
          : ''};
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
