import { theme } from 'assets/styles/theme';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { FlattenObjectKeys } from 'models/utils.model';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '../Typography/Typography';
import * as Styled from './CircularProgressBar.styles';

export type strokeColor = FlattenObjectKeys<typeof theme.color>;

export interface ProgressBarProps {
  size?: number;
  progress?: number;
  strokeWidth?: number;
  trackColor?: strokeColor;
  indicatorColor?: strokeColor;
  children?: React.ReactNode;
  title?: string;
  description?: string;
  startGradientColor?: strokeColor;
  endGradientColor?: strokeColor;
  showCompletionText?: boolean;
  label?: string;
  footerLabel?: string | React.ReactNode;
  ariaLabel?: string;
}

export const CircularProgressBar: React.FC<ProgressBarProps> = ({
  size = 48,
  progress,
  strokeWidth = 4,
  trackColor = 'black.50',
  indicatorColor = 'blue.400',
  children,
  title,
  description,
  startGradientColor,
  endGradientColor,
  showCompletionText,
  label,
  footerLabel,
  ariaLabel,
}: ProgressBarProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.circularProgressBar' });

  const isGradientColor = startGradientColor && endGradientColor;

  const center = size / 2;
  // Circle radius
  const radius = center - strokeWidth;
  // Initial length of the line - the circumference of the circle
  const dashArray = 2 * Math.PI * radius;
  // Calculate length of the gap that will cut line to the required length of the progress
  const dashOffset = !isNil(progress) ? dashArray * ((100 - (progress > 100 ? 100 : progress)) / 100) : dashArray;

  const trackStrokeColor = get(theme.color, trackColor);
  const indicatorStrokeColor = get(theme.color, indicatorColor);
  const startGradientStrokeColor = startGradientColor && get(theme.color, startGradientColor);
  const endGradientStrokeColor = endGradientColor && get(theme.color, endGradientColor);

  return (
    <>
      <Styled.CircuralProgressWrapper style={{ width: size, height: size }}>
        <Styled.CircuralProgress
          style={{ width: size, height: size }}
          aria-label={ariaLabel || undefined}
          aria-labelledby={ariaLabel ? undefined : 'circularProgressTitle circularProgressDescription'}
          role="img"
        >
          {title && <title id="circularProgressTitle">{title}</title>}
          {description && <desc id="circularProgressDescription">{description}</desc>}
          {isGradientColor && (
            <defs>
              <linearGradient id="gradient" x1="80%" x2={0} y2={0} y1={0}>
                <stop offset="0%" stopColor={startGradientStrokeColor} />
                <stop offset="100%" stopColor={endGradientStrokeColor} />
              </linearGradient>
            </defs>
          )}
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackStrokeColor}
            strokeWidth={strokeWidth}
          />
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={isGradientColor ? 'url(#gradient)' : indicatorStrokeColor}
            strokeWidth={strokeWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={'round'}
          />
        </Styled.CircuralProgress>
        {(children || showCompletionText) && (
          <Styled.Content>
            {children}
            {showCompletionText && (
              <Styled.CompletedPercentageContent>
                <Typography.Span variant="Headline1" color="black.800">
                  {label || `${progress}%`}
                </Typography.Span>
                <Typography variant="Helper1" color="black.600">
                  {footerLabel || t('complete')}
                </Typography>
              </Styled.CompletedPercentageContent>
            )}
          </Styled.Content>
        )}
      </Styled.CircuralProgressWrapper>
    </>
  );
};

export default CircularProgressBar;
