/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client';
import { GET_ACTIVITY_DATA } from 'api/fitbit/fitbit.query';
import { GET_COURSE_MEMBERSHIP } from 'api/questionnaires/questionnaires.query';
import { theme } from 'assets/styles/theme';
import { ReactComponent as InfoIcon } from 'assets/vectors/fi-rr-exclamation.svg';
import { useCompleteActivityGoal } from 'hooks/useCompleteActivityGoal';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Diagram } from 'components/ui/Diagram/Diagram';
import { Icon } from 'components/ui/Icon/Icon';
import Modal from 'components/ui/Modal/Modal';
import { TimeTabs, TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitch, TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';
import { Typography } from 'components/ui/Typography/Typography';

import { mapIndexToTabName, mapTabNameToIndex } from '../utils';
import {
  DiagramContainer,
  ModalButton,
  ModalContentContainer,
  ModalText,
  ModalTitle,
  Section,
  TimeSwitchContainer,
  ViewReadingsButton,
} from './Activity.styles';
import ActivityGoalSection from './components/ActivityGoalSection';
import HeartRateSection from './components/ActivityHeartRate';
import { ActivityLoading } from './components/ActivityLoading';
import StepsSection from './components/StepsSection';
import {
  getAverage,
  getDates,
  getEntriesTimeRange,
  getIntervalForTab,
  getStepsByDate,
  getTimeRange,
  isThisWeekCompleted,
} from './timeUtils';

export const Activity: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.activity' });

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');

  const { loadingFitbitInfo } = useCurrentFitbitTokens();
  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState<TimeSwitchType>('this');
  const [timeInterval, setTimeInterval] = useState(getTimeRange('thisWeek'));
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const handleShowInfoModal = () => setInfoModalVisible(true);

  const { stepsGoal, goalLoading, heartRateGoal, currentSteps, isFirstWeek } = useCompleteActivityGoal();

  useEffect(() => {
    const tabIndex = mapTabNameToIndex(tabParam);
    setCurrentTab(tabIndex);
  }, [tabParam]);

  const onTabChange = (tab: number) => {
    setCurrentTab(tab);
    setCurrentSubTab('this');

    const tabName = mapIndexToTabName(tab);
    navigate(`${paths.activity}?tab=${tabName}`, { replace: true });
  };

  const { data, loading: activityLoading } = useQuery(GET_ACTIVITY_DATA, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: courseData } = useQuery(GET_COURSE_MEMBERSHIP);

  const courseStartDate = courseData?.currentUser?.course_memberships?.[0]?.start_at;

  const entries = data?.entries || [];
  const hasEntries = !isEmpty(entries);
  const chartFromDate = timeInterval?.startRange || getEntriesTimeRange(entries, courseStartDate).startRange;
  const chartToDate = timeInterval?.endRange || getEntriesTimeRange(entries).endRange;

  const chartLabels = hasEntries ? getDates(chartFromDate, chartToDate, 'M/dd') : [];
  const chartData = hasEntries ? getStepsByDate(entries, getDates(chartFromDate, chartToDate, 'yyyy-MM-dd')) : [];

  const average = getAverage(chartData);
  const goalAveragePercentage = stepsGoal
    ? average < stepsGoal
      ? round((average * 100) / stepsGoal)
      : 100
    : undefined;

  const shouldHideAverage = !isThisWeekCompleted(entries) && isFirstWeek;

  const setChartInterval = () =>
    setTimeInterval(getIntervalForTab(currentTab, currentSubTab, entries, courseStartDate));

  useEffect(() => {
    setChartInterval();
  }, [currentTab, currentSubTab]);

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Progress - Activity' });
  }, []);

  const navigate = useNavigate();

  const shouldShowLoading = activityLoading || goalLoading || loadingFitbitInfo;

  if (shouldShowLoading) return <ActivityLoading />;

  return (
    <BaseLayout headerProps={{ title: t('title'), variant: 'back', onBackClick: () => navigate(-1) }} footer={null}>
      <Modal isOpen={infoModalVisible} onRequestClose={() => setInfoModalVisible(false)} contentLabel={t('modalLabel')}>
        <ModalContentContainer>
          <ModalTitle variant="Headline2" color="black.800">
            {t('modalTitle')}
          </ModalTitle>
          <Icon element={InfoIcon} size={108} color="black.600" />
          <ModalText variant="Body1" color="black.800">
            {t('modalInfo')}
          </ModalText>
          <ModalButton onClick={() => setInfoModalVisible(false)} size="lg">
            {t('closeButton')}
          </ModalButton>
        </ModalContentContainer>
      </Modal>
      <TimeTabs selectedTab={currentTab} onTabChange={onTabChange} />
      {currentTab !== TimeTabsIndex.all_time && (
        <TimeSwitchContainer>
          <TimeSwitch
            time={currentTab === TimeTabsIndex.week ? 'week' : 'month'}
            onSelect={(value: TimeSwitchType) => setCurrentSubTab(value)}
            selected={currentSubTab}
          />
        </TimeSwitchContainer>
      )}
      {chartData && (
        <DiagramContainer>
          <Diagram
            data={average ? chartData : []}
            labels={chartLabels}
            ariaLabel={t('activityChartLabel')}
            height="196px"
            unit={t('chartStepsLabel')}
            dataLegendLabel={capitalize(t('chartStepsLabel'))}
            lineColor={theme.color.other.greenDark}
            showTendencyOnly={currentTab !== TimeTabsIndex.week}
          />
        </DiagramContainer>
      )}
      <Section>
        <Typography.H1 variant="SubHeadline1Medium">{t('activityStepsSectionTitle')}</Typography.H1>
        <StepsSection
          currentSteps={currentSteps}
          average={average}
          shouldShowAverage={!shouldHideAverage}
          onShowInfo={handleShowInfoModal}
        />
        <ActivityGoalSection
          stepsGoal={stepsGoal}
          percentage={goalAveragePercentage}
          shouldShowPercentage={!shouldHideAverage}
          onShowInfo={handleShowInfoModal}
        />
        {!isNil(heartRateGoal) && <HeartRateSection heartRate={heartRateGoal} />}
      </Section>
      <Section>
        <Typography.H1 variant="SubHeadline1Medium">{t('reportsSectionTitle')}</Typography.H1>
        <ViewReadingsButton
          id="saveChanges"
          onClick={() => {
            if (tabParam) {
              navigate(`${paths.activityReadings}?tab=${tabParam}`);
              return;
            }
            navigate(paths.activityReadings);
          }}
        >
          <Typography.Span variant="Body1Bold" color="blue.600">
            {t('viewReadingsButton')}
          </Typography.Span>
        </ViewReadingsButton>
      </Section>
    </BaseLayout>
  );
};
