import { gql } from '__generated__/gql';

export const CREATE_FORM_ANSWER_GROUP = gql(`
  mutation createUserFormAnswerGroup($input: createFormAnswerGroupInput) {
    createFormAnswerGroup(input: $input) {
      form_answer_group {
        id
        name
        form_answers {
          id
          label
          answer
          custom_module {
            id
          }
        }
      }
    }
  }
`);

export const UPDATE_FORM_ANSWER_GROUP = gql(`
    mutation updateUserFormAnswerGroup($input: updateFormAnswerGroupInput) {
      updateFormAnswerGroup(input: $input) {
        form_answer_group {
          id
          name
        }
      }
  }
`);
