import { useQuery } from '@apollo/client';
import { GET_SINGLE_APPOINTMENT } from 'api/appointment/appointment.query';
import { subMinutes } from 'date-fns';
import { AppointmentsList } from 'pages/appointments/components/AppointmentsList/AppointmentsList';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { parseHealthieDate } from 'utils/helpers';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import { PageContentContainer } from '../appointments/Appointments.styles';
import { CancelModal } from './components/CancelModal/CancelModal';
import { ErrorModal } from './components/ErrorModal/ErrorModal';

export const Appointment: React.FC = () => {
  const { appointmentId } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.appointments' });
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_SINGLE_APPOINTMENT, {
    variables: {
      id: appointmentId,
    },
  });

  const canReschedule = useMemo(() => {
    if (data?.appointment?.start && data.appointment.minimum_advance_reschedule_time) {
      return (
        subMinutes(parseHealthieDate(data?.appointment?.start), data?.appointment?.minimum_advance_reschedule_time) >
        new Date()
      );
    }
    // If 0 set then unlimited time = reschedule anytime
    return true;
  }, [data]);

  const canCancel = useMemo(() => {
    if (data?.appointment?.start && data.appointment.minimum_advance_cancel_time) {
      return (
        subMinutes(parseHealthieDate(data?.appointment?.start), data?.appointment?.minimum_advance_cancel_time) >
        new Date()
      );
    }
    // If 0 set then unlimited time = cancel anytime
    return true;
  }, [data]);

  const onBackClick = () => {
    navigate(paths.homePage);
  };

  const handleReschedule = () => {
    if (appointmentId) {
      if (canReschedule) {
        navigate(paths.rescheduleAppointment.replace(':appointmentId', appointmentId));
      } else {
        setIsErrorModalOpen(true);
      }
    }
  };

  const handleCancel = () => {
    if (canCancel) {
      setIsCancelModalOpen(true);
    } else {
      setIsErrorModalOpen(true);
    }
  };

  const appointments = [
    {
      title: data?.appointment?.appointment_type?.name,
      id: data?.appointment?.id,
      end: data?.appointment?.end,
      start: data?.appointment?.start,
      //Healthie has different type (once string, once number) - converting to string
      appointmentTypeId: `${data?.appointment?.appointment_type_id}`,
      isDetailsPage: true,
    },
  ];

  if (loading) return <LoadingPage />;

  return (
    <>
      <CancelModal
        appointmentId={appointmentId}
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
      />
      <ErrorModal onClose={() => setIsErrorModalOpen(false)} isOpen={isErrorModalOpen} />
      <BaseLayout
        footer={null}
        headerProps={{
          variant: 'back',
          title: t('editHeader'),
          onBackClick,
        }}
      >
        <PageContentContainer>
          <div style={{ flex: 1 }}>
            <AppointmentsList items={appointments} />
          </div>

          <Button onClick={handleReschedule}>{t('reschedule')}</Button>
          <Button variant="link" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </PageContentContainer>
      </BaseLayout>
    </>
  );
};
