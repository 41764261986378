import { ReactComponent as Tick } from 'assets/vectors/fi-rr-cross-done.svg';
import { ReactComponent as Cross } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as EyeOpen } from 'assets/vectors/fi-rr-eye-1.svg';
import { ReactComponent as EyeClosed } from 'assets/vectors/fi-rr-eye.svg';
import { Field, FieldProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { onEnterKey } from 'utils/helpers';

import { Icon } from 'components/ui/Icon/Icon';

import * as Styled from './Input.styles';

type FieldType = 'text' | 'password' | 'number' | 'date' | 'textarea';

type InputProps = Omit<React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>, 'label' | 'ref' | 'as' | 'type'> & {
  name: string;
  label?: string;
  helperText?: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  type?: FieldType;
  ariaLabel?: string;
};

export const Input: React.FC<InputProps> = ({
  name,
  label,
  helperText,
  disabled,
  type = 'text',
  hideErrorMessage = false,
  ariaLabel = label,
  ...rest
}) => {
  const [inputType, setInputType] = useState(type);
  const [textAreaHeight, setTextAreaHeight] = useState<number | undefined>(26);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const toggleType = () => {
    setInputType(inputType === 'text' ? 'password' : 'text');
  };

  useEffect(() => {
    setTextAreaHeight(textRef.current?.scrollHeight);
  }, [textRef.current?.scrollHeight]);

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const hasErrors = form.touched[name] && form.errors[name] != null;
        const isValid = form.touched[name] && !hasErrors && field.value;
        return (
          <Styled.Container>
            <Styled.InputContainer $disabled={disabled} $error={hasErrors} $valid={isValid}>
              {type === 'textarea' ? (
                <>
                  <Styled.TextArea
                    placeholder=" "
                    {...field}
                    disabled={disabled}
                    aria-label={ariaLabel}
                    {...rest}
                    ref={textRef}
                    $height={textAreaHeight}
                  />
                  <Styled.TextAreaLabel id={`${name}-input-label`} htmlFor={name}>
                    {label}
                  </Styled.TextAreaLabel>
                </>
              ) : (
                <>
                  <Styled.Input
                    placeholder=" "
                    {...field}
                    disabled={disabled}
                    aria-label={ariaLabel}
                    type={type === 'password' ? inputType : type}
                    {...rest}
                  />
                  <Styled.InputLabel id={`${name}-input-label`} htmlFor={name}>
                    {label}
                  </Styled.InputLabel>
                </>
              )}

              {type === 'password' && (
                <Icon
                  onClick={toggleType}
                  onKeyDown={(e) => onEnterKey(e, toggleType)}
                  element={inputType === 'password' ? EyeClosed : EyeOpen}
                  color="black.800"
                  tabIndex={0}
                />
              )}
              {isValid && <Icon element={Tick} size={18} color="other.green" />}
            </Styled.InputContainer>
            {helperText && !hasErrors && (
              <Styled.HelperText variant="Helper1" color={disabled ? 'black.300' : 'black.600'}>
                {helperText}
              </Styled.HelperText>
            )}
            {hasErrors && !hideErrorMessage && (
              <Styled.ErrorMessage variant="Helper1" color="other.red">
                <Icon element={Cross} size={16} color="other.red" />
                <Trans>{form.errors[name]}</Trans>
              </Styled.ErrorMessage>
            )}
          </Styled.Container>
        );
      }}
    </Field>
  );
};
