import { ReactComponent as Add } from 'assets/vectors/fi-rr-add.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Header } from 'components/layout/BaseLayout/Header';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import {
  AppointmentsSubHeadline,
  Center,
  IconContainer,
  LoadMoreButton,
  PageContentContainer,
  ScheduleButton,
} from './Appointments.styles';
import { AppointmentsList } from './components/AppointmentsList/AppointmentsList';
import { SingleAppointment } from './components/AppointmentsList/types';
import { useAppointmentsData } from './hooks';

const VISIBLE_APPOINTMENTS = 3;

export const Appointments: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.appointments' });
  const navigate = useNavigate();
  const [visibleUpcomingAppointments, setVisibleUpcomingAppointments] = useState<SingleAppointment[]>([]);
  const [visiblePastAppointments, setVisiblePastAppointments] = useState<SingleAppointment[]>([]);

  const { pastAppointments, pastAppointmentsLoading, upcomingAppointments, upcomingAppointmentsLoading } =
    useAppointmentsData();

  const handleScheduleClick = () => {
    navigate(paths.scheduleAppointment);
  };

  const onBackClick = () => {
    navigate(paths.homePage);
  };

  useEffect(() => {
    if (upcomingAppointments) {
      setVisibleUpcomingAppointments(upcomingAppointments.slice(0, VISIBLE_APPOINTMENTS));
    }
    if (pastAppointments) {
      setVisiblePastAppointments(pastAppointments.slice(0, VISIBLE_APPOINTMENTS));
    }
  }, [upcomingAppointments, pastAppointments]);

  const loadMoreUpcomingAppointments =
    upcomingAppointments.length > 0 && visibleUpcomingAppointments.length !== upcomingAppointments.length;
  const loadMorePastAppointments =
    pastAppointments.length > 0 && visiblePastAppointments.length !== pastAppointments.length;

  if (pastAppointmentsLoading || upcomingAppointmentsLoading) return <LoadingPage />;

  return (
    <BaseLayout
      footer={null}
      header={
        <Header onBackClick={onBackClick} variant="back">
          <Typography variant="Headline1">{t('header')}</Typography>
        </Header>
      }
    >
      <PageContentContainer>
        <ScheduleButton onClick={handleScheduleClick}>
          <IconContainer>
            <Icon color="other.white" element={Add} style={{ marginRight: 16 }} />
            {t('scheduleAppointment')}
          </IconContainer>
        </ScheduleButton>
        <div>
          <AppointmentsSubHeadline variant="SubHeadline1">{t('upcoming')}</AppointmentsSubHeadline>
          <AppointmentsList items={visibleUpcomingAppointments} />
          {loadMoreUpcomingAppointments && (
            <Center>
              <LoadMoreButton variant="link" onClick={() => setVisibleUpcomingAppointments(upcomingAppointments)}>
                {t('loadMore')}
              </LoadMoreButton>
            </Center>
          )}
        </div>
        <div>
          <AppointmentsSubHeadline variant="SubHeadline1">{t('past')}</AppointmentsSubHeadline>
          <AppointmentsList items={visiblePastAppointments} isPast />
          {loadMorePastAppointments && (
            <Center>
              <LoadMoreButton
                style={{ marginBottom: 20 }}
                variant="link"
                onClick={() => setVisiblePastAppointments(pastAppointments)}
              >
                {t('loadMore')}
              </LoadMoreButton>
            </Center>
          )}
        </div>
      </PageContentContainer>
    </BaseLayout>
  );
};
