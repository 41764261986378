import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  flex: 1;
`;

export const ButtonContainer = styled.div`
  margin: 16px;
`;
