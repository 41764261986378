import { gql } from '__generated__/gql';

export const GET_USER_MOTIVATION_ENTRY = gql(`
  query motivationEntry(
    $type: String,
    $category: String,
    $client_id: String,
  ) {
    entries(
      type: $type,
      category: $category,
      client_id: $client_id,
    ) {
      id
      type
      added_by_user {
        id
      }
      category
      emotions
      name
      description
      reflection
    }
  }
`);
