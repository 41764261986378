import styled from 'styled-components';

import { ArticleHeader } from 'components/ui/ArticleHeader/ArticleHeader';

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 16px 16px 0 16px;
`;

export const Line = styled.div`
  margin: 20px 0 20px 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ExerciseProgramWrapper = styled.div`
  width: 100%;
  padding: 20px 0 20px 0;
  border-top: 1px solid ${(props) => props.theme.color.black[50]};
`;

export const ExerciseItems = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
`;

export const ContentHeader = styled(ArticleHeader)`
  margin: 0px 20px;
`;

export const MarkAsCompletedWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.other.linen};
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 18px;
  width: 100%;
`;

export const MarkAsCompletedArea = styled.div`
  padding-bottom: 90px;
`;

export const ContactWithSupport = styled.div`
  padding: 20px;
`;
