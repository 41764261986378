import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, SwitchButton, SwitchText } from './TimeSwitch.styles';

export type TimeSwitchType = 'this' | 'last';

export interface TimeSwitchProps {
  onSelect?: (value: TimeSwitchType) => void;
  time?: 'week' | 'month';
  selected?: TimeSwitchType;
}

export const TimeSwitch: React.FC<TimeSwitchProps> = ({
  onSelect,
  time = 'week',
  selected = 'this',
}: TimeSwitchProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.timeSwitch' });

  const handleSelect = (value: TimeSwitchType) => () => {
    if (onSelect) {
      onSelect(value);
    }
  };

  return (
    <Container>
      <SwitchButton onClick={handleSelect('last')} selected={selected === 'last'}>
        <SwitchText>{t('previous')}</SwitchText>
      </SwitchButton>
      <SwitchButton onClick={handleSelect('this')} selected={selected === 'this'}>
        <SwitchText>{time === 'week' ? t('thisWeek') : t('thisMonth')}</SwitchText>
      </SwitchButton>
    </Container>
  );
};
