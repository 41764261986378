import { ReactComponent as CallOutline } from 'assets/vectors/call-outline-2.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { Container, FooterContainer, Headline } from './ErrorLayout.styles';

interface ErrorSchedulingProps {
  headerTitle: string;
  headline: string;
  description: string;
  onClick: () => void;
  onBackClick?: () => void;
  buttonContent?: string;
  iconElement?: React.ComponentProps<typeof Icon>['element'];
}

export const ErrorScheduling: React.FC<ErrorSchedulingProps> = ({
  headerTitle,
  headline,
  description,
  onClick,
  onBackClick,
  buttonContent,
  iconElement,
}) => {
  const navigate = useNavigate();
  const defaultBackClick = () => {
    navigate(paths.homePage);
  };

  return (
    <BaseLayout
      footer={
        <>
          <FooterContainer>
            <Button onClick={onClick}>{buttonContent ? buttonContent : 'Back'}</Button>
          </FooterContainer>
        </>
      }
      headerProps={{
        title: headerTitle,
        variant: 'back',
        onBackClick: onBackClick || defaultBackClick,
      }}
    >
      <Container>
        <Headline>
          <Typography variant="Headline2">{headline}</Typography>
        </Headline>
        <Icon element={iconElement || CallOutline} title={'Call outline'} size={108} />
        <Typography variant="Body1">{description}</Typography>
      </Container>
    </BaseLayout>
  );
};
