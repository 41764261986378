import { useMutation } from '@apollo/client';
import { CANCEL_APPOINTMENT } from 'api/appointment/appointment.mutation';
import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as QuestionIcon } from 'assets/vectors/fi-rr-question.svg';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Maybe } from 'utils/types';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { PopupModal } from 'components/ui/PopupModal/PopupModal';

import * as Styled from './CancelModal.styles';

interface CancelModalProps {
  onClose: () => void;
  isOpen: boolean;
  appointmentId: Maybe<string>;
}

export const CancelModal: React.FC<CancelModalProps> = ({ onClose, isOpen, appointmentId }: CancelModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.appointments.cancelModal' });
  const [executeCancelMutation, { data, loading }] = useMutation(CANCEL_APPOINTMENT);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && data?.deleteAppointment?.messages === null && appointmentId) {
      navigate(
        paths.appointmentActionConfirmation
          .replace(':appointmentId', appointmentId)
          .replace(':actionType', 'canceled-completed'),
      );
    }
  }, [data, loading, appointmentId, navigate]);

  const cancelAppointment = () => {
    executeCancelMutation({
      variables: {
        id: appointmentId,
      },
    });
  };
  return (
    <PopupModal isOpen={isOpen} onRequestClose={onClose}>
      <Styled.Content>
        <Styled.SIcon element={CloseIcon} size={16} onClick={onClose} tabIndex={0} aria-label="Close modal" />
        <Styled.Descriptions>
          <Styled.Description variant="Headline2">{t('title')}</Styled.Description>
        </Styled.Descriptions>
        <Icon element={QuestionIcon} size={112} onClick={onClose} color="black.600" />
        <Styled.ButtonsContainer>
          <Button style={{ width: 'auto', backgroundColor: 'transparent' }} variant="secondary" onClick={onClose}>
            {t('no')}
          </Button>
          <Button style={{ width: 'auto', marginLeft: 5 }} variant="primary" onClick={cancelAppointment}>
            {t('yes')}
          </Button>
        </Styled.ButtonsContainer>
      </Styled.Content>
    </PopupModal>
  );
};
