import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Header } from 'components/layout/BaseLayout/Header';
import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

import { PageContentContainer } from './ScheduleAppointment.styles';
import { useGetAllAppointmentTypes, useGetAppointmentTypesToDisplay } from './hooks';

export const SelectAppointmentType: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.scheduleAppointment' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();
  const appointmentTypes = useGetAllAppointmentTypes();
  const appointmentsToDisplay = useGetAppointmentTypesToDisplay();

  const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState<string | null>(null);

  const handleSelectSpecialistType = (appointmentTypeId: string | null | undefined) => () => {
    if (appointmentTypeId) {
      setSelectedAppointmentTypeId(appointmentTypeId === selectedAppointmentTypeId ? null : appointmentTypeId);
    }
  };

  const handleNextStepClick = () => {
    const appointment = appointmentTypes.find((item) => item.id === selectedAppointmentTypeId);
    if (appointment) {
      navigate(
        generatePath(paths.scheduleAppointmentWithSpecialist, {
          appointmentTypeId: appointment.id,
          specialistType: appointment.type,
        }),
      );
    } else {
      alert(tCommon('somethingWentWrong'));
      navigate(paths.appointments);
    }
  };

  return (
    <BaseLayout
      footer={null}
      header={<Header onBackClick={() => navigate(paths.homePage)} variant="back" title={t('title')} />}
    >
      <PageContentContainer>
        <div style={{ flex: 1 }}>
          <div>
            <div style={{ marginBottom: 24 }}>
              <Typography variant="Headline1">{t('questionScheduling')}</Typography>
              <Typography variant="Helper1">{t('questionSchedulingTip')}</Typography>
            </div>
            <div>
              {appointmentsToDisplay.map((item) => {
                return (
                  <Button
                    onClick={handleSelectSpecialistType(item?.id)}
                    style={{ marginBottom: 16 }}
                    variant={selectedAppointmentTypeId === item?.id ? 'square-primary' : 'square-secondary'}
                    key={item?.id}
                  >
                    {item?.name}
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <Button disabled={!selectedAppointmentTypeId} onClick={handleNextStepClick}>
            {tCommon('next')}
          </Button>
        </div>
      </PageContentContainer>
    </BaseLayout>
  );
};
