import { env } from 'index';
import type React from 'react';

export const checkAccessibility = async (App: typeof React): Promise<void> => {
  if (env.NODE_ENV !== 'production') {
    const axe = await import('@axe-core/react');
    const ReactDOM = await import('react-dom');

    const config = {
      runOnly: ['wcag2a', 'wcag2aa', 'wcag21a', 'wcag21aa'],
    };

    axe.default(App, ReactDOM, 1000, config);
  }
};

export default checkAccessibility;
