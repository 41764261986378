import { Entry } from '__generated__/graphql';
import isNil from 'lodash/isNil';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseHealthieDate } from 'utils/helpers';

import ReadingsList, { Reading } from 'components/ui/ReadingsList/ReadingsList';
import { Typography } from 'components/ui/Typography/Typography';

import { BloodPressureMeasurements, BloodPressureReadingsListProps, ReadingsContentProps } from '../types';

const getMeasurement = (entry: Entry, measurementType: BloodPressureMeasurements) => {
  return entry?.subentries?.find((item) => item?.category?.toLowerCase().includes(measurementType))?.metric_stat;
};

const Content: React.FC<ReadingsContentProps> = ({ content, suffix }) => {
  return (
    <>
      <Typography.Span variant="Body1Bold" color="other.red">
        {content}
      </Typography.Span>{' '}
      <Typography.Span variant="Body1">{suffix}</Typography.Span>
    </>
  );
};

export const BloodPressureReadingsList: React.FC<BloodPressureReadingsListProps> = ({ entries }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure.readings' });

  const entriesWithDates = entries?.filter((entry) => !isNil(entry) && !isNil(entry?.updated_at));
  const readingsData: Reading[] = entriesWithDates.map((entry) => {
    const heartRateMeasurement = getMeasurement(entry, 'heart rate');
    return {
      id: entry?.id || '',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      createdAt: parseHealthieDate(entry!.updated_at!),
      leftContent: (
        <Content
          content={t('value', {
            systolic: getMeasurement(entry, 'systolic'),
            diastolic: getMeasurement(entry, 'diastolic'),
          })}
          suffix={t('mmHg')}
        />
      ),

      rightContent: heartRateMeasurement ? (
        <Content content={t('heartRateValue', { value: heartRateMeasurement })} suffix={t('bpm')} />
      ) : null,
    };
  });

  return <ReadingsList data={readingsData} leftLabel={t('activityLabel')} rightLabel={t('heartRate')} />;
};

export default BloodPressureReadingsList;
