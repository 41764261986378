import React from 'react';

import * as Styled from './Badge.styles';

type Props = {
  header: string;
  children?: React.ReactNode;
};

export const Badge: React.FC<Props> = ({ header, children }: Props) => {
  return (
    <Styled.Card>
      <Styled.Main variant="SubHeadline1">{header}</Styled.Main>
      {children}
    </Styled.Card>
  );
};
