import { useTranslation } from 'react-i18next';
import { Maybe } from 'utils/types';

interface useTitleInput {
  isLoading: boolean;
  repeat: Maybe<string>;
}

export const useTitle = ({ isLoading, repeat }: useTitleInput): string => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.titles' });
  const title = repeat === 'Daily' ? t('titleDaily') : t('titleWeekly');
  return isLoading ? '' : title;
};
