import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';

import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

import { SuccessButton } from '../ForgotPassword.styles';
import { MailSentTo } from './EmailSent.styles';

export const EmailSent: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.forgotPassword.emailSent' });
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const email = params.get('to');
  return (
    <TaskCompletionPage
      headerTitle={t('reset')}
      taskTitle={t('checkEmail')}
      taskDescription={t('linkDesc')}
      color="blue.700"
      footer={<SuccessButton onClick={() => navigate(paths.homePage)}>{t('goBackHome')}</SuccessButton>}
    >
      {email && (
        <MailSentTo variant="Helper1" color="other.white">
          {t('linkSentTo')}: <strong>{email}</strong>
        </MailSentTo>
      )}
    </TaskCompletionPage>
  );
};
