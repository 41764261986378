import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const IconContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 0;
`;

export const LoadMoreButton = styled(Button)`
  margin-top: 20px;
`;

export const Center = styled.div`
  justify-content: center;
  display: flex;
`;

export const AppointmentsSubHeadline = styled(Typography)`
  margin-bottom: 16px;
`;

export const ScheduleButton = styled(Button)`
  height: 48px;
  margin-top: 8px;
`;
