import React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components/ui/Spinner/Spinner';
import { Typography } from 'components/ui/Typography/Typography';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  loading?: boolean;
  label: string;
  labelProps?: React.ComponentProps<typeof Typography.Label>;
  onChange: () => void;
}

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(Typography.Label)`
  flex: 1;
  margin-right: 10px;
`;

export const CustomCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.color.black[900]};
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  &:after {
    background-color: ${({ theme }) => theme.color.other.green};
    content: '';
    position: absolute;
    left: 5px; /* Adjust left position to center the checkmark horizontally */
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: ${(props) => (props.checked ? 1 : 0)};
  }
  &:checked {
    background: ${({ theme }) => theme.color.other.green};
    border: none;
  }
  &:disabled {
    border-color: ${({ theme }) => theme.color.black[50]};
    ~ ${Label} {
      color: ${({ theme }) => theme.color.black[50]};
    }
  }
`;
const LeftLabelCheckbox: React.FC<CheckboxProps> = ({ label, loading, checked, onChange, labelProps, ...rest }) => {
  return (
    <CheckboxContainer>
      <Label weight={500} size={16} lineHeight={24} {...labelProps}>
        {label}
      </Label>
      {loading ? (
        <Spinner size={20} />
      ) : (
        <CustomCheckbox type="checkbox" checked={checked} onChange={onChange} {...rest} />
      )}
    </CheckboxContainer>
  );
};

export default LeftLabelCheckbox;
