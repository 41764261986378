import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.color.other.linen};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0px 24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 4px;
`;

export const CardDescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  margin: 16px;
`;

export const RadiosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  gap: 16px;
`;
