import { icons } from 'assets/vectors/iconsList';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { BoxVertical, HorizontalFlexContainer, StepsBox } from '../Activity.styles';

interface ActivityHeartRateInterface {
  heartRate?: number | null;
}

export const HeartRateSection: React.FC<ActivityHeartRateInterface> = ({ heartRate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.activity' });

  return (
    <HorizontalFlexContainer>
      <BoxVertical>
        <Icon element={icons['fi-rr-heart-color.svg']} size={24} color="other.red" />
        <StepsBox style={{ border: 'none', padding: 0 }}>
          <Typography variant="Body1" color="black.600">
            {t('peakHeartRate')}
          </Typography>
          <Typography.Span color="black.800" weight={500} size={20}>
            {heartRate ?? t('notAvailable')} {t('bpm')}
          </Typography.Span>
        </StepsBox>
      </BoxVertical>
    </HorizontalFlexContainer>
  );
};

export default HeartRateSection;
