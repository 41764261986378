import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button/Button';
import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

interface Step0Props {
  onGoNext: () => void;
}

export const Step0: React.FC<Step0Props> = ({ onGoNext }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.fitbitSetup.step0' });

  return (
    <TaskCompletionPage
      headerTitle={t('header')}
      taskTitle={t('header')}
      taskDescription={t('description')}
      icon="fi-rr-exclamation.svg"
      footer={
        <Button variant="linen" onClick={onGoNext}>
          {t('connectButton')}
        </Button>
      }
      color="other.backgroundBlue"
      backPath={-1}
    />
  );
};
