import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { setProgramDate } from 'api/program/program.api';
import React, { useEffect } from 'react';
import { useMutation as useReactQueryMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { FormAnswerType } from '../ConsentCheckbox/ConsentCheckbox';

type Props = {
  userID: string;
  answers: FormAnswerType[];
  afterSubmit: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customStringify = (obj: any): string => {
  if (typeof obj !== 'object' || obj === null) {
    // For non-object values, use the default JSON.stringify behavior
    return JSON.stringify(obj);
  }

  if (Array.isArray(obj)) {
    const parts: string[] = obj.map((value) => customStringify(value));
    return `[${parts.join(',')}]`;
  }

  const parts: string[] = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const stringifiedValue = customStringify(value);
      // Remove double quotes from keys and build the string
      parts.push(`${key}:${stringifiedValue}`);
    }
  }

  return `{${parts.join(',')}}`;
};

export const ConsentSubmitter: React.FC<Props> = ({ answers, userID, afterSubmit }: Props) => {
  const navigate = useNavigate();
  const { mutateAsync: setupProgramDate } = useReactQueryMutation(setProgramDate);

  const mutations = answers.map(
    (answer) => `
        create${answer.formID}: createFormAnswerGroup(input: {
          finished: true
          custom_module_form_id: "${answer.formID}"
          user_id: "${userID}"
          form_answers: ${customStringify(answer.answers)}
        }) {
            form_answer_group {
                id
            }
        }
      `,
  );
  const mutation = gql`mutation {${mutations.join('\n')}}`;
  const [submitAll] = useMutation(mutation);

  useEffect(() => {
    submitAll().then(async () => {
      try {
        // after consenting we send request to our backend to set start date of program
        Mixpanel.track('Account_created');
        await setupProgramDate();
        afterSubmit();
        navigate(paths.consentsAccepted, { state: { onboarding: true } });
      } catch (error) {
        afterSubmit();
        console.error(error);
      }
    });
  }, [afterSubmit, navigate, setupProgramDate, submitAll]);

  return <></>;
};
