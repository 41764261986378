import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { onEnterKey } from 'utils/helpers';

import { PopupModal } from 'components/ui/PopupModal/PopupModal';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './GetHelpModal.styles';

interface HelpModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const GetHelpModal: React.FC<HelpModalProps> = ({ onClose, isOpen }: HelpModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings.getHelpModal' });
  return (
    <PopupModal isOpen={isOpen} onRequestClose={onClose}>
      <Styled.Content>
        <Styled.SIcon
          element={CloseIcon}
          size={20}
          onClick={onClose}
          tabIndex={0}
          aria-label={t('closeModal')}
          onKeyDown={(e) => onEnterKey(e, onClose)}
        />
        <Styled.Title weight={600}>{t('immediateAttention')}</Styled.Title>
        <Typography variant="Helper2">{t('pause')}</Typography>
        <Styled.NumberedItems>
          {[...Array(16)].map((_, i) => (
            <li key={i}>{t(`${i + 1}`)}</li>
          ))}
        </Styled.NumberedItems>
        <Typography variant="Helper2">{t('note')}</Typography>
        <Typography variant="Helper2">{t('especially')}</Typography>
      </Styled.Content>
    </PopupModal>
  );
};
