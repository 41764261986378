import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow: hidden;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const Section = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.black[50]};
  display: flex;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.color.black[50]};
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.blue[700]};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  z-index: 0;
`;

export const SButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.other.linen};
  padding: 5px 18px;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const FormButton = styled(Button)`
  flex-basis: 50%;
  padding: 14px 19px;
  font-weight: 600;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const FieldText = styled(Typography)`
  overflow-wrap: break-word;
`;

export const EmailInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;
