import { Form } from 'formik';
import styled from 'styled-components';

import { Viewport } from 'components/layout/Viewport/Viewport';
import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const ConsentWrapper = styled(Viewport)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  transition: height 0.25s ease;
`;

export const ConsentForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 40px;
  flex: 1;
`;

export const CreateAccountButton = styled(Button)`
  margin-top: auto;
`;

export const MotivNote = styled(Typography.Div)`
  display: flex;
  gap: 8px;
`;
