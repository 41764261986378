import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';

import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import { Slots } from './components/Slots/Slots';
import { useGetUserProviderAppointmentDetails, useGetUserTherapistAppointmentDetails } from './hooks';
import { SpecialistTypesParams } from './types';

export const ScheduleAppointmentSlot: React.FC = () => {
  const { specialistType, appointmentTypeId } = useParams<keyof SpecialistTypesParams>() as SpecialistTypesParams;

  const userProvider = useGetUserProviderAppointmentDetails(appointmentTypeId);
  const userTherapist = useGetUserTherapistAppointmentDetails(appointmentTypeId);
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const renderFallback = () => {
    alert(t('somethingWentWrong'));
    return <Navigate to={paths.appointments} />;
  };

  const renderLoading = () => {
    return <LoadingPage />;
  };

  switch (specialistType) {
    case 'coach': {
      if (userProvider.loading) {
        return renderLoading();
      }
      if (!userProvider.providerId) {
        return renderFallback();
      }
      return (
        <Slots
          appointmentTypeId={userProvider.appointmentTypeId}
          providerId={userProvider.providerId}
          specialistType={specialistType}
        />
      );
    }
    case 'therapist': {
      if (userTherapist.loading) {
        return renderLoading();
      }
      if (!userTherapist.providerId) {
        return renderFallback();
      }
      return (
        <Slots
          appointmentTypeId={userTherapist.appointmentTypeId}
          providerId={userTherapist.providerId}
          specialistType={specialistType}
        />
      );
    }
  }

  return renderFallback();
};
