import { useFitbitLogin } from 'hooks/useFitbitLogin';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { ErrorLayout } from 'components/layout/Error/ErrorLayout';
import { LoadingLayout } from 'components/layout/Loading/LoadingLayout';
import { Button } from 'components/ui/Button/Button';

import { ButtonContainer } from './FitbitSetup.styles';
import { Step0, Step1, Step2, Step3, Step4 } from './components/steps';

const NUMBER_OF_STEPS = 4;

export const FitbitSetup: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.fitbitSetup' });
  const { login, loading: fitbitLoginLoading, error } = useFitbitLogin();

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  if (fitbitLoginLoading && step === NUMBER_OF_STEPS) {
    return (
      <LoadingLayout
        headerTitle={t('connectingWithFitbitHeaderTitle')}
        headline={t('connectingWithFitbitTitle')}
        description={t('connectingWithFitbitDescription')}
        backNavigateTo={paths.homePage}
      />
    );
  }

  if (error && step === NUMBER_OF_STEPS) {
    return (
      <ErrorLayout
        headerTitle={t('connectingWithFitbitHeaderTitle')}
        headline={t('connectWithFitbitErrorTitle')}
        description={t('connectWithFitbitErrorDescription')}
        backNavigateTo={paths.homePage}
      />
    );
  }

  const onNextClick = () => {
    if (step === NUMBER_OF_STEPS) {
      login(location?.state?.next ? location.state.next : paths.homePage);
      return;
    }
    setStep((prev) => prev + 1);
  };

  const onBackClick = () => {
    if (step === 0) {
      navigate(paths.progress);
      return;
    }
    setStep(step - 1);
  };

  if (step === 0) {
    return <Step0 onGoNext={() => setStep(1)} />;
  }

  return (
    <BaseLayout
      headerProps={{
        variant: 'back',
        onBackClick: onBackClick,
        title: t('title', { step: step, total: NUMBER_OF_STEPS }),
      }}
      footer={
        <ButtonContainer>
          <Button onClick={onNextClick}>{step === NUMBER_OF_STEPS ? t('connect') : t('next')}</Button>
        </ButtonContainer>
      }
    >
      {(() => {
        switch (step) {
          case 1:
            return <Step1 />;
          case 2:
            return <Step2 />;
          case 3:
            return <Step3 />;
          case 4:
            return <Step4 />;
          default:
            return <Navigate to={paths.homePage} />;
        }
      })()}
    </BaseLayout>
  );
};

export default FitbitSetup;
