import { useCurrentToken } from 'hooks/useCurrentToken';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { paths } from '../paths';

interface Props {
  children: JSX.Element;
}

export const DisabledRouteWhenAuthenticated: React.FC<Props> = ({ children }) => {
  const { getToken } = useCurrentToken();

  if (getToken()) {
    return <Navigate to={paths.homePage} replace />;
  }

  return children;
};
