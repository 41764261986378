import MotivKit from 'assets/images/kit/KitNew.png';
import { ReactComponent as CloseIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui/Icon/Icon';
import { PopupModal } from 'components/ui/PopupModal/PopupModal';

import * as Styled from './KitModal.styles';

interface KitModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const KitModal: React.FC<KitModalProps> = ({ onClose, isOpen }: KitModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.logIn.address' });

  return (
    <PopupModal isOpen={isOpen} onRequestClose={onClose}>
      <Styled.Content>
        <Styled.SButton variant="icon" onClick={onClose} aria-label="Close modal">
          <Icon element={CloseIcon} size={16} />
        </Styled.SButton>
        <Styled.Descriptions>
          <Styled.Description variant="Headline2">{t('yourMotivKit')}</Styled.Description>
          <Styled.Description variant="Headline2">{t('includesFollowing')}</Styled.Description>
        </Styled.Descriptions>
        <Styled.ItemsList>
          {/* <li>{t('fitbit')}</li> */}
          <li>{t('bloodPressureCuff')}</li>
          <li>{t('bands')}</li>
          <li>{t('scale')}</li>
          {/* <li>{t('towel')}</li> */}
        </Styled.ItemsList>
        <Styled.ScreenShots>
          <img style={{ width: '100%' }} src={MotivKit} alt={t('kitAltText')} />
        </Styled.ScreenShots>
      </Styled.Content>
    </PopupModal>
  );
};
