import { User } from '__generated__/graphql';
import { getProviders } from 'api/providers/providers.api';
import { useEffect, useState } from 'react';

interface UseGetProvidersProps {
  userId?: string;
}

export const useGetProviders = ({
  userId,
}: UseGetProvidersProps): {
  providers: User[] | null;
  providersLoading: boolean;
} => {
  const [providers, setProviders] = useState<User[] | null>(null);
  const [providersLoading, setProvidersLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      setProvidersLoading(true);
      getProviders({ userId })
        .then((providers) => {
          setProviders(providers);
        })
        .catch(() => {
          /* Intentionally left empty */
          setProviders([]);
        })
        .finally(() => {
          setProvidersLoading(false);
        });
    } else {
      setProviders(null);
      setProvidersLoading(false);
    }

    return () => {
      setProvidersLoading(false);
    };
  }, [userId]);

  return { providers, providersLoading };
};
