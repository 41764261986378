import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { differenceInYears, parse } from 'date-fns';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Mixpanel } from 'utils/mixpanel';

import './i18n/i18n';
import { router } from './router/routes';

export const App: React.FC = () => {
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
      mixpanel.track('App_opened');
    }
  }, []);

  useEffect(() => {
    const id = currentUserData?.currentUser?.id;

    if (id) {
      Mixpanel.identify(id);
    }
  }, [currentUserData?.currentUser?.id]);

  useEffect(() => {
    const email = currentUserData?.currentUser?.email;

    if (email) {
      Mixpanel.people.set({ $email: email });
    }
  }, [currentUserData?.currentUser?.email]);

  useEffect(() => {
    const currentUser = currentUserData?.currentUser;

    if (currentUser?.first_name || currentUser?.last_name) {
      Mixpanel.people.set({ $name: `${currentUser?.first_name} ${currentUser?.last_name}` });
    }

    if (currentUser?.dob) {
      Mixpanel.people.set({
        Age: differenceInYears(new Date(), parse(currentUser?.dob, 'yyyy-MM-dd', new Date())),
      });
    }
  }, [currentUserData]);

  return <RouterProvider router={router} />;
};
