import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 0;
  height: 100%;
`;

export const ExtendingContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  align-items: center;
`;

export const BloodPressureManualContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px;
  width: 100%;
`;

export const FooterContainerWithHorizontalPadding = styled(FooterContainer)`
  padding: 16px 22px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: 16px;
`;

export const ImageContainer = styled.div<{ isDark?: boolean }>`
  height: 350px;
  width: 100%;
  background-color: ${(props) =>
    props.isDark ? props.theme.color.other.backgroundPhotoDarkGrey : props.theme.color.other.backgroundPhotoLightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.color.blue[900]};
  text-align: center;
  justify-content: center;
`;

export const StepContainer = styled.div`
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0px 24px;
  gap: 8px;
`;

export const SButton = styled(Button)`
  display: flex;
  flex-basis: 50%;
  font-weight: 600;
  white-space: nowrap;
  justify-content: center;
`;
