import { useMutation, useQuery } from '@apollo/client';
import { CREATE_ENTRY } from 'api/entry/entry.mutation';
import { CREATE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_GOAL } from 'api/goal/goal.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as IconComp } from 'assets/vectors/fi-rr-weight.svg';
import format from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import { Formik } from 'formik';
import { useTitle } from 'hooks/useTitle';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { DATE_FORMAT } from 'utils/helpers';
import { findIncompleteSubgoal, getCompletionPercentage } from 'utils/helpers/goals';
import { Mixpanel } from 'utils/mixpanel';
import * as Yup from 'yup';

import { Header } from 'components/layout/BaseLayout/Header';
import { Button } from 'components/ui/Button/Button';
import CircularProgressBar from 'components/ui/CircularProgressBar/CircularProgressBar';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './RecordYourWeight.styles';

const CHAR_WIDTH = 40;
const MAX_INPUT_LENGTH = 7;
const MIN_WEIGHT = 0;
const MAX_WEIGHT = 1000;

export const RecordYourWeight: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.recordYourWeight' });

  const navigate = useNavigate();
  const { goalId } = useParams();
  const [inputWidth, setInputWidth] = useState(0);

  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);
  const {
    data: goalData,
    refetch,
    loading: isLoading,
  } = useQuery(GET_GOAL, {
    variables: { id: goalId, date: format(Date.now(), DATE_FORMAT) },
    fetchPolicy: 'network-only',
  });
  const [createEntry, { data: createEntryData, loading: createEntryLoading, error: createEntryError }] =
    useMutation(CREATE_ENTRY);
  const [
    createGoalHistory,
    { data: createGoalHistoryData, loading: createGoalHistoryLoading, error: createGoalHistoryError },
  ] = useMutation(CREATE_GOAL_HISTORY);

  const title = useTitle({ isLoading, repeat: goalData?.goal?.repeat });

  const formattedDate = format(new Date(), "'Today,' EEE, MMM d", { locale: enUS });
  const initialValues: { weight: string } = {
    weight: '0',
  };
  const validation = Yup.object().shape({
    weight: Yup.number()
      .required()
      .min(MIN_WEIGHT, t('minWeightValidation', { min: MIN_WEIGHT }))
      .max(MAX_WEIGHT, t('maxWeightValidation', { max: MAX_WEIGHT })),
  });

  const onSuccess = useCallback(() => {
    navigate(paths.recordYourWeightCompleted);
  }, [navigate]);

  const displayErrorIfExists = (error: string | undefined) => {
    if (error) {
      alert(error);
    }
  };

  // Create Entry
  useEffect(() => {
    if (createEntryData?.createEntry?.messages === null && !createEntryLoading && !createEntryError) {
      onSuccess();
    }
    const apiError = createEntryData?.createEntry?.messages?.[0]?.message;
    displayErrorIfExists(apiError);
  }, [createEntryData?.createEntry?.messages, createEntryError, createEntryLoading, onSuccess]);

  // Create goal history
  useEffect(() => {
    if (
      createGoalHistoryData?.createGoalHistory?.messages === null &&
      !createGoalHistoryLoading &&
      !createGoalHistoryError
    ) {
      onSuccess();
    }
    const apiError = createGoalHistoryData?.createGoalHistory?.messages?.[0]?.message;
    displayErrorIfExists(apiError);
  }, [createGoalHistoryData?.createGoalHistory?.messages, createGoalHistoryError, createGoalHistoryLoading, onSuccess]);

  const onSubmit = async (values: { weight: string }) => {
    const goal = findIncompleteSubgoal(goalData?.goal);
    const { data: refetchedGoalData } = await refetch();
    const isCurrentSubgoalCompleted =
      refetchedGoalData?.goal?.is_completed_for_date ||
      refetchedGoalData?.goal?.subgoals?.find((subgoal) => subgoal?.id === goal?.subgoalId)?.is_completed_for_date;
    if (isCurrentSubgoalCompleted) {
      navigate(paths.homePage);
    } else {
      try {
        await createEntry({
          variables: {
            user_id: currentUserData?.currentUser?.id,
            category: 'Weight',
            type: 'MetricEntry',
            metric_stat: values.weight.toString(),
            created_at: new Date(Date.now()).toISOString(),
          },
        });
        const markParentComplete = !!goal?.goalId;
        if (markParentComplete) {
          Mixpanel.track('Weight_Goal');
        }
        await createGoalHistory({
          variables: {
            user_id: currentUserData?.currentUser?.id,
            goal_id: goal?.subgoalId || goal?.goalId,
            completed_on: format(Date.now(), DATE_FORMAT),
            mark_parent_complete: markParentComplete,
          },
        });
      } catch (error) {
        //TODO ERROR HANDLING
        throw new Error(`Weight saving failed: ${error}`);
      }
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit} validateOnMount>
      {({ handleChange, submitForm, isValid, touched }) => (
        <Styled.PageContainer>
          <Styled.HeaderContainer>
            <Header onBackClick={() => navigate(paths.homePage)} variant="back" title={title} />
          </Styled.HeaderContainer>
          <Styled.Content>
            <Styled.ContentHeader>
              <CircularProgressBar size={65} progress={getCompletionPercentage(goalData?.goal)} strokeWidth={4}>
                <Icon element={IconComp} size={20} />
              </CircularProgressBar>
              <Styled.CardDescriptionContainer>
                <Typography weight={500} size={20} lineHeight={28}>
                  {goalData?.goal?.name}
                </Typography>
                <Typography size={14} color="black.600">
                  {formattedDate}
                </Typography>
              </Styled.CardDescriptionContainer>
            </Styled.ContentHeader>
            <Styled.WeightContainer>
              <Styled.WeightInputContainer>
                <Styled.WeightInput
                  type="text"
                  name="weight"
                  placeholder="0"
                  // eslint-disable-next-line
                  autoFocus
                  onChange={(e) => {
                    handleChange(e);
                    setInputWidth(e.target.value.length * CHAR_WIDTH);
                  }}
                  $width={inputWidth}
                  inputMode="decimal"
                  maxLength={MAX_INPUT_LENGTH}
                />
                <Typography variant="Headline2" color="other.violet">
                  {t('lbs')}
                </Typography>
              </Styled.WeightInputContainer>
              <Styled.WeightInstruction variant="Helper1">{t('pleaseEnterYourWeight')}</Styled.WeightInstruction>
            </Styled.WeightContainer>
          </Styled.Content>
          <Styled.ButtonContainer>
            <Button type="submit" onClick={submitForm} disabled={!touched || !isValid}>
              {t('save')}
            </Button>
          </Styled.ButtonContainer>
        </Styled.PageContainer>
      )}
    </Formik>
  );
};
