import { useLazyQuery, useQuery } from '@apollo/client';
import { Goal } from '__generated__/graphql';
import { GET_CARE_PLAN_DOCUMENTS } from 'api/documents/documents.query';
import { getPhysiotecProgram } from 'api/exercises/exercises.api';
import { Exercise } from 'api/exercises/exercises.model';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as CheckArrowIconComp } from 'assets/vectors/fi-rr-cross-done.svg';
import { CompleteActivityGoalType } from 'hooks/useCompleteActivityGoal';
import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import { env } from 'index';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { useExerciseChart } from 'pages/home/hooks/useExerciseChart';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery as useReactQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { onEnterKey } from 'utils/helpers';
import { WeeklyReportProps, getCurrentWeek } from 'utils/helpers/exercises';
import { GoalType, GoalsMapping, getCompletionNumericalRange, getCompletionPercentage } from 'utils/helpers/goals';
import { Mixpanel } from 'utils/mixpanel';
import { Maybe } from 'utils/types';

import { Button } from 'components/ui/Button/Button';
import { CircularProgressBar } from 'components/ui/CircularProgressBar/CircularProgressBar';
import { Icon } from 'components/ui/Icon/Icon';
import { TextColor } from 'components/ui/Typography/Typography.model';

import * as Styled from './Card.styles';

interface TitleProps {
  color?: TextColor;
}

interface TaskCardProps {
  goal: Maybe<Goal>;
  titleProps?: TitleProps;
  activity?: Pick<CompleteActivityGoalType, 'stepsGoal' | 'percentage' | 'currentSteps'>;
}

export const getGoalType = (goal: Maybe<Goal>): GoalType => {
  return Object.keys(GoalsMapping).find((key) =>
    goal?.name ? goal?.name.toLowerCase().includes(key.toLowerCase()) : undefined,
  ) as GoalType;
};

export const TaskCard: React.FC<TaskCardProps> = ({ goal, titleProps, activity }: TaskCardProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home.progress' });
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);
  const { userChart } = useExerciseChart(currentUserData?.currentUser?.id || '');
  const [completedThisWeek, setCompletedThisWeek] = useState<number>(0);
  const [totalThisWeek, setTotalThisWeek] = useState<number>(0);
  const currentWeek = getCurrentWeek();
  const goalType = getGoalType(goal);

  const programId = goalType === 'exercise' ? goal?.description?.split(',')[0] : undefined;
  const { data: programData } = useReactQuery('getPhysiotecProgram', () => getPhysiotecProgram({ programId }), {
    enabled: !!programId,
    cacheTime: 300000, // 5mins
  });

  useEffect(() => {
    if (programData) {
      const program = programData?.data?.program_details;
      const exercises = program?.exercise ? Object.values(program?.exercise) : [];
      setTotalThisWeek(exercises.length);
    }
  }, [programData]);

  useEffect(() => {
    if (userChart && programData) {
      const chart = userChart?.formAnswerGroups?.[0];
      const exerciseJson = chart?.form_answers?.find((exercise) => exercise.label === 'Exercise JSON');
      const exercises: WeeklyReportProps[] = JSON.parse(exerciseJson?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '[]');
      const thisWeek = exercises.find((ex) => ex.startDate === currentWeek.start);

      const program = programData?.data?.program_details;
      const programExercises = (program?.exercise ? Object.values(program?.exercise) : []).reduce((acc, curr) => {
        acc[curr.code] = curr;
        return acc;
      }, {} as Record<string, Exercise>);

      const completedExercises =
        thisWeek?.exerciseData.filter((ex) => ex.completed === true && !isEmpty(programExercises[ex.execise_id]))
          .length || 0;
      setCompletedThisWeek(completedExercises);
    }
    // Do not add any other dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChart, programData]);

  const navigate = useNavigate();

  const progress =
    goalType === 'moving' && activity?.percentage
      ? activity?.percentage
      : goalType === 'exercise' && totalThisWeek > 0
      ? (completedThisWeek / totalThisWeek) * 100
      : getCompletionPercentage(goal);

  const isCompleted = progress === 100;

  const progressRange = getCompletionNumericalRange(goal);
  const { hasLoggedInBefore } = useCurrentFitbitTokens();
  const [getDocuments] = useLazyQuery(GET_CARE_PLAN_DOCUMENTS, {
    fetchPolicy: 'network-only',
  });

  let progressLabel;

  switch (goalType) {
    case 'moving':
      progressLabel = activity?.stepsGoal
        ? `${activity?.currentSteps.toLocaleString('en-US')}/${activity?.stepsGoal.toLocaleString('en-US')} ${t(
            'activity',
          )}`
        : ``;
      break;
    case 'weight':
    case 'pressure':
      progressLabel = `${progressRange} ${t('recorded')}`;
      break;
    case 'medications':
      progressLabel = isCompleted ? t('medicationsRecorded') : t('medicationsNotRecorded');
      break;
    case 'lessons':
      progressLabel = `${progressRange} ${t('lessons')}`;
      break;
    case 'assessments':
      progressLabel = `${progressRange} ${t('assessments')}`;
      break;
    case 'certificate':
      progressLabel = ``;
      break;
    case 'summary':
      progressLabel = ``;
      break;
    case 'exercise':
      progressLabel = totalThisWeek > 0 ? `${completedThisWeek}/${totalThisWeek} ${t('completed')}` : t('notCompleted');
      break;
    case 'check':
      progressLabel = isCompleted ? capitalize(t('completed')) : t('notCompleted');
      break;
    default:
      progressLabel = `${progressRange} ${t('completed')}`;
  }

  const renderAction = () => {
    switch (goalType) {
      case 'check': {
        return isCompleted ? null : (
          <Button variant="primary" size="sm">
            {t('schedule')}
          </Button>
        );
      }
      case 'certificate':
      case 'summary': {
        return isCompleted ? null : (
          <Button variant="primary" size="sm">
            {t('print')}
          </Button>
        );
      }
    }
  };

  const navigateToGoal = () => {
    if (goalType === 'certificate' || goalType === 'summary') {
      const windowReference = window.open();
      if (goalType === 'certificate') {
        Mixpanel.track('certificate_printed');
      } else if (goalType === 'summary') {
        Mixpanel.track('summary_printed');
      }
      getDocuments().then((res) => {
        const files = res?.data?.currentUser?.active_care_plan?.documents;
        const file = files?.find((file) => file.id === goal?.description);
        if (windowReference && file?.expiring_url) {
          windowReference.location = file.expiring_url;
        } else {
          windowReference?.close();
        }
      });
    } else if (!isCompleted || GoalsMapping[goalType].redirectOnCompletion) {
      if (goal && !hasLoggedInBefore && goalType === 'moving') {
        navigate(paths.fitbitSetup);
        return;
      }
      if (goalType === 'check') {
        navigate(
          generatePath(GoalsMapping[goalType].path, {
            appointmentTypeId: env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
          }),
        );
        return;
      }
      if (goal) {
        navigate(GoalsMapping[goalType].path.replace(':goalId', goal?.id));
      }
    } else if (isCompleted && goalType === 'medications') {
      navigate(paths.medications);
    }
  };

  return (
    <Styled.CardContainer
      onClick={navigateToGoal}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => onEnterKey(e, navigateToGoal)}
      aria-label={goal?.name as string | undefined}
    >
      {isCompleted ? (
        <Styled.CompletedCircle>
          <Icon element={CheckArrowIconComp} size={24} color="other.green" innerAria={t('taskCompletedIcon')} />
        </Styled.CompletedCircle>
      ) : (
        <CircularProgressBar size={48} progress={progress} strokeWidth={3} ariaLabel={`${goalType} ${t('icon')}`}>
          <Icon
            element={GoalsMapping[goalType]?.icon || CheckArrowIconComp}
            innerAria={`${goalType} ${t('icon')}`}
            size={20}
            color={['assessments', 'certificate', 'summary', 'personal'].includes(goalType) ? null : undefined}
          />
        </CircularProgressBar>
      )}
      <Styled.CardDescriptionContainer>
        <Styled.CardTitle weight={600} color="blue.700" {...titleProps}>
          {goal?.name?.includes('Blood') ? goal?.name?.replace(/\d+$/, '') : goal?.name}
        </Styled.CardTitle>
        <Styled.CardProgress size={14} color="black.600">
          {progressLabel}
        </Styled.CardProgress>
      </Styled.CardDescriptionContainer>
      {renderAction()}
    </Styled.CardContainer>
  );
};
