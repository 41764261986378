import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from 'utils/mixpanel';

import { Header } from 'components/layout/BaseLayout/Header';
import { Accordion, AccordionItem } from 'components/ui/Accordion/Accordion';

import * as Styled from '../General.styles';

export const Faq: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings.faqPage' });
  const navigate = useNavigate();

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - FAQ' });
  }, []);

  const FAQ_ACCORDION_ITEMS: AccordionItem[] = [
    {
      label: t('general'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('what'),
              content: t('whatDescription'),
            },
            {
              label: t('cost'),
              content: t('costDescription1'),
            },
            {
              label: t('duration'),
              content: t('durationDescription'),
            },
            {
              label: t('help'),
              content: t('helpDescription'),
            },
            {
              label: t('access'),
              content: t('accessDescription'),
            },
            {
              label: t('save'),
              content: (
                <>
                  <b>{t('saveForIphones')}</b>
                  {t('saveForIphonesDescription')}
                  <b>{t('saveForAndroids')}</b>
                  {t('saveForAndroidsDescription')}
                </>
              ),
            },
            {
              label: t('computer'),
              content: t('computerDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('accountManage'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('forgotEmail'),
              content: t('forgotEmailDescription'),
            },
            {
              label: t('forgotPassword'),
              content: t('forgotPasswordDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('motivKitTitle'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('motivKit'),
              content: t('motivKitDescription'),
            },
            {
              label: t('getMotivKit'),
              content: t('getMotivKitDescription'),
            },
            {
              label: t('questionsKit'),
              content: t('questionsKitDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('motivClinicalTeam'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('whoCoach'),
              content: t('whoCoachDescription'),
            },
            {
              label: t('contactcoach'),
              content: t('contactCoachDescription'),
            },
            {
              label: t('contactTherapist'),
              content: t('contactTherapistDescription'),
            },
            {
              label: t('connectTherapist'),
              content: t('connectTherapistDescription'),
            },
            {
              label: t('whenContact'),
              content: (
                <>
                  {t('whenContactDescription')}
                  <br />
                  <br />
                  {t('warning')}
                </>
              ),
            },
          ]}
        />
      ),
    },
    {
      label: t('emailsAndReminders'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('whatEmails'),
              content: t('whatEmailsDescription'),
            },
            {
              label: t('changeEmailNotifications'),
              content: t('changeEmailNotificationsDescription'),
            },
            {
              label: t('changeSMS'),
              content: t('changeSMSDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('techSupp'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('issues'),
              content: t('issuesDescription'),
            },
            {
              label: t('gettingStarted'),
              content: (
                <>
                  <p>{t('gettingStartedDescription')}</p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '100%',
                      marginTop: '20px',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        maxWidth: '600px',
                      }}
                    >
                      <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                        <video
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                          controls
                          poster="https://motiv-public-images.s3.amazonaws.com/Getting_Started_Thumbnail.png"
                        >
                          <source
                            src="https://motiv-public-videos.s3.amazonaws.com/motiv_getting_started.mp4"
                            type="video/mp4"
                          />
                          <track kind="captions" src="" srcLang="en" label="English" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </>
              ),
            },
            {
              label: t('appTutorial'),
              content: (
                <>
                  <p>{t('appTutorialDescription')}</p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '100%',
                      marginTop: '20px',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        maxWidth: '600px',
                      }}
                    >
                      <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                        <video
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                          controls
                          poster="https://motiv-public-images.s3.amazonaws.com/Motiv_App_Tutorial_Thumbnail.png"
                        >
                          <source
                            src="https://motiv-public-videos.s3.amazonaws.com/motiv_app_tutorial.mp4"
                            type="video/mp4"
                          />
                          <track kind="captions" src="" srcLang="en" label="English" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      ),
    },
    {
      label: t('downloadReports'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('downloadBP'),
              content: t('downloadBPDescription'),
            },
            // {
            //   label: t('downloadActivity'),
            //   content: t('downloadActivityDescription'),
            // },
          ]}
        />
      ),
    },
    {
      label: t('endOfProgram'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('end'),
              content: t('endDescription'),
            },
            {
              label: t('unable'),
              content: t('unableDescription'),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Header variant="back" onBackClick={() => navigate(-1)} title={t('faq')} />
      </Styled.HeaderContainer>
      <Accordion accordionItems={FAQ_ACCORDION_ITEMS} />
    </Styled.Container>
  );
};
