import { ReactComponent as CrossDone } from 'assets/vectors/fi-rr-cross-done.svg';
import { ReactComponent as PlayComp } from 'assets/vectors/fi-rr-video.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { onEnterKey } from 'utils/helpers';
import { Maybe } from 'utils/types';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import { ArticleDescription, ArticleItem, ViewButton } from './ArticleTab.styles';

type Props = {
  label?: string;
  title?: Maybe<string>;
  onClick?: () => void;
  isCompleted?: boolean;
  hidePlayIcon?: boolean;
  grayOutIfCompleted?: boolean;
  className?: string;
};

export const ArticleTab: React.FC<Props> = ({
  label,
  title,
  onClick,
  isCompleted,
  hidePlayIcon,
  grayOutIfCompleted,
  className,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.articleTab' });
  return (
    <ArticleItem
      onClick={onClick}
      onKeyDown={onClick ? (e) => onEnterKey(e, onClick) : undefined}
      role="button"
      className={className}
      tabIndex={0}
    >
      {label && (
        <Typography variant="Helper1" color="black.600">
          {label}
        </Typography>
      )}
      <ArticleDescription>
        {!hidePlayIcon && <Icon element={PlayComp} size={16} color="blue.700" aria-hidden />}
        <Typography weight={600} color={isCompleted && grayOutIfCompleted ? 'black.500' : 'blue.700'}>
          {title}
        </Typography>
        {isCompleted ? (
          <Icon element={CrossDone} size={16} color="other.green" aria-label={t('completedAria', { label })} />
        ) : (
          <ViewButton variant="primary" size="sm">
            {t('view')}
          </ViewButton>
        )}
      </ArticleDescription>
    </ArticleItem>
  );
};
