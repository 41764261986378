import { format, parse } from 'date-fns';
import { chunk, groupBy, isNil, sortBy } from 'lodash';

import { PdfData, StepsEntry } from './types';

export const preparePdfData = (data: StepsEntry[]): PdfData[][][][] => {
  const entriesWithDates = data.filter((entry) => !isNil(entry) && !isNil(entry?.created_at));
  const readingsData: PdfData[] = entriesWithDates.map((entry) => ({
    id: entry?.id || '',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createdAt: parse(entry!.created_at!, 'yyyy-MM-dd HH:mm:ss X', new Date()),
    updatedAt: entry?.updated_at ? parse(entry.updated_at, 'yyyy-MM-dd HH:mm:ss X', new Date()) : undefined,
    entryStat: entry?.metric_stat ? entry.metric_stat.toLocaleString('en-US') : 0,
  }));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const groupedData = groupBy(readingsData, (el) => format(el!.createdAt!, 'yyyy-MM-dd'));
  const sortedData = sortBy(Object.values(groupedData), (group) => {
    return group[0].createdAt.getTime();
  });

  const output = [];
  let column: PdfData[][] = [];
  let accumulator = 0;
  for (let i = 0; i < sortedData.length; i++) {
    const day = sortedData[i];
    if (accumulator + day.length + 1 <= 14) {
      column.push(day);
      accumulator += day.length + 1;
    } else {
      if (day.length > 13) {
        const chunks = chunk(day, 13);
        let j = 0;
        for (j; j < chunks.length - 1; j++) {
          output.push([chunks[j]]);
        }
        column = [chunks[j]];
        accumulator = chunks[j].length;
        continue;
      }
      output.push(column);
      column = [];
      column.push(day);
      accumulator = day.length + 1;
    }
  }
  output.push(column);

  const chunkData = chunk(output, 2);
  return chunkData;
};
