import React from 'react';
import parse from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { Header } from 'components/layout/BaseLayout/Header';
import { Typography } from 'components/ui/Typography/Typography';

import { ConsentContent, ConsentWrapper, ModalContent, ModalOverlay } from './ConsentModal.styles';

export type ConsentModalProps = {
  headline?: string;
  content?: string;
};

type Props = ConsentModalProps & {
  visible?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

export const ConsentModal: React.FC<Props> = ({ visible, onClose, headline, content, children }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });
  return (
    <ReactModal
      className="_"
      overlayClassName="_"
      ariaHideApp={false}
      isOpen={visible || false}
      contentElement={(props, children) => <ModalContent {...props}>{children}</ModalContent>}
      overlayElement={(props, contentElement) => <ModalOverlay {...props}>{contentElement}</ModalOverlay>}
    >
      <Header variant="back" onBackClick={onClose} title={t('myConsents')} />
      <ConsentWrapper>
        <Typography variant="Title1">{parse(headline || '')}</Typography>
        <ConsentContent variant="Body1">{parse(content || '')}</ConsentContent>
        {children}
      </ConsentWrapper>
    </ReactModal>
  );
};
