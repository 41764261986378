import { gql } from '__generated__/gql';

export const CREATE_MOTIVATION_ENTRY = gql(`
  mutation createMotivationEntry(
    $type: String,
    $category: String,
    $description: String,
    $user_id: String,
    $reflection: String
  ) {
    createEntry(input: {
      type: $type,
      description: $description,
      user_id: $user_id,
      category: $category,
      reflection: $reflection
    })
      {
      entry {
        id
      }
      messages {
        field
        message
      }
    }
  }
`);

export const UPDATE_MOTIVATION_ENTRY = gql(`
  mutation updateMotivationEntry(
    $id: ID,
    $reflection: String
  ) {
    updateEntry(input: {
      id: $id,
      reflection: $reflection
    })
      {
      entry {
        id
      }
      messages {
        field
        message
      }
    }
  }
`);
