/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as FitbitLogo } from 'assets/images/kit/fitbit-logo.svg';
import { ReactComponent as InfoIcon } from 'assets/vectors/fi-rr-exclamation.svg';
import { ReactComponent as StepsIcon } from 'assets/vectors/fi-rr-steps.svg';
import { icons } from 'assets/vectors/iconsList';
import { format } from 'date-fns';
import { useCompleteActivityGoal } from 'hooks/useCompleteActivityGoal';
import isNil from 'lodash/isNil';
import { ActivityLoading } from 'pages/progress/activity/components/ActivityLoading';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import CircularProgressBar from 'components/ui/CircularProgressBar/CircularProgressBar';
import { Icon } from 'components/ui/Icon/Icon';
import Modal from 'components/ui/Modal/Modal';
import TaskHeader from 'components/ui/TaskHeader/TaskHeader';
import { Typography } from 'components/ui/Typography/Typography';

import {
  Footer,
  GoodJobText,
  HeaderContainer,
  HeartRateContainer,
  HeartRateValue,
  ModalButton,
  ModalContentContainer,
  ModalText,
  ModalTitle,
  ProgressCircleContainer,
} from './KeepMoving.styles';

export const KeepMoving: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.keepMoving' });
  const navigate = useNavigate();
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const { stepsGoal, percentage, currentSteps, goalLoading, goalName, heartRateGoal } = useCompleteActivityGoal();

  if (goalLoading) {
    return <ActivityLoading />;
  }

  return (
    <BaseLayout
      headerProps={{
        title: t('titleDaily'),
        variant: 'back',
        onBackClick: () => navigate(paths.homePage),
      }}
      footer={null}
    >
      <Modal isOpen={infoModalVisible} onRequestClose={() => setInfoModalVisible(false)} contentLabel={t('modalLabel')}>
        <ModalContentContainer>
          <ModalTitle variant="Headline2" color="black.800">
            {t('modalTitle')}
          </ModalTitle>
          <Icon element={InfoIcon} size={108} color="black.600" />
          <ModalText variant="Body1" color="black.800">
            {t('modalInfo')}
          </ModalText>
          <ModalButton onClick={() => setInfoModalVisible(false)} size="lg">
            {t('closeButton')}
          </ModalButton>
        </ModalContentContainer>
      </Modal>
      <HeaderContainer>
        <TaskHeader
          icon={StepsIcon}
          name={goalName.replace(/\d/g, '')}
          progressPercentage={percentage ? percentage : 0}
          description={t('headerDescription', { date: format(new Date(), 'EEE, MMM d') })}
          circleProps={{
            startGradientColor: 'other.green',
            endGradientColor: 'other.greenForest',
          }}
          onInformationIconClick={() => setInfoModalVisible(true)}
        />
        <FitbitLogo title={t('fitbitLogoAlt')} width={56} />
      </HeaderContainer>
      <ProgressCircleContainer>
        <CircularProgressBar
          startGradientColor="other.green"
          endGradientColor="other.greenForest"
          progress={percentage}
          size={170}
          strokeWidth={6}
        >
          <Typography.Div variant="Helper1Bold" color="black.600" style={{ marginBottom: 3 }}>
            {t('steps')}
          </Typography.Div>
          <Typography.Div variant="Title1" color="other.green">
            {currentSteps.toLocaleString('en-US')}
          </Typography.Div>
          {!!stepsGoal && (
            <Typography.Div variant="Helper1Bold" color="black.800" style={{ whiteSpace: 'nowrap' }}>
              {t('goal', { goal: stepsGoal.toLocaleString('en-US') })}
            </Typography.Div>
          )}
        </CircularProgressBar>
        {percentage === 100 && <GoodJobText color="other.green">{t('goodJobText')}</GoodJobText>}
        {!isNil(heartRateGoal) && (
          <HeartRateContainer>
            <HeartRateValue>
              <Icon element={icons['fi-rr-heart-color.svg']} size={32} color="other.red" />
              <Typography.Span size={40} weight={600} color="other.red" style={{ marginLeft: 8 }}>
                {heartRateGoal}
              </Typography.Span>
              <Typography.Span variant="Body1Bold" color="other.red" style={{ marginLeft: 4 }}>
                {t('bpm')}
              </Typography.Span>
            </HeartRateValue>
            <Typography variant="Body1" color="black.600">
              {t('peakHeartRate')}
            </Typography>
          </HeartRateContainer>
        )}
      </ProgressCircleContainer>
      <Footer>
        <Button onClick={() => navigate(paths.activity)}>{t('viewProgressButton')}</Button>
      </Footer>
    </BaseLayout>
  );
};
