import { useTranslation } from 'react-i18next';

type Return = {
  parseError: (error?: string) => string | null;
};

export const useErrorParser = (): Return => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.forgotPassword.errors' });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseError = (error?: any): string | null => {
    if (!error) {
      return null;
    }
    if (typeof error === 'string') {
      if (error.includes('does not exist')) {
        return t('userNotExisting');
      } else if (error.includes('Invalid token')) {
        return t('linkExpired');
      }
    }

    if (error?.[0]?.message?.toLowerCase().includes('password is too weak')) {
      return t('passwordTooWeak');
    }

    return null;
  };

  return {
    parseError,
  };
};
