import styled from 'styled-components';

export const DeviceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  margin: 0 -16px;
  align-items: center;

  border-top: 1px solid ${({ theme }) => theme.color.black[50]};
  border-bottom: 1px solid ${({ theme }) => theme.color.black[50]};
`;

export const FitbitLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;
