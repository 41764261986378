import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledEmoji, Wrapper } from './EmptyPlaceholder.styles';

interface Props {
  patientName: string;
  providerName: string;
  providerRole: string;
}

export const EmptyPlaceholder: React.FC<Props> = ({ patientName, providerName, providerRole }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.chat' });

  return (
    <Wrapper>
      <StyledEmoji>👋</StyledEmoji>
      <div>{t('emptyPlaceholder1', { patientName, providerName, providerRole })}</div>
      <div>{t('emptyPlaceholder2')}</div>
      <div>{t('emptyPlaceholder3')}</div>
    </Wrapper>
  );
};
