import { useQuery } from '@apollo/client';
import { GET_ALL_CARE_PLANS } from 'api/progress/allCarePlans.query';
import { GET_COURSE_MEMBERSHIP } from 'api/questionnaires/questionnaires.query';
import { format } from 'date-fns';
import { last } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { paths } from 'router/paths';
import { DATE_FORMAT, parseHealthieDate } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';
import { Maybe } from 'utils/types';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { ErrorLayout } from 'components/layout/Error/ErrorLayout';
import { LoadingLayout } from 'components/layout/Loading/LoadingLayout';
import { Typography } from 'components/ui/Typography/Typography';

import { getDateRangeWeekly } from '../activity/timeUtils';
import { HeaderContainer, SelectContainer } from './WeeklyReport.styles';
import { DownloadButton } from './components/DownloadButton';
import { WeeklyPlanContent } from './components/WeeklyReportContent';
import { DateOption } from './types';
import { dateOptionsTransform } from './utils';

export const WeeklyPlan: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.weeklyReport' });
  const navigate = useNavigate();
  const [isSingle, setIsSingle] = useState(false);

  const { data: courseData, loading } = useQuery(GET_COURSE_MEMBERSHIP);

  const { data: carePlanData, loading: carePlanLoading } = useQuery(GET_ALL_CARE_PLANS, {
    skip: !courseData?.currentUser?.id,
    variables: {
      patient_id: courseData?.currentUser?.id || '',
    },
  });

  const courseStartDate = useMemo(() => {
    let motivCareplanDate = '';
    const week1 = carePlanData?.carePlans
      ?.find((item) => /^Week 1\b/.test(item?.name || ''))
      ?.description?.slice(8, 18);
    const motivCareplan = carePlanData?.carePlans?.find((item) =>
      /^Motiv Careplan\b/.test(item?.name || ''),
    )?.created_at;

    if (motivCareplan) {
      motivCareplanDate = format(new Date(parseHealthieDate(motivCareplan) ?? ''), 'MM.dd.yyyy');
    }
    return week1 ? week1 : motivCareplanDate ? motivCareplanDate : '';
  }, [carePlanData]);

  const weeklyDateRanges = useMemo(() => {
    const [month, day, year] = courseStartDate?.split('.') || [];
    return getDateRangeWeekly(new Date(Number(year), Number(month) - 1, Number(day)), new Date(), false);
  }, [courseStartDate]);

  const dateOptions = useMemo(() => {
    // display date range only when specific care plan number exists
    return dateOptionsTransform(weeklyDateRanges);
  }, [weeklyDateRanges]);

  const [selectedDate, setSelectedDate] = useState<Maybe<DateOption>>(last(dateOptions));

  const startDate = selectedDate ? format(selectedDate.start, DATE_FORMAT) : null;
  const endDate = selectedDate ? format(selectedDate.end, DATE_FORMAT) : null;

  const selectedCarePlan = useMemo(() => {
    let selected = selectedDate
      ? carePlanData?.carePlans?.find((item) => item?.name?.includes(`Week ${selectedDate.value}`))
      : null;
    setIsSingle(false);
    if (!selected) {
      setIsSingle(true);
      selected = selectedDate ? carePlanData?.carePlans?.find((item) => item?.name?.includes(`Careplan`)) : null;
    }
    return selected;
  }, [selectedDate, carePlanData]);

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Progress - Weekly Report' });
  }, []);

  useEffect(() => {
    const selectedDate = last(dateOptions);
    if (selectedDate) {
      setSelectedDate(selectedDate);
    }
  }, [dateOptions]);

  if (loading || carePlanLoading) {
    return <LoadingLayout headerTitle={t('title')} headline={''} description={''} backNavigateTo={paths.progress} />;
  }

  if (!courseStartDate) {
    return (
      <ErrorLayout
        headerTitle={t('title')}
        headline={t('errorHeadline')}
        description={t('errorDescription')}
        backNavigateTo={paths.homePage}
      />
    );
  }

  return (
    <BaseLayout
      footer={null}
      headerProps={{ variant: 'back', title: t('title'), onBackClick: () => navigate(paths.progress) }}
    >
      <HeaderContainer>
        <SelectContainer>
          <Select
            value={selectedDate}
            options={dateOptions}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            placeholder={t('week')}
          />
        </SelectContainer>
        {startDate && endDate && selectedCarePlan?.goals && (
          <DownloadButton
            data={{
              startDate,
              endDate,
              goals: selectedCarePlan?.goals,
            }}
            dateRange={selectedDate}
          />
        )}
      </HeaderContainer>
      <Typography variant="Body1Bold" color="black.700">
        {t('summary')}
      </Typography>
      <WeeklyPlanContent goals={selectedCarePlan?.goals} startDate={startDate} endDate={endDate} isSingle={isSingle} />
    </BaseLayout>
  );
};
