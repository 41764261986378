import { gql } from '__generated__/gql';

export const BOOK_APPOINTMENT = gql(`
  mutation completeCheckout($input: completeCheckoutInput!) {
    completeCheckout(input: $input) {
      messages {
        field
        message
      }
      appointment {
        provider {
            first_name
            name
            id
            avatar_url
            phone_number
        }
        id
        location
        locationResource
        zoom_join_url
        external_videochat_url
        is_zoom_chat
      }
    }
  }
`);

export const RESCHEDULE_APPOINTMENT = gql(`
  mutation updateAppointment(
    $client_updating: Boolean
    $datetime: String
    $id: ID
    $client_confirmed: Boolean
    $user_id: String
  ) {
    updateAppointment(
      input: {
        client_updating: $client_updating
        datetime: $datetime
        id: $id
        client_confirmed: $client_confirmed
        user_id: $user_id
      }
    ) {
      appointment {
        provider {
            first_name
            name
            id
            avatar_url
            phone_number
        }
        id
        date
      }
      messages {
        field
        message
      }
    }
  }
`);

export const CANCEL_APPOINTMENT = gql(`
  mutation deleteAppointment($id: ID, $deleteRecurring: Boolean) {
    deleteAppointment(input: { id: $id, deleteRecurring: $deleteRecurring }) {
      appointment {
        id
      }
      messages {
        field
        message
      }
    }
  }
`);

export const UPDATE_PROVIDER = gql(`
    mutation UpdateClient($input: updateClientInput) {
      updateClient(input: $input) {
        user {
          id
        }
      }
    }
  `);
