import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
`;

export const Title = styled(Typography)`
  margin-top: 20px;
  text-align: center;
`;

export const SButton = styled(Button)`
  align-self: flex-end;
  width: auto;
`;

export const ItemsList = styled.ul`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-position: inside;
  text-align: center;
  margin: 40px 35px 20px 35px;
`;

export const ScreenShots = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

export const Descriptions = styled.div`
  margin-top: 20px;
`;

export const Description = styled(Typography)`
  text-align: center;
`;
