import styled from 'styled-components';

export const Modal = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.color.other.linen};
  border-radius: 16px;
  max-height: calc(100% - 32px);
  overflow: auto;
  outline: none;
`;

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.color.other.modalBackground};
`;
