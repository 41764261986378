import { ReactComponent as MotivLogo } from 'assets/vectors/logo.svg';
import { useCurrentToken } from 'hooks/useCurrentToken';
import React, { useEffect } from 'react';

import { Spinner } from '../Spinner/Spinner';
import { Container, LogoContainer } from './LoadingPage.styles';

interface LoadingPageProps {
  title?: string;
  withLogo?: boolean;
}

export const LoadingPage: React.FC<LoadingPageProps> = ({ title, withLogo }) => {
  const isFirstLoading = sessionStorage.getItem('firstLoading') !== 'true';
  const { getToken } = useCurrentToken();

  useEffect(() => {
    if (getToken()) {
      setTimeout(() => {
        sessionStorage.setItem('firstLoading', 'true');
      }, 2000);
    }
  }, [getToken]);

  const shouldShowMotivLogo = (isFirstLoading && !title) || withLogo;

  return (
    <Container>
      {shouldShowMotivLogo && (
        <LogoContainer>
          <MotivLogo />
        </LogoContainer>
      )}
      <Spinner size={shouldShowMotivLogo ? 40 : 104} />
    </Container>
  );
};

export default LoadingPage;
