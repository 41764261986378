import bloodPressurePng from 'assets/images/pdf/blood-pressure.png';
import dumbellPng from 'assets/images/pdf/dumbell.png';
import flagPng from 'assets/images/pdf/flag.png';
import lessonsPng from 'assets/images/pdf/lessons.png';
import medicationPng from 'assets/images/pdf/medication.png';
import phonePng from 'assets/images/pdf/phone.png';
import stepsPng from 'assets/images/pdf/steps.png';
import weightPng from 'assets/images/pdf/weight.png';
import { ReactComponent as Exercise } from 'assets/vectors/fi-rr-exercise_small.svg';
import { ReactComponent as Flag } from 'assets/vectors/fi-rr-flag.svg';
import { ReactComponent as HeartRate } from 'assets/vectors/fi-rr-heart rate small.svg';
import { ReactComponent as Lesson } from 'assets/vectors/fi-rr-lesson.svg';
import { ReactComponent as Medications } from 'assets/vectors/fi-rr-medication small.svg';
import { ReactComponent as Phone } from 'assets/vectors/fi-rr-phone small.svg';
import { ReactComponent as Steps } from 'assets/vectors/fi-rr-steps small.svg';
import { ReactComponent as Weight } from 'assets/vectors/fi-rr-weight small.svg';
import compareAsc from 'date-fns/compareAsc';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'utils/types';

import { CarePlanGoal, CarePlanItem } from '../../CarePlan.model';

type CarePlanItemValues = {
  title: string;
  description: string | string[] | null;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  pngIcon: string;
};

export const usePlanItem = (goal: Maybe<CarePlanGoal>, type?: CarePlanItem): CarePlanItemValues | null => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.carePlan.items' });
  if (!goal || !type) {
    return null;
  }

  const isCurrent = () => {
    return compareAsc(Date.now(), new Date(goal.start_on as string)) >= 0;
  };

  if (!isCurrent()) {
    return null;
  }

  switch (type) {
    case 'personal':
      return {
        title: t('goals'),
        description: goal.subgoals?.map((subgoal) => subgoal?.name) as string[],
        icon: Flag,
        pngIcon: flagPng,
      };
    case 'pressure':
      return {
        title: t('bloodPressure'),
        description:
          goal.subgoals && goal.subgoals.length > 0
            ? goal.subgoals.length < 4
              ? t(goal.subgoals.length.toString())
              : t('bloodPressureDesc', { times: goal.subgoals.length })
            : '',
        icon: HeartRate,
        pngIcon: bloodPressurePng,
      };
    case 'activity': {
      const activityTime = goal.description ? parseInt(goal.description) : 0;
      return {
        title: t('activity'),
        description: t('activityDesc', { daily: activityTime.toLocaleString('en-US') }),
        icon: Steps,
        pngIcon: stepsPng,
      };
    }
    case 'exercise':
      return {
        title: t('exercise'),
        description: t('exerciseDesc'),
        icon: Exercise,
        pngIcon: dumbellPng,
      };
    case 'weight':
      return {
        title: t('weight'),
        description: t('weightDesc', { times: goal.subgoals?.length || 0 }),
        icon: Weight,
        pngIcon: weightPng,
      };

    case 'appointments':
      return {
        title: t('appointments'),
        description: goal.subgoals?.map((subgoal) => subgoal?.name) as string[],
        icon: Phone,
        pngIcon: phonePng,
      };
    case 'medications':
      return {
        title: t('medications'),
        description: t('medicationsDesc'),
        icon: Medications,
        pngIcon: medicationPng,
      };
    case 'lessons':
      return {
        title: t('lessons'),
        description: t('lessonsDesc'),
        icon: Lesson,
        pngIcon: lessonsPng,
      };
  }
};
