import { format } from 'date-fns';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { Typography } from '../Typography/Typography';
import { Box, DataContainer, EntriesContainer, GroupContainer, Label } from './ReadingsList.styled';

export type Reading = {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export interface ReadingsListProps {
  data: Reading[];
  leftLabel?: string;
  rightLabel?: string;
}

export const ReadingsList: React.FC<ReadingsListProps> = ({ data, leftLabel, rightLabel }) => {
  const groupedData = groupBy(data, (el) => format(el.createdAt, 'yyyy-MM-dd'));
  const sortedData = sortBy(groupedData, (group) => {
    return data.indexOf(group[0]);
  });

  return (
    <GroupContainer>
      {sortedData.map((group) => (
        <div key={format(group[0].createdAt, 'EEE-MMM-d')}>
          <Typography variant="Body1Bold" color="black.700">
            {format(group[0].createdAt, 'EEE, MMM d')}
          </Typography>
          <Label>
            <Typography variant="Helper1" color="black.600">
              {leftLabel}
            </Typography>
            <Typography variant="Helper1" color="black.600">
              {rightLabel}
            </Typography>
          </Label>
          <EntriesContainer>
            {group.map((item) => (
              <Box key={item.id}>
                <Typography size={10} color="black.800" lineHeight={12}>
                  {format(item.updatedAt ? item.updatedAt : item.createdAt, 'h:mm aaa')}
                </Typography>
                <DataContainer>
                  <div>{item.leftContent}</div>
                  <div>{item.rightContent}</div>
                </DataContainer>
              </Box>
            ))}
          </EntriesContainer>
        </div>
      ))}
    </GroupContainer>
  );
};

export default ReadingsList;
