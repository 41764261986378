import axios from 'axios';
import { env } from 'index';

export const setProgramDate = (): Promise<void> =>
  axios.post(
    `${env.REACT_APP_AWS_LAMBDA_URL}/program-start-date`,
    {},
    {
      headers: {
        'healthie-token': localStorage.getItem('token'),
      },
    },
  );
