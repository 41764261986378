export type Maybe<T> = T | null | undefined;

export type Group = {
  name: string;
  created_at: string;
  notifications?: {
    notified_at: string;
  }[];
};

export enum DaysOfWeek {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export type MotivUserData = {
  outreach: Group[];
  reminderDays: DaysOfWeek[];
  emailReminders?: boolean;
  textReminders?: boolean;
  stopMessageReceivedAt?: string;
  onHoldActivePatientsTimestamp?: string[];
  removedFromOnHoldTimestamp?: string[];
};
