import axios from 'axios';
import { env } from 'index';

export type ResetLinkPayload = {
  email: string;
};
export const sendResetPasswordLink = ({ email }: ResetLinkPayload): Promise<void> =>
  axios.post(`${env.REACT_APP_AWS_LAMBDA_URL}/forgot-password`, { email });

export type NewPasswordPayload = {
  token: string;
  password: string;
};
export const sendNewPassword = ({ token, password }: NewPasswordPayload): Promise<void> =>
  axios.post(`${env.REACT_APP_AWS_LAMBDA_URL}/forgot-password`, { token, password }).then((result) => result.data);
