import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as AngleSmallRight } from 'assets/vectors/fi-rr-angle-small-right.svg';
import { ReactComponent as Logo } from 'assets/vectors/logo.svg';
// import { useCurrentFitbitTokens } from 'hooks/useCurrentFitbitTokens';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';

import * as Styled from './Settings.styles';
import { SettingsLayout } from './components/Layout/SettingsLayout';
import { LogOutModal } from './components/LogOutModal/LogOutModal';

const testUsers = ['474472'];
const prodUsers = ['5367155'];
const usersList = process.env.NODE_ENV === 'production' ? prodUsers : testUsers;

export const Settings: React.FC = () => {
  const navigate = useNavigate();
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);
  const userId = currentUserData?.currentUser?.id;

  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  // const { hasLoggedInBefore } = useCurrentFitbitTokens();

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings' });
  }, []);

  const SETTINGS_SECTIONS = [
    { title: t('personalInfo'), path: paths.settings.personalInfo },
    { title: t('accountSettings'), path: paths.settings.accountSettings },
    ...(userId && usersList.includes(userId) ? [{ title: t('devices'), path: paths.settings.devices }] : []),
    { title: t('notifications'), path: paths.settings.notifications },
    { title: t('myConsents'), path: paths.settings.consents },
    { title: t('frequentlyAskedQuestions'), path: paths.settings.faq },
  ];

  return (
    <SettingsLayout>
      {SETTINGS_SECTIONS.map((section, idx) => (
        <Styled.Section
          tabIndex={0}
          key={idx}
          color="blue.700"
          onClick={() => (section.path ? navigate(section.path) : null)}
        >
          {section.title}
          <Button variant="icon" style={{ width: 'auto' }} aria-label={section.title}>
            <Icon element={AngleSmallRight} title={section.title} color="blue.700" />
          </Button>
        </Styled.Section>
      ))}
      <Styled.ButtonContainer>
        <Styled.SButton variant="secondary" onClick={() => setIsLogOutModalOpen(true)}>
          {t('logOut')}
        </Styled.SButton>
      </Styled.ButtonContainer>
      <Styled.SIcon element={Logo} color="blue.700" title={t('logo')} />
      <LogOutModal isOpen={isLogOutModalOpen} onClose={() => setIsLogOutModalOpen(false)}></LogOutModal>
    </SettingsLayout>
  );
};
