import styled from 'styled-components';

import { ArticleHeader } from 'components/ui/ArticleHeader/ArticleHeader';
import { ArticleTab } from 'components/ui/ArticleTab/ArticleTab';
import { Typography } from 'components/ui/Typography/Typography';

export const AllFilled = styled(Typography)`
  text-align: center;
`;

export const AssessmentTab = styled(ArticleTab)`
  margin: 0 -16px;
`;

export const AssessmentHeader = styled(ArticleHeader)`
  margin-bottom: 16px;
`;
