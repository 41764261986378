import React from 'react';
import styled, { DefaultTheme, StyledComponent, css } from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

type Props = {
  $disabled?: boolean;
  $error?: boolean;
  $valid?: boolean;
};

export const Container = styled.div`
  width: 100%;
`;

export const HelperText = styled(Typography.Span)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 6px;
`;

export const ErrorMessage = styled(Typography.Div)`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 6px;
`;

export const InputContainer = styled.div<Props>`
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  background-color: ${({ theme }) => theme.color.other.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;
  padding: 16px;
  ${({ theme, $disabled, $error, $valid }) =>
    $disabled
      ? css`
          border: 1px solid ${theme.color.black[100]};
        `
      : $error
      ? css`
          border: 1.5px solid ${theme.color.other.red};
          &&&& ${InputLabel}, &&&& ${TextAreaLabel} {
            color: ${theme.color.other.red};
          }
        `
      : $valid
      ? css`
          border: 1.5px solid ${theme.color.other.green};
          &&&& ${InputLabel}, &&&& ${TextAreaLabel} {
            color: ${theme.color.other.green};
          }
        `
      : css`
          &:focus-within {
            border: 1.5px solid ${theme.color.blue[500]};
          }
        `}
`;

const txtStyle = css`
  background: ${({ theme }) => theme.color.other.white};
  color: ${({ theme }) => theme.color.black[900]};
  font-size: 16px;
  border-radius: 4px;
  border: none;
  display: block;
  width: 100%;
`;

const labelStyle = css`
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  padding: 0 5px;
  background: linear-gradient(180deg, transparent 0 41%, ${({ theme }) => theme.color.other.white} 41% 100%);
  transition: 0.2s ease all;
  transform: translateY(-50%);
`;

const withLabel = (Label: StyledComponent<React.FC<Props & React.HTMLProps<HTMLLabelElement>>, DefaultTheme>) => css`
  &:focus {
    outline: none;
    ~ ${Label} {
      top: -10px;
      font-size: 14px;
      color: ${({ theme }) => theme.color.blue[600]};
      transform: none;
    }
  }

  &:not(:placeholder-shown) ~ ${Label} {
    top: -10px;
    font-size: 14px;
    transform: none;
  }

  &:disabled {
    ~ ${Label} {
      color: ${({ theme }) => theme.color.black[300]};
    }
  }
`;

export const InputLabel = styled(Typography.Label)`
  ${labelStyle}
  top: 50%;
`;

export const TextAreaLabel = styled(Typography.Label)`
  ${labelStyle}
  top: 30px;
`;

export const TextArea = styled.textarea<{ $height?: number | string }>`
  ${txtStyle}
  ${withLabel(TextAreaLabel)}
  min-height: 26px;
  height: ${(props) => props.$height}px;
  resize: none;
`;

export const Input = styled.input`
  ${txtStyle}
  ${withLabel(InputLabel)}
`;
