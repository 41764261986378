import { ReactComponent as CrossIcon } from 'assets/vectors/fi-rr-cross-small.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { CloseButtonContainer, Content, Overlay } from './Modal.styles';

export const Modal: React.FC<ReactModal.Props> = ({ ...props }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.modal' });

  return (
    <ReactModal
      contentElement={(props, children) => <Content {...props}>{children}</Content>}
      overlayElement={(props, contentElement) => <Overlay {...props}>{contentElement}</Overlay>}
      className="_"
      overlayClassName="_"
      ariaHideApp={false}
      {...props}
    >
      {!!props.onRequestClose && (
        <CloseButtonContainer>
          <Button variant="icon" size="sm" onClick={props.onRequestClose} aria-label={t('close')}>
            <Icon element={CrossIcon} />
          </Button>
        </CloseButtonContainer>
      )}
      {props.children}
    </ReactModal>
  );
};

export default Modal;
