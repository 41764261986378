import { theme } from 'assets/styles/theme';
import { FlattenObjectKeys } from 'models/utils.model';

export type TextColor = FlattenObjectKeys<typeof theme.color>;

export type TextVariant =
  | 'Title1'
  | 'Headline1'
  | 'Headline2'
  | 'SubHeadline1'
  | 'SubHeadline1Medium'
  | 'SubHeadline2'
  | 'Body1'
  | 'Body1Bold'
  | 'Helper1'
  | 'Helper1Bold'
  | 'Helper2'
  | 'Helper2Bold'
  | 'Link1';

export type TextStyle = {
  size: keyof typeof theme.font.size;
  lineHeight: keyof typeof theme.font.lineHeight;
  weight?: keyof typeof theme.font.weight;
  letterSpacing?: string;
  as?: React.ElementType;
};

export const textStyles: Record<TextVariant, TextStyle> = {
  Title1: {
    size: 36,
    lineHeight: 48,
    weight: 500,
    as: 'h1',
    letterSpacing: '-0.035em',
  },
  Headline1: {
    size: 28,
    lineHeight: 36,
    weight: 600,
    as: 'h2',
    letterSpacing: '-0.02em',
  },
  Headline2: {
    size: 24,
    lineHeight: 32,
    weight: 600,
    as: 'h3',
    letterSpacing: '-0.02em',
  },
  SubHeadline1: {
    size: 20,
    lineHeight: 28,
    weight: 400,
    as: 'h4',
    letterSpacing: '-0.015em',
  },
  SubHeadline1Medium: {
    size: 20,
    lineHeight: 28,
    weight: 500,
    as: 'h4',
    letterSpacing: '-0.015em',
  },
  SubHeadline2: {
    size: 18,
    lineHeight: 24,
    weight: 400,
    as: 'h5',
    letterSpacing: '-0.015em',
  },
  Body1: {
    size: 16,
    lineHeight: 20,
    weight: 400,
    as: 'p',
    letterSpacing: '0.03em',
  },
  Body1Bold: {
    size: 16,
    lineHeight: 20,
    weight: 600,
    as: 'p',
    letterSpacing: '0.03em',
  },
  Helper1: {
    size: 14,
    lineHeight: 20,
    letterSpacing: '0em',
    weight: 400,
  },
  Helper1Bold: {
    size: 14,
    lineHeight: 20,
    letterSpacing: '0.02em',
    weight: 500,
  },
  Helper2: {
    size: 12,
    lineHeight: 20,
    letterSpacing: '0.02em',
    weight: 400,
  },
  Helper2Bold: {
    size: 12,
    lineHeight: 20,
    letterSpacing: '0.02em',
    weight: 600,
  },
  Link1: {
    size: 16,
    lineHeight: 20,
    weight: 400,
    as: 'a',
    letterSpacing: '0.02em',
  },
};
