import { theme } from 'assets/styles/theme';
import { icons } from 'assets/vectors/iconsList';
import get from 'lodash/get';
import { FlattenObjectKeys } from 'models/utils.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { Icon } from '../Icon/Icon';
import * as Styled from './TaskCompletionPage.styles';

type BackgroundColor = FlattenObjectKeys<typeof theme.color>;

interface TaskCompletedPageProps {
  headerTitle?: string;
  taskTitle: string;
  taskDescription: string;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  color?: BackgroundColor;
  icon?: keyof typeof icons;
  progressPath?: string;
  backPath?: string | -1;
}

export const TaskCompletionPage: React.FC<TaskCompletedPageProps & React.HTMLProps<HTMLDivElement>> = ({
  headerTitle,
  taskTitle,
  taskDescription,
  footer,
  icon,
  color = 'other.violet',
  progressPath = paths.homePage,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  backPath = paths.homePage,
  children,
  ...rest
}: TaskCompletedPageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.completedPage' });

  const bgColor = get(theme.color, color) || '#000';

  return (
    <Styled.PageContainer bgColor={bgColor} {...rest}>
      {headerTitle && (
        <Styled.HeaderContainer>
          {/* <Styled.SButton
            aria-label={t('goBackToHome')}
            onClick={() => {
              navigate(backPath as string);
            }}
            variant="icon"
          >
            <Icon element={icons['fi-rr-angle-small-left.svg']} color="other.white" />
          </Styled.SButton> */}
          <div style={{ flex: 1 }}></div>
          <Styled.HeaderTitle variant="Helper1" color="other.white">
            {headerTitle}
          </Styled.HeaderTitle>
          <Styled.Spacer />
        </Styled.HeaderContainer>
      )}
      <Styled.Content>
        <Styled.TaskTitle variant="Headline1" color="other.white">
          {taskTitle}
        </Styled.TaskTitle>
        {children}
        <Icon element={icons[icon ? icon : 'fi-rr-done circle.svg']} size={112} color="other.white"></Icon>
        <Styled.TaskDescription variant="Body1" color="other.white">
          {taskDescription}
        </Styled.TaskDescription>
      </Styled.Content>
      <Styled.ButtonContainer>
        {footer ? (
          footer
        ) : (
          <>
            <Styled.ProgressButton type="submit" variant="primary" onClick={() => navigate(progressPath)}>
              {t('viewMyProgress')}
            </Styled.ProgressButton>
            <Styled.HomeButton
              type="submit"
              variant="secondary"
              onClick={() => navigate(paths.homePage)}
              bgColor={bgColor}
            >
              {t('goBackToHome')}
            </Styled.HomeButton>
          </>
        )}
      </Styled.ButtonContainer>
    </Styled.PageContainer>
  );
};
