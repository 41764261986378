import { gql } from '__generated__/gql';

export const CREATE_FITBIT = gql(`
  mutation createFitbit($input: createFitbitInput!) {
    createFitbit(input: $input) {
      fitbit {
        id
      }
      messages {
        field
        message
      }
    }
  }
`);

export const SAVE_USER_FITBIT_CRED = gql(`
    mutation saveUserFitbitCred($form_group_id: ID, $form_answer: [FormAnswerInput!]!) {
        updateFormAnswerGroup(input: {
            id: $form_group_id
          form_answers: $form_answer
        } ) {
          form_answer_group {
            name
            form_answers {
              answer
            }
          }
        }
    }
`);
