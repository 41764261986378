import { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';

export const mapTabNameToIndex = (tab: string | null): number => {
  switch (tab) {
    case 'week':
      return TimeTabsIndex.week;
    case 'month':
      return TimeTabsIndex.month;
    case 'all':
      return TimeTabsIndex.all_time;
    default:
      return TimeTabsIndex.week;
  }
};

export const mapIndexToTabName = (tab: number): string => {
  switch (tab) {
    case 0:
      return 'week';
    case 1:
      return 'month';
    case 2:
      return 'all';
    default:
      return 'week';
  }
};
