import styled from 'styled-components';

export const HomeLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
  min-height: 100%;
`;

export const HomePageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  padding-bottom: 0;
  margin-bottom: calc(${(props) => props.theme.nav.height} + 40px);
`;

export const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const HomeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  margin-bottom: 0;
`;
