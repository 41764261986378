import React from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'router/paths';

import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

export const RecordYourMedicationsCompleted: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.recordYourMedications' });
  return (
    <TaskCompletionPage
      taskTitle={t('completed.completedGoalTitle')}
      headerTitle={t('recordYourMedications')}
      taskDescription={t('completed.completedGoalDescription')}
      color="other.backgroundBlue"
      progressPath={paths.medications}
    />
  );
};
