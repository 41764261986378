import { useVisualViewport } from 'hooks/useVisualViewport';
import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { Navigation } from 'components/ui/Navigation/Navigation';

import { Header } from '../BaseLayout/Header';
import * as Styled from './ChatLayout.styles';

interface ChatLayoutProps {
  children: React.ReactNode;
  layoutRef?: MutableRefObject<HTMLDivElement>;
  headerRef?: MutableRefObject<HTMLDivElement>;
  navigationRef?: MutableRefObject<HTMLDivElement>;
  hideNavigation?: boolean;
}

export const ChatLayout: React.FC<ChatLayoutProps> = ({
  children,
  layoutRef,
  headerRef,
  navigationRef,
  hideNavigation = false,
}: ChatLayoutProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.chat' });
  const { visualViewportHeight } = useVisualViewport();

  return (
    <Styled.LayoutContainer ref={layoutRef} style={{ height: `${visualViewportHeight}px` }}>
      <Styled.Header ref={headerRef}>
        <Header title={t('chat')} />
      </Styled.Header>
      <Styled.PageContentContainer>{children}</Styled.PageContentContainer>
      {!hideNavigation && (
        <Styled.NavigationContainer ref={navigationRef}>
          <Navigation />
        </Styled.NavigationContainer>
      )}
    </Styled.LayoutContainer>
  );
};
