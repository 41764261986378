import { Goal } from '__generated__/graphql';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { Maybe } from 'utils/types';

type Statistics = {
  requiredMeasurementsInWeek: number;
  completedMeasurements: number;
  goal: Maybe<string>;
};

export const useGetMeasurementsStatistics = (goals: Goal[]): Statistics => {
  const filteredGoals = goals?.filter((goal) => /pressure/i.test(goal?.name?.toLowerCase() || '')) || [];

  // Sort the filtered goals by the start_on date, newest first
  filteredGoals.sort((a, b) => {
    const dateA: Date = new Date(a.start_on);
    const dateB: Date = new Date(b.start_on);
    return dateB > dateA ? 1 : dateB < dateA ? -1 : 0;
  });

  const pressureGoal = !isEmpty(filteredGoals) ? filteredGoals[0] : null;

  const requiredMeasurementsInWeek = useMemo(() => {
    return (pressureGoal?.subgoals?.length || 0) * 7;
  }, [pressureGoal]);

  const completedMeasurements =
    pressureGoal?.subgoals?.reduce((acc, item) => {
      return acc + (item?.goal_histories?.length || 0);
    }, 0) || 0;

  return {
    requiredMeasurementsInWeek,
    completedMeasurements,
    goal: pressureGoal?.description,
  };
};
