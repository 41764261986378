import { format, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT, parseHealthieDate } from 'utils/helpers';
import { Maybe } from 'utils/types';

export const useAppointmentDate = (date: Maybe<string>): string => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home' });

  if (date) {
    const appointmentDate = parseHealthieDate(date);
    if (isToday(appointmentDate)) {
      return t('todayAppointment', { value: format(appointmentDate, 'h:mmaaa') });
    } else {
      return format(parseHealthieDate(date), DATE_TIME_FORMAT);
    }
  }
  return '';
};
