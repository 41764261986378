import { useQuery } from '@apollo/client';
import { GET_APPOINTMENTS } from 'api/appointment/appointment.query';
import { useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { DEFAULT_POLLING_INTERVAL } from 'utils/helpers';

import { ApiAppointment, AppointmentStatus, SingleAppointment } from './components/AppointmentsList/types';

interface UseAppointmentsData {
  pastAppointments: SingleAppointment[];
  pastCompletedAppointments: SingleAppointment[];
  upcomingAppointments: SingleAppointment[];
  upcomingAppointmentsLoading: boolean;
  pastAppointmentsLoading: boolean;
  pastCompletedAppointmentsLoading: boolean;
  isFirstAppointment: boolean;
  onDownloadZoomClick: () => void;
}

const transformAppointmentData = (item: ApiAppointment) => {
  return {
    title: item?.appointment_type?.name,
    id: item?.id,
    end: item?.end,
    start: item?.start,
    zoomJoinUrl: item?.zoom_join_url,
    appointmentTypeId: item?.appointment_type?.id,
    status: item?.pm_status as AppointmentStatus,
  };
};

export const useAppointmentsData = (): UseAppointmentsData => {
  const [isFirst, setIsFirst] = useState(false);

  const { data: upcomingAppointmentsData, loading: upcomingAppointmentsLoading } = useQuery(GET_APPOINTMENTS, {
    variables: {
      should_paginate: false,
      filter: 'upcoming',
    },
    pollInterval: DEFAULT_POLLING_INTERVAL,
    fetchPolicy: 'cache-and-network',
  });

  const { data: pastAppointmentsData, loading: pastAppointmentsLoading } = useQuery(GET_APPOINTMENTS, {
    variables: {
      should_paginate: false,
      filter: 'past',
      with_all_statuses: true,
    },
    pollInterval: DEFAULT_POLLING_INTERVAL,
    fetchPolicy: 'cache-and-network',
  });

  const { data: pastCompletedAppointmentsData, loading: pastCompletedAppointmentsLoading } = useQuery(
    GET_APPOINTMENTS,
    {
      variables: {
        should_paginate: false,
        filter: 'past',
      },
      pollInterval: DEFAULT_POLLING_INTERVAL,
      fetchPolicy: 'cache-and-network',
    },
  );

  const upcomingAppointments = useMemo(() => {
    if (!upcomingAppointmentsData?.appointments) {
      return [];
    }
    return upcomingAppointmentsData?.appointments?.map((item) => transformAppointmentData(item as ApiAppointment));
  }, [upcomingAppointmentsData]);

  const pastAppointments = useMemo(() => {
    if (!pastAppointmentsData?.appointments) {
      return [];
    }
    return pastAppointmentsData?.appointments?.map((item) => transformAppointmentData(item as ApiAppointment));
  }, [pastAppointmentsData]);

  const pastCompletedAppointments = useMemo(() => {
    if (!pastCompletedAppointmentsData?.appointments) {
      return [];
    }
    return pastCompletedAppointmentsData?.appointments?.map((item) => transformAppointmentData(item as ApiAppointment));
  }, [pastCompletedAppointmentsData]);

  useEffect(() => {
    setIsFirst(
      pastAppointments.length === 0 &&
        upcomingAppointments.length === 1 &&
        localStorage.getItem('zoomDownloaded') != 'true',
    );
  }, [pastAppointments, upcomingAppointments]);

  const onDownloadZoomClick = () => {
    localStorage.setItem('zoomDownloaded', 'true');
    setIsFirst(false);
    if (isIOS) {
      window.location.href = 'https://itunes.apple.com/us/app/id546505307';
    } else if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=us.zoom.videomeetings';
    } else {
      window.location.href = 'https://zoom.us/download';
    }
  };

  return {
    pastAppointments,
    pastAppointmentsLoading,
    pastCompletedAppointments,
    pastCompletedAppointmentsLoading,
    upcomingAppointments,
    upcomingAppointmentsLoading,
    isFirstAppointment: isFirst,
    onDownloadZoomClick,
  };
};
