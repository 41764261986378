import styled, { css } from 'styled-components';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

export const AccordionSections = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const AccordionSection = styled.div<{ isAccordion: boolean }>`
  display: flex;
  flex-direction: column;
  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.color.black[50]};
  }
  border-bottom: 1px solid ${({ theme }) => theme.color.black[50]};
`;

export const AccordionTitle = styled(Typography)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  cursor: pointer;
  padding: 24px 16px;
`;

export const AccordionContent = styled(Typography)<{ isActive: boolean; isAccordion: boolean }>`
  max-height: 0;
  overflow: hidden;
  padding: 0px ${(props) => (props.isAccordion ? '0px' : '16px')};
  ${({ isActive, isAccordion }) =>
    isActive &&
    css`
      max-height: 100%;
      padding-bottom: ${() => (isAccordion ? '0px' : '16px')};
    `}
`;

export const ArrowIcon = styled(Icon)<{ isOpen: boolean }>`
  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) => isOpen && 'transform: rotate(180deg)'};
`;
