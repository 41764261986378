import { ReactComponent as Step_3 } from 'assets/images/BP-manual/BP-step-3.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from '../../BloodPressureManual.styles';

export const Step3: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressureManual.step3' });

  return (
    <Styled.StepContainer>
      <Styled.ImageContainer>
        <Step_3 />
      </Styled.ImageContainer>
      <Styled.DescriptionContainer>
        <Styled.Description weight={700}>{t('description1')}</Styled.Description>
        <Styled.Description>{t('description2')}</Styled.Description>
      </Styled.DescriptionContainer>
    </Styled.StepContainer>
  );
};
