import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
  padding: 20px 20px;

  // &:last-child {
  //   border-top: 1px solid ${(props) => props.theme.color.black[50]};
  // }
`;

export const Container = styled(Typography.Div)`
  font-size: large;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
  }
`;
