import { GoalsQuery } from '__generated__/graphql';
import { useMemo } from 'react';

import { healthieDateToISO } from '../activity/timeUtils';
import { Answer } from './MedicationsReadings';

export const useGetMappedMedications = (data: GoalsQuery | undefined): Answer[] => {
  const filteredMedicationsAnswers = useMemo(
    () =>
      data?.goals?.flatMap((goal) => {
        return goal?.subgoals?.flatMap((subgoal, index) => {
          return subgoal?.goal_histories.map((history) => {
            if (history.created_at)
              return {
                name: subgoal.name,
                percentageCompleted: 100 - index * 25,
                date: healthieDateToISO(history.created_at),
                subgoalId: subgoal.id,
              };
          });
        });
      }) ?? [],
    [data?.goals],
  );

  return filteredMedicationsAnswers.filter(Boolean) as Answer[];
};
