import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_DASI_ENTRY,
  CREATE_QUESTIONNAIRE_ANSWER,
  UPDATE_QUESTIONNAIRE_ANSWER,
} from 'api/questionnaires/questionnaires.mutation';
import { GET_SINGLE_QUESTIONNAIRE } from 'api/questionnaires/questionnaires.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { FormikHelpers } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Maybe } from 'utils/types';

import { QuestionOption } from 'components/ui/Question/Question';

import {
  Answer,
  BRACKET,
  Question,
  calculateDasi,
  dummyQuestion,
  mapAnswerForSubmit,
  modTypeMapping,
} from '../Questionnaires.model';

export type QuestionnaireFormValues = {
  answer: string | string[];
};

type Return = {
  onBackClick: (setValues: FormikHelpers<QuestionnaireFormValues>['setValues']) => void;
  onSubmit: (values: QuestionnaireFormValues, { setValues }: FormikHelpers<QuestionnaireFormValues>) => void;
  saveForLater: (values: QuestionnaireFormValues) => void;
  isSubmitStep: boolean;
  currentQuestion: Question | undefined;
  questionIndex: number;
  answers: Answer[];
  questionCount: number | undefined;
  title: string;
};

export const useQuestionnaire = (
  questionnaireId?: Maybe<string>,
  course_id?: Maybe<string>,
  course_item_id?: Maybe<string>,
  incompleteId?: Maybe<string>,
): Return => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.questionnaires' });
  const navigate = useNavigate();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<Question[]>();
  const [title, setTitle] = useState('');
  const [isDasiQuestion, setIsDasiQuestion] = useState(false);

  const [createQuestionAnswer] = useMutation(CREATE_QUESTIONNAIRE_ANSWER);
  const [updateQuestionAnswer] = useMutation(UPDATE_QUESTIONNAIRE_ANSWER);
  const [createDasiEntry] = useMutation(CREATE_DASI_ENTRY);
  const userId = useQuery(GET_CURRENT_USER_DATA, { fetchPolicy: 'no-cache' }).data?.currentUser?.id;
  const { data } = useQuery(GET_SINGLE_QUESTIONNAIRE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_id: userId,
      custom_module_form_id: questionnaireId,
      incomplete_form_id: incompleteId || undefined,
    },
    skip: !userId,
  });

  useEffect(() => {
    const questionTitle =
      data?.customModuleForm?.custom_modules.find((q) => q.mod_type === 'read_only')?.options_array?.join('') ||
      data?.customModuleForm?.custom_modules.find((q) => q.mod_type === 'label')?.label ||
      '';
    setTitle(questionTitle);
    const initialQuestions = data?.customModuleForm?.custom_modules?.filter((q) =>
      Object.keys(modTypeMapping).includes(q.mod_type ?? ''),
    );
    const questions = initialQuestions?.map((question) => {
      const isRadioQuestion = question.mod_type === 'radio' || question.mod_type === 'horizontal_radio';
      const isCheckboxQuestion = question.mod_type === 'checkbox';
      const isAgreeQuestion = question.mod_type === 'agree_to_above';
      const answer = data?.initialFormAnswers?.find((item) => question.id === item?.custom_module_id)?.answer;
      return {
        id: question.id,
        title: question.label,
        type: modTypeMapping[question.mod_type as string],
        options:
          isRadioQuestion || isCheckboxQuestion
            ? question?.options_array?.map((option) => {
                if (option?.match(BRACKET)) {
                  setIsDasiQuestion(true);
                }
                return { value: option, label: option?.replace(BRACKET, ''), name: 'answer' } as QuestionOption;
              })
            : isAgreeQuestion
            ? [{ value: t('agree'), label: t('agree'), name: 'answer' } as QuestionOption]
            : [{ label: t('enterAnswer'), name: 'answer' } as QuestionOption],
        answer: answer ? (isCheckboxQuestion || isAgreeQuestion ? answer.split('\n') : answer) : '',
      };
    });
    setQuestions(questions);
  }, [data?.customModuleForm?.custom_modules, data?.initialFormAnswers, t]);

  const isSubmitStep = questionIndex === questions?.length;
  const currentQuestion: Question | undefined = isSubmitStep ? dummyQuestion : questions?.[questionIndex];
  const answers: Answer[] = useMemo(
    () =>
      questions?.map((q) => ({
        custom_module_id: q.id,
        user_id: userId,
        answer: q.answer,
        label: q.title,
      })) || [],
    [questions, userId],
  );

  const onSubmit = async (
    { answer }: QuestionnaireFormValues,
    { setValues }: FormikHelpers<QuestionnaireFormValues>,
  ) => {
    if (isSubmitStep) {
      if (isDasiQuestion) {
        const { total, vo2Peak, mets } = calculateDasi(answers);
        await createDasiEntry({
          variables: {
            type: 'NoteEntry',
            category: 'DASI',
            user_id: userId,
            description: data?.customModuleForm?.name,
            reflection: `Total Score: ${total}\nEstimate peak O2: ${vo2Peak}\nMETS: ${mets}`,
          },
        });
      }
      if (incompleteId) {
        await updateQuestionAnswer({
          variables: {
            id: incompleteId,
            finished: true,
            form_answers: answers.map(mapAnswerForSubmit),
            course_item_id: course_item_id,
          },
        });
      } else {
        await createQuestionAnswer({
          variables: {
            finished: true,
            custom_module_form_id: data?.customModuleForm?.id,
            user_id: userId,
            form_answers: answers.map(mapAnswerForSubmit),
            course_id: course_id,
            course_item_id: course_item_id,
          },
        });
      }
      navigate(paths.questionnaireComplete);
    } else {
      setQuestions((oldQuestions) => oldQuestions?.map((q) => (q?.id === currentQuestion?.id ? { ...q, answer } : q)));
      questionIndex + 1 !== questions?.length && setValues({ answer: questions?.[questionIndex + 1].answer || '' });
      setQuestionIndex(questionIndex + 1);
    }
  };

  const saveForLater = async ({ answer }: QuestionnaireFormValues) => {
    if (incompleteId) {
      await updateQuestionAnswer({
        variables: {
          id: incompleteId,
          finished: false,
          form_answers: answers
            .map((a) => (a.custom_module_id === currentQuestion?.id ? { ...a, answer } : a))
            .map(mapAnswerForSubmit),
          course_item_id: course_item_id,
        },
      });
    } else {
      await createQuestionAnswer({
        variables: {
          finished: false,
          custom_module_form_id: data?.customModuleForm?.id,
          user_id: userId,
          form_answers: answers
            .map((a) => (a.custom_module_id === currentQuestion?.id ? { ...a, answer } : a))
            .map(mapAnswerForSubmit),
          course_id: course_id,
          course_item_id: course_item_id,
        },
      });
    }
    navigate(paths.questionnaires);
  };

  const onBackClick = (setValues: FormikHelpers<QuestionnaireFormValues>['setValues']) => {
    if (questionIndex === 0) {
      navigate(paths.questionnaires);
    } else {
      setQuestionIndex(questionIndex - 1);
      setValues({ answer: questions?.[questionIndex - 1].answer || '' });
    }
  };

  return {
    onBackClick,
    onSubmit,
    saveForLater,
    isSubmitStep,
    currentQuestion,
    questionIndex,
    answers,
    questionCount: questions?.length,
    title,
  };
};
