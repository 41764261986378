import { ReactComponent as FirstAppointment } from 'assets/vectors/appointment.svg';
import { ReactComponent as CallOutline } from 'assets/vectors/call-outline-2.svg';
import { ReactComponent as ErrorSyncing } from 'assets/vectors/error-syncing.svg';
import { ReactComponent as Exercise } from 'assets/vectors/ff-rr-exercise.svg';
import { ReactComponent as MessagesColor } from 'assets/vectors/fi--rr-messages-color.svg';
import { ReactComponent as Messages } from 'assets/vectors/fi--rr-messages.svg';
import { ReactComponent as Add } from 'assets/vectors/fi-rr-add.svg';
import { ReactComponent as AngleSmallDown } from 'assets/vectors/fi-rr-angle-small-down.svg';
import { ReactComponent as AngleSmallLeft } from 'assets/vectors/fi-rr-angle-small-left.svg';
import { ReactComponent as AngleSmallRight } from 'assets/vectors/fi-rr-angle-small-right.svg';
import { ReactComponent as AngleSmallUp } from 'assets/vectors/fi-rr-angle-small-up.svg';
import { ReactComponent as AppointmentColor } from 'assets/vectors/fi-rr-appoitment-color.svg';
import { ReactComponent as Appointment } from 'assets/vectors/fi-rr-appoitment.svg';
import { ReactComponent as Attach } from 'assets/vectors/fi-rr-attach.svg';
import { ReactComponent as BloodPressure } from 'assets/vectors/fi-rr-blood pressure.svg';
import { ReactComponent as BookColor } from 'assets/vectors/fi-rr-book-color.svg';
import { ReactComponent as Book } from 'assets/vectors/fi-rr-book.svg';
import { ReactComponent as Books } from 'assets/vectors/fi-rr-books.svg';
import { ReactComponent as Camera1 } from 'assets/vectors/fi-rr-camera-1.svg';
import { ReactComponent as Camera } from 'assets/vectors/fi-rr-camera.svg';
import { ReactComponent as CrossDone } from 'assets/vectors/fi-rr-cross-done.svg';
import { ReactComponent as CrossRegular } from 'assets/vectors/fi-rr-cross-regular.svg';
import { ReactComponent as CrossSmall } from 'assets/vectors/fi-rr-cross-small.svg';
import { ReactComponent as DoneCircle } from 'assets/vectors/fi-rr-done circle.svg';
import { ReactComponent as Education } from 'assets/vectors/fi-rr-education.svg';
import { ReactComponent as Exclamation } from 'assets/vectors/fi-rr-exclamation.svg';
import { ReactComponent as Eye1 } from 'assets/vectors/fi-rr-eye-1.svg';
import { ReactComponent as Eye } from 'assets/vectors/fi-rr-eye.svg';
import { ReactComponent as FlipCamera } from 'assets/vectors/fi-rr-flip camera.svg';
import { ReactComponent as HeartColor } from 'assets/vectors/fi-rr-heart-color.svg';
import { ReactComponent as Info } from 'assets/vectors/fi-rr-info.svg';
import { ReactComponent as LeftAlignment } from 'assets/vectors/fi-rr-lef aligment.svg';
import { ReactComponent as Lesson } from 'assets/vectors/fi-rr-lesson.svg';
import { ReactComponent as Mail } from 'assets/vectors/fi-rr-mail.svg';
import { ReactComponent as Medication } from 'assets/vectors/fi-rr-medication.svg';
import { ReactComponent as Microphone1 } from 'assets/vectors/fi-rr-microphone-1.svg';
import { ReactComponent as Microphone } from 'assets/vectors/fi-rr-microphone.svg';
import { ReactComponent as Minus } from 'assets/vectors/fi-rr-minus.svg';
import { ReactComponent as NoInternet } from 'assets/vectors/fi-rr-no internet.svg';
import { ReactComponent as Phone } from 'assets/vectors/fi-rr-phone.svg';
import { ReactComponent as Picture } from 'assets/vectors/fi-rr-picture.svg';
import { ReactComponent as ProgressColor } from 'assets/vectors/fi-rr-progress2-color.svg';
import { ReactComponent as Progress } from 'assets/vectors/fi-rr-progress2.svg';
import { ReactComponent as ScheduleFirstCall } from 'assets/vectors/fi-rr-schedule first call.svg';
import { ReactComponent as SettingsColor } from 'assets/vectors/fi-rr-settings-color.svg';
import { ReactComponent as Settings } from 'assets/vectors/fi-rr-settings.svg';
import { ReactComponent as Share } from 'assets/vectors/fi-rr-share.svg';
import { ReactComponent as Steps } from 'assets/vectors/fi-rr-steps.svg';
import { ReactComponent as Time } from 'assets/vectors/fi-rr-time.svg';
import { ReactComponent as VitalsColor } from 'assets/vectors/fi-rr-vitals-color.svg';
import { ReactComponent as Vitals } from 'assets/vectors/fi-rr-vitals.svg';
import { ReactComponent as Waiting } from 'assets/vectors/fi-rr-waiting.svg';
import { ReactComponent as Weight } from 'assets/vectors/fi-rr-weight.svg';
import { ReactComponent as Home } from 'assets/vectors/fi=rr=home.svg';
import { ReactComponent as HomeColor } from 'assets/vectors/fi=rr=home_color.svg';
import { ReactComponent as Logo } from 'assets/vectors/logo.svg';
import { ReactComponent as Send } from 'assets/vectors/send.svg';

export const icons = {
  'fi--rr-exercise.svg': Exercise,
  'fi--rr-messages-color.svg': MessagesColor,
  'fi--rr-messages.svg': Messages,
  'fi--rr-phone.svg': Phone,
  'fi-rr-add.svg': Add,
  'fi-rr-angle-small-down.svg': AngleSmallDown,
  'fi-rr-angle-small-left.svg': AngleSmallLeft,
  'fi-rr-angle-small-right.svg': AngleSmallRight,
  'fi-rr-angle-small-up.svg': AngleSmallUp,
  'fi-rr-appoitment-color.svg': AppointmentColor,
  'fi-rr-appoitment.svg': Appointment,
  'fi-rr-attach.svg': Attach,
  'fi-rr-blood pressure.svg': BloodPressure,
  'fi-rr-book-color.svg': BookColor,
  'fi-rr-book.svg': Book,
  'fi-rr-books.svg': Books,
  'fi-rr-camera-1.svg': Camera1,
  'fi-rr-camera.svg': Camera,
  'fi-rr-cross-done.svg': CrossDone,
  'fi-rr-cross-regular.svg': CrossRegular,
  'fi-rr-cross-small.svg': CrossSmall,
  'fi-rr-done circle.svg': DoneCircle,
  'fi-rr-education.svg': Education,
  'fi-rr-exclamation.svg': Exclamation,
  'fi-rr-eye-1.svg': Eye1,
  'fi-rr-eye.svg': Eye,
  'fi-rr-flip camera.svg': FlipCamera,
  'fi-rr-info.svg': Info,
  'fi-rr-lef aligment.svg': LeftAlignment,
  'fi-rr-lesson.svg': Lesson,
  'fi-rr-mail.svg': Mail,
  'fi-rr-medication.svg': Medication,
  'fi-rr-microphone-1.svg': Microphone1,
  'fi-rr-microphone.svg': Microphone,
  'fi-rr-minus.svg': Minus,
  'fi-rr-no internet.svg': NoInternet,
  'fi-rr-picture.svg': Picture,
  'fi-rr-schedule first call.svg': ScheduleFirstCall,
  'fi-rr-settings.svg': Settings,
  'fi-rr-settings-color.svg': SettingsColor,
  'fi-rr-steps.svg': Steps,
  'fi-rr-vitals-color.svg': VitalsColor,
  'fi-rr-vitals.svg': Vitals,
  'fi-rr-waiting.svg': Waiting,
  'fi-rr-weight.svg': Weight,
  'fi=rr=home.svg': Home,
  'fi=rr=home_color.svg': HomeColor,
  'fi-rr-progress2.svg': Progress,
  'fi-rr-progress2-color.svg': ProgressColor,
  'send.svg': Send,
  'logo.svg': Logo,
  'fi-rr-cross-done-circle.svg': DoneCircle,
  'error-syncing.svg': ErrorSyncing,
  'fi-rr-heart-color.svg': HeartColor,
  'fi-rr-time.svg': Time,
  'fi-rr-share.svg': Share,
  firstAppointment: FirstAppointment,
  callOutline: CallOutline,
};
