import { getGoalType } from 'pages/home/components/Card/TaskCard';
import { useCarePlanGoals } from 'pages/home/hooks/useCarePlan';
import { HorizontalFlexContainer, StepsBox } from 'pages/progress/activity/Activity.styles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/ui/Typography/Typography';

import { Measurements } from '../types';
import { useGetAverage } from '../utils';

export const StatisticsSection: React.FC<Measurements> = ({ systolicData, diastolicData }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure' });
  const average = useGetAverage(systolicData, diastolicData);

  const { dailyGoals } = useCarePlanGoals();

  const pressureGoal = useMemo(() => {
    return dailyGoals.find((item) => getGoalType(item) === 'pressure');
  }, [dailyGoals]);

  return (
    <HorizontalFlexContainer>
      <StepsBox>
        <Typography variant="Body1" color="black.600">
          {t('average')}
        </Typography>
        <Typography.Span color="black.800">
          <Typography.Span size={20} weight={500}>
            {average}
          </Typography.Span>{' '}
          {t('mmHg')}
        </Typography.Span>
      </StepsBox>
      <StepsBox>
        <Typography.Span variant="Body1" color="black.600">
          {t('goal')}
        </Typography.Span>
        <Typography.Span color="black.800">
          <Typography.Span size={20} weight={500}>
            {t('goalValue', { value: pressureGoal?.description })}
          </Typography.Span>{' '}
          {t('mmHg')}
        </Typography.Span>
      </StepsBox>
    </HorizontalFlexContainer>
  );
};

export default StatisticsSection;
