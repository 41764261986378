import styled from 'styled-components';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

export const Label = styled(Typography.Label)`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.color.black[900]};
`;

export const TickIcon = styled(Icon)`
  position: absolute;
  pointer-events: none;
  display: none;
`;

export const Input = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 0;
  flex-shrink: 0;
  background: ${({ theme }) => theme.color.other.white};
  border: 2px solid ${({ theme }) => theme.color.black[900]};

  &:checked {
    background: ${({ theme }) => theme.color.other.green};
    border: none;

    ~ ${TickIcon} {
      display: flex;
    }

    &:disabled {
      background: ${({ theme }) => theme.color.black[50]};
    }
  }
  &:disabled {
    border-color: ${({ theme }) => theme.color.black[50]};
    ~ ${Label} {
      color: ${({ theme }) => theme.color.black[50]};
    }
  }
`;
