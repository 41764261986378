import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
`;

export const QuestionDesc = styled(Typography.P)`
  margin: -8px 0 8px;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 16px;

  & > * {
    flex-basis: calc(100% / 6);
  }
`;
