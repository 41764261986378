import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_APPOINTMENT_TYPES } from 'api/appointment/appointment.query';
import checkRepeatIntake from 'api/appointment/checkRepeatIntake';
import { INTAKE_FLOW } from 'api/consents/consents.query';
import { GET_CURRENT_USER_PROVIDER } from 'api/user/user.query';
import { env } from 'index';
import sample from 'lodash/sample';
import { useAppointmentsData } from 'pages/appointments/hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppointmentType, UserProviderDetails } from './types';

export const useGetUserProviderAppointmentDetails = (appointmentTypeId: string): UserProviderDetails => {
  const { data, loading } = useQuery(GET_CURRENT_USER_PROVIDER);
  const providerId = data?.provider?.id;

  return {
    appointmentTypeId: appointmentTypeId,
    providerId,
    loading,
  };
};

export const useGetUserTherapistAppointmentDetails = (appointmentTypeId: string): UserProviderDetails => {
  const { data, loading } = useQuery(GET_APPOINTMENT_TYPES);

  const app = data?.appointmentTypes?.find((item) => item?.id === appointmentTypeId);

  // Get random therapist to divide work between all
  const therapist = sample(app?.provider_appt_type_connections);

  return {
    appointmentTypeId: appointmentTypeId,
    providerId: therapist?.user_id,
    loading,
  };
};

export const useGetAllAppointmentTypes = (): AppointmentType[] => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.scheduleAppointment' });
  return [
    {
      id: env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
      type: 'coach',
      name: t('coachCheckIn'),
    },
    {
      id: env.REACT_APP_COACH_APPOINTMENT_TYPE_ID,
      type: 'coach',
      name: t('coachCheckIn'),
    },
    {
      id: env.REACT_APP_THERAPIST_APPOINTMENT_TYPE_ID,
      type: 'therapist',
      name: t('therapistCheckIn'),
    },
    {
      id: env.REACT_APP_THERAPIST_APPOINTMENT_FIRST_TIME_TYPE_ID,
      type: 'therapist',
      name: t('therapistCheckIn'),
    },
    {
      id: env.REACT_APP_GROUP_ORIENTATION_APPOINTMENT_TYPE_ID,
      type: 'coach',
      name: t('orientation'),
    },
    {
      id: env.REACT_APP_INDIVIDUAL_ORIENTATION_APPOINTMENT_TYPE_ID,
      type: 'coach',
      name: t('orientation'),
    },
  ];
};

export const useGetAppointmentTypesToDisplay = (): AppointmentType[] => {
  const [setupFirstAppointment, setSetupFirstAppointment] = useState(false);

  const [getUserId] = useLazyQuery(INTAKE_FLOW);
  const getUserIdRef = useRef(getUserId);

  const appointmentTypes = useGetAllAppointmentTypes();

  const findType = (id: string) => appointmentTypes.find((item) => item?.id === id);

  const { pastCompletedAppointments, upcomingAppointments } = useAppointmentsData();
  const allAppointments = [...pastCompletedAppointments, ...upcomingAppointments];

  const isFollowUpAppointmentWithCoach =
    !setupFirstAppointment &&
    allAppointments.some((item) => item.appointmentTypeId === env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID);

  const hadCallWithTherapist = allAppointments.some(
    (item) =>
      item.appointmentTypeId === env.REACT_APP_THERAPIST_APPOINTMENT_FIRST_TIME_TYPE_ID ||
      item.appointmentTypeId === env.REACT_APP_THERAPIST_APPOINTMENT_TYPE_ID,
  );

  const coachAppointmentType = findType(
    isFollowUpAppointmentWithCoach
      ? env.REACT_APP_COACH_APPOINTMENT_TYPE_ID
      : env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
  ) as AppointmentType;

  const therapistAppointmentType = findType(env.REACT_APP_THERAPIST_APPOINTMENT_TYPE_ID) as AppointmentType;

  useEffect(() => {
    if (getUserIdRef.current) {
      const checkRepeatIntakeNotScheduled = async () => {
        const userId = (await getUserIdRef.current()).data?.currentUser?.id;
        if (!userId) {
          return;
        }
        const { shouldScheduleRepeatIntake } = userId
          ? await checkRepeatIntake(userId)
          : { shouldScheduleRepeatIntake: false };
        const intakeAppointment = upcomingAppointments.find(
          (item) => item.appointmentTypeId === env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
        );
        if (shouldScheduleRepeatIntake && !intakeAppointment) {
          setSetupFirstAppointment(true);
        }
      };
      checkRepeatIntakeNotScheduled();
    }
  }, [upcomingAppointments]);

  if (hadCallWithTherapist) {
    return [coachAppointmentType, therapistAppointmentType];
  }

  return [coachAppointmentType];
};
