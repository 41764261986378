import React from 'react';
import { useTranslation } from 'react-i18next';

import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

export const RecordYourWeightCompleted: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.recordYourWeight' });
  return (
    <TaskCompletionPage
      taskTitle={t('completed.completedGoalTitle')}
      headerTitle={t('recordYourWeight')}
      taskDescription={t('completed.completedGoalDescription')}
    />
  );
};
