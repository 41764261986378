import { theme } from 'assets/styles/theme';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { FlattenObjectKeys } from 'models/utils.model';
import React from 'react';

import * as Styled from './Icon.style';

type IconColor = FlattenObjectKeys<typeof theme.color> | null;

export type IconProps = Omit<React.HTMLProps<HTMLElement>, 'ref' | 'as' | 'color'> & {
  element: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: IconColor | null;
  size?: keyof typeof theme.icon.size;
  strokeColor?: IconColor;
  innerAria?: string;
};

export const Icon: React.FC<IconProps> = ({
  element,
  color,
  strokeColor,
  size = 24,
  innerAria,
  ...rest
}: IconProps) => {
  const iconColor = !isNil(color) ? (color ? get(theme.color, color) : '#000') : undefined;
  const iconStrokeColor = strokeColor ? get(theme.color, strokeColor) : undefined;
  const iconSize = theme.icon.size[size];
  const Component = element;
  return (
    <Styled.IconWrap $color={iconColor} $size={iconSize} $strokeColor={iconStrokeColor} {...rest}>
      <Component aria-label={innerAria} />
    </Styled.IconWrap>
  );
};
