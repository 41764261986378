import styled from 'styled-components';

import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

export const ScheduleFirstAppointmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
  height: 100%;
  padding: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  flex-grow: 1;
`;

export const Avatar = styled.img`
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
`;

export const CoachName = styled(Typography.Div)`
  margin-top: 16px;
`;

export const Description = styled(Typography.Div)`
  margin-top: 20px;
  text-align: center;
`;

export const Excited = styled(Typography.Div)`
  margin-top: 20px;
  text-align: center;
`;

export const SButton = styled(Button)`
  flex-basis: 50%;
`;

export const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
`;
