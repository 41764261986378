import { useMutation, useQuery } from '@apollo/client';
import { CREATE_GOAL_HISTORY, DELETE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_GOAL } from 'api/goal/goal.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as Personal } from 'assets/vectors/fi-rr-flag.svg';
import { format } from 'date-fns';
import { useTitle } from 'hooks/useTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { DATE_FORMAT } from 'utils/helpers';
import { findIncompleteSubgoal, getCompletionNumericalRange, getCompletionPercentage } from 'utils/helpers/goals';
import { Mixpanel } from 'utils/mixpanel';

import LeftLabelCheckbox from 'components/form/Checkbox/LeftLabelCheckbox/LeftLabelCheckbox';
import { Header } from 'components/layout/BaseLayout/Header';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';

import * as Styled from './CompletePersonalGoals.styles';

export const CompletePersonalGoals: React.FC = () => {
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  const { t } = useTranslation('translation', { keyPrefix: 'pages.personal' });
  const navigate = useNavigate();
  const [createGoalHistory, { loading: createloading }] = useMutation(CREATE_GOAL_HISTORY);
  const [deleteGoalHistory, { loading: deleteloading }] = useMutation(DELETE_GOAL_HISTORY);
  const { goalId } = useParams();
  const [currentGoalId, setCurrentGoalId] = useState('0');

  useEffect(() => {
    Mixpanel.track('Personal_Goal');
  }, []);

  const { data: goalData, loading: isLoading } = useQuery(GET_GOAL, {
    variables: { id: goalId, date: format(Date.now(), DATE_FORMAT) },
    fetchPolicy: 'network-only',
  });

  const title = useTitle({ isLoading, repeat: goalData?.goal?.repeat });
  const goalFrequency = goalData?.goal?.repeat;

  const subgoals = useMemo(
    () =>
      goalData?.goal?.subgoals?.map((subgoal) => {
        return {
          id: subgoal?.id,
          name: subgoal?.name,
          completed: subgoal?.is_completed_for_date,
          goalHistories: subgoal?.goal_histories,
        };
      }),
    [goalData],
  );

  type SubGoals = typeof subgoals;

  function findFirstDateWithinCurrentWeek(): string {
    const today: Date = new Date();
    const startOfWeek: Date = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek: Date = new Date(today.setDate(today.getDate() + 6));

    if (goalData?.goal?.goal_histories) {
      for (const goal of goalData.goal.goal_histories) {
        if (goal.completed_on) {
          const date = new Date(goal.completed_on);
          if (date >= startOfWeek && date <= endOfWeek) {
            return format(date, DATE_FORMAT);
          }
        }
      }
    }

    return ''; // If no date within the current week is found
  }

  function isSubgoalComplete(id: string, subGoals: SubGoals) {
    const subgoalCompleted = subGoals?.some((subgoal) => {
      return subgoal.id === id && subgoal.completed;
    });

    return subgoalCompleted;
  }

  const updateParentGoal = async (id: string) => {
    const gId = findIncompleteSubgoal(goalData?.goal)?.goalId;
    const subgoalId = findIncompleteSubgoal(goalData?.goal)?.subgoalId;
    if (gId && subgoalId === id) {
      try {
        await createGoalHistory({
          variables: {
            user_id: currentUserData?.currentUser?.id,
            goal_id: goalId,
            mark_parent_complete: true,
          },
          refetchQueries: [GET_GOAL],
        });
      } catch (error) {
        throw new Error(`Failed to update: ${error}`);
      }
    } else if (gId && !subgoalId) {
      let completed_on = goalData?.goal?.goal_histories.find((goal) => goal.completed_on)?.completed_on;
      if (goalFrequency) {
        if (goalFrequency === 'Once') {
          completed_on = goalData?.goal?.goal_histories.find((goal) => goal.completed_on)?.completed_on;
        }
        if (goalFrequency === 'Daily') {
          completed_on = goalData?.goal?.goal_histories.find(
            (goal) => goal.completed_on === format(new Date(), DATE_FORMAT),
          )?.completed_on;
        }
        if (goalFrequency === 'Weekly') {
          completed_on = findFirstDateWithinCurrentWeek();
        }
      }
      try {
        await deleteGoalHistory({
          variables: {
            id: goalId,
            completed_on: completed_on || format(Date.now(), DATE_FORMAT),
          },
          refetchQueries: [GET_GOAL],
        });
      } catch (error) {
        throw new Error(`Failed to update: ${error}`);
      }
    }
  };

  const isGoalComplete = (id: string, subGoals: SubGoals) => {
    return isSubgoalComplete(id, subGoals);
  };

  const addGoalHistory = async (id: string) => {
    try {
      await createGoalHistory({
        variables: {
          user_id: currentUserData?.currentUser?.id,
          goal_id: id,
          completed_on: format(Date.now(), DATE_FORMAT),
        },
        refetchQueries: [GET_GOAL],
      });
      updateParentGoal(id);
    } catch (error) {
      throw new Error(`Failed to update: ${error}`);
    }
  };

  const removeGoalHistory = async (id: string) => {
    try {
      await deleteGoalHistory({
        variables: {
          id: id,
          completed_on: format(Date.now(), DATE_FORMAT),
        },
        refetchQueries: [GET_GOAL],
      });
      updateParentGoal(id);
    } catch (error) {
      throw new Error(`Failed to update: ${error}`);
    }
  };

  const onChange = (id: string, subGoals: SubGoals) => {
    setCurrentGoalId(id);
    isSubgoalComplete(id, subGoals) ? removeGoalHistory(id) : addGoalHistory(id);
  };

  const renderSubgoals = () => {
    return (
      <>
        {subgoals?.map((goal) => (
          <Styled.GoalItem key={goal.id}>
            <LeftLabelCheckbox
              loading={(deleteloading || createloading) && currentGoalId === goal.id}
              label={goal.name || ''}
              checked={isGoalComplete(goal.id, subgoals) && (!createloading || !deleteloading || !isLoading)}
              onChange={() => onChange(goal.id, subgoals)}
            />
          </Styled.GoalItem>
        ))}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Styled.PageContainer>
          <Styled.HeaderContainer>
            <Header onBackClick={() => navigate(paths.homePage)} variant="back" title={title} />
          </Styled.HeaderContainer>
          <Styled.Content>
            <Styled.ContentHeader
              label={goalData?.goal?.name?.replace(/\d/g, '')}
              description={t('goalsCompleted', { range: getCompletionNumericalRange(goalData?.goal) })}
              icon={<Icon element={Personal} size={20} aria-hidden />}
              progress={getCompletionPercentage(goalData?.goal)}
            />
            {renderSubgoals()}
          </Styled.Content>
          <Styled.ViewMyProgress>
            <Button onClick={() => navigate('/progress/personal-goals')}>{t('viewMyProgress')}</Button>
          </Styled.ViewMyProgress>
        </Styled.PageContainer>
      )}
    </>
  );
};
