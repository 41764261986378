import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.color.other.linen};
`;

export const LoadingPageTitle = styled(Typography)`
  margin-bottom: 48px;
  margin-top: -32px;
`;

export const LoadingPageSubtitle = styled(Typography)`
  margin-top: 30px;
`;

export const LogoContainer = styled.div`
  margin-bottom: 24px;
`;
