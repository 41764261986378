import { gql } from '__generated__/gql';

export const GET_ACTIVITY_DATA = gql(`
  query getActivityData(
    $client_id: String,
    $start_range: String,
    $end_range: String
  ) {
    entries(
      client_id: $client_id
      type: "MetricEntry"
      category: "Steps"
      page_size: 500
      start_range: $start_range,
      end_range: $end_range
    ) {
      id
      metric_stat
      created_at
      updated_at
    }
  }
`);

export const GET_ACTIVITY_DATA_WITH_GOALS = gql(`
  query getActivityDataWithGoals (
    $date: String!
    $start_range: String,
    $end_range: String
  ) {
    entries(
      type: "MetricEntry"
      category: "Steps"
      page_size: 500
      start_range: $start_range,
      end_range: $end_range
    ) {
      id
      metric_stat
      created_at
      updated_at
    }
    currentUser {
      id
      active_care_plan {
        id
        name
        created_at
        goals {
          id
          name
          start_on
          due_date
          description
          is_completed_for_date(date: $date)
          goal_histories{
            id
            completed_on
          }
          subgoals {
            id
            name
            created_at
          }
        }
      }
    }
  }
`);

export const GET_FORM_BY_NAME = gql(`
  query getFormByName($user_id:String, $form_name: String) {
    formAnswerGroups(user_id: $user_id, name: $form_name) {
      id
      name
      form_answers{
        custom_module {
          id
        }
        label
        id
        displayed_answer
      }
    }
  }
`);
