import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - ${(props) => props.theme.nav.height});
  gap: 24px;
  padding: 12px 0 0 0;
  padding-bottom: 0;
  margin-bottom: calc(${(props) => props.theme.nav.height} + 40px);
`;

export const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px;
  margin-bottom: 0;
`;
