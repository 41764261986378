import styled from 'styled-components';

import { TextStyle } from './Typography.model';

type Props = {
  [K in keyof TextStyle as `$${K}`]: TextStyle[K];
} & {
  $color: string;
  $letterSpacing: string;
};

export const Component = styled.span<Props>`
  font-size: ${({ theme, $size }) => theme.font.size[$size]};
  font-weight: ${({ theme, $weight }) => theme.font.weight[$weight || 400]};
  line-height: ${({ theme, $lineHeight }) => theme.font.lineHeight[$lineHeight]};
  color: ${({ $color }) => $color};
  letter-spacing: ${({ $letterSpacing }) => $letterSpacing};
`;
