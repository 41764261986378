/* eslint-disable @typescript-eslint/no-explicit-any */
import mixpanel from 'mixpanel-browser';

const hasMixpanel = !!process.env.REACT_APP_MIXPANEL_TOKEN;
const init = () => {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
};
const actions = {
  identify: (id: string): any => {
    if (hasMixpanel) {
      init();
      mixpanel.identify(id);
    }
  },
  track: (name: string, props?: object): any => {
    if (hasMixpanel) {
      init();
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props: object): any => {
      if (hasMixpanel) {
        init();
        mixpanel.people.set(props);
      }
    },
  },
  track_pageview: (props?: object): any => {
    if (hasMixpanel) {
      init();
      // @ts-expect-error @types/mixpanel-browser should be updated to mixpanel-browser v.2.47 that includes track_pageview()
      mixpanel.track_pageview(props);
    }
  },
};

export const Mixpanel = actions;
