import { ReactComponent as Tick } from 'assets/vectors/fi-rr-cross-done.svg';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { onEnterKey } from 'utils/helpers';

import * as Styled from './Checkbox.styles';

type CheckboxProps = Omit<React.HTMLProps<HTMLInputElement>, 'label' | 'ref' | 'as'> & {
  label: React.ReactNode;
};

export const Checkbox: React.FC<CheckboxProps> = ({ name, label, disabled, ...rest }) => {
  return (
    <Field name={name} type="checkbox" disabled={disabled}>
      {({ field, form }: FieldProps) => {
        return (
          <Styled.Label>
            <Styled.Input
              type="checkbox"
              {...rest}
              {...field}
              disabled={disabled}
              onChange={(e) => {
                // field.onChange is always present, rest.onChange only when we set it
                // no way to override field.onChange now, maybe something to do later if needed
                rest.onChange?.(e);
                field.onChange?.(e);
              }}
              onKeyDown={(e) => {
                onEnterKey(
                  e,
                  () => {
                    rest.onChange?.(e);
                    field.onChange?.(e);
                    form.setFieldValue(field.name, !field.value);
                  },
                  true,
                );
              }}
            />
            {label}
            <Styled.TickIcon element={Tick} color="other.white" size={20} />
          </Styled.Label>
        );
      }}
    </Field>
  );
};
