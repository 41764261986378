import styled from 'styled-components';

export const ReadingsContainer = styled.div`
  margin-top: 24px;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 74px;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  text-align: end;
`;
